import React, { useEffect, useRef, useState } from 'react';
import { dotsthree, goaldisableIcon, goalenableIcon, refreshIcon } from 'res/images';
import { Button, Popover, Whisper } from 'rsuite';
import { useNavigate } from 'react-router-dom';
import { dateFormat, timeAgo, timeFormat } from '../Helper';
import {
  useDeleteSegmentDataMutation,
  useExportSegmentMutation,
  useGetSegmentCountMutation,
  useGetSegmentDataQuery,
} from 'redux/segmentApis';
import { Loader } from '../index';
import { handleResult } from '../Helper';
import CommonToolTip from '../CommonToolTip';
import useApiManager from 'networking/ApiManager';

function SegmentListItem(props) {
  const { segment, segmentIndex, setAlerts, currentPage, refetchApi } = props;
  const navigate = useNavigate();
  const [deleteSegmentData, deleteResult] = useDeleteSegmentDataMutation();
  const [notifyEnable, setNotifyEnable] = useState(segment.notify_daily || false);
  const [exportData, exportResult] = useExportSegmentMutation();
  const [countData, countResult] = useGetSegmentCountMutation();
  const [isLoadingPopOver, setIsLoadingPopover] = useState(false);
  const [isRegister, setIsRegister] = useState(segment.notify_daily || false);

  const segmentId = segment.id;
  const ApiClient = useApiManager();
  const ref = useRef();
  useEffect(() => {
    countResult.reset();
  }, [segment]);

  useEffect(() => {
    if (exportResult?.data?.data && exportResult?.data?.status < 300 && exportResult?.data?.status >= 200) {
      exportToCsv(exportResult?.data?.data);
    }

    handleResult(exportResult, 'Segment exported successfully', setAlert, setAlertType);
  }, [exportResult]);

  useEffect(() => {
    handleResult(deleteResult, 'Segment Deleted successfully', setAlert, setAlertType);
  }, [deleteResult]);

  useEffect(() => {
    handleResult(countResult, '', setAlert, setAlertType);
  }, [countResult]);

  const setAlert = (alert) => {
    setAlerts((prev) => ({ ...prev, message: alert }));
  };

  const setAlertType = (type) => {
    setAlerts((prev) => ({ ...prev, type: type }));
  };

  const exportToCsv = (data) => {
    const csvContent = 'data:text/csv;charset=utf-8,' + data;
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `segment-${new Date()}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const actionsSheetItems = [
    {
      msg: 'Edit',
      icon: 'edit',
      action: (segmentId) => {
        navigate(`/segments/${segmentId}/edit`, { state: { currentPage } });
      },
    },
    {
      msg: 'Delete',
      icon: 'delete',
      action: (segmentId) => {
        const confirmdelete = window.confirm('Are you sure you want to delete?');
        if (confirmdelete) {
          deleteSegmentData(segmentId);
        }
      },
    },
    {
      msg: 'Export',
      icon: 'send_time_extension',
      action: (segmentId) => {
        exportData(segmentId);
      },
    },
  ];

  const createNotify = () => {
    setIsLoadingPopover(true);
    ApiClient.createNotify(segmentId)
      .then((res) => {
        setIsLoadingPopover(false);
        handleResult(refetchApi, res?.data?.message || 'Notified created successfully', setAlert, setAlertType);
        refetchApi?.refetch();
        ref.current.close();
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          setAlert(err?.response?.data?.message);
          setAlertType('alert-danger');
        }
        setIsLoadingPopover(false);
        ref.current.close();
      });
  };

  const createRegisterEvent = () => {
    setIsLoadingPopover(true);
    ApiClient.createRegisterEvent(segmentId)
      .then((res) => {
        setIsLoadingPopover(false);
        handleResult(refetchApi, res?.data?.message || 'Register event created successfully', setAlert, setAlertType);
        refetchApi?.refetch();
        ref.current.close();
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          setAlert(err?.response?.data?.message);
          setAlertType('alert-danger');
        }
        setIsLoadingPopover(false);
        ref.current.close();
      });
  };

  const updateNotify = () => {
    setIsLoadingPopover(true);
    const params = { notify_daily: !notifyEnable };
    ApiClient.updateNotify(segmentId, params)
      .then((res) => {
        setNotifyEnable(!notifyEnable);
        setIsLoadingPopover(false);
        setIsRegister(res.data.notify_daily);
        handleResult(
          refetchApi,
          res.data.notify_daily
            ? 'Daily Notification Enabled successfully'
            : 'Daily Notification disabled successfully',
          setAlert,
          setAlertType
        );
        refetchApi?.refetch();
        ref.current.close();
      })
      .catch((err) => {
        setIsLoadingPopover(false);
        ref.current.close();
      });
  };

  const DefaultPopover = React.forwardRef(({ content, ...props }, ref) => {
    return (
      <Popover ref={ref} {...props}>
        <div className='Segmentpopover-list'>
          {segment.is_registered && (
            <span
              className='pointer '
              onClick={() => {
                createNotify();
              }}
            >
              Push Immediate
            </span>
          )}
          {!segment.is_registered ? (
            <span
              className='pointer'
              onClick={() => {
                createRegisterEvent();
                setNotifyEnable(true);
              }}
            >
              {/* <img src={refreshIcon} /> */}
              Register Event
            </span>
          ) : segment.notify_daily ? (
            <span
              className='pointer '
              onClick={() => {
                const windowConfirm = window.confirm('Are you sure you want to enable notify ?');
                if (windowConfirm) {
                  updateNotify();
                }
              }}
            >
              <img className='pointer me-2' src={goalenableIcon} />
              Push Daily
            </span>
          ) : (
            <span
              className='pointer'
              onClick={() => {
                const windowConfirm = window.confirm('Are you sure you want to disable notify ?');
                if (windowConfirm) {
                  updateNotify();
                }
              }}
            >
              <img className='pointer me-2' src={goaldisableIcon} />
              Push Daily
            </span>
          )}
        </div>
      </Popover>
    );
  });

  const CustomComponent = (icon, segment) => (
    <Whisper
      trigger='click'
      placement={'auto'}
      controlId={`control-id`}
      ref={ref}
      speaker={<DefaultPopover item={segment} />}
    >
      <Button>
        <img src={icon} />
      </Button>
    </Whisper>
  );

  const renderActionSheetItems = () => {
    return actionsSheetItems.map((item, index) => {
      return (
        <CommonToolTip
          placement={'top'}
          key={index}
          item={
            <div
              onClick={() => {
                item.action(segmentId);
              }}
              className='goalPopover segmentlist-icon pointer mt-1 ms-2'
            >
              <span className='material-icons'>{item.icon}</span>
            </div>
          }
          showValue={item.msg}
        />
      );
    });
  };

  const ActionSheet = React.forwardRef(({ style, onClose, ...rest }, ref) => {
    const styles = {
      ...style,
      color: '#000',
      background: '#fff',
      padding: 15,
      borderRadius: 4,
      position: 'absolute',
      border: '1px solid #ddd',
      boxShadow: '0 3px 6px -2px rgba(0, 0, 0, 0.6)',
    };

    return (
      <div {...rest} style={styles} ref={ref}>
        {renderActionSheetItems()}
      </div>
    );
  });

  return (
    <tr className='borderBox'>
      <td>{segmentIndex + 1 + (currentPage - 1) * 10}</td>
      <td>{segment.title}</td>
      <td>
        {countResult?.data?.data?.query_count >= 0 || segment.count ? (
          <div className='d-flex'>
            <div className='d-flex flex-column'>
              <div className='d-flex'>
                Count : {countResult?.data?.data?.query_count ?? segment.count}
                <CommonToolTip
                  placement={'top'}
                  item={
                    <span
                      className='pointer ms-2'
                      onClick={() => {
                        countData(segment.id);
                      }}
                    >
                      <img src={refreshIcon} />
                    </span>
                  }
                  showValue='Refresh'
                />
              </div>
              <div className='font-12px'>
                Last fetch at :{' '}
                {dateFormat(countResult?.data?.data?.last_fetch_at || segment.last_fetch_at) +
                  ' ' +
                  timeFormat(countResult?.data?.data?.last_fetch_at || segment.last_fetch_at)}
              </div>
            </div>
          </div>
        ) : (
          <div className='d-flex'>
            <span
              className='pointer'
              onClick={() => {
                countData(segment.id);
              }}
            >
              Count
            </span>
            <CommonToolTip
              placement={'top'}
              item={
                <span
                  className='pointer ms-2'
                  onClick={() => {
                    countData(segment.id);
                  }}
                >
                  <img src={refreshIcon} />
                </span>
              }
              showValue='Refresh'
            />
          </div>
        )}
      </td>
      <td>
        {segment?.admin?.full_name || ''} <br />
        <span className='font-12px'>
          {dateFormat(segment?.created_at) || ''} &nbsp;
          {timeFormat(segment?.created_at) || ''}
        </span>
      </td>
      <td className='text-center'>
        <div className='d-flex justify-content-center'>
          <div className='d-flex justify-content-center'>{renderActionSheetItems()}</div>
          <div className='SegmentAction'>{CustomComponent(dotsthree, segment)} </div>
        </div>

        {/* <Whisper
          placement="auto"
          controlId="control-id-with-dropdown"
          trigger="click"
          speaker={(props, ref) => {
            const { className, left, top, onClose } = props;
            return (
              <ActionSheet style={{ left, top }} onClose={onClose} className={className} ref={ref} id={segment.id} />
            );
          }}
        >
          <Button>
            <img src={dotsthree} />
          </Button>
        </Whisper> */}
      </td>
      {!isLoadingPopOver ? (
        <Loader show={deleteResult.isLoading || countResult.isLoading || exportResult.isLoading} />
      ) : (
        <Loader show={isLoadingPopOver} />
      )}
    </tr>
  );
}

export default SegmentListItem;
