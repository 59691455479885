import React from 'react';
import { CoreButton, CoreInputBox } from 'whealth-core-web/components';

function SettingsForm(props) {
  const { placeholder, value, error, isUpdate, setValue, label, required, onClick } = props;
  return (
    <div>
      <CoreInputBox
        showMSG={error}
        placeholder={placeholder}
        value={value}
        label={label}
        setValue={setValue}
        retuired
        msgStyle={{ color: 'red' }}
        maxLength={255}
      />
      <CoreButton onClick={onClick} title={isUpdate ? 'Update' : 'Save'} />
    </div>
  );
}

export default SettingsForm;
