import { datePickerToolbarClasses } from '@mui/x-date-pickers';
import useApiManager from 'networking/ApiManager';
import React, { useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DatePicker } from 'rsuite';
import { dateFormat, dateFormatStringSingleDate, timeFormat } from './Helper';

function DateCards(props) {
  const {
    data,
    getPatientData,
    index,
    isTimeShow,
    isEditable,
    title,
    value,
    calendarAlign,
    nextCheckIn,
    prescriptionData,
    previousDateEnable,
  } = props;
  const [inputDataObj, setinputDataObj] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const ApiClient = useApiManager();
  const inputRef = useRef();
  const { id } = useParams();
  const navigate = useNavigate();

  const submitQuestionAnswers = () => {
    let inputDataArr = inputDataObj.filter((item) => !!item && item);
    setIsLoading(true);
    ApiClient.createProfileAnswers(id, inputDataArr)
      .then((res) => {
        setIsLoading(false);
        setinputDataObj([]);
        getPatientData();
        navigate(`/patient/${id}/dashboard`);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // const submitNextCheckIn = (date, item) => {
  //   let inputDataArr = inputDataObj.filter((item) => !!item && item);
  //   const params = {
  //     name: 'next_checkin_date',
  //     type: 'date',
  //     label: 'Next Follow Up Date',
  //     order: 9,
  //     answer: date,
  //   };
  //   setIsLoading(true);
  //   ApiClient.createProfileAnswers(id, item.id, params)
  //     .then((res) => {
  //       setIsLoading(false);
  //       setinputDataObj([]);
  //       getPatientData();
  //       navigate(`/patient/${id}/dashboard`);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       console.log(err);
  //     });
  // };

  const renderConsultantDate = (item) => {
    return (
      <div className='hideCalender'>
        <DatePicker
          placement={calendarAlign || 'bottomStart'}
          ref={inputRef}
          format='yyyy-MM-dd HH:mm'
          placeholder='YYYY-MM-DD HH:MM'
          disabledDate={(date) => {
            if (new Date(date) < new Date().setHours(0, 0, 0) && !previousDateEnable) {
              return true;
            } else {
              return false;
            }
          }}
          onChange={(data) => {
            let questionObj = {
              profile_question_id: item.profile_question_id,
              answer: isTimeShow ? data : dateFormatStringSingleDate(data),
            };
            inputDataObj[index] = questionObj;
            setinputDataObj([...inputDataObj]);
          }}
          onOk={(date) => {
            const windowConfirm = window.confirm('Are you sure you want to update?');
            if (windowConfirm) {
              submitQuestionAnswers(date);
            }
          }}
        />
      </div>
    );
  };

  return (
    <div className='d-flex renewalDate flex-column pe-3 mb-3'>
      <div className='d-flex d-flex justify-content-between w-100 '>
        <div className='d-block capitalize detailsHeading'>{title}</div>
        {isEditable && renderConsultantDate(data)}
      </div>
      {value && (
        <div className='detailsDescription'>
          {dateFormat(value) ?? value} {isTimeShow && timeFormat(value)}
        </div>
      )}
    </div>
  );
}

export default DateCards;
