import { AdminPanelHeader } from 'components';
import React, { useMemo } from 'react';

function SideBarReport() {
  let { REACT_APP_METABASE_URL } = process.env;
  const renderHeader = useMemo(() => {
    return (
      <div className='headerFiles'>
        <AdminPanelHeader hideSearch iconTitle='notifications' title='Notification' />
      </div>
    );
  }, []);
  return (
    <div className='wrapper'>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader hideSearch iconTitle='analytics' title='Report' />
        </div>
        <div className='hrLine mb-3' />
        <div style={{ border: '1 px solid black' }}>
          <iframe src={REACT_APP_METABASE_URL} frameborder='0' width='100%' height='800' allowtransparency></iframe>
        </div>
      </div>

      {/* {App()} */}
    </div>
  );
}

export default SideBarReport;
