import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { editIcon, femaleUser, maleUser, warningBadge } from 'res/images';
import CoreButton from './CoreButton';
import './showTeamMembersList.scss';
import { profileImage, teamRoles } from 'whealth-core-web/components/Helper.js';

function ShowTeamMembersList(props) {
  const { data, changeStatus } = props;
  const navigate = useNavigate();

  const dateFormat = (data) => {
    const newDate = new Date(data);

    const date = newDate.getDate();
    const month = newDate.toLocaleString('default', { month: 'short' });
    const year = newDate.getFullYear();

    return `${date} ${month} ${year}`;
  };

  const userCard = (data) => {
    return (
      <div className='w-100 d-flex userImageView'>
        <div className='userImage'>
          <img className='listUserImage' src={profileImage(data.gender, data.profile_url)} />
          <div className={`statusDot borderWhite ${data?.status?.title?.toLowerCase()?.replaceAll(' ', '')}`} />
        </div>
        <div className='linesView'>
          <div
            className='userName'
            onClick={() => {
              navigate(`/team/${data.id}/edit`, {
                state: { data },
              });
            }}
          >
            {data?.full_name}
          </div>
          <div className='bottomTextView'>{data.phone}</div>
        </div>
      </div>
    );
  };

  const roleView = (data) => {
    return (
      <div className='linesView'>
        <div className={`badge capitalize ${data?.status?.title?.toLowerCase()?.replaceAll(' ', '')} ${data?.role} `}>
          {teamRoles(data?.role)}
          {data?.status?.title && <span> · {data?.status?.title?.replaceAll('_', ' ')}</span>}
        </div>
        <div className='bottomTextView'>Member Since · {dateFormat(data.created_at)} </div>
      </div>
    );
  };

  const languageView = (data) => {
    return (
      <div className='linesView'>
        <div className='badge capitalize lightGrayBadge'>Assign Patients · {data.assigned_patient_count || 'NA'} </div>
        <div className='bottomTextView capitalize'>{data?.language?.replaceAll(',', ', ')}</div>
      </div>
    );
  };

  const editBtnView = (data) => {
    return (
      <div className='linesView'>
        <div>
          <CoreButton
            onClick={() => navigate(`/team/${data.id}/edit`)}
            customClass='editBtn'
            icon={editIcon}
            title='Edit Profile'
          />
        </div>
      </div>
    );
  };

  const renderData = useMemo(() => {
    return (
      <div className='table-responsive'>
        <table className='w-100'>
          <tbody>
            {data &&
              data.map((item, index) => {
                return (
                  <tr
                    onClick={() => {
                      item.status == 'in_active' && navigate(`/team/${item.id}/edit`);
                    }}
                    key={index}
                    className={`userFullCard ${item.status == 'in_active' && 'usersDivDisable'}`}
                  >
                    <td> {userCard(item)}</td>
                    <td>{roleView(item)}</td>
                    <td>{languageView(item)}</td>
                    <td> {editBtnView(item)}</td>
                    {item.status == 'in_active' && <td className='usersDivDisable' />}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }, [data]);

  return <div className='showTeamMembersList'>{renderData}</div>;
}

export default ShowTeamMembersList;
