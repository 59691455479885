import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'rsuite';
import { CoreButton } from '..';
import Select2DropDown from '../common/Select2DropDown';
import CommonBorderBox from '../CommonBorderBox';
import CoreInputBox from '../CoreInputBox';
import CoreTextArea from '../CoreTextArea';
import Loader from '../Loader';
import Pagination from '../Pagination';
import NotesTemplateList from './NotesTemplateList';

function NotesTemplate(props) {
  const { isLoading, setAlertType, setIsShowAlert, setIsLoading } = props;
  const [isEditMode, setEditMode] = useState(false);
  const { id } = useParams();
  const [errorInputValues, setErrorInputValues] = useState(' ');
  const [notesTemplate, setNotesTemplate] = useState([]);
  const [categories, setCategories] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchInputTemplate, setSearchInputTemplate] = useState('');
  const [content, setContent] = useState('');
  const [titleReal, setTitleReal] = useState('');
  const [noteCategory, setNoteCategory] = useState('');
  const [selectedCatObj, setSelectedCatObj] = useState({});

  const ApiClient = useApiManager();
  useEffect(() => {
    let timeOut;
    timeOut = setTimeout(() => {
      if (searchInputTemplate?.trim()?.length > 2) {
        searchNotesTemplate();
      }
    }, 300);
  }, [searchInputTemplate]);

  useEffect(() => {
    if (titleReal?.length > 0 || selectedCatObj.title?.length > 0) {
      setErrorInputValues(false);
    }
  }, [titleReal, selectedCatObj]);

  useEffect(() => {
    let timeOut;
    timeOut = setTimeout(() => {
      if (!searchInputTemplate || searchInputTemplate?.trim()?.length == 0) {
        getNotesTemplate();
      }
    }, 300);
  }, [currentPage, searchInputTemplate?.trim()?.length == 0]);

  useEffect(() => {
    notesCategoryGet();
  }, []);

  const searchCategory = (searchQuery) => {
    ApiClient.searchNotesCategory(searchQuery)
      .then((res) => {
        setCategories(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const notesCategoryGet = () => {
    setIsLoading(true);
    ApiClient.notesCategoryGet(1)
      .then((res) => {
        setCategories(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const searchNotesTemplate = () => {
    setIsLoading(true);
    ApiClient.searchNotesTemplate(searchInputTemplate)
      .then((res) => {
        setTotalPages(1);
        setIsLoading(false);
        setNotesTemplate(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getNotesTemplate = async () => {
    setIsLoading(true);
    let page = { page: currentPage };
    await ApiClient.notesTemplateGet(page).then((res) => {
      setTotalPages(res.headers['total-pages']);
      setIsLoading(false);
      setNotesTemplate(res.data);
    });
    setIsLoading(false);
  };

  const createNotesTemplate = () => {
    const params = {
      content: content,
      category_id: noteCategory,
      title: titleReal,
    };
    ApiClient.createNotesTemplate(params)
      .then((res) => {
        setIsLoading(false);
        getNotesTemplate();
        setContent('');
        setTitleReal('');
        setNoteCategory('');
        setSelectedCatObj('');
        setIsShowAlert('Notes template created successfully');
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail);
        setErrorInputValues(err.response.data.errors);
      });
  };

  const updateNotesTemplate = (
    content,
    titleReal,
    notesUpdateId,
    catId,
    closeModal,
    setShowAlert,
    setAlertTypes,
    setErrors
  ) => {
    const data = {
      content: content,
      title: titleReal,
      category_id: catId,
    };
    ApiClient.updateNotesTemplate(data, notesUpdateId)
      .then((res) => {
        setIsLoading(false);
        getNotesTemplate();
        setEditMode(false);
        setAlertType('alert-success');
        setIsShowAlert('Notes template updated successfully');
        closeModal({});
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertTypes('alert-danger');
        setShowAlert(err.response.data.detail);
        setErrors(err.response.data.errors);
      });
  };

  const deleteData = async (id) => {
    setErrorInputValues({});
    setIsLoading(true);
    await ApiClient.deleteNotesTemplate(id)
      .then((res) => {
        getNotesTemplate();
        setAlertType('alert-success');
        setIsShowAlert('Notes template Deleted successfully');
      })
      .catch((err) => {
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail);
      });
    setIsLoading(false);
  };

  const renderTitle = () => {
    return (
      <div className='mb-3'>
        <CoreInputBox
          maxLength={30}
          allowStirngAndForwardSlashAndSpacialChar
          showMSG={errorInputValues?.title}
          placeholder='Title'
          value={titleReal}
          label='title'
          setValue={setTitleReal}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
    );
  };

  const renderContent = () => {
    return (
      <CoreTextArea
        rows={5}
        label='Content'
        showMSG={errorInputValues?.content}
        value={content}
        setValue={setContent}
        msgStyle={{ color: 'red' }}
      />
    );
  };

  return (
    <div className='mb-3'>
      <CommonBorderBox className='settingFormOutline'>
        <div className='formTitle'>Add Notes template</div>
        <div className='form-group my-4'>
          <div className='mb-3'>
            <Select2DropDown
              data={
                !categories.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id
                  ? [...categories, selectedCatObj]
                  : categories
              }
              placeholder='Select Category'
              label='Select Category'
              retuired={true}
              labelStyle={{ fontWeight: 'var(--lightFont)' }}
              value={noteCategory}
              labelKey={'title'}
              valueKey={'id'}
              showMSG={errorInputValues?.category_id}
              getObjData
              onSelect={(val, data) => {
                setNoteCategory(val);
                setSelectedCatObj(data);
              }}
              isValidation
              onSearch={(srcVal) => {
                searchCategory(srcVal);
              }}
            />
          </div>
          {renderTitle()}
          {renderContent()}
          <div>
            <Button
              className='form-control coreBtn text-white btn btn-secondary btn-md tabClickBtn'
              onClick={createNotesTemplate}
            >
              Save
            </Button>
          </div>
        </div>
        <Loader show={isLoading} />
        <Pagination
          seterror={setErrorInputValues}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </CommonBorderBox>
      <div className='table-responsive'>
        <NotesTemplateList
          selectedCatObj={selectedCatObj}
          setSelectedCatObj={setSelectedCatObj}
          searchInputTemplate={searchInputTemplate}
          setSearchInputTemplate={setSearchInputTemplate}
          errorInputValues={errorInputValues}
          notesTemplate={notesTemplate}
          deleteData={deleteData}
          setEditMode={setEditMode}
          isEditMode={isEditMode}
          content={content}
          setContent={setContent}
          setTitleReal={setTitleReal}
          setNoteCategory={setNoteCategory}
          titleReal={titleReal}
          searchCategory={searchCategory}
          noteCategory={noteCategory}
          categories={categories}
          btnTitle={'Update'}
          handleOnClick={updateNotesTemplate}
          setErrorInputValues={setErrorInputValues}
        />
      </div>
    </div>
  );
}

export default NotesTemplate;
