import React from 'react';
import CommonBorderBox from '../CommonBorderBox';
import CoreButton from '../CoreButton';
import CoreInputBox from '../CoreInputBox';

function CreateResource(props) {
  const { inputFileValues, errorInputValues, handleOnChanges, btnTitle, handleOnClick, setErrorInputValues } = props;
  return (
    <CommonBorderBox>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={errorInputValues?.title}
          placeholder='Title'
          value={inputFileValues.title || ''}
          label='title'
          setValue={(data) => {
            handleOnChanges({ ...inputFileValues, title: data });
            setErrorInputValues({ ...errorInputValues, title: '' });
          }}
          msgStyle={{ color: 'red' }}
          retuired
        />
        <CoreButton
          onClick={() => {
            handleOnClick();
          }}
          title={btnTitle}
        />
      </div>
    </CommonBorderBox>
  );
}

export default CreateResource;
