import React from 'react';

function useDigits() {
  let value;

  const onlyNumbers = (input, setInput, maxLength, type) => {
    if (type == 'number') {
      value = input.replace(/[^0-9]/g, '');
    }
    value = input.substring(0, maxLength);
    setInput(value);
  };

  return { onlyNumbers };
}

export default useDigits;
