import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { add, upload, uploaded } from 'res/images';
import { CommonAlert, Loader, Pagination } from 'whealth-core-web/components';
import { dateFormat, pdfData, pdfDataDirectDownload } from 'whealth-core-web/components/Helper';
import DietplanList from 'whealth-core-web/components/patient/DietplanList';
import { useDispatch } from 'react-redux';

function RenderDietPlan(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [dietplanList, setDietplanList] = useState([]);
  const [pdfModal, setPdfModule] = useState(false);
  const [pdfModalDownload, setPdfModuleDownload] = useState(false);

  const [pdfUrl, setPdfUrl] = useState('');
  const [printItem, setprintItem] = useState({});

  const ApiClient = useApiManager();
  const { id } = useParams();
  const dispatch = useDispatch();
  const { newQuestions } = props;

  useEffect(() => {
    getListData();
  }, [id, currentPage]);

  const closeModalPdf = (item) => {
    setPdfModule(!pdfModal);
  };
  const closeModalPdfDownload = (item) => {
    setPdfModuleDownload(!pdfModalDownload);
  };

  const getListData = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.getDietPlanTemplateList(id, params)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setDietplanList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPdf = (item, index) => {
    setIsLoading(true);
    ApiClient.dietPlansPdfDownload(id, item.id)
      .then((res) => {
        setIsLoading(false);
        pdfDataDirectDownload(
          res.data,
          `EN Diet Plan - ${dietplanList[index]?.admin?.full_name} - ${dateFormat(dietplanList[index]?.created_at)}`
        );
        setPdfModuleDownload(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const openModalPdfDownload = (item) => {
    setprintItem(item);
    if (!pdfModal) {
      setIsLoading(true);
      ApiClient.dietPlansPdfDownload(id, item.id)
        .then((res) => {
          setIsLoading(false);
          var blob = new Blob([res.data], { type: 'application/pdf' }); //this make the magic
          var blobURL = URL.createObjectURL(blob);
          setPdfUrl(blobURL);
          setPdfModuleDownload(!pdfModalDownload);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const openModalPdf = (item) => {
    setprintItem(item);
    if (!pdfModal) {
      setIsLoading(true);
      ApiClient.dietPlansPdfDownload(id, item.id)
        .then((res) => {
          setIsLoading(false);
          var blob = new Blob([res.data], { type: 'application/pdf' }); //this make the magic
          var blobURL = URL.createObjectURL(blob);
          setPdfUrl(blobURL);
          setPdfModule(!pdfModal);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };

  const handlePublishAndPrint = () => {
    const windowConfirm = window.confirm(`Are you sure, you want to publish Diet Plan?`);
    if (windowConfirm) {
      ApiClient.DietPlanStatusPublish(id, printItem.id)
        .then((res) => {
          setIsLoading(false);
          getListData();
        })
        .catch((err) => {
          setIsLoading(false);
        });
      setPdfModule(false);
    }
  };

  const dietPlanDelete = (dietPlanid) => {
    setIsLoading(true);
    ApiClient.deleteDietsPlan(id, dietPlanid)
      .then((res) => {
        setIsLoading(false);
        getListData();
        console.log(res);
        setIsShowAlert('Diet Plan deleted successfully.');
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.message);
        }
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
        console.log(err);
      });
  };

  const statusPublish = (item) => {
    setIsLoading(true);
    ApiClient.DietPlanStatusPublish(id, item.id)
      .then((res) => {
        setIsLoading(false);
        getListData();
        setIsShowAlert('Diet Plan Published Successfully.');
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const dietPlanClone = (question_id) => {
    setIsLoading(true);
    ApiClient.duplicateDietplan(id, question_id)
      .then((res) => {
        getListData();
        setIsLoading(false);
        setIsShowAlert('Diet Plan Duplicated Successfully.');
        setAlertType('alert-success');
        // navigate(`/patient/${id}/questions/${res.data.id}/edit`);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertType={alertType} />
      <div className='d-flex'>
        <div className='pt-3'>
          <button onClick={newQuestions} type='button' className='btn mt-3 btn-outline-primary'>
            <img src={add} className='icon' /> Add Diet Plan
          </button>
        </div>
      </div>
      <Loader show={isLoading} />
      <DietplanList
        getPdf={getPdf}
        openModalPdf={openModalPdf}
        handlePublishAndPrint={handlePublishAndPrint}
        statusPublish={statusPublish}
        dietplanList={dietplanList}
        dietPlanDelete={dietPlanDelete}
        dietPlanClone={dietPlanClone}
        closeModalPdf={closeModalPdf}
        closeModalPdfDownload={closeModalPdfDownload}
        pdfModalDownload={pdfModalDownload}
        pdfModal={pdfModal}
        pdfUrl={pdfUrl}
        openModalPdfDownload={openModalPdfDownload}
      />
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </div>
  );
}

export default RenderDietPlan;
