import React from "react";
import CommonBorderBox from "../CommonBorderBox";
import CoreButton from "../CoreButton";
import CoreInputBox from "../CoreInputBox";

function AdherenceCreateForm(props) {
  const { inputFileValues, errorInputValues, handleOnChanges, btnTitle, handleOnClick, setErrorInputValues } = props;
  return (
    <CommonBorderBox>
      <div style={{ margin: "20px 0" }}>
        <CoreInputBox
          showMSG={errorInputValues?.title}
          placeholder="Title"
          value={inputFileValues.title || ""}
          label="title"
          setValue={(data) => {
            handleOnChanges({ ...inputFileValues, title: data });
            setErrorInputValues({ ...errorInputValues, title: "" });
          }}
          msgStyle={{ color: "red" }}
          retuired
        />
      </div>
      <div style={{ margin: "20px 0", gap: "10px" }} className="d-flex w-100 justify-content-between medicineNamerow">
        <div className="w-100">
          <CoreInputBox
            validateNumbers
            showMSG={errorInputValues?.start_range}
            placeholder="Start Range"
            value={inputFileValues.start_range}
            label="Start Range"
            setValue={(data) => {
              handleOnChanges({ ...inputFileValues, start_range: data });
              setErrorInputValues({ ...errorInputValues, start_range: "" });
            }}
            msgStyle={{ color: "red" }}
            retuired
          />
        </div>
        <div className="w-100">
          <CoreInputBox
            validateNumbers
            showMSG={errorInputValues?.end_range}
            placeholder="End Range "
            value={inputFileValues.end_range || ""}
            label="End Range"
            setValue={(data) => {
              handleOnChanges({ ...inputFileValues, end_range: data });
              setErrorInputValues({ ...errorInputValues, end_range: "" });
            }}
            msgStyle={{ color: "red" }}
            retuired
          />
        </div>
      </div>
      <CoreButton
        onClick={() => {
          handleOnClick();
        }}
        title={btnTitle}
      />
    </CommonBorderBox>
  );
}

export default AdherenceCreateForm;
