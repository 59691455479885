import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { add, editIconY, uploaded } from 'res/images';
import { Modal } from 'rsuite';
import PrescriptionActivityCreate from 'whealth-core-web/forms/PrescriptionActivityCreate';
import CoreButton from './CoreButton';
import { titlizeString, profileImage, dateFormat, dayTime, timeFormat, teamRoles, scrollToTop } from './Helper';
import Loader from './Loader';
import './prescription.css';
import { Pagination } from 'whealth-core-web/components';
import CommonToolTip from './CommonToolTip';

function RenderSummaryTab(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { newSummary } = props;
  const { id } = useParams();
  const ApiClient = useApiManager();
  const navigate = useNavigate();
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [prescriptionData, setPrescriptionData] = useState([]);

  useEffect(() => {
    getListData();
  }, [id, currentPage]);

  const getListData = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.prescriptionMDTSummeryList(id, params)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setPrescriptionData(res.data);
        scrollToTop();
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  //Set Summary as published
  const statusPublish = (item) => {
    setIsLoading(true);
    ApiClient.mdtSUmmaryPublish(id, item.id)
      .then((res) => {
        setIsLoading(false);
        getListData();
      })
      .catch((err) => {
        setIsLoading(false);
      });

    let updatedList = [];
    prescriptionData.map((obj) => {
      if (obj.id !== item.id) updatedList.push({ ...obj, status: 'unpublished' });
      else updatedList.push({ ...obj, status: 'published' });
    });
    setIsLoading(false);
  };

  const statusUnpublished = (item) => {
    setIsLoading(true);
    ApiClient.mdtSUmmaryUnpublish(id, item.id)
      .then((res) => {
        setIsLoading(false);
        getListData();
      })
      .catch((err) => {
        setIsLoading(false);
      });

    let updatedList = [];
    prescriptionData.map((obj) => {
      updatedList.push({ ...obj, status: 'unpublished' });
    });
    setIsLoading(false);
  };

  const handleModalClose = () => {
    getListData();
    setOpen(false);
  };

  const customGoalActivityModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={() => handleModalClose()}>
          <Modal.Header />
          <PrescriptionActivityCreate handleSubmit={handleModalClose} setLoading={setIsLoading} data={open} />
        </Modal>
      </div>
    );
  };

  const getPdf = (item) => {
    setIsLoading(true);
    const date = new Date();
    ApiClient.summaryPdfDownload(id, item.id)
      .then((res) => {
        setIsLoading(false);
        var blob = new Blob([res.data], { type: 'application/pdf' });
        var blobURL = URL.createObjectURL(blob);
        let iframe = document.createElement('iframe');
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const prescriptionSummaryDelete = (questionId) => {
    setIsLoading(true);
    ApiClient.prescriptionMDTSummeryDelete(id, questionId)
      .then((res) => {
        setIsLoading(false);
        getListData();
        setIsShowAlert('Summary deleted successfully.');
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.message);
        }
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
        console.log(err);
      });
  };

  const renderData = (data, key) => {
    if (data && Array.isArray(data)) {
      return data.map((item, index) => {
        if (item.name == key) {
          return (
            <pre key={index} style={{ whiteSpace: 'pre-wrap' }} className='mb-0 prefont_family'>
              {item.answer}
            </pre>
          );
        }
      });
    }
    return;
  };

  const renderTableMap = (data) => {
    return (
      data &&
      Array.isArray(data) &&
      data.map((item, index) => {
        if (renderData(item?.data, 'diagnosis') || renderData(item?.data, 'advice')) {
          return (
            <tr key={index}>
              <td>{item?.department?.name}</td>
              <td>{renderData(item?.data, 'diagnosis')}</td>
              <td>{renderData(item?.data, 'advice')}</td>
            </tr>
          );
        } else if (item.diagnosis || item.advice) {
          return (
            <tr key={index}>
              <td>{item?.department?.name}</td>
              <td>{item.diagnosis}</td>
              <td>{item.advice}</td>
            </tr>
          );
        } else {
          return;
        }
      })
    );
  };

  const renderMDTSummaryDetail = (data, item) => {
    return (
      <>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex'>
            <div className='me-2'>
              <img
                className='rounded coachImage float-start img-fluid'
                src={profileImage(item?.admin?.gender, item?.admin?.profile_url)}
              />
            </div>
            <div className='w-75 '>
              <h5 className='coachtext capitalize'>{item?.admin?.full_name}</h5>
              <p className='zeefitness capitalize'>{teamRoles(item?.admin?.role)}</p>
            </div>
          </div>
        </div>
        <div className='d-flex mt-4 align-items-center justify-content-center'>
          <table class='table'>
            <thead>
              <tr>
                <th scope='col'>Speciality Name</th>
                <th scope='col' style={{ width: '40%' }}>
                  Area of concern
                </th>
                <th scope='col' style={{ width: '40%' }}>
                  Advice
                </th>
              </tr>
            </thead>
            {data && Array.isArray(data) && data.length ? (
              <tbody>{renderTableMap(data)}</tbody>
            ) : (
              <tbody>
                <tr>
                  <td colspan='3' className='text-center p-4'>
                    No record found
                  </td>
                </tr>
              </tbody>
            )}
          </table>
        </div>
      </>
    );
  };

  const renderRecommendation = (item) => {
    let data = Object.keys(item);

    return data.map((key, index) => {
      if (typeof item[key] == 'string' && item[key]) {
        return (
          <div key={index}>
            <div className='w-100 mb-3 font-12px'>
              <span className='semiBold capitalize'>{titlizeString(key)}</span>
              <span className='prescriptiontime capitalize'>{item[key] ?? ''}</span>
            </div>
          </div>
        );
      } else {
        return renderRecommendation(item[key]);
      }
    });
  };

  const renderPrescriptionList = useMemo(() => {
    return (
      prescriptionData &&
      prescriptionData.map((item, index) => {
        return (
          <>
            <div className='cardDataBorder'>
              <div key={index} className='d-flex'>
                <div className='w-100'>
                  <div className='cardMilestone'>
                    <div className='headingMilestone d-flex justify-content-between'>
                      <div className='d-flex'>
                        <div>
                          <b>Assigned On &nbsp; &nbsp;</b>
                          {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
                        </div>
                        <div className='d-block  ms-2 '>
                          <span className={`badge bg-info  capitalize ${item.status}`}>{item.status}</span>
                        </div>
                      </div>

                      <div
                        className='memberShipDetails'
                        style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                      >
                        <>
                          {item.status == 'draft' && (
                            <div
                              className='mb-1 ms-2 pointer'
                              onClick={() => {
                                let windoConfirm = window.confirm('Are you sure you want to delete this Summary?');
                                if (windoConfirm) {
                                  prescriptionSummaryDelete(item.id);
                                }
                              }}
                            >
                              <div className='pt-1'>
                                <CommonToolTip
                                  placement={'top'}
                                  item={<span class='material-icons-outlined'>delete_outline</span>}
                                  showValue={'Delete'}
                                />
                              </div>
                            </div>
                          )}
                          {item.status == 'published' && (
                            <div className='mb-1 ms-2 pointer' onClick={() => getPdf(item)}>
                              <CommonToolTip
                                placement={'top'}
                                item={<img src={uploaded} />}
                                showValue={'Download Pdf'}
                              />
                            </div>
                          )}
                          {item.status == 'draft' && (
                            <div
                              onClick={() => {
                                navigate(`/patient/${id}/summaries/${item.id}/edit`);
                              }}
                              className='mb-2 ms-2 pointer'
                            >
                              <CommonToolTip
                                placement={'top'}
                                item={<img src={editIconY} className='icon' style={{ cursor: 'pointer' }} />}
                                showValue={'Edit'}
                              />
                            </div>
                          )}

                          <CommonToolTip
                            placement={'top'}
                            item={
                              <div className='mb-2 ms-2 pointer'>
                                <div className='form-check form-switch toggleBtn pt-1'>
                                  <input
                                    className='form-check-input'
                                    style={{ cursor: 'pointer' }}
                                    checked={item.status == 'published'}
                                    onChange={(e) => {
                                      const windowConfirm = window.confirm(
                                        `Are you sure,you want to ${
                                          item.status == 'published' ? 'unpublish' : 'publish'
                                        } Summary?`
                                      );
                                      if (windowConfirm) {
                                        if (item.status !== 'published') {
                                          statusPublish(item);
                                        } else {
                                          statusUnpublished(item);
                                        }
                                      }
                                    }}
                                    type='checkbox'
                                    role='switch'
                                  />
                                </div>
                              </div>
                            }
                            showValue={` ${item.status == 'published' ? 'Unpublish' : 'Publish'} `}
                          />
                        </>
                      </div>
                    </div>
                    <div className='d-flex flex-column'>
                      {renderMDTSummaryDetail(item?.prescriptions, item)}
                      <div className='w-100 mb-3'>
                        <span className='semiBold'>Overall MDT Summary </span>
                        <span className='prescriptiontime'>{item.mdt_summary}</span>
                      </div>
                      <div className='w-100 mb-3'>
                        <span className='semiBold'>Recommendation </span>
                        <br />
                        <span className='font-12px capitalize'>
                          {item.recommedation && titlizeString(Object.keys(item.recommedation)[0])}
                        </span>
                      </div>

                      {renderRecommendation(item?.recommedation)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })
    );
  }, [prescriptionData]);

  return (
    <>
      <div className='d-flex'>
        <div className='pt-3'>
          <button type='button' className='btn mt-3 btn-outline-primary' onClick={newSummary}>
            <img src={add} className='icon' /> New Summary
          </button>
        </div>
      </div>

      {renderPrescriptionList}

      {customGoalActivityModal()}
      <Loader show={isLoading} />
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </>
  );
}

export default RenderSummaryTab;
