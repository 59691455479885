import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import CommonBorderBox from './CommonBorderBox';
import EmptyView from './EmptyView';
import Pagination from './Pagination';
import ProfileListViewAndEdit from './ProfileListViewAndEdit';
import SettingBehaviourForm from './SettingBehaviourForm';

function BehaviorQuestions(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType, isNivaan } = props;

  const ApiClient = useApiManager();

  const [BehaviourQuestion, setBehaviourQuestion] = useState([]);
  const [createData, setCreateData] = useState({});
  const [updateData, setupdateData] = useState({});
  const [error, seterror] = useState('');
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getProfileQuestions();
  }, [currentPage]);

  const getProfileQuestions = () => {
    const pageQry = { page: currentPage };
    setIsLoading(true);
    ApiClient.getBehaviourQuestions(pageQry)
      .then((res) => {
        setIsLoading(false);
        let tmpData = [...res.data];
        setTotalPages(res.headers['total-pages']);

        tmpData.map((item) => {
          item.category_id = item?.category?.id;
        });
        setBehaviourQuestion(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const createBehaviourQuestion = () => {
    seterror({});
    setIsLoading(true);
    ApiClient.createBehaviourQuestion(createData)
      .then((res) => {
        setCreateData({});
        setIsLoading(false);
        getProfileQuestions();
        setIsShowAlert(
          `${isNivaan ? 'Conversion log Question Created Successfully ' : 'Behaviour Question Created Successfully'}`
        );
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        seterror(err.response.data.errors);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.detail);
        }
      });
  };

  const updateBehaviourData = () => {
    seterror({});
    setIsLoading(true);
    ApiClient.updateBehaviourQuestionData(updateData, updateData.id)
      .then((res) => {
        setCreateData({});
        setupdateData({});
        getProfileQuestions();
        setIsShowAlert(
          `${isNivaan ? 'Conversion log Question Updated Successfully ' : 'Behaviour Question Created Successfully'}`
        );
        setAlertType('alert-success');
        console.log(res);
        setIsLoading(false);
        handleModalClose();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.data?.detail) {
          setIsShowAlert(err.response.data.detail);
          setOpen(false);
        } else {
          seterror(err.response.data.errors);
        }
        setAlertType('alert-danger');
      });
  };

  const renderBehaviourForm = useCallback(
    (updateData, setupdateData) => {
      return (
        <CommonBorderBox className='settingFormOutline'>
          <div className='formTitle'>{isNivaan ? 'Conversion Log Question' : 'Regime Question '}</div>
          <SettingBehaviourForm
            error={error}
            valueObj={updateData || createData}
            setValueObj={setupdateData || setCreateData}
            isUpdate={updateData}
            onClick={updateData ? updateBehaviourData : createBehaviourQuestion}
          />
        </CommonBorderBox>
      );
    },
    [error, createData, updateData]
  );

  const updateQuestion = (data) => {
    setOpen(true);
    setupdateData(data);
    seterror('');
  };
  const handleModalClose = () => {
    setOpen(false);
    setCreateData({});
    seterror('');
  };
  const updateModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          {renderBehaviourForm(updateData, setupdateData)}
        </Modal>
      </div>
    );
  };
  const behaviouQuestionDelete = (id) => {
    setIsLoading(true);
    seterror({});
    ApiClient.profileBehaviorQuestionDelete(id)
      .then((res) => {
        setIsLoading(false);
        getProfileQuestions();
        console.log(res);
        setIsShowAlert(
          `${isNivaan ? 'Conversion log Question Deleted Successfully ' : 'Behaviour Question Deleted Successfully'}`
        );
        setAlertType('alert-success');
      })
      .catch((err) => {
        console.warn('ff', err);
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(isNivaan ? 'Conversion log question has multiple answers.' : err.response.data.message);
        }
        setAlertType('alert-danger');
        // setIsShowAlert(err.response.data.detail || err.response.data.message);
        console.log(err);
      });
  };

  const patination = () => {
    return <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />;
  };

  return (
    <div>
      {renderBehaviourForm()}
      {BehaviourQuestion?.length > 0 ? (
        <ProfileListViewAndEdit
          setEditError={seterror}
          editError={error}
          data={BehaviourQuestion}
          setUpdateData={updateQuestion}
          deleteData={behaviouQuestionDelete}
        />
      ) : (
        <EmptyView />
      )}
      <div className='d-flex justify-content-end mt-3'>{patination()}</div>
      {updateModal()}
    </div>
  );
}

export default BehaviorQuestions;
