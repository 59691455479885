import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import ChipsTagsView from './ChipsTagsView';

function CategoryFilter(props) {
  const { filterData, clearData, setFilterData } = props;

  const [data, setData] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [selectedData, setselectedData] = useState([]);
  const ApiClient = useApiManager();

  useEffect(() => {
    setData([]);
    setActiveData(new Array());
    setselectedData(new Array());
  }, [clearData]);

  useEffect(() => {
    getCategoryData();
  }, [clearData]);

  useEffect(() => {
    let stringActiveData = activeData?.toString();
    setFilterData({ category_ids: stringActiveData, category_Data: selectedData });
  }, [activeData, data, selectedData]);

  useEffect(() => {
    if (Object.keys(filterData).length == 0) {
      setActiveData([]);
    } else if (!Array.isArray(filterData)) {
      let arrData =
        filterData?.category_ids && filterData?.category_ids?.split(',').map((item) => item && parseInt(item));
      setActiveData(arrData);
    }
  }, [filterData]);

  const renderHeading = () => {
    return <div className="filterHeadings">Select Category</div>;
  };

  const renderChipsTags = () => {
    return (
      <ChipsTagsView
        setselectedData={setselectedData}
        setActiveTags={setActiveData}
        activeTags={activeData}
        data={data}
      />
    );
  };

  const getCategoryData = () => {
    ApiClient.articleCategoryGet().then((res) => {
      setData(res.data);
    });
  };

  return (
    <div>
      {renderHeading()}
      {renderChipsTags()}
    </div>
  );
}

export default CategoryFilter;
