import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { searchLight } from 'res/images';
import CoreInputBox from './CoreInputBox';

function GoalSearch(props) {
  const { setValue, value, isShowSearch, showMSG, label, placeholder } = props;
  const [data, setData] = useState([]);
  const [apiCall, setapiCall] = useState(true);
  const [inputValue, setinputValue] = useState(value || '');
  const ApiClient = useApiManager();

  let timeOut;

  useEffect(() => {
    if (apiCall && isShowSearch && inputValue?.length > 2) {
      timeOut = setTimeout(() => {
        searchGoals(inputValue);
      }, 300);
    }
  }, [inputValue]);

  useEffect(() => {
    setinputValue(value);
  }, [value]);

  useEffect(() => {
    if (!apiCall) {
      setapiCall(true);
    }
  }, [apiCall]);

  const handleItemClick = (item) => {
    clearTimeout(timeOut);
    setValue(item);
    setinputValue(item.title);
    setData([]);
    setapiCall(!apiCall);
  };

  const dataMap = () => {
    return data.map((item, index) => {
      return (
        <div onClick={() => handleItemClick(item)} className="dropDwonItems" key={index}>
          {item.title}
        </div>
      );
    });
  };

  const searchGoals = (inputValue) => {
    ApiClient.searchGoals(inputValue)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderSearh = useMemo(() => {
    return (
      <div>
        <CoreInputBox
          labelStyle={{ fontWeight: 'var(--lightWeight)' }}
          label={label}
          placeholder={placeholder}
          value={inputValue}
          showMSG={showMSG}
          setValue={(data) => {
            clearTimeout(timeOut);
            setinputValue(data);
          }}
          leftIcon={searchLight}
        />
        {isShowSearch && data?.length > 0 && (
          <div
            style={{
              left: 15,
              right: 15,
            }}
            className="dropDownDiv"
          >
            {dataMap()}
          </div>
        )}
      </div>
    );
  }, [data, inputValue, showMSG]);

  return <div className="mb-3">{renderSearh}</div>;
}

export default GoalSearch;
