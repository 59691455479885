import React from 'react';
import { editTheme, trashRed } from 'res/images';
import CommonToolTip from '../CommonToolTip';
import { dateFormat, teamRoles, timeFormat } from '../Helper';

function ResourceList(props) {
  const { tableList, updateDataModalOpen, deleteData } = props;
  return (
    <table className='w-100 table '>
      <tr key={tableList.id} className='my-3 font-12px'>
        <td colSpan={2}>
          <div className='d-flex mb-1 align-items-center justify-content-between'>
            <div className='capitalize '>
              <div>
                <span className='badge capitalize lightGrayBadge'> {tableList.title}</span>{' '}
              </div>
            </div>
            <div
              className='d-flex text-muted justify-content-between align-items-center medicineDataList'
              style={{ gap: '10px' }}
            >
              <div className='capitalize'>
                <p>
                  Created by · {tableList?.admin?.full_name} ({teamRoles(tableList?.admin?.role)})
                </p>
                <p>
                  Created at · {dateFormat(tableList.created_at)} . {timeFormat(tableList.created_at)}
                </p>
              </div>
              <div className='action-images d-flex'>
                <CommonToolTip
                  placement={'top'}
                  item={
                    <img
                      onClick={() => {
                        updateDataModalOpen(tableList);
                      }}
                      src={editTheme}
                    />
                  }
                  showValue={'Edit'}
                />

                <CommonToolTip
                  placement={'top'}
                  item={
                    <img
                      onClick={() => {
                        let windoConfirm = window.confirm('Are you sure you want to delete master status?');
                        if (windoConfirm) {
                          deleteData(tableList.id);
                        }
                      }}
                      src={trashRed}
                    />
                  }
                  showValue={'Delete'}
                />
              </div>
            </div>
          </div>
        </td>
      </tr>
    </table>
  );
}

export default ResourceList;
