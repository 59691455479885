import { AdminPanelHeader, SimpleHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { useCompleteAssigndTaskMutation, useGetDashBoardDataQuery, useShowAssigndTaskQuery } from 'redux/dashboardApis';
import { infoIcon } from 'res/images';
import { CommonAlert, CommonBorderBox, Loader, Pagination } from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { dateFormat, timeFormat, teamRoles } from 'whealth-core-web/components/Helper';
import { ZohoCalendar } from 'whealth-core-web/components/ZohoCalendar/ZohoCalendar';
import { ZohoConsultation } from 'whealth-core-web/components/ZohoConsultation/ZohoConsultation';

function Dashboard() {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const ApiClient = useApiManager();
  const [taskData, setTaskData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [taskCurrentPage, setTaskCurrentPage] = useState(1);
  const [TaskTotalPages, setTaskTotalPages] = useState(1);
  const [businessConfig, setBusinessConfig] = useState({});
  const [consultationCockpit, setConsultationCockpit] = useState('');

  const { id } = useParams();
  let getDashboard = useGetDashBoardDataQuery();
  let taskApiData = useShowAssigndTaskQuery({ page: taskCurrentPage });
  let [completeTask, responseData] = useCompleteAssigndTaskMutation();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'consultations' && item.isActive) {
          setConsultationCockpit(item.isActive);
        }
      });
    }
  }, [configData]);

  useEffect(() => {
    setIsLoading(!!getDashboard?.isFetching);
    getDashboard?.data?.data && setData(getDashboard?.data?.data);
  }, [getDashboard]);

  useEffect(() => {
    taskApiData.refetch();
  }, [taskCurrentPage]);

  useEffect(() => {
    const getBusinessConfig = async () => {
      const res = await ApiClient.getConfig();
      if (res.status == 200) setBusinessConfig(res.data.configuration);
    };
    getBusinessConfig();
  }, []);

  useEffect(() => {
    setIsLoading(taskApiData?.isFetching);
    setTaskTotalPages(taskApiData?.data?.headers['total-pages']);
    taskApiData?.data?.data && setTaskData(taskApiData?.data?.data);
  }, [taskApiData]);

  useEffect(() => {
    setIsLoading(responseData?.isLoading);
    responseData?.data?.data && setTaskData(taskApiData?.data?.data);

    if (responseData?.data?.status >= 400) {
      setIsShowAlert(responseData?.data?.data?.detail);
      setAlertType('alert-danger');
    } else if (responseData?.data?.status >= 200 && responseData?.data?.status < 300) {
      setIsShowAlert('Status marked completed');
      setAlertType('alert-success');
    }
  }, [responseData]);

  const showCompeleted = (item) => {
    completeTask({ id: item.user.id, taskId: item?.id });
  };

  let hiddenTotal_articles_count = data?.article?.total_articles_count?.hidden;
  let hiddenLive_articles_count = data?.article?.live_articles_count?.hidden;
  let articleTitle = hiddenLive_articles_count ? 'Total Articles' : 'Total Articles Live';

  const dashboardData = [
    {
      icon: infoIcon,
      title: 'Active Patients',
      count: data?.users?.active_users?.count,
      onClick: () => navigate('/patient'),
      hidden: data?.users?.active_users?.hidden,
    },
    {
      icon: infoIcon,
      title: 'Inactive Patients',
      count: data?.users?.inactive_users?.count,
      onClick: () => navigate('/patient'),
      hidden: data?.users?.inactive_users?.hidden,
    },
    {
      icon: infoIcon,
      title: userData.role == 'admin' || userData.role == 'health_coach_lead' ? 'Assigned Patients' : 'Total Patients',
      count: data?.users?.total_user?.count,
      onClick: () => navigate('/patient'),
      hidden: data?.users?.total_user?.hidden,
    },
    {
      hidden: data?.article?.total_articles_count?.hidden && data?.article?.live_articles_count?.hidden,
      hidden1: hiddenTotal_articles_count,
      hidden2: hiddenLive_articles_count,
      icon: infoIcon,
      title: articleTitle,
      count: data?.article?.total_articles_count.count,
      count1: data?.article?.live_articles_count.count,
      countStyle: { color: 'var(--lightBlack)' },
      onClick: () => navigate('/articles'),
    },
    {
      hidden: data?.team?.total_admins?.hidden,
      icon: infoIcon,
      title: 'Total Team Members',
      count: data?.team?.total_admins?.count,
      onClick: () => navigate('/team'),
    },
  ];

  const dashboardDataMap = () => {
    return dashboardData.map((item, index) => {
      if (item.hidden) {
        return;
      }
      return (
        <CommonBorderBox key={index} onClick={item.onClick} className='dashboardBox w-100 '>
          <div className='card w-100'>
            <div className='card-body'>
              <div className='d-flex justify-content-between dashBoardBoxTitle'>
                <div>
                  <span className='roundedDot' /> {item.title}
                </div>
                <div>
                  <img src={item.icon} />
                </div>
              </div>
              <div className='dashBoardCount'>
                {!item.hidden2 && (item.count1 || item.count1 == 0) && (
                  <span>
                    {item.count1} {!item.hidden1 && !item.hidden2 && '/'}{' '}
                  </span>
                )}
                {!item.hidden1 && item.count}
              </div>
            </div>
          </div>
        </CommonBorderBox>
      );
    });
  };

  const TaskContent = () => {
    return (
      <>
        <div className='mt-4 mb-4 table-responsive'>
          <table className='table table-bordered' cellspacing='5'>
            <thead>
              <tr>
                <th scope='col'>Assigned By (Role)</th>
                <th scope='col'>Assigned To (Role)</th>
                <th scope='col'>Patient Name</th>
                <th scope='col'>Content</th>
                <th scope='col'>Status</th>
                <th scope='col'>Assigned On</th>
                <th scope='col'>Completion Date</th>
              </tr>
            </thead>
            <tbody>
              {taskData?.map((item, index) => {
                const isActive = item.status == 'completed' ? 'badge active' : '';
                return (
                  <tr key={index}>
                    <td className='capitalize'>
                      {item.admin.full_name} ({teamRoles(item.admin.role)})
                    </td>
                    <td className='capitalize'>
                      {item.assigned_to.full_name} ({teamRoles(item.assigned_to.role)})
                    </td>
                    <td className='capitalize'>{item.user.full_name}</td>
                    <td className='capitalize'>{item.content}</td>

                    <td className='capitalize'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <span className={`me-2 ${isActive}`}>{item.status}</span>
                        {item.status !== 'completed' && item.editable && (
                          <button
                            onClick={() => {
                              let windoConfirm = window.confirm('Are you sure you want to mark complete status?');
                              if (windoConfirm) {
                                showCompeleted(item);
                              }
                            }}
                            type='button'
                            className='btn btn-primary btn-sm updatetaskbtn'
                          >
                            Complete
                          </button>
                        )}
                      </div>
                    </td>
                    <td>
                      {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
                    </td>
                    <td>{item.completion_date ? item.completion_date : 'N/A'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className='d-flex justify-content-end mb-4 dashboard-pagination'>
            <Pagination
              totalPages={TaskTotalPages}
              currentPage={taskCurrentPage}
              setCurrentPage={setTaskCurrentPage}
              dashboard
            />
          </div>
        </div>
      </>
    );
  };

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search a patient by name or mobile number'
        searchMethod='PATIENTSEARCH'
        label='full_name'
        searchId='Patients'
        renderPath='/patient/{source_id}/dashboard' //ShowPatientProfile
        imagePath='profile_url'
      />
    );
  }, []);

  const renderDeshBoard = useMemo(() => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='grid_view' title='Dashboard' searchBox={renderSearchBox} />
          <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertType={alertType} />
          <SimpleHeader
            consultationCockpit={consultationCockpit}
            title='Overall Statistics'
            isZohoMeetingEnabled={businessConfig.enable_zoho_meeting ?? false}
            zohoOauthLink={data?.connect_zoho_meeting_url}
            isZohoOauthExpired={data?.is_zoho_token_expired}
          />
        </div>
      </div>
      <div className='px-4 mb-4 pb-4 wrapperContent'>
        {Object.keys(data)?.length ? <div className='dashboardBody'>{dashboardDataMap()}</div> : ''}
        {taskData?.length > 0 && TaskContent()}
        {businessConfig?.enable_zoho_meeting && !data?.is_zoho_token_expired && !consultationCockpit && (
          <div className='mt-4 admin-zoho-calendar'>
            <ZohoCalendar />
          </div>
        )}
        {consultationCockpit && (
          <div className='mt-4 admin-zoho-calendar'>
            <ZohoConsultation configData={configData} />
          </div>
        )}
      </div>
    </>
  ));

  return (
    <>
      <div className='wrapper'>
        {/* {sideBar} */}
        {renderDeshBoard}
        <Loader show={isLoading} />
      </div>
    </>
  );
}

export default Dashboard;
