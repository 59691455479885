import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import CommonAlert from '../CommonAlert';
import CreateResource from './CreateResource';
import { Modal } from 'rsuite';
import ResourceList from './ResourceList';
import Loader from '../Loader';
import Pagination from '../Pagination';

function ResourceStatus(props) {
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  let inputFiledObj = {
    title: '',
    type: 'patient',
    color: '#123',
  };
  const [resourceCreate, setResourceCreate] = useState(inputFiledObj);
  const [errorInputValues, setErrorInputValues] = useState(inputFiledObj);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [isEditMode, setEditMode] = useState(false);

  const ApiClient = useApiManager();

  useEffect(() => {
    handleGetResourceList();
  }, [currentPage]);

  const handleGetResourceList = async () => {
    setIsLoading(true);
    let page = { page: currentPage, type: 'patient' };
    await ApiClient.getResource(page).then((res) => {
      setTotalPages(res.headers['total-pages']);
      setTableData(res?.data);
      setEditMode(false);
    });
    setIsLoading(false);
  };

  const handleSaveAction = async () => {
    setIsLoading(true);
    await ApiClient.createResourceStatus(resourceCreate)
      .then((res) => {
        handleGetResourceList();
        setCurrentPage(1);
        setResourceCreate(inputFiledObj);
        setAlertType('alert-success');
        setIsShowAlert('Master Status Created successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.errors.non_field_errors);
        setErrorInputValues(err.response.data.errors || err.response.data.errors.non_field_errors);
      });
  };

  const updateDataModalOpen = (data) => {
    setEditMode(true);
    setResourceCreate(data);
    setErrorInputValues({});
  };
  const handleEditAction = async () => {
    setIsLoading(true);
    await ApiClient.updateResourceStatus(resourceCreate.uuid, resourceCreate)
      .then((res) => {
        handleGetResourceList();
        setResourceCreate(inputFiledObj);
        setAlertType('alert-success');
        setIsShowAlert('Master status updated successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');

        if (err.response?.data?.detail || err.response.data.errors.non_field_errors) {
          setIsShowAlert(err.response.data.detail || err.response.data.errors.non_field_errors);
        } else {
          setErrorInputValues(err.response.data.errors);
        }
      });
  };

  const deleteData = async (item) => {
    setErrorInputValues({});
    setIsLoading(true);
    await ApiClient.deleteResourceStatus(item.uuid)
      .then((res) => {
        handleGetResourceList();
        setAlertType('alert-success');
        setIsShowAlert('Master status deleted successfully');
      })
      .catch((err) => {
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
      });
    setIsLoading(false);
  };

  return (
    <div className='medicine-form medicine-alert'>
      <div className='commonBorderBox settingFormOutline mb-4 '>
        <div className=''>
          {!isEditMode && (
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          )}
        </div>
        <div className='formTitle'>Master Status Form</div>
        <CreateResource
          inputFileValues={isEditMode ? inputFiledObj : resourceCreate}
          errorInputValues={isEditMode ? inputFiledObj : errorInputValues}
          handleOnChanges={setResourceCreate}
          btnTitle={'Save'}
          handleOnClick={handleSaveAction}
          setErrorInputValues={setErrorInputValues}
        />
        <div className='table-responsive'>
          {tableData.map((item) => {
            return (
              <ResourceList
                tableList={item}
                updateDataModalOpen={updateDataModalOpen}
                deleteData={() => deleteData(item)}
              />
            );
          })}
        </div>
        <Loader show={isLoading} />
        <Pagination
          seterror={setErrorInputValues}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <Modal
          backdrop='static'
          size='md'
          open={isEditMode}
          onClose={() => {
            setEditMode(false);
            setErrorInputValues({});
            setResourceCreate(inputFiledObj);
          }}
        >
          <Modal.Header />
          <div className='mt-3 me-4'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
          <CreateResource
            inputFileValues={resourceCreate}
            errorInputValues={errorInputValues}
            handleOnChanges={setResourceCreate}
            btnTitle={'Update'}
            handleOnClick={handleEditAction}
            setErrorInputValues={setErrorInputValues}
          />
        </Modal>
      </div>
    </div>
  );
}

export default ResourceStatus;
