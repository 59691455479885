import React, { useEffect, useState } from 'react';
import '../CallLog/callLog.scss';
import { adherenceH, adherenceRed, editIconBlack, uploaded } from 'res/images';
import { useSelector } from 'react-redux';
import {
  dateFormat,
  dateFormatString,
  getGoalValuePercent,
  pdfData,
  profileImage,
  showArrayValues,
  sumVal,
  timeFormat,
  titlizeString,
} from '../Helper';
import { useNavigate, useParams } from 'react-router-dom';
import useApiManager from 'networking/ApiManager';
import Accordian from './Accordian';
import CommonToolTip from '../CommonToolTip';
import CommonAlert from '../CommonAlert';
import Loader from '../Loader';
import EmptyView from '../EmptyView';

const CallLog = (props) => {
  const ApiClient = useApiManager();
  const {
    data,
    callLogData,
    getRegimeData,
    setCallLogData,
    reportData,
    notesData,
    prescriptionData,
    goalData,
    behaviourQuetionData,
    setCallLogNotes,
  } = props;
  const { patientDetails } = useSelector((state) => state.sliceReducer);
  const { id, pid } = useParams();
  const [categoryQuestions, setCategoryQuestions] = useState({});
  const [showMore, setShowMore] = useState([]);
  const [showMoreDescription, setShowMoreDescription] = useState([]);
  const [notesShow, setNotesShow] = useState(false);
  const [goalShow, setGoalShow] = useState(false);

  const [medicalHistoryShow, setMedicalHistoryShow] = useState(false);
  const [reportShow, setReportShow] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const modifyParams = (data) => {
    let newData = { ...data };
    data.behaviour_questionnaire_answers.map((item, index) => {
      if (typeof item.behaviour_question == 'object') {
        item.behaviour_question = item.behaviour_question.id;
      }
    });

    return newData;
  };

  const updateBehaviourQuestion = (data) => {
    setIsLoading(true);
    let params = modifyParams({ ...data });
    ApiClient.updateBehaviorPrescription(id, behaviourQuetionData[0]?.id, params)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Call Log Updated Successfully');
        getRegimeData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getPdf = (item) => {
    ApiClient.prescriptionPdfDownload(id, item.id)
      .then((res) => {
        var blob = new Blob([res.data], { type: 'application/pdf' }); //this make the magic
        var blobURL = URL.createObjectURL(blob);
        let iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);
        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function () {
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
          }, 1);
        };
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRegimePdf = () => {
    ApiClient.regimePdfDownload(id)
      .then((res) => {
        pdfData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderNotesItems = () => {
    const notesItems = notesData?.map((item, index) => {
      return <div>{renderNotes(item, index)}</div>;
    });

    if (notesShow) {
      return notesItems;
    } else {
      return notesItems?.slice(0, 3);
    }
  };

  const renderMedicalHistory = () => {
    const medicalHistoryItems = prescriptionData?.map((item, index) => {
      return renderPrescribedMedicine(item);
    });
    if (medicalHistoryShow) {
      return medicalHistoryItems;
    } else {
      return medicalHistoryItems?.slice(0, 1);
    }
  };

  const renderPrescribedMedicine = (item) => {
    return (
      <>
        <div className='d-flex justify-content-center'>
          {item.prescription_medicines.length ? '' : 'No Medical History Found'}
        </div>
        <div className='d-flex justify-content-end'>
          {item.prescription_medicines.length ? (
            <a style={{ cursor: 'pointer' }} onClick={() => getPdf(item)} className='d-flex align-items-center'>
              <span class='material-icons me-1'>visibility</span> View Prescription
            </a>
          ) : null}
        </div>
        <table className='table' cellSpacing='4'>
          {item.prescription_medicines.map((medicine) => {
            return (
              <tr>
                <td>
                  {medicine?.medicine?.formulation}&nbsp;
                  {medicine?.medicine?.name || 'No Medicine'}
                </td>
                <td align='right'>
                  <div className='dose-medicine'>{medicine.dosage}</div>
                </td>
                {/* <td align='right'>
                  <div className=' badge success d-flex align-items-center'>
                    <div class='pie-wrapper progress-40 style-2 me-1'>
                      <span class='label'>
                        40<span class='smaller'>%</span>
                      </span>
                      <div class='pie'>
                        <div class='left-side half-circle'></div>
                        <div class='right-side half-circle'></div>
                      </div>
                      <div class='shadow'></div>
                    </div>
                    {data?.adherence?.adherence_range}
                    low
                  </div>
                </td> */}
              </tr>
            );
          })}
        </table>
      </>
    );
  };

  const renderNotes = (item, index) => {
    return (
      <>
        <div className='notes-box'>
          <p>
            {showMore[index] ? item.content : `${item.content.substring(0, 30)}...`}
            <span
              className=''
              onClick={() =>
                setShowMore((prevShowMore) => {
                  const newShowMore = [...prevShowMore];
                  newShowMore[index] = !newShowMore[index];
                  return newShowMore;
                })
              }
            >
              {item.content.length > 20 && (showMore[index] ? 'Show less' : 'Show more')}
            </span>
          </p>
          <div className='d-flex justify-content-end notes-assign'>
            {/* <div className=''>Assigned to: Coach Neha | Due on: 24.07.23</div> */}
            By {item?.admin?.full_name} | {dateFormat(item?.created_at)}
          </div>
        </div>
      </>
    );
  };

  const renderMedicalIssue = (item) => {
    if (item.key === 'medical_condition') {
      return item?.answer?.length ? <li>{item.answer}</li> : <div>No Medical Issues Found</div>;
    }
  };

  const renderReportItem = () => {
    const renderReportItems = reportData?.map((item, index) => {
      return renderReport(item, index);
    });
    if (reportShow) {
      return renderReportItems;
    } else {
      return renderReportItems?.slice(0, 3);
    }
  };

  const renderReport = (item, index) => {
    return (
      <div className='reports-box mb-2'>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='reports-name capitalize'>
            {item.title}
            <br />
            {item.description && (
              <span>{showMoreDescription[index] ? item.description : `${item.description?.substring(0, 15)}`}</span>
            )}
            <span
              className='showmore-btn'
              onClick={() =>
                setShowMoreDescription((prevShowMore) => {
                  const newShowMore = [...prevShowMore];
                  newShowMore[index] = !newShowMore[index];
                  return newShowMore;
                })
              }
            >
              {item.description?.length > 20 && (showMoreDescription[index] ? 'Show less' : 'Show more')}
            </span>
          </div>
          <div className='report-action d-flex justify-content-between align-items-center '>
            <div className='badge badge-pill completed'>{titlizeString(item?.status)}</div>
            <div className='date px-1'> {item && item.updated_at && dateFormat(item.updated_at)}</div>
            <div className='icon d-flex'>
              {/* <span class='material-icons'>visibility</span> */}
              {item.report && (
                <CommonToolTip
                  placement={'top'}
                  item={
                    <a href={item.report} target='_blank'>
                      <span class='material-icons-outlined'>file_download</span>
                    </a>
                  }
                  src={editIconBlack}
                  className='icon'
                  style={{ cursor: 'pointer' }}
                  showValue={'Download'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const calculateBarval = (value, targetValue) => {
    const percentage = (value / targetValue) * 100;
    const roundedPercentage = Math.floor(percentage / 10) * 10;
    return roundedPercentage > 100 ? 100 : roundedPercentage;
  };

  const renderGoalItems = () => {
    const renderGoal = goalData?.map((item, index) => {
      console.warn('item', goalData.length);
      return renderGoalData(item, index);
    });
    if (goalShow) {
      return renderGoal;
    } else {
      return renderGoal?.slice(0, 6);
    }
  };

  const renderGoalData = (item) => {
    const barval = calculateBarval(item?.activity?.value, item?.activity?.target_value);

    if (item?.goal?.category == 'default') {
      return (
        <div className='goal-card d-flex mb-3'>
          <div className='w-100 d-flex align-items-center justify-content-between '>
            <div>
              {item?.goal?.title}
              <span>
                {item?.goal?.medicinal_goal
                  ? showArrayValues(
                      [
                        showArrayValues([sumVal(item?.activity?.value), sumVal(item?.activity?.target_value)], '/'),
                        item?.goal.measuring_event?.measuring_unit,
                      ],
                      ' '
                    )
                  : showArrayValues(
                      [
                        showArrayValues(
                          [
                            showArrayValues(
                              [
                                item?.activity?.value ? parseFloat(item?.activity?.value).toFixed(2) : '',
                                item.activity?.value2 ? parseFloat(item?.activity?.value2).toFixed(2) : '',
                              ],
                              ' - '
                            ),
                            item?.activity?.target_value,
                          ],
                          '/'
                        ),
                        item?.goal.measuring_event?.measuring_unit,
                      ],
                      '  '
                    )}
              </span>
            </div>
            <div className=''>
              <div
                className={`pie-wrapper progress-${
                  item?.activity?.value && item?.goal?.type == 'logging' ? '100' : barval
                } style-2 me-1 pie-wrapper-2`}
              >
                <span class='label'>
                  {item?.activity?.value && item?.goal?.type == 'logging' ? '100' : barval ? barval : '0%'}
                  <span class='smaller'>%</span>
                </span>
                <div class='pie'>
                  <div class='left-side half-circle'></div>
                  <div class='right-side half-circle'></div>
                </div>
                <div class='shadow'></div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  const updateRecommendation = (index, value) => {
    const updatedCategoryQuestions = { ...categoryQuestions };
    updatedCategoryQuestions[index].recommendation = value;
    setCategoryQuestions(updatedCategoryQuestions);
  };

  const highIntensityBedge = data?.profile_info?.find((item) => item.key == 'diet_intensity');
  const goalCategory = goalData.find((item) => item?.goal?.category);
  const currentDate = new Date();
  const dateString = dateFormat(data?.consultation_datetime?.upcoming_consultation_datetime);
  const dateObject = new Date(dateString);
  const todayDate = currentDate.getDate();
  const upcomingDate = dateObject.getDate();

  return (
    <>
      <div class='offcanvas-header'>
        <h5 id='offcanvasRightLabel'>Call Log</h5>
        <button type='button' class='btn-close text-reset' data-bs-dismiss='offcanvas' aria-label='Close'></button>
      </div>
      <div class='offcanvas-body'>
        <div className='call-log-offcanvas d-flex'>
          <div className='call-log-left'>
            <div className='call-log-left-part'>
              <div className='d-flex call-log-hr pb-3 '>
                <div className='patient-pic'>
                  <img className='rounded img-fluid float-start ' src={profileImage(data.gender, data.profile_url)} />
                </div>

                <div className='patient-data w-100'>
                  <div className='Pname capitalize'>
                    {data.full_name}
                    <span>{showArrayValues([showArrayValues([data.age, data.gender], ', '), data.city], ' · ')}</span>
                  </div>
                  <div className='d-flex mt-2'>
                    <div className='badge badge-pill profile-badge me-2'>{highIntensityBedge?.answer}</div>
                    <div
                      className={`badge badge-pill profile-badge ${
                        data?.status?.title == 'Subscription Expired' ? 'info' : ''
                      }`}
                    >
                      {titlizeString(data?.status?.title)}
                    </div>
                  </div>
                </div>
                <div className='edit-icon' style={{ cursor: 'pointer' }}>
                  <CommonToolTip
                    placement={'top'}
                    item={
                      <span
                        data-bs-dismiss='offcanvas'
                        aria-label='Close'
                        onClick={() => navigate(`/patient/${id}/edit`)}
                        class='justify-content-end material-icons-outlined'
                      >
                        edit
                      </span>
                    }
                    showValue={'Edit'}
                  />
                </div>
              </div>
              <div className='member-details-box mt-3  call-log-hr pb-3'>
                <div className='d-flex justify-content-between mb-2'>
                  <div className='head'>Member Details</div>
                  {/* <a href='#'>Renew Subscription</a> */}
                </div>
                <div className='d-flex '>
                  <div className='member-details-card me-2'>
                    <span>Member Since</span>
                    <div className='d-flex align-items-center'>
                      {data.created_at && <span class='material-icons-outlined'>calendar_month</span>}
                      <div className='bottomTextView'> {dateFormat(data.created_at)} </div>
                    </div>
                  </div>
                  <div className='member-details-card'>
                    <span>Renewal on</span>
                    <div className='d-flex align-items-center'>
                      <span class='material-icons-outlined'>calendar_month</span>
                      {dateFormat(patientDetails.subscription_expiry_date)}
                    </div>
                  </div>
                </div>
              </div>
              <div className='member-details-box mt-3  call-log-hr pb-3'>
                <div className='d-flex justify-content-between mb-2'>
                  <div className='head'>Doctor Consultation</div>
                  <a
                    style={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/patient/${id}/consultation`)}
                    data-bs-dismiss='offcanvas'
                    aria-label='Close'
                  >
                    Book Consultation
                  </a>
                </div>
                <div className='d-flex '>
                  <div className='member-details-card me-2'>
                    <div className='d-flex justify-content-between'>
                      <span>Last on</span>
                    </div>

                    <div className='d-flex align-items-center'>
                      <span class='material-icons-outlined'>calendar_month</span>
                      {data?.consultation_datetime &&
                        data?.consultation_datetime?.previous_consultation_datetime &&
                        dateFormat(data?.consultation_datetime?.previous_consultation_datetime)}
                    </div>
                  </div>
                  <div className='member-details-card'>
                    <div className='d-flex justify-content-between'>
                      <span>Due on</span>
                      {data?.consultation_datetime?.upcoming_consultation_datetime && (
                        <div className='badge info sm-badge'>{`in ${upcomingDate - todayDate} days`}</div>
                      )}
                    </div>
                    <div className='d-flex align-items-center'>
                      <span class='material-icons-outlined'>calendar_month</span>
                      {data?.consultation_datetime &&
                        data?.consultation_datetime?.upcoming_consultation_datetime &&
                        dateFormat(data?.consultation_datetime?.upcoming_consultation_datetime)}
                    </div>
                  </div>
                </div>
              </div>
              <div className='medical-issues mt-3  call-log-hr'>
                <div className='d-flex justify-content-between mb-2'>
                  <div className='head'>Medical Issues</div>
                  <CommonToolTip
                    placement={'top'}
                    item={
                      <span
                        data-bs-dismiss='offcanvas'
                        aria-label='Close'
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/patient/${id}/edit`, { state: { key: 'call_log' } })}
                        class='material-icons-outlined'
                      >
                        edit
                      </span>
                    }
                    showValue={'Edit'}
                  />
                </div>
                <ul className='d-flex justify-content-between'>
                  {data?.profile_info?.map((item) => {
                    return renderMedicalIssue(item);
                  })}
                </ul>
              </div>
              <div className='medical-history mt-3'>
                <div className='d-flex justify-content-between mb-2'>
                  <div className='head'>Medical History</div>
                </div>
                {prescriptionData.length ? '' : 'No Medical History Found'}
                {renderMedicalHistory()}
                {prescriptionData.length > 2 && (
                  <a
                    style={{ cursor: 'pointer' }}
                    className='d-flex justify-content-end align-items-center '
                    onClick={() => setMedicalHistoryShow(!medicalHistoryShow)}
                  >
                    <span className='material-icons-outlined '>
                      {medicalHistoryShow ? 'expand_less' : 'expand_more'}
                    </span>{' '}
                    {medicalHistoryShow ? 'View Less' : 'View More'}
                  </a>
                )}
              </div>
            </div>
            <div className='call-log-left-part mt-3'>
              <div className='d-flex justify-content-between mb-2'>
                <div className='head'>Reports</div>
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(`/patient/${id}/reports/new`)}
                  data-bs-dismiss='offcanvas'
                  aria-label='Close'
                >
                  + Add New Report
                </a>
              </div>
              <div className='d-flex justify-content-center'>{reportData?.length ? '' : 'No Report Found'}</div>
              {renderReportItem()}
              {reportData?.length > 3 && (
                <a
                  style={{ cursor: 'pointer' }}
                  className='d-flex justify-content-end align-items-center'
                  onClick={() => setReportShow(!reportShow)}
                >
                  <span class='material-icons-outlined'>{reportShow ? 'expand_less' : 'expand_more'}</span>{' '}
                  {reportShow ? 'View Less' : 'View More'}
                </a>
              )}
            </div>

            <div className='call-log-left-part mt-3'>
              <div className='d-flex justify-content-between mb-2'>
                <div className='head'>View Notes</div>
                <a
                  onClick={() => setCallLogNotes('notes')}
                  data-bs-dismiss='offcanvas'
                  style={{ cursor: 'pointer' }}
                  aria-label='Close'
                >
                  + Add New Notes
                </a>
              </div>
              <div className='d-flex justify-content-center'>{notesData?.length ? '' : 'No Notes found'}</div>
              {renderNotesItems()}
              {notesData.length > 3 && (
                <a
                  style={{ cursor: 'pointer' }}
                  className='d-flex justify-content-end align-items-center'
                  onClick={() => setNotesShow(!notesShow)}
                >
                  <span class='material-icons-outlined'>{notesShow ? 'expand_less' : 'expand_more'}</span>{' '}
                  {notesShow ? 'View Less' : 'View More'}
                </a>
              )}
            </div>
          </div>

          <div className='call-log-right ms-3  w-100 '>
            <div className='performance-part d-flex'>
              <div className='performance-box'>
                <div className='head'>Performance</div>
                <div className='adherence-box d-flex align-items-center justify-content-end mb-3'>
                  <div className='adherence-name'>Adherence</div>
                  <div className='value mx-3'>{data?.adherence?.percentage}%</div>
                  {data?.adherence?.adherence_range == 'high' ? (
                    <div className='badge success info'>
                      <img src={adherenceH} className='icon' /> {data?.adherence?.adherence_range}
                    </div>
                  ) : (
                    <div className='badge info'>
                      <img src={adherenceRed} className='icon' /> {data?.adherence?.adherence_range}
                    </div>
                  )}
                </div>
                {/* <div className='progress-tiles info-box mb-3'>
                  <span>10% average weightloss</span>
                  over the patient’s life Falls in the bottom 25% of all patients
                </div> */}
                <table className='table w-100 adherence-table'>
                  <tr>
                    <th>Date</th>
                    <th>Weight</th>
                    <th>BMI</th>
                  </tr>
                  <tr>
                    <td>
                      Current{' '}
                      {callLogData?.performance.current?.date ? (
                        <span>{dateFormat(callLogData?.performance.current?.date)}</span>
                      ) : (
                        'null'
                      )}
                    </td>
                    <td>
                      <strong>{callLogData?.performance.current?.weight || 'null'}</strong>
                    </td>
                    <td>
                      <strong>{callLogData?.performance.current?.bmi || 'null'}</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Started{' '}
                      {callLogData?.performance?.started?.date ? (
                        <span>{dateFormat(callLogData?.performance?.started?.date)}</span>
                      ) : (
                        'null'
                      )}
                    </td>
                    <td>{callLogData?.performance?.started?.weight || 'null'}</td>
                    <td>{callLogData?.performance?.started?.bmi || 'null'}</td>
                  </tr>
                </table>
              </div>
              <div className='goal-box '>
                <div className='head'>Goal</div>
                <div className='d-flex justify-content-center'>{goalCategory ? '' : 'No  Goals Found'}</div>
                <div className='d-flex  align-items-center justify-content-between mt-3 flex-wrap'>
                  {/* <div className='d-flex justify-content-center'>{goalData.goal?.length ? '' : 'No Report Found'}</div> */}

                  {renderGoalItems()}
                  {goalData.length > 6 && (
                    <a
                      style={{ cursor: 'pointer' }}
                      className='d-flex justify-content-end align-items-center'
                      onClick={() => setGoalShow(!goalShow)}
                    >
                      <span class='material-icons-outlined'>{goalShow ? 'expand_less' : 'expand_more'}</span>{' '}
                      {goalShow ? 'View Less' : 'View More'}
                    </a>
                  )}
                </div>
              </div>
            </div>
            <div className='call-log-part mt-3'>
              <div className='d-flex call-log-head justify-content-between'>
                <div className='head'>Call log</div>

                <div className='icon'>
                  {/* <span class='material-icons-outlined'>save</span>
                  <span class='material-icons-outlined'>file_copy</span> */}
                  <CommonToolTip
                    placement={'top'}
                    item={
                      <span style={{ cursor: 'pointer' }} onClick={getRegimePdf} class='material-icons-outlined'>
                        file_download
                      </span>
                    }
                    showValue={'Download'}
                  />
                </div>
              </div>
              <div className='mt-4'>
                <Loader show={isLoading} />
                <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertType={alertType} />
              </div>
              <div>
                <Accordian
                  behaviourQuetionData={behaviourQuetionData}
                  setCategoryQuestions={setCategoryQuestions}
                  categoryQuestions={categoryQuestions}
                  updateRecommendation={updateRecommendation}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                />
              </div>
              {behaviourQuetionData.length ? (
                <div
                  onClick={() => {
                    const newPayload = {
                      behaviour_questionnaire_answers: [],
                    };

                    Object.keys(categoryQuestions).forEach((category) => {
                      newPayload.behaviour_questionnaire_answers = newPayload.behaviour_questionnaire_answers.concat(
                        categoryQuestions[category].questions
                      );
                    });

                    updateBehaviourQuestion(newPayload);
                  }}
                  className='d-flex  m-3'
                >
                  <button className='d-flex  w-100 call-log-btn'>Save</button>
                </div>
              ) : (
                <EmptyView className='mb-4' title='No Call Log Found' />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CallLog;
