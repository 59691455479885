import React, { useState, useEffect } from 'react';
import { DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import 'rsuite/dist/rsuite.min.css';
import './dateRange.scss';
import { clickOnElmOnBodyClick, stoppropagation } from './Helper';

function DateRange(props) {
  const {
    filterData,
    prescriptionDate,
    setFilterData,
    handleClearDate,
    clearFiltersData,
    title,
    disableDate,
    pms,
    pmsConsultDate,
    pmsDate,
  } = props;
  const [date, setDate] = useState([]);

  useEffect(() => {
    if (date?.length > 0) {
      if (!pms) {
        setFilterData({ date: date });
      }
      if (pmsDate) {
        setFilterData({
          pmsDate: date,
        });
      }
      if (prescriptionDate) {
        setFilterData({
          prescriptionDate: date,
        });
      }
      if (pmsConsultDate) {
        setFilterData({
          pmsConsultDate: date,
        });
      }
    }
  }, [date]);

  useEffect(() => {
    setDate([]);
  }, [clearFiltersData]);

  useEffect(() => {
    if (!pms && filterData?.date) {
      setDate([new Date(filterData?.date[0]), new Date(filterData?.date[1])]);
    } else if (pmsDate && filterData?.pmsDate) {
      setDate([new Date(filterData?.pmsDate[0]), new Date(filterData?.pmsDate[1])]);
    } else if (prescriptionDate && filterData?.prescriptionDate) {
      setDate([new Date(filterData?.prescriptionDate[0]), new Date(filterData?.prescriptionDate[1])]);
    } else if (pmsConsultDate && filterData?.pmsConsultDate) {
      setDate([new Date(filterData?.pmsConsultDate[0]), new Date(filterData?.pmsConsultDate[1])]);
    } else {
      setDate([]);
    }
  }, [filterData]);

  const renderTitle = () => {
    return <div className='dateHeading'>{title || 'Date Range'}</div>;
  };

  const predefinedBottomRanges = [
    {
      label: 'Today',
      value: [new Date(), new Date()],
      placement: 'left',
    },
    {
      label: 'Yesterday',
      value: [addDays(new Date(), -1), addDays(new Date(), -1)],
      placement: 'left',
    },
    {
      label: 'This week',
      value: [startOfWeek(new Date()), endOfWeek(new Date())],
      placement: 'left',
    },
    {
      label: 'Last 7 days',
      value: [subDays(new Date(), 6), new Date()],
      placement: 'left',
    },
    {
      label: 'Last 30 days',
      value: [subDays(new Date(), 29), new Date()],
      placement: 'left',
    },
    {
      label: 'This month',
      value: [startOfMonth(new Date()), new Date()],
      placement: 'left',
    },
    {
      label: 'Last month',
      value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
      placement: 'left',
    },
    {
      label: 'This year',
      value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
      placement: 'left',
    },
    {
      label: 'Last year',
      value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
      placement: 'left',
    },
  ];

  const datePicker = () => {
    return (
      <Stack direction='column' spacing={8} alignItems='auto'>
        <DateRangePicker
          disabledDate={disableDate}
          container={() => document.getElementById('datePickerContainer')}
          showOneCalendar
          onChange={(evt) => setDate(evt)}
          ranges={predefinedBottomRanges}
          placeholder='Select Date'
          style={{ width: '100%' }}
          value={date || []}
          onClean={() =>
            handleClearDate(
              pmsDate ? 'pmsDate' : pmsConsultDate ? 'pmsConsultDate' : prescriptionDate ? 'prescriptionDate' : 'date'
            )
          }
        />
      </Stack>
    );
  };

  return (
    <div className='dateRangeDiv'>
      {renderTitle()}
      <div id='datePickerContainer' className='position-relative'>
        {datePicker()}
      </div>
    </div>
  );
}

export default DateRange;
