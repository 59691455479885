import React, { useState } from 'react';

function ReadMoreReadLess(props) {
  const { data, limit } = props;

  const [isReadMore, setIsReadMore] = useState(false);

  const readMoreBtn = (test) => {
    return (
      <span
        className='text-primary'
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setIsReadMore(!isReadMore);
        }}
      >
        {test}
      </span>
    );
  };

  if (data?.length > limit) {
    if (isReadMore) {
      return (
        <div>
          {data}
          {readMoreBtn(' Read Less')}
        </div>
      );
    } else {
      return (
        <div>
          {data.slice(0, limit)}
          {readMoreBtn('... Read More')}
        </div>
      );
    }
  } else {
    return data;
  }
}

export default ReadMoreReadLess;
