import React, { useEffect } from 'react';
import { TagPicker, Toggle } from 'rsuite';
import TagSelectPicker from '../common/TagSelectPicker';
import CoreDropDown from '../CoreDropDown';
import CoreInputBox from '../CoreInputBox';
import CoreTextArea from '../CoreTextArea';
import { titlizeString } from '../Helper';

function WebEngageForm(props) {
  const { setOpen, createData, setCreateData, errors, webListUpdate } = props;
  const newItem = webListUpdate?.notification_messages?.filter((element) => element?.is_enable);

  return (
    <>
      <div className='d-flex align-items-center justify-content-between mb-4'>
        <div className='d-flex '>
          <div class='editInput px-0 gap-10 webEngage-Tab'>
            <span
              onClick={(data) => {
                const newData = webListUpdate?.notification_messages?.find((item) => item.channel === 'sms');
                setCreateData({
                  ...newData,
                  channel: 'sms',
                  notification_body: newData?.notification_body || '',
                  notification_title: newData?.notification_title || '',
                  is_enable: newData?.is_enable || '',
                });
              }}
              className={`badge ${
                newItem && newItem.some((e) => e.channel === 'sms') ? 'bg-info' : 'lightGrayBadge'
              } capitalize published ${createData.channel == 'sms' ? 'channelActive' : ''}`}
            >
              SMS
            </span>
            <span
              onClick={(data) => {
                const newData = webListUpdate?.notification_messages?.find((item) => item.channel === 'email');
                setCreateData({
                  ...newData,
                  channel: 'email',
                  is_enable: newData?.is_enable,
                  notification_body: newData?.notification_body || '',
                  notification_title: newData?.notification_title || '',
                });
              }}
              className={`badge ${
                newItem && newItem.some((e) => e.channel === 'email') ? 'bg-info' : 'lightGrayBadge'
              } capitalize published ${createData.channel == 'email' ? 'channelActive' : ''}`}
            >
              Email
            </span>
            <span
              onClick={(data) => {
                const newData = webListUpdate?.notification_messages?.find((item) => item.channel === 'whatsapp');
                setCreateData({
                  ...newData,
                  channel: 'whatsapp',
                  is_enable: newData?.is_enable,
                  notification_body: newData?.notification_body || '',
                  notification_title: newData?.notification_title || '',
                });
              }}
              className={`badge ${
                newItem && newItem.some((e) => e.channel === 'whatsapp') ? 'bg-info' : 'lightGrayBadge'
              } capitalize published ${createData.channel == 'whatsapp' ? 'channelActive' : ''}`}
            >
              Whatsapp
            </span>
            <span
              onClick={(data) => {
                const newData = webListUpdate?.notification_messages?.find((item) => item.channel === 'push');
                setCreateData({
                  ...newData,
                  channel: 'push',
                  is_enable: newData?.is_enable,
                  notification_body: newData?.notification_body || '',
                  notification_title: newData?.notification_title || '',
                });
              }}
              className={`badge ${
                newItem && newItem.some((e) => e.channel === 'push') ? 'bg-info' : 'lightGrayBadge'
              } capitalize published ${createData.channel == 'push' ? 'channelActive' : ''}`}
            >
              Push
            </span>
            <span
              onClick={(data) => {
                const newData = webListUpdate?.notification_messages?.find((item) => item.channel === 'web_push');
                setCreateData({
                  ...newData,
                  channel: 'web_push',
                  is_enable: newData?.is_enable,
                  notification_body: newData?.notification_body || '',
                  notification_title: newData?.notification_title || '',
                });
              }}
              className={`badge ${
                newItem && newItem.some((e) => e.channel === 'web_push') ? 'bg-info' : 'lightGrayBadge'
              } capitalize published ${createData.channel == 'web_push' ? 'channelActive' : ''}`}
            >
              Web Push
            </span>
          </div>
        </div>
      </div>
      <div className='webEngage-form'>
        <div className='formTitle border-0 capitalize'>
          {titlizeString(createData?.channel) || 'Sms'}

          <span className='my-3'>
            <Toggle
              className='ms-2'
              onChange={(data) => setCreateData({ ...createData, is_enable: data })}
              type='checkbox'
              checked={createData?.is_enable}
            />
          </span>
        </div>
        <div className='d-flex gap-10'>
          <div className=' w-100'>
            <CoreInputBox
              showMSG={errors.external_campaign_id}
              label='Campain ID'
              value={createData.external_campaign_id || ''}
              setValue={(data) => {
                setCreateData({ ...createData, external_campaign_id: data });
              }}
            />
          </div>
          <div className='w-100'>
            <CoreInputBox
              showMSG={errors.notification_title}
              label='Notification title'
              value={createData.notification_title}
              setValue={(data) => {
                setCreateData({ ...createData, notification_title: data });
              }}
            />
          </div>
        </div>
        <div className='w-100 mt-3'>
          <div className=' w-100'>
            <CoreTextArea
              showMSG={errors.notification_body}
              label='Notification Body'
              rows={3}
              value={createData.notification_body}
              setValue={(data) => {
                setCreateData({ ...createData, notification_body: data });
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default WebEngageForm;
