import React from 'react';
import './ReusableBtn.css';

function ReusableButton(props) {
  const { title, icon, active, onClick, outline, style } = props;

  const isActiveBtn = active ? 'reusableBtnActive' : 'reusableBtnDisabled';
  const outlineInput = outline ? 'btn btn-outline-success' : '';

  return (
    <div onClick={onClick} style={style} className={`${isActiveBtn} ${outlineInput} reusableBtn`}>
      {icon && <img src={icon} alt={title || ''} className="reusableBtnIcon" />}
      {title}
    </div>
  );
}

export default ReusableButton;
