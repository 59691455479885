import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { addZerosinTime, showAmPm } from 'whealth-core-web/components/Helper';
import Select2DropDown from '../common/Select2DropDown';
import CoreButton from '../CoreButton';
import CoreInputBox from '../CoreInputBox';
import CoreTextArea from '../CoreTextArea';

function CommonDayPart(props) {
  const { valueObj, setValueObj, error, setError, diet, getData } = props;
  const [dayPartData, setDayPartData] = useState([]);
  const { id } = useParams;
  const ApiClient = useApiManager();
  useEffect(() => {
    getDayPart();
    handleOnFocus();
  }, []);

  const handleOnFocus = () => {
    let params = {
      page: 1,
    };
    ApiClient.getDayParts(params)
      .then((res) => {
        let tmpData = res.data;
        tmpData.map((item) => {
          item.title += ` (${formatAMPM(item.start_time)} - ${formatAMPM(item.end_time)})`;
        });
        setDayPartData(tmpData);
      })
      .catch((err) => console.log(err));
  };

  const formatAMPM = (time) => {
    let hours = null;
    let minutes = null;
    if (time.toString().length === 3) {
      hours = time.toString().slice(0, 1);
      minutes = time.toString().slice(1, 3);
    } else if (time.toString().length < 3) {
      minutes = time.toString().slice(0, 3);
    } else {
      hours = time.toString().slice(0, 2);
      minutes = time.toString().slice(2, 4);
    }

    let ampm = hours >= 12 && parseInt(hours) <= 23 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    let strTime = hours + ':' + (time.toString() < 10 ? '0' : '') + minutes + ' ' + ampm;
    return strTime;
  };

  const getDayPart = (query, id) => {
    if (!valueObj.searchData) {
      valueObj.searchData = [];
    }
    ApiClient.dayPartGet(query)
      .then((res) => {
        valueObj.searchData[id] = res.data;
        setValueObj({ ...valueObj });
      })
      .catch((err) => console.log(err));
  };

  let initialDayPartValue = {
    day_part: '',
    time: '',
  };

  let initialDayPartValueObj = { day_part_id: '', notification_time: '', recipe_link: '', regime: '' };

  const itrateTimeFromDayPart = (id) => {
    let singleData = dayPartData.find((item) => item.id == id);
    let startTime = singleData?.start_time;
    let endTime = singleData?.end_time;
    let timeArr = createArrayFromTime(startTime, endTime);

    return timeArr;
  };

  const removeFormField = (index) => {
    let windowConfirm = window.confirm('Remove Day Part');
    if (windowConfirm) {
      let dataObj = valueObj;
      dataObj.day_parts.splice(index, 1);
      error?.day_parts?.splice(index, 1);
      setError && setError({ ...error });
      setValueObj((prevState) => {
        return { ...prevState, ...dataObj };
      });
    }
  };

  const removeFormFieldForDiet = (index) => {
    let windowConfirm = window.confirm('Remove Diet Plan');
    if (windowConfirm) {
      let dataObj = valueObj;
      dataObj.diet_plans.splice(index, 1);
      error?.diet_plans?.splice(index, 1);
      setError && setError({ ...error });
      setValueObj((prevState) => {
        return { ...prevState, ...dataObj };
      });
    }
  };

  const addDayPart = () => {
    valueObj.day_parts.push(initialDayPartValue);
    setValueObj({ ...valueObj });
  };
  const addDayPartForDiet = () => {
    valueObj.diet_plans.push(initialDayPartValueObj);
    setValueObj({ ...valueObj });
  };

  const createArrayFromTime = (start, end) => {
    const timeRange = [];
    let startLoop = parseInt(start);
    let endLoop = parseInt(end);
    if (startLoop > endLoop) {
      endLoop += 2400;
    }
    while (startLoop <= endLoop) {
      if (!(startLoop % 100 == 0 || startLoop % 100 == 30)) {
        startLoop += 1;
        continue;
      }

      let obj = {};
      if (startLoop % 100 >= 60) {
        startLoop = Math.floor(startLoop / 100) * 100 + 100;
      }
      if (startLoop >= 1200) {
        obj.title = `${formatAMPM(startLoop)}`;
        obj.id = `${String(Number(startLoop))}`;
        timeRange.push(obj);
      } else {
        obj.title = `${formatAMPM(startLoop)} `;
        obj.id = `${String(Number(startLoop))}`;
        timeRange.push(obj);
      }
      startLoop += 1;
    }
    return timeRange;
  };

  const renderDayTimeFields = () => {
    if (valueObj?.day_parts) {
      return (
        <div className='w-100  mt-3'>
          {valueObj?.day_parts?.length ? (
            valueObj?.day_parts?.map((item, index) => {
              if (typeof valueObj.day_parts[index].day_part == 'object') {
                valueObj.day_parts[index].day_part = valueObj.day_parts[index].day_part.id;
              }

              let timeData = valueObj?.day_parts[index]?.day_part
                ? itrateTimeFromDayPart(valueObj?.day_parts[index]?.day_part)
                : [];

              return (
                <div
                  key={'daypart-row' + index}
                  className='d-flex justify-content-between w-100 gap-10 mb-2 daypart_box'
                >
                  <div className='daypart-input'>
                    <Select2DropDown
                      retuired
                      label='Day Part'
                      data={dayPartData}
                      style={{ width: '100%' }}
                      labelKey='title'
                      valueKey='id'
                      value={item.day_part}
                      onSearch={(query) => {
                        getDayPart(query, index);
                      }}
                      showMSG={(error?.day_parts && error?.day_parts[index] && error?.day_parts[index]?.day_part) || ''}
                      placeholder='Day Part'
                      // value={valueObj?.day_parts[index]?.day_part || ""}
                      defaultValue={valueObj?.day_parts[index]?.day_part || ''}
                      onSelect={(data) => {
                        valueObj.day_parts[index].day_part = data;
                        setValueObj({ ...valueObj });
                      }}
                      msgStyle={{ color: 'red' }}
                    />
                  </div>

                  <div className='daypart-input'>
                    <Select2DropDown
                      label='Notification Time'
                      data={timeData}
                      style={{ width: '100%' }}
                      labelKey='title'
                      valueKey='id'
                      showMSG={(error?.day_parts && error?.day_parts[index] && error?.day_parts[index]?.time) || ''}
                      placeholder='Time'
                      value={String(item.time) || ''}
                      defaultValue={String(item.time) || ''}
                      onSelect={(data) => {
                        valueObj.day_parts[index].time = data;
                        setValueObj({ ...valueObj });
                      }}
                      msgStyle={{ color: 'red' }}
                    />
                  </div>
                  <div className='d-flex justify-content-end align-items-end daypart-all-btn'>
                    <div className='d-flex day_part-btn gap-10'>
                      <span onClick={() => removeFormField(index)} className='material-icons pointer'>
                        close
                      </span>
                      {index + 1 == valueObj?.day_parts?.length && <CoreButton onClick={addDayPart} title={'+ Add'} />}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className='my-4'>
              <CoreButton outline onClick={addDayPart} title={'+ Add Day Part'} />
            </div>
          )}
        </div>
      );
    } else {
      return;
    }
  };

  const renderDayTimeForDietPlan = () => {
    if (valueObj?.diet_plans) {
      return (
        <div className='w-100  mt-3'>
          {valueObj?.diet_plans?.length ? (
            valueObj?.diet_plans?.map((item, index) => {
              if (typeof item.day_part_id == 'object') {
                item.day_part_id = item.day_part_id?.id;
              }

              let timeData = valueObj.diet_plans[index]?.day_part_id
                ? itrateTimeFromDayPart(valueObj.diet_plans[index]?.day_part_id)
                : [];

              return (
                <>
                  <div
                    key={'daypart-row' + index}
                    className='d-flex flex-column justify-content-between w-100 gap-10 mb-2 daypart_box'
                  >
                    <div className='d-flex justify-content-between gap-10'>
                      <Select2DropDown
                        retuired
                        label='Day Part'
                        data={dayPartData}
                        style={{ width: '100%' }}
                        labelKey='title'
                        valueKey='id'
                        className='w-100'
                        value={item?.day_part_id || ''}
                        defaultValue={valueObj.diet_plans[index].day_part_id || ''}
                        onSearch={(query) => {
                          getDayPart(query, index);
                        }}
                        showMSG={
                          (error?.diet_plans && error?.diet_plans[index] && error?.diet_plans[index]?.day_part_id) || ''
                        }
                        placeholder='Day Part'
                        onSelect={(data) => {
                          valueObj.diet_plans[index].day_part_id = data;
                          valueObj.diet_plans[index].notification_time = '';
                          setValueObj({ ...valueObj });
                        }}
                        msgStyle={{ color: 'red' }}
                      />

                      <Select2DropDown
                        retuired
                        className='w-100'
                        label='Time'
                        data={timeData}
                        style={{ width: '100%' }}
                        labelKey='title'
                        valueKey='id'
                        showMSG={
                          (error?.diet_plans &&
                            error?.diet_plans[index] &&
                            error?.diet_plans[index]?.notification_time) ||
                          ''
                        }
                        placeholder='Time'
                        value={String(item.notification_time || '')}
                        onSelect={(data) => {
                          valueObj.diet_plans[index].notification_time = data;
                          setValueObj({ ...valueObj });
                        }}
                        msgStyle={{ color: 'red' }}
                      />
                    </div>
                    <div className='flex-column'>
                      <div className='mt-3'>
                        <CoreTextArea
                          showMSG={
                            (error?.diet_plans && error?.diet_plans[index] && error?.diet_plans[index]?.regime) || ''
                          }
                          retuired
                          labelStyle={{ fontWeight: 'var(--lightWeight)' }}
                          label={'Diet Regime'}
                          placeholder={'Diet Regime'}
                          rows={5}
                          value={item.regime || ''}
                          setValue={(data) => {
                            valueObj.diet_plans[index].regime = data;
                            setValueObj({ ...valueObj });
                          }}
                        />
                      </div>
                      <div className='mt-3'>
                        <CoreInputBox
                          showMSG={
                            (error?.diet_plans && error?.diet_plans[index] && error?.diet_plans[index]?.recipe_link) ||
                            ''
                          }
                          placeholder='Recipe Link'
                          value={item.recipe_link || ''}
                          setValue={(data) => {
                            valueObj.diet_plans[index].recipe_link = data;
                            setValueObj({ ...valueObj });
                          }}
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end align-items-end daypart-all-btn w-100'>
                      <div className='d-flex day_part-btn gap-10 justify-content-end '>
                        {valueObj?.diet_plans?.length > 1 && (
                          <span onClick={() => removeFormFieldForDiet(index)} className='material-icons pointer'>
                            close
                          </span>
                        )}

                        {index + 1 == valueObj?.diet_plans?.length && (
                          <CoreButton onClick={addDayPartForDiet} title={'+ Add'} />
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <div className='my-4'>
              <CoreButton outline onClick={addDayPartForDiet} title={'+ Add Day Part'} />
            </div>
          )}
        </div>
      );
    } else {
      return;
    }
  };

  return <div>{diet ? renderDayTimeForDietPlan() : renderDayTimeFields()}</div>;
}

export default CommonDayPart;
