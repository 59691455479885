import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAlerts } from "redux/Slices";
import { scrollToTop } from "./Helper";

function CommonAlert(props) {
  const { isShowAlert, setIsShowAlert, alertType, className, redux } = props;
  const { alerts } = useSelector((state) => state.sliceReducer);
  const dispatch = useDispatch();

  const alertKey = alerts[redux];
  const firstKey = Object.keys(alerts)[0];

  useEffect(() => {
    if (isShowAlert || alertKey) {
      scrollToTop();
    }
  }, [isShowAlert, alertKey]);

  useEffect(() => {
    if (!redux) {
      setTimeout(() => {
        setIsShowAlert(false);
      }, 6000);
    }
  }, [isShowAlert]);

  useEffect(() => {
    if (redux && alertKey) {
      setTimeout(() => {
        dispatch(setAlerts({}));
      }, 6000);
    }
  }, [alertKey]);

  if (redux && alertKey) {
    if (firstKey == redux) {
      return (
        <div
          className={`alert ${alertKey?.type || "alert-success"} alert-dismissible fade show ${className}`}
          role="alert"
        >
          {alertKey?.title}
          <button
            type="button"
            onClick={() => dispatch(setAlerts({}))}
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      );
    }
  } else {
    if (isShowAlert) {
      let showAlert = "";
      if (typeof isShowAlert == "string") {
        showAlert = isShowAlert;
      } else if (typeof isShowAlert == "object") {
        showAlert = Object.values(isShowAlert)[0];
      }
      return (
        <div className={`alert ${alertType || "alert-success"} alert-dismissible fade show ${className}`} role="alert">
          {showAlert}
          <button
            type="button"
            onClick={() => setIsShowAlert(false)}
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      );
    }
  }
  return;
}

export default CommonAlert;
