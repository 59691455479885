const TEXT = 'text';
const INTEGER = 'integer';
const INTEGER_RANGE = 'integer_range';
const EQUALS = 'equals';
const NOT_EQUALS = 'not_equal';
const DATE = 'date';
const DATE_RANGE = 'date_range';

const EQUAL_OR_NOT_EQUAL_OPTIONS = [
  {
    title: 'Equal',
    value: EQUALS,
  },
  {
    title: 'Does Not equal to',
    value: NOT_EQUALS,
  },
];

const EQUAL_OR_NOT_EQUAL_OPTIONS_WITH_INPUT = [
  {
    title: 'Equal',
    value: EQUALS,
    inputType: TEXT,
  },
  {
    title: 'Does Not equal to',
    value: NOT_EQUALS,
    inputType: TEXT,
  },
];

const GENDER_OPTIONS = [
  { title: 'Male', value: 'male' },
  { title: 'Female', value: 'female' },
];

const BETWEEN_OPTIONS = [
  {
    title: 'In',
    value: 'in',
    inputType: INTEGER,
  },
  {
    title: 'Not in',
    value: 'not_in',
    inputType: INTEGER,
  },

  {
    title: 'Between',
    value: 'between',
    inputType: INTEGER_RANGE,
  },

  {
    title: 'Not Between',
    value: 'not_between',
    inputType: INTEGER_RANGE,
  },
];

const DATE_TYPE_OPTIONS = [
  {
    title: 'Today',
    value: 'today',
  },
  {
    title: 'Tomorrow',
    value: 'tomorrow',
  },
  {
    title: 'Yesterday',
    value: 'yesterday',
  },
  {
    title: 'Specific Date',
    value: 'specific_date',
    inputType: DATE,
  },
];

const DATE_TYPE_RANGE_OPTIONS = [
  {
    title: 'Current month',
    value: 'current_month',
  },
  {
    title: 'Upcoming Month',
    value: 'upcoming_month',
  },
  {
    title: 'Previous Month',
    value: 'previous_month',
  },
  {
    title: 'Current Year',
    value: 'current_year',
  },
  {
    title: 'Specific Date Range',
    value: 'specific_date_range',
    inputType: DATE_RANGE,
  },
];

const DATE_OPTIONS = [
  {
    title: 'On',
    value: 'on',
    valueOptions: DATE_TYPE_OPTIONS,
  },
  {
    title: 'Not on ',
    value: 'not_on',
    valueOptions: DATE_TYPE_OPTIONS,
  },
  {
    title: 'WithIn',
    value: 'within',
    valueOptions: DATE_TYPE_RANGE_OPTIONS,
  },
  {
    title: 'Not within',
    value: 'not_within',
    valueOptions: DATE_TYPE_RANGE_OPTIONS,
  },
  {
    title: 'Between',
    value: 'between',
    inputType: DATE_RANGE,
  },
  {
    title: 'Before',
    value: 'before',
    inputType: DATE,
  },
  {
    title: 'After',
    value: 'after',
    inputType: DATE,
  },
  {
    title: 'Day Ago',
    value: 'day_ago',
    inputType: INTEGER,
  },
];

const NUMBER_OPTIONS = [
  {
    title: 'Less than',
    value: 'less_than',
    inputType: INTEGER,
  },
  {
    title: 'Less than or equal to',
    value: 'less_than_equal_to',
    inputType: INTEGER,
  },
  {
    title: 'Greater than',
    value: 'greater_than',
    inputType: INTEGER,
  },
  {
    title: 'Greater than Equal To',
    value: 'greater_than_equal_to',
    inputType: INTEGER,
  },
  {
    title: 'Equal',
    value: EQUALS,
    inputType: INTEGER,
  },
  {
    title: 'Does Not equal to',
    value: NOT_EQUALS,
    inputType: INTEGER,
  },
  {
    title: 'With in ',
    value: 'within',
    inputType: INTEGER_RANGE,
  },
  {
    title: 'Not within',
    value: 'not_within',
    inputType: INTEGER_RANGE,
  },
];

const SegmentDataAttributeList = [
  {
    title: 'Age',
    value: 'age',
    opratorOptions: NUMBER_OPTIONS,
    limit: 3,
    max: 150,
  },
  {
    title: 'Gender',
    value: 'gender',
    opratorOptions: EQUAL_OR_NOT_EQUAL_OPTIONS,
    valueOptions: GENDER_OPTIONS,
  },
  {
    title: 'PinCode',
    value: 'pin_code',
    inputType: INTEGER,
    limit: 6,
    opratorOptions: BETWEEN_OPTIONS,
  },
  {
    title: 'City',
    value: 'city',
    limit: 20,
    opratorOptions: EQUAL_OR_NOT_EQUAL_OPTIONS_WITH_INPUT,
  },
  {
    title: 'Guest Type',
    value: 'channel',
    opratorOptions: EQUAL_OR_NOT_EQUAL_OPTIONS_WITH_INPUT,
    // valueOptions: 'channel_options',
  },
  {
    title: 'Sign up user',
    value: 'created_at',
    opratorOptions: DATE_OPTIONS,
    inputType: DATE,
    operatorTitle: 'Duration',
  },
  {
    title: 'Blood Test Date',
    value: 'blood_test_date',
    opratorOptions: DATE_OPTIONS,
    inputType: DATE,
    operatorTitle: 'Duration',
  },
  {
    title: 'Consultant Date',
    value: 'consultant_date',
    opratorOptions: DATE_OPTIONS,
    inputType: DATE,
    operatorTitle: 'Duration',
  },
  {
    title: 'Goals',
    value: 'consultant_date',
    opratorOptions: DATE_OPTIONS,
    inputType: DATE,
    operatorTitle: 'Duration',
  },
];

export default SegmentDataAttributeList;
