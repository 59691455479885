import { createApi } from "@reduxjs/toolkit/query/react";
import endPoints from "networking/endPoints";
import auth_axios from "networking/AuthAxios";

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, body, params }) => {
    try {
      const result = await auth_axios({ url: baseUrl + url, method, data: body, params });
      return { data: result };
    } catch (err) {
      return { data: err.response };
    }
  };

export const cockpitSlice = createApi({
  reducerPath: "cockpit",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["cockpitConf"],

  endpoints: (builder) => ({
    getCockpitData: builder.query({
      query: () => {
        return {
          url: endPoints.BUSINESSCONFIGCOCKPIT,
          method: "get",
        };
      },
      providesTags: ["cockpitConf"],
    }),

    updateCockpitConfigData: builder.mutation({
      query: (params) => {
        return {
          url: endPoints.BUSINESSCONFIGCOCKPIT,
          method: "patch",
          body: params,
        };
      },

      invalidatesTags: ["cockpitConf"],
    }),
  }),
});

export const { useGetCockpitDataQuery, useUpdateCockpitConfigDataMutation } = cockpitSlice;
