import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CommonAlert, CoreButton, CoreDropDown, CoreInputBox, RenderCheckBoxes } from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import CommonDayPart from 'whealth-core-web/components/DayPart/CommonDayPart';
import { valiDateData } from 'whealth-core-web/components/Helper';

function AddAttatchGoalForm(props) {
  const {
    valueObj,
    open,
    error,
    errors,
    setValueObj,
    setIsLoading,
    onClick,
    data,
    attatchData,
    pid,
    behaviourQuestionPrevious,
    attatchGoal,
    GoalTitle,
    setInitialValues,
    UpdateDataId,
    behaviourQuestion,
    attatchGoalShowBtn,
    behaviorGoal,
    isShowAlert,
    setIsShowAlert,
    alertType,
    setAttatchError,
  } = props;
  const ApiClient = useApiManager();
  const [goalsData, setGoalsData] = useState({});
  const [measuringUnit, setMeasuringUnit] = useState('');
  const [searchData, setSearchData] = useState({});
  const [suggestionList, setSuggestionList] = useState([]);

  const isMedicineGoal = !!attatchData?.medicinal_goal;
  const clearMenu = useRef(null);

  useEffect(() => {
    if (Object.keys(attatchData).length > 0) {
      attatchData.goal_id = attatchData?.id;
      setValueObj({ ...attatchData });
      setMeasuringUnit(attatchData?.measuring_event?.measuring_unit);
      setAttatchError({});
    }
  }, [attatchData]);
  const typeArray = [
    { title: 'Daily', id: 'daily' },
    { title: 'Weekly', id: 'weekly' },
    { title: 'Monthly', id: 'monthly' },
    { title: 'Yearly', id: 'yearly' },
  ];

  useEffect(() => {
    handleSetSuggestionList();
  }, []);

  const renderDuration = () => {
    if (isMedicineGoal) {
      return (
        <div className='w-100 ' style={{ margin: '20px 0 ' }}>
          <CoreInputBox
            disabled={attatchData.status == 'enable'}
            data={attatchData}
            showMSG={errors?.duration}
            value={valueObj?.duration || ''}
            placeholder='Duration'
            label='Duration'
            validateNumbers
            setValue={(data) => {
              setValueObj({ ...valueObj, duration: data });
            }}
            maxLength={255}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
      );
    } else {
      return;
    }
  };

  const renderValuefield = () => {
    if (isMedicineGoal) {
      return (
        <div className='w-100 ' style={{ margin: '20px 0 ' }}>
          <div className='mb-2'>Value</div>
          <RenderCheckBoxes
            data={{
              value: valueObj.value,
              targetValue: '111',
              returnValue: valueObj,
              setreturnValue: setValueObj,
              enable: true,
              disabled: attatchData.status == 'enable',
            }}
          />
          {errors?.value && <div className='text-danger'>{errors?.value}</div>}
        </div>
      );
    } else {
      return (
        <div className='w-100 d-flex' style={{ margin: '20px 0 ' }}>
          <div className='w-100'>
            <CoreInputBox
              disabled={attatchData.status == 'enable'}
              inputStyle={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              data={attatchData}
              showMSG={errors?.value}
              value={valueObj?.value || ''}
              placeholder='Value'
              label='Value'
              setValue={(data) => {
                setValueObj({ ...valueObj, value: data });
              }}
              maxLength={255}
              msgStyle={{ color: 'red' }}
              retuired
            />
          </div>
          <div className='mt-4'>
            <div className='unitBox capitalize'>{measuringUnit ? measuringUnit : 'Unit'}</div>
          </div>
        </div>
      );
    }
  };

  const handleSetSuggestionList = async () => {
    if (!suggestionList.length) {
      setIsLoading(true);
      let res = await ApiClient.getGoals({ page: 1 })
        .then((res) => setSuggestionList(res.data.slice(0, 5)))
        .catch((err) => {});

      setIsLoading(false);
    }
  };

  const renderSearchBox = () => {
    return (
      <div>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        <SearchBox
          disabled={behaviorGoal}
          newRef={clearMenu}
          defaultInputValue={behaviorGoal}
          placeHolder='Search Goal'
          searchMethod='GOALSEARCH'
          label='title'
          imagePath='image_url'
          stopNavigation
          suggestionList={suggestionList}
          hanldeSuggestions={handleSetSuggestionList}
          minLength={0}
        />
      </div>
    );
  };

  return (
    <div className='measuringForm'>
      <div style={{ display: 'flex', flexDirection: 'column' }} className='d-flex w-100 flexmeasuringForm'>
        <div className='w-100'>
          <div className='d-flex'>
            <span className='requiredInput me-1'> * </span>
            <div className='mb-2'>Goal Name</div>
          </div>
          {renderSearchBox()}
          {error?.goal_id && (
            <div className='inputMsg' style={{ color: 'var(--dustRed)' }}>
              {error?.goal_id}
            </div>
          )}
        </div>
      </div>

      <div className='d-md-flex  ' style={{ gap: '15px' }}>
        {attatchData?.type != 'logging' && searchData.type != 'logging' && renderValuefield()}

        <div className='w-100' style={{ margin: '20px 0 ' }}>
          <CoreDropDown
            disabled={attatchData.status == 'enable'}
            data={typeArray}
            showMSG={error?.frequency}
            placeholder='Frequency'
            value={valueObj?.frequency || ''}
            label='Frequency'
            setValue={(data) => {
              setValueObj({ ...valueObj, frequency: data });
            }}
            retuired
            msgStyle={{ color: 'red' }}
          />
        </div>
      </div>

      {/* {renderDuration()} */}

      <div style={{ margin: '0 0 20px 0' }}>
        <CoreInputBox
          data={attatchData}
          showMSG={error?.note}
          placeholder='Note'
          value={valueObj?.note || ''}
          label='Note'
          setValue={(data) => {
            setValueObj({ ...valueObj, note: data });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>

      <CommonDayPart valueObj={valueObj} setValueObj={setValueObj} error={errors} />

      <CoreButton onClick={onClick} title={'Attach Goal'} />
    </div>
  );
}

export default AddAttatchGoalForm;
