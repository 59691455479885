import React, { useEffect, useState } from 'react';
import Select2DropDown from '../common/Select2DropDown';
import CommonBorderBox from '../CommonBorderBox';
import CoreButton from '../CoreButton';
import CoreCheckBox from '../CoreCheckBox';
import CoreInputBox from '../CoreInputBox';
import useApiManager from 'networking/ApiManager';
import CommonAlert from '../CommonAlert';
import CommonDayPart from '../DayPart/CommonDayPart';
import CoreTextArea from '../CoreTextArea';
import { useParams } from 'react-router-dom';

function DietTemplateForm(props) {
  const [open, setOpen] = useState(false);

  const ApiClient = useApiManager();
  const {
    isEditMode,
    setEditMode,
    questionsList,
    getDietTemplatesList,
    dietTemplate,
    setDietTemplate,
    setQuestionsList,
    setIsUpdateAlert,
    setIsUpdateAlertType,
    setIsShowAlert,
    setAlertType,
    onClick,
    data,
    error,
  } = props;

  const [errors, setErrors] = useState({});

  const [dayPartData, setDayPartData] = useState([]);
  const { id } = useParams;

  useEffect(() => {
    getDayPart();
    handleOnFocus();
  }, []);

  const handleOnFocus = () => {
    let params = {
      page: 1,
    };
    ApiClient.getDayParts(params)
      .then((res) => {
        let tmpData = res.data;
        tmpData.map((item) => {
          item.title += ` (${formatAMPM(item.start_time)} - ${formatAMPM(item.end_time)})`;
        });
        setDayPartData(tmpData);
      })
      .catch((err) => console.log(err));
  };

  const formatAMPM = (time) => {
    let hours = null;
    let minutes = null;
    if (time.toString().length === 3) {
      hours = time.toString().slice(0, 1);
      minutes = time.toString().slice(1, 3);
    } else if (time.toString().length < 3) {
      minutes = time.toString().slice(0, 3);
    } else {
      hours = time.toString().slice(0, 2);
      minutes = time.toString().slice(2, 4);
    }

    let ampm = hours >= 12 && parseInt(hours) <= 23 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    let strTime = hours + ':' + (time.toString() < 10 ? '0' : '') + minutes + ' ' + ampm;
    return strTime;
  };

  const getDayPart = (query, id) => {
    if (!dietTemplate.searchData) {
      dietTemplate.searchData = [];
    }
    ApiClient.dayPartGet(query)
      .then((res) => {
        dietTemplate.searchData[id] = res.data;
        setDietTemplate({ ...dietTemplate });
      })
      .catch((err) => console.log(err));
  };

  let initialDayPartValue = {
    day_part: '',
    time: '',
  };

  let initialDayPartdietTemplate = {
    day_part_id: '',
    notification_time: '',
    diet_plan_food_items: [
      {
        food: '',
        quantity: '',
        recipe_link: '',
        twins: '1',
      },
    ],
  };

  const itrateTimeFromDayPart = (id) => {
    let singleData = dayPartData.find((item) => item.id == id);
    let startTime = singleData?.start_time;
    let endTime = singleData?.end_time;
    let timeArr = createArrayFromTime(startTime, endTime);

    return timeArr;
  };

  const removeFormField = (index) => {
    let windowConfirm = window.confirm('Remove Day Part');
    if (windowConfirm) {
      let dataObj = dietTemplate;
      dataObj.day_parts.splice(index, 1);
      error?.day_parts?.splice(index, 1);
      setErrors && setErrors({ ...error });
      setDietTemplate((prevState) => {
        return { ...prevState, ...dataObj };
      });
    }
  };

  const removeFormFieldForDiet = (index) => {
    let windowConfirm = window.confirm('Remove Day Part');
    if (windowConfirm) {
      let dataObj = dietTemplate;
      dataObj.diet_plans.splice(index, 1);
      error?.diet_plans?.splice(index, 1);
      setErrors && setErrors({ ...error });
      setDietTemplate((prevState) => {
        return { ...prevState, ...dataObj };
      });
    }
  };

  const addDayPart = () => {
    dietTemplate.day_parts.push(initialDayPartValue);
    setDietTemplate({ ...dietTemplate });
  };

  const addDayPartForDiet = () => {
    setDietTemplate((prev) => ({
      ...prev,
      diet_plans: [
        ...prev.diet_plans,
        {
          day_part_id: '',
          notification_time: '',
          diet_plan_food_items: [
            {
              food: '',
              quantity: '',
              recipe_link: '',
              twins: '1',
            },
          ],
        },
      ],
    }));
  };

  const [inputValues, setInputValues] = useState([{ id: 1, value: '' }]);

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index].value = value;
    setInputValues(newInputValues);
  };

  const [foodItems, setFoodItems] = useState(dietTemplate?.diet_plans || []);

  const addNewFood = (dayPartIndex) => {
    setDietTemplate((prevValue) => {
      const existingFoodItems = prevValue.diet_plans[dayPartIndex].diet_plan_food_items;
      const newFoodItem = {
        food: '',
        quantity: '',
        recipe_link: '',
        twins: String(
          existingFoodItems.length > 0 ? Number(existingFoodItems[existingFoodItems.length - 1].twins) + 1 : 1
        ),
      };

      return {
        ...prevValue,
        diet_plans: prevValue.diet_plans.map((dayPart, index) => {
          if (index === dayPartIndex) {
            return {
              ...dayPart,
              diet_plan_food_items: [...dayPart.diet_plan_food_items, newFoodItem],
            };
          }

          return dayPart;
        }),
      };
    });
  };

  const removeInputBox = (optionIndex, setIndex) => {
    setDietTemplate((prev) => {
      const newDietPlans = [...prev.diet_plans];
      newDietPlans[optionIndex].diet_plan_food_items.splice(setIndex, 1);
      return { ...prev, diet_plans: newDietPlans };
    });
  };

  const addInputBox = (optionIndex, setIndex) => {
    setDietTemplate((prev) => {
      const newDietPlans = [...prev.diet_plans];
      const currentSet = newDietPlans[optionIndex].diet_plan_food_items[setIndex];

      const currentTwins = currentSet.twins;

      const newSet = {
        food: '',
        quantity: '',
        recipe_link: '',
        twins: currentTwins,
      };

      newDietPlans[optionIndex].diet_plan_food_items.splice(setIndex + 1, 0, newSet);

      return { ...prev, diet_plans: newDietPlans };
    });
  };

  const handleChange = (optionIndex, setIndex, field, data) => {
    setDietTemplate((prev) => {
      const newDietPlans = [...prev.diet_plans];
      newDietPlans[optionIndex].diet_plan_food_items[setIndex][field] = data;
      return { ...prev, diet_plans: newDietPlans };
    });
  };

  const createArrayFromTime = (start, end) => {
    const timeRange = [];
    let startLoop = parseInt(start);
    let endLoop = parseInt(end);
    if (startLoop > endLoop) {
      endLoop += 2400;
    }
    while (startLoop <= endLoop) {
      if (!(startLoop % 100 == 0 || startLoop % 100 == 30)) {
        startLoop += 1;
        continue;
      }

      let obj = {};
      if (startLoop % 100 >= 60) {
        startLoop = Math.floor(startLoop / 100) * 100 + 100;
      }
      if (startLoop >= 1200) {
        obj.title = `${formatAMPM(startLoop)}`;
        obj.id = `${String(Number(startLoop))}`;
        timeRange.push(obj);
      } else {
        obj.title = `${formatAMPM(startLoop)} `;
        obj.id = `${String(Number(startLoop))}`;
        timeRange.push(obj);
      }
      startLoop += 1;
    }
    return timeRange;
  };

  const onsucccess = () => {
    getDietTemplatesList();
    setDietTemplate({
      diet_plans: [
        {
          day_part_id: '',
          notification_time: '',
          diet_plan_food_items: [
            {
              food: '',
              quantity: '',
              recipe_link: '',
              twins: '1',
            },
          ],
        },
      ],
    });
    setErrors({});
  };

  const setAlert = (alertMsg, alertType) => {
    setAlertType(alertType);
    setIsShowAlert(alertMsg);
  };

  const createDietTemplate = () => {
    const requestBody = {
      id: dietTemplate.id,
      title: dietTemplate.title,
      default: dietTemplate.default,
      guidelines: dietTemplate.guidelines,
      diet_plan_day_parts: dietTemplate.diet_plans.map((item) => ({
        notification_time: item.notification_time,
        day_part_id: item.day_part_id,
        diet_plan_food_items: item.diet_plan_food_items,
      })),
    };
    setErrors({});
    ApiClient.createDietTemplate(requestBody)
      .then((res) => {
        onsucccess();
        setAlert('Diet Template Added Successfully', 'alert-success');
      })
      .catch((err) => {
        if (err?.response?.data?.errors?.non_field_errors) {
          setIsShowAlert(err?.response?.data?.errors?.non_field_errors[0]);
          setAlertType('alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        }
      });
  };

  const updateDietTemplate = () => {
    const modalBody = document.querySelector('.rs-modal-body');
    if (modalBody) {
      modalBody.scrollTop = 0;
    }
    const requestBody = {
      id: dietTemplate.id,
      title: dietTemplate.title,
      default: dietTemplate.default,
      guidelines: dietTemplate.guidelines,
      diet_plan_day_parts: dietTemplate.diet_plans.map((item) => ({
        notification_time: item.notification_time,
        day_part_id: item.day_part_id,
        diet_plan_food_items: item.diet_plan_food_items,
      })),
    };
    ApiClient.updateDietTemplate(dietTemplate.id, requestBody)
      .then((res) => {
        onsucccess();
        setEditMode(false);
        setAlert('Diet Template Updated Successfully', 'alert-success');
      })
      .catch((err) => {
        console.warn('err?.response', err?.response);
        if (err?.response?.data?.errors?.non_field_errors) {
          setIsUpdateAlert(err?.response?.data?.errors?.non_field_errors[0]);
          setIsUpdateAlertType('alert-danger');
        } else if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        } else {
          setErrors({});
        }
      });
  };

  const renderDayTimeForDietPlan = () => {
    if (dietTemplate?.diet_plans) {
      return (
        <div className='w-100  mt-3'>
          {dietTemplate?.diet_plans?.length ? (
            dietTemplate?.diet_plans?.map((item, index) => {
              if (typeof item.day_part_id == 'object') {
                item.day_part_id = item.day_part_id?.id;
              }

              let timeData = dietTemplate.diet_plans[index]?.day_part_id
                ? itrateTimeFromDayPart(dietTemplate.diet_plans[index]?.day_part_id)
                : [];
              const dietPlans = dietTemplate?.diet_plans || [];

              return (
                <>
                  <div
                    key={'daypart-row' + index}
                    className='d-flex flex-column justify-content-between w-100 gap-10 mb-2 daypart_box'
                  >
                    <div className='d-flex justify-content-between gap-10'>
                      <Select2DropDown
                        retuired
                        label='Day Part'
                        data={dayPartData}
                        style={{ width: '100%' }}
                        labelKey='title'
                        valueKey='id'
                        className='w-100'
                        value={item?.day_part_id || ''}
                        defaultValue={dietTemplate.diet_plans[index].day_part_id || ''}
                        onSearch={(query) => {
                          getDayPart(query, index);
                        }}
                        showMSG={
                          errors?.diet_plan_day_parts?.length &&
                          errors?.diet_plan_day_parts[index] &&
                          errors?.diet_plan_day_parts[index]?.day_part_id
                        }
                        placeholder='Day Part'
                        onSelect={(data) => {
                          dietTemplate.diet_plans[index].day_part_id = data;
                          dietTemplate.diet_plans[index].notification_time = '';
                          setDietTemplate({ ...dietTemplate });
                        }}
                        msgStyle={{ color: 'red' }}
                      />

                      <Select2DropDown
                        retuired
                        className='w-100'
                        label='Time'
                        data={timeData}
                        style={{ width: '100%' }}
                        labelKey='title'
                        valueKey='id'
                        showMSG={
                          errors?.diet_plan_day_parts?.length &&
                          errors?.diet_plan_day_parts[index] &&
                          errors?.diet_plan_day_parts[index]?.notification_time
                        }
                        placeholder='Time'
                        value={String(item.notification_time || '')}
                        onSelect={(data) => {
                          dietTemplate.diet_plans[index].notification_time = data;
                          setDietTemplate({ ...dietTemplate });
                        }}
                        msgStyle={{ color: 'red' }}
                      />
                    </div>
                    <div>
                      <label>
                        <h7 className='inputlabel mb-2 mt-4'>Diet Regime</h7>
                      </label>

                      <div key={index}>
                        {item.diet_plan_food_items.map((set, setIndex) => (
                          <>
                            {setIndex > 0 &&
                              item.diet_plan_food_items[setIndex]?.twins !=
                                item.diet_plan_food_items[setIndex - 1]?.twins && (
                                <hr style={{ borderBlockColor: '#000000d4' }} />
                              )}
                            <div className='d-flex justify-content-between gap-10 mt-10 mb-10'>
                              <div className='mb-2 w-100'>
                                <CoreInputBox
                                  retuired
                                  label='Food'
                                  value={set.food}
                                  showMSG={
                                    errors?.diet_plan_day_parts?.length &&
                                    errors?.diet_plan_day_parts[index] &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items?.length &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items[setIndex]?.food
                                  }
                                  labelKey='food'
                                  placeholder='Food'
                                  style={{ width: '100%' }}
                                  className='w-100'
                                  setValue={(data) => handleChange(index, setIndex, 'food', data)}
                                />
                              </div>
                              <div className='mb-2 w-100'>
                                <CoreInputBox
                                  retuired
                                  label='Quantity'
                                  value={set.quantity}
                                  showMSG={
                                    errors?.diet_plan_day_parts &&
                                    errors?.diet_plan_day_parts[index] &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items?.length &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items[setIndex]?.quantity
                                  }
                                  labelKey='quantity'
                                  placeholder='Quantity'
                                  style={{ width: '100%' }}
                                  className='w-100'
                                  setValue={(data) => handleChange(index, setIndex, 'quantity', data)}
                                />
                              </div>
                              <div className='mb-2 w-100'>
                                <CoreInputBox
                                  label='Recipe Link'
                                  labelKey='recipe_link'
                                  value={set.recipe_link || ''}
                                  showMSG={
                                    errors?.diet_plan_day_parts &&
                                    errors?.diet_plan_day_parts[index] &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items?.length &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items[setIndex]?.recipe_link
                                  }
                                  placeholder='Recipe Link'
                                  style={{ width: '100%' }}
                                  className='w-100'
                                  setValue={(data) => handleChange(index, setIndex, 'recipe_link', data)}
                                />
                              </div>
                              <div className='d-flex align-items-end day_part-btn mb-2'>
                                {item.diet_plan_food_items.length > 1 && (
                                  <span onClick={() => removeInputBox(index, setIndex)} class='material-icons pointer'>
                                    close
                                  </span>
                                )}
                                <div className='ms-2 w-100'>
                                  <CoreButton onClick={() => addInputBox(index, setIndex)} title='+' />
                                </div>
                              </div>
                            </div>
                          </>
                        ))}

                        {/* <hr style={{ borderBlockColor: '#000000d4' }} /> */}
                      </div>

                      <div className='d-flex justify-content-end align-items-end daypart-all-btn w-100'>
                        <div className='ms-2'>
                          <CoreButton onClick={() => addNewFood(index)} title='+ Add Food Item' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='d-flex day_part-btn gap-10 justify-content-end '
                    style={{ marginBottom: '10px', marginTop: '10px' }}
                  >
                    {dietTemplate?.diet_plans?.length > 1 && (
                      <span onClick={() => removeFormFieldForDiet(index)} className='material-icons pointer'>
                        close
                      </span>
                    )}
                    {dietTemplate?.diet_plans?.length - 1 == index ? (
                      <CoreButton onClick={addDayPartForDiet} title={'+ Add Day Part'} />
                    ) : null}
                  </div>
                </>
              );
            })
          ) : (
            <div className='my-4'>
              <CoreButton outline onClick={addDayPartForDiet} title={'+ Add Day Part'} />
            </div>
          )}
        </div>
      );
    } else {
      return;
    }
  };

  return (
    <div className='measuringForm'>
      <div className='d-flex w-100 flexmeasuringForm'>
        <div className='w-100'>
          <CoreInputBox
            retuired
            showMSG={errors?.title}
            placeholder={'Diet Template Name'}
            value={dietTemplate?.title || ''}
            label={'Diet Template Name'}
            setValue={(data) => setDietTemplate({ ...dietTemplate, title: data })}
            msgStyle={{ color: 'red' }}
            maxLength={255}
          />
          {renderDayTimeForDietPlan()}
        </div>
      </div>

      <div className='mt-3'>
        <CoreTextArea
          showMSG={(error?.diet_plans && error?.diet_plans && error?.diet_plans?.regime) || ''}
          labelStyle={{ fontWeight: 'var(--lightWeight)' }}
          label={'guidelines'}
          placeholder={'Enter Guidelines'}
          rows={5}
          value={dietTemplate?.guidelines || ''}
          setValue={(data) => {
            setDietTemplate((prevDietTemplate) => ({
              ...prevDietTemplate,
              guidelines: data,
            }));
          }}
        />
      </div>

      <div className='mt-4'>
        <div className='d-flex'>
          <CoreCheckBox
            id={isEditMode ? 'defaultTemp' : 'Default'}
            htmlFor={isEditMode ? 'defaultTemp' : 'Default'}
            checked={dietTemplate.default}
            className={'me-4'}
            title='Make as Default'
            onChange={(val, checked) => {
              setDietTemplate({ ...dietTemplate, default: checked });
            }}
          />
        </div>
      </div>

      <CoreButton
        onClick={isEditMode ? updateDietTemplate : createDietTemplate}
        title={isEditMode ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default DietTemplateForm;
