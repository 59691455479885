import React from 'react';
import { arrowLeft, ArrowRight, arrowRight } from 'res/images';
import { scrollToTop } from './Helper';
import './pagination.scss';

const Pagination = (props) => {
  const { totalPages, currentPage, setCurrentPage, data, length, seterror, dashboard, scroll } = props;

  const pagesList = () => {
    let arr = new Array();
    arr.length = totalPages;
    arr.fill(0);
    return arr.map((item, index) => {
      const page = index + 1;
      if (page > currentPage - 3 && page < currentPage + 3) {
        return (
          <div
            className={`pageDiv ${currentPage == page ? 'activePage' : ''}`}
            key={index}
            onClick={() => {
              setCurrentPage(page);
              // seterror(false);
            }}
          >
            {page}
          </div>
        );
      } else {
        return;
      }
    });
  };

  const renderPages = () => {
    return (
      <div className={`d-flex justify-content-between align-items-center w-100 ${!dashboard ? 'paginationPart' : ''}`}>
        <div className='totalResult'>
          {length ? (
            <>
              Total Result:
              <span>{length}</span>
            </>
          ) : null}
        </div>
        {totalPages > 1 ? (
          <div onClick={!scroll && scrollToTop} className='paginationDiv'>
            {currentPage != 1 && (
              <div className='pageDiv' onClick={() => setCurrentPage(currentPage - 1)}>
                <img src={arrowLeft} />
              </div>
            )}
            {pagesList()}
            {totalPages > currentPage && (
              <div className='pageDiv' onClick={() => setCurrentPage(currentPage + 1)}>
                <img src={ArrowRight} />
              </div>
            )}
          </div>
        ) : null}
      </div>
    );
  };
  return renderPages();
};

export default Pagination;
