import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Modal, Panel, PanelGroup } from 'rsuite';
import { dateFormat, profileImage } from '../Helper';
import Loader from '../Loader';

function TeleconsultationDetail() {
  const [open, setOpen] = useState(false);
  const [sessionData, setSessionData] = useState([]);
  const [modalSessionData, setModalSessionData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const ApiClient = useApiManager();
  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    sessionDetails();
  }, []);

  const sessionDetails = () => {
    setIsLoading(true);
    // let params = { room_id: '657dee912230de2e7bd2db35' };
    ApiClient.teleConsultationDetail(id, location?.state?.teleId)
      .then((res) => {
        setIsLoading(false);
        setSessionData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const modalSessionDetails = (sessionId) => {
    setOpen(true);
    setIsLoading(true);
    let params = { session_id: sessionId };
    ApiClient.modalTeleConsultationDetail(id, location?.state?.teleId, params)
      .then((res) => {
        setIsLoading(false);
        setModalSessionData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const modalDownloadBtnDetails = (assetId) => {
    setOpen(true);
    setIsLoading(true);
    let params = { asset_id: assetId };
    ApiClient.modalDownloadButtonDetail(id, location?.state?.teleId, params)
      .then((res) => {
        setIsLoading(false);
        if (res.data.url) {
          window.open(res.data.url, '_blank');
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails.name,
      link: `/patient/${location.state.teleId}/dashboard`,
    },
    { title: '' + ' ' + 'Session Details', link: '' },
  ];

  const renderPannelHeader = (session, index) => {
    return (
      <div className='d-flex justify-content-between'>
        <div>{`session ${index + 1}`}</div>
        <div className='me-4'>{`Create at ${dateFormat(session.created_at)}`}</div>
      </div>
    );
  };

  const showPatient = () => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>

        <div className='addnewform-part'>{''} Session Details</div>
        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        <div className=' '>
          <PanelGroup accordion defaultActiveKey={0}>
            <div className='d-flex justify-content-center'>{sessionData.data ? '' : 'No Sessions found'}</div>

            {sessionData?.data?.map((session, index) => {
              return (
                <Panel header={renderPannelHeader(session, index)} eventKey={index} id={'panel' + index}>
                  <div className='accordion-part mb-3'>
                    <>
                      <div className='d-flex justify-content-end profile-call-log-btn '>
                        <button onClick={() => modalSessionDetails(session.id)} className='d-flex  w-20 call-log-btn '>
                          View Assets
                        </button>
                      </div>
                      <div className='table-responsive'>
                        <table class='table table-striped'>
                          <thead>
                            <tr>
                              <th scope='col'>Sr. No.</th>
                              <th scope='col'>Peers Name</th>
                              <th scope='col'>Role</th>
                              <th scope='col'>Join At</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.values(session.peers).map((peerItem, peerIndex) => {
                              return (
                                <tr>
                                  <td scope='row'>{peerIndex + 1}</td>
                                  <td>{peerItem?.name}</td>
                                  <td>{peerItem?.role}</td>
                                  <td>{dateFormat(peerItem?.joined_at)}</td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </>
                  </div>
                </Panel>
              );
            })}
          </PanelGroup>
        </div>
      </div>
    </>
  );

  const renderModalButton = (btnType) => {
    if (btnType.type === 'room-composite') {
      return (
        <div className='d-flex justify-content-end profile-call-log-btn '>
          {btnType.status !== 'completed' ? (
            <div className='badge capitalize busy super_admin bg-white'>processing ...</div>
          ) : (
            <button onClick={() => modalDownloadBtnDetails(btnType.id)} className=' d-flex  w-20 call-log-btn '>
              View Recording
            </button>
          )}
        </div>
      );
    } else if (btnType.type === 'chat') {
      return (
        <div className='d-flex justify-content-end profile-call-log-btn '>
          {btnType.status !== 'completed' ? (
            <div className='badge capitalize busy super_admin bg-white'>processing ...</div>
          ) : (
            <button onClick={() => modalDownloadBtnDetails(btnType.id)} className=' d-flex  w-20 call-log-btn '>
              Download Chat
            </button>
          )}
        </div>
      );
    } else if (btnType.type === 'summary') {
      return (
        <div className='d-flex justify-content-end profile-call-log-btn '>
          {btnType.status !== 'completed' ? (
            <div className='badge capitalize busy super_admin bg-white'>processing ...</div>
          ) : (
            <button onClick={() => modalDownloadBtnDetails(btnType.id)} className=' d-flex  w-20 call-log-btn '>
              View File
            </button>
          )}
        </div>
      );
    } else if (btnType.type === 'transcript') {
      return (
        <div className='d-flex justify-content-end profile-call-log-btn '>
          {btnType.status !== 'completed' ? (
            <div className='badge capitalize busy super_admin bg-white'>processing ...</div>
          ) : (
            <button
              onClick={() => modalDownloadBtnDetails(btnType.id)}
              disabled={btnType.status !== 'completed'}
              className=' d-flex  w-20 call-log-btn '
            >
              View File
            </button>
          )}
        </div>
      );
    }
  };
  return (
    <div className='wrapper'>
      {showPatient()}
      <Modal backdrop='static' size='md' open={open} onClose={() => setOpen(false)}>
        <Modal.Header />
        <div className='table-responsive'>
          <table class='table table-striped'>
            <thead>
              <tr>
                <th scope='col'>Sr. No.</th>
                <th scope='col'>Type</th>
                <th scope='col'>Size</th>
                <th scope='col'>Duration </th>
                <th scope='col'>Asset Url </th>
              </tr>
            </thead>
            <tbody>
              {modalSessionData?.data?.map((sessionViewItem, sessionViewIndex) => {
                return (
                  <tr>
                    <td scope='row'>{sessionViewIndex + 1}</td>
                    <td>{sessionViewItem?.type}</td>
                    <td>{sessionViewItem?.size}</td>
                    <td>{sessionViewItem?.duration}</td>
                    <td>
                      <div className='d-flex profile-call-log-btn '>
                        {renderModalButton(sessionViewItem, sessionViewIndex)}
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
      <Loader show={isLoading} />
    </div>
  );
}

export default TeleconsultationDetail;
