import React, { useEffect, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import { Modal } from 'rsuite';
import { dateFormat, timeFormat, titlizeString } from '../Helper';
import CoreInputBox from '../CoreInputBox';
import CommonAlert from '../CommonAlert';
function OrdersDetail(props) {
  const ApiClient = useApiManager();

  const { openDetailModal, setOpenDetailModal, orderedDetail, setOrderedDetail, getOrders, setAlert, alert } = props;

  const [isStatusUpdate, setIsStatusUpdate] = useState(false);
  const [updateOrderItems, setUpdateOrderItems] = useState([]);
  const [alertDetail, setAlertDetail] = useState({});
  useEffect(() => {
    setAlertDetail({});
  }, [openDetailModal]);

  const updateOrder = () => {
    let apiData = {
      order_id: orderedDetail.order_id,
      ehr_order_status: orderedDetail.ehr_order_status,
    };
    if (updateOrderItems?.length) {
      apiData.item_details = updateOrderItems;
    }

    ApiClient.updateOrders(apiData)
      .then((res) => {
        setOpenDetailModal(!openDetailModal);
        setAlert({ type: 'alert-success', message: 'Order updated successfully' });
        getOrders();
      })
      .catch((err) => {
        console.warn('updateOrders', err.response);
        setAlertDetail({ type: 'alert-danger', message: err?.response?.data?.msg || 'Failed to save.' });
      });
  };

  const handleQuantity = (index, index1, value) => {
    let newData = { ...orderedDetail.fulfilled_order[index] };
    newData.order_items[index1].fulfilled_qty = Number(value);

    orderedDetail.fulfilled_order[index] = newData;
    setOrderedDetail({ ...orderedDetail });
    let oldValueIndex = updateOrderItems.findIndex((item2) => item2.item_id == newData.order_items[index1].id);
    if (oldValueIndex == -1) {
      updateOrderItems.push({ item_id: newData.order_items[index1].id, fulfilled_qty: Number(value) });
    } else {
      updateOrderItems[oldValueIndex].fulfilled_qty = Number(value);
    }
    setUpdateOrderItems([...updateOrderItems]);
  };

  const orderStatus = [
    {
      title: 'Pending',
      id: 'to_order',
    },
    {
      title: 'Order Placed',
      id: 'order_placed',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
    },
    {
      title: ' Partially Ordered',
      id: 'ordered_partially',
    },
  ];

  const renderOrderStatusDropdown = () => {
    return (
      <div className='d-flex justify-content-end align-items-center'>
        <div className='me-2 text-right justify-content-end'>Order Status</div>
        <div>
          <div className='order-dropdown-part'>
            <span
              onClick={() => setIsStatusUpdate(!isStatusUpdate)}
              className={`align-items-center badge capitalize active ms-3 d-flex align-items-center ${orderedDetail.ehr_order_status}`}
            >
              {titlizeString(orderStatus.find((item4) => item4.id == orderedDetail.ehr_order_status)?.title)}
              <span class='material-icons-outlined ms-1 '>edit</span>
            </span>
          </div>
          <div className={`order-dropdown  ${isStatusUpdate ? 'orderList' : ''}`}>
            <ul>
              {orderStatus.map((statusItem) => (
                <li key={statusItem.id}>
                  <span
                    className=''
                    onClick={() => {
                      orderedDetail.ehr_order_status = statusItem.id;
                      setOrderedDetail({ ...orderedDetail });
                      setIsStatusUpdate(!isStatusUpdate);
                    }}
                  >
                    {statusItem.title}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const handleCloseModal = () => {
    setIsStatusUpdate(false);
    setOpenDetailModal(!openDetailModal);
  };

  const showOrders = () => {
    return (
      <Modal
        backdrop='static'
        size='md'
        keyboard={false}
        open={openDetailModal}
        onClose={() => {
          handleCloseModal();
        }}
      >
        <Modal.Header />
        <Modal.Body>
          <div className='order-popup'>
            <CommonAlert
              className='mt-3'
              setIsShowAlert={setAlert}
              isShowAlert={alertDetail.message}
              alertType={alertDetail.type}
            />
            <div className='mt-4 d-flex justify-content-between align-items-start w-100'>
              <div className='order-popup-head'>
                <span>
                  <strong>Order ID:</strong> {orderedDetail.order_id}
                </span>
                <br />
                <span>
                  <strong>Patient:</strong> {orderedDetail?.user?.full_name}
                </span>
                <br />
                <span>
                  <strong>Address:</strong> {orderedDetail.shipping_address}
                </span>
              </div>
              <div className='order-popup-head order-date'>
                <div className='d-flex justify-content-end align-items-end flex-column'>
                  <span>
                    <strong>Ordered on </strong>
                  </span>
                  {dateFormat(orderedDetail.created_at)} {timeFormat(orderedDetail.created_at)}
                </div>
                <div className='mt-3'>{renderOrderStatusDropdown()}</div>
              </div>
            </div>

            {orderedDetail?.fulfilled_order?.map((item1, index) => {
              return (
                <div className='order-popup-body mt-4'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      Sub-order {index + 1}:{/* {item1?.vendor?.vendor_name} */}
                    </div>
                  </div>
                  <div className='table-responsive mt-4'>
                    <table class='table'>
                      <thead>
                        <tr>
                          <th scope='col'>Sr.No.</th>
                          <th scope='col'>Medicine</th>
                          <th scope='col'>Quantity</th>
                          <th scope='col'>Price</th>
                          <th scope='col'>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {item1?.order_items?.map((item3, index1) => {
                          return (
                            <tr>
                              <td>{index1 + 1}</td>
                              <td>
                                <div className='capitalize'>
                                  {item3?.product?.medicine.formulation}&nbsp;
                                  {item3?.product?.product_name}
                                </div>

                                {/* <br />
                                bottle of 500 pellets */}
                              </td>
                              {/* <td>{item3?.fulfilled_qty}</td> */}
                              <td>
                                <CoreInputBox
                                  disabled={
                                    orderedDetail.ehr_order_status == 'order_placed' ||
                                    orderedDetail.ehr_order_status == 'cancelled'
                                  }
                                  value={item3?.fulfilled_qty}
                                  setValue={(data) => {
                                    handleQuantity(index, index1, data);
                                  }}
                                  // showMSG={item3?.error}
                                />
                              </td>
                              <td>Rs. {parseFloat(item3?.item_amount).toFixed(2)}</td>
                              <td>Rs. {(item3?.fulfilled_qty * item3?.item_amount || 0).toFixed(2)} </td>
                            </tr>
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colSpan={4} align='right'>
                            <strong>Total:</strong>
                          </td>
                          <td>
                            <strong>
                              Rs.{' '}
                              {(
                                item1?.order_items.reduce((accumulator, object) => {
                                  return accumulator + object?.fulfilled_qty * object?.item_amount;
                                }, 0) || 0
                              ).toFixed(2)}
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer className='d-flex justify-content-end'>
          <div
            onClick={() => updateOrder()}
            appearance='primary'
            className='form-control  coreBtn text-white undefined btn d-flex w-auto'
          >
            Update
          </div>
        </Modal.Footer>
      </Modal>
    );
  };
  return <div>{showOrders()}</div>;
}

export default OrdersDetail;
