import React, { useEffect, useState } from "react";
import { CloseWhiteTransParent } from "res/images";
import "./chipsTagsView.scss";
import CommonToolTip from "./CommonToolTip";

function ChipsTagsView(props) {
  const { data, setData, setselectedData, activeTags, setActiveTags, singleChips } = props;
  const [activeTagsIDS, setActiveTagsIDS] = useState(new Set(activeTags));
  const [activeTagsData, setActiveTagsData] = useState([]);

  useEffect(() => {
    setActiveTagsIDS(new Set(activeTags));
  }, [activeTags]);

  useEffect(() => {
    if (setselectedData) {
      setselectedData(activeTagsData);
    }
  }, [activeTagsData]);

  useEffect(() => {
    filterData();
  }, [activeTags]);

  const filterData = () => {
    let activeData = [];
    data.map((item) => {
      if (singleChips) {
        if (item.id == [...activeTagsIDS][0]?.id) {
          activeData.push(item);
        }
      } else {
        if (activeTagsIDS.has(item.id)) {
          activeData.push(item);
        }
      }
    });

    setActiveTagsData([...activeData]);
  };

  const handleChpisClick = (item) => {
    if (singleChips) {
      if (activeTags.length && activeTags[0]?.id == item.id) {
        setActiveTags([]);
      } else {
        setActiveTags([item]);
      }
    } else {
      if (activeTagsIDS.has(item.id)) {
        activeTagsIDS.delete(item.id);
        if (setData) {
          const removeId = data.findIndex((item2) => item2.id == item.id);
          data.splice(removeId, 1);
          setData(data);
        }
      } else {
        activeTagsIDS.add(item.id);
      }
      setActiveTags([...activeTagsIDS]);
    }
  };

  const renderDataMap = () => {
    if (data) {
      return data.map((item, index) => {
        let activeTagsstr;
        if (activeTagsIDS.has(item.id)) {
          activeTagsstr = "activeTagsStyle";
        } else {
          activeTagsstr = "";
        }
        if (singleChips) {
          if (activeTags[0]?.id == item.id) {
            activeTagsstr = "activeTagsStyle";
          }
        }

        return (
          <div
            key={index}
            className={`chips ${activeTagsstr}`}
            onClick={() => {
              if (!activeTagsstr) {
                handleChpisClick(item);
              }
            }}
          >
            <div>{item.title || item.full_name}</div>
            {activeTagsstr && (
              <CommonToolTip
                placement={"top"}
                item={activeTagsstr && <img onClick={() => handleChpisClick(item)} src={CloseWhiteTransParent} />}
                showValue={"Remove"}
              />
            )}
          </div>
        );
      });
    } else {
      return;
    }
  };

  return <div className="chipsTagsView">{renderDataMap()}</div>;
}

export default ChipsTagsView;
