import React from 'react';
import './loader.css';

function Loader(props) {
  const { show, customClass, css } = props;

  if (show) {
    return (
      <div id="loaderDiv">
        <div className="loaderParent">
          <div className={`loader ${customClass}`} style={css} />
        </div>
      </div>
    );
  } else {
    return;
  }
}

export default Loader;
