import React from 'react';
import { DatePicker } from 'rsuite';

function CoreDatePicker(props) {
  let { calanderProps, retuired, label, showMSG } = props;
  return (
    <div className='w-100 inputTimePicker'>
      {label ? (
        <label className='inputlabel'>
          {retuired && <span className='requiredInput'> * </span>} {label}
        </label>
      ) : (
        <div />
      )}
      <DatePicker format='yyyy-MM-dd' placeholder='YYYY-MM-DD' {...calanderProps} />
      {showMSG && <div className='inputMsg'>{showMSG}</div>}
    </div>
  );
}

export default CoreDatePicker;
