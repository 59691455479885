import { createApi } from '@reduxjs/toolkit/query/react';
import endPoints from 'networking/endPoints';
import auth_axios from 'networking/AuthAxios';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, body, params }) => {
    try {
      const result = await auth_axios({ url: baseUrl + url, method, data: body, params });
      return { data: result };
    } catch (err) {
      return { data: err.response };
    }
  };

export const segmentApis = createApi({
  reducerPath: 'segmentApisData',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: ['updateList'],

  endpoints: (builder) => ({
    getSegmentData: builder.query({
      query: (page) => {
        return {
          url: endPoints.SEGMENT(),
          method: 'get',
          params: { page: page },
        };
      },
      providesTags: ['updateList'],
    }),

    getSegmentAttributes: builder.query({
      query: () => {
        return {
          url: endPoints.SEGMENTATTRIBUTES,
          method: 'get',
        };
      },
      providesTags: [],
    }),

    getSegmentCount: builder.mutation({
      query: (id) => {
        return {
          url: endPoints.SEGMENTCOUNT(id),
          method: 'get',
        };
      },
    }),

    exportSegment: builder.mutation({
      query: (id) => {
        return {
          url: endPoints.SEGMENTEXPORT(id),
          method: 'get',
        };
      },
    }),

    getSegmentDetails: builder.mutation({
      query: (id) => {
        return {
          url: endPoints.SEGMENTLISTING(id),
          method: 'get',
        };
      },
    }),

    getSignleSegmentData: builder.mutation({
      query: (id) => {
        return {
          url: endPoints.SEGMENTDATA(id),
          method: 'get',
        };
      },
    }),

    createSegmentData: builder.mutation({
      query: (data) => {
        return {
          url: endPoints.SEGMENT(),
          method: 'post',
          body: data,
        };
      },
      invalidatesTags: ['updateList'],
    }),

    updateSegmentData: builder.mutation({
      query: (data) => {
        return {
          url: endPoints.SEGMENT(data.id),
          method: 'patch',
          body: data.data,
        };
      },
      invalidatesTags: ['updateList'],
    }),

    deleteSegmentData: builder.mutation({
      query: (id) => {
        return {
          url: endPoints.SEGMENT(id),
          method: 'delete',
        };
      },
      invalidatesTags: ['updateList'],
    }),
  }),
});

export const {
  useGetSegmentDataQuery,
  useGetSegmentAttributesQuery,
  useExportSegmentMutation,
  useGetSegmentCountMutation,
  useCreateSegmentDataMutation,
  useDeleteSegmentDataMutation,
  useGetSignleSegmentDataMutation,
  useUpdateSegmentDataMutation,
} = segmentApis;
