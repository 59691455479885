import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { add, upload, checkmarkSm, starSmall, adherenceGreen } from 'res/images';
import { dateFormat, timeFormat, dayTime, iconImages, titlizeString, getReadTime, exportToCsv } from './Helper';
import Loader from './Loader';
import { EmptyView, Pagination, PostBody } from 'whealth-core-web/components';
import { useDispatch, useSelector } from 'react-redux';
import { AddDepartmentModal } from 'components/AddDepartmentModal';
import { Button, Table } from 'rsuite';
import { addDepartment, setAlerts, setPatientDetails } from 'redux/Slices';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { EditDepartmentModal } from 'components/EditDepartmentModal';
import CommonToolTip from './CommonToolTip';
import { toast } from 'react-toastify';
import labels from '../../inputFields.json';
import AddTimelineTab from './AddTimelineTab';
const { Column, HeaderCell, Cell } = Table;

function RenderTimelineTab(props) {
  const ApiClient = useApiManager();
  const {
    setStatus,
    newGoal,
    newPrescription,
    newConsultation,
    uploadReport,
    getGraphData,
    addSservice,
    getPatientData,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [dateData, setDateData] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [openDeptModal, setOpenDeptModal] = useState(false);
  const [openEditDeptModal, setOpenEditDeptModal] = useState(false);
  const departmentsFromRedux = useSelector((state) => state?.sliceReducer?.patientDetails?.departments);
  const selectedDepartments = useSelector((state) => state?.sliceReducer?.patientDetails?.selected_departments);
  const [deptTableData, setDeptTableData] = useState([]);
  const [selectedDept, setSelectedDept] = useState();
  const [isNivanCare, setIsNivanCare] = useState(false);
  const dispatch = useDispatch();
  const { id } = useParams();
  let modes = labels?.consultantMode?.options || [];

  const addDept = () => {
    getPatientData();
  };

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'departments_clinics' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
      });
    }
  }, [configData]);

  const { updatePatinetTab } = useSelector((state) => state.sliceReducer);

  useEffect(() => {
    setDeptTableData(departmentsFromRedux);
  }, departmentsFromRedux);

  useEffect(() => {
    getTimeline();
  }, [id, currentPage, updatePatinetTab?.update]);

  const getTimeline = () => {
    let params = {
      page: currentPage,
    };
    setIsLoading(true);
    ApiClient.patientProfileTimeline(id, params)
      .then((res) => {
        setData(res.data);
        setTotalPages(res.headers['total-pages']);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleExportChat = (chatID) => {
    setIsLoading(true);
    ApiClient.exportAIChat(id, chatID)
      .then((res) => {
        setIsLoading(false);
        exportToCsv(res.data, `AI_Chat_${dateFormat(new Date())}-${timeFormat(new Date())}.csv`);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const renderActivity = (date) => {
    if (date) {
      return dateFormat(date) + ' · ' + timeFormat(date);
    } else {
      return 'No Activity';
    }
  };

  const getItemVal = (value, unit) => {
    if (unit == 'mood' || unit == 'energy') {
      return (
        <div className='show-graph-emoji ms-1'>
          {' '}
          <img src={iconImages()[unit][value]} />{' '}
        </div>
      );
    } else {
      return value;
    }
  };

  const InnerWraper = (props) => {
    const { children, date } = props;
    return (
      <div>
        <div className='d-flex justify-content-between gap-10'>
          <div className='d-flex flex-column w-100'>{children}</div>
          {date && <div className='date no-wrap'>{date}</div>}
        </div>
      </div>
    );
  };

  const renderTags = (arr) => {
    return arr?.map((item, index) => {
      if (item?.condition) {
        return (
          <div
            key={index}
            className={`badge badge-pill milestoneAchived capitalize text-center ${!item.icon && 'pl-10'}`}
          >
            {item.icon && <img src={item.icon} className='icon' />}
            {item.title}
          </div>
        );
      } else {
        return null;
      }
    });
  };

  const renderMedicineGoal = (data, item, title) => {
    return (
      <PostBody title={title} isEdit={data} handleChange={getTimeline}>
        <InnerWraper>
          <div className='d-flex justify-content-between'>
            <div className='cardHeading capitalize'>
              <b>{item?.goal?.title}</b>
              <div>{item?.medicine?.medicine_category}</div>
            </div>
            <div className='date'>{renderActivity(data?.record_date)}</div>
          </div>

          <div className='tagsHolder mt-2'>
            {showDosageTimes(dayTime(data?.value, checkmarkSm))}
            {data?.channel && (
              <div className='badge badge-pill me-3 milestoneAchived channel capitalize'>{data?.channel}</div>
            )}
          </div>

          <div className='progressBar mt-2  d-flex align-items-center'>
            <div className='progress w-100 flex-fill'>
              <div
                className='progress-bar'
                role='progressbar'
                aria-valuenow='0'
                style={{
                  width: (getArrData(data?.value) / getArrData(data?.target_value)) * 100 + '%',
                }}
                aria-valuemin='0'
                aria-valuemax={item?.value}
              ></div>
            </div>
            <div className='progress-value '>
              {getArrData(data?.value)} / {getArrData(data?.target_value)} Dose
            </div>
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderGoalData = (data, item, title) => {
    let isMedicineGoal = !!item?.goal?.medicinal_goal;
    if (isMedicineGoal) {
      return renderMedicineGoal(data, item, title);
    }

    let renderValue2 = data?.value2;
    let value2 = renderValue2 ? '/' + renderValue2 : '';

    const tagsArr = [
      {
        title:
          (item?.goal?.type == 'measuring' ? (data?.target_value ? data?.target_value : '' + ' ') : '') +
          item?.goal?.measuring_event?.measuring_unit,
        icon: checkmarkSm,
        condition: true,
      },
      {
        title: item?.frequency,
        icon: checkmarkSm,
        condition: true,
      },
      {
        title: titlizeString(data?.channel),
        condition: data?.channel,
      },
      {
        title: titlizeString(data?.patient_goal.adherence?.adherence_range),
        condition: data?.patient_goal?.adherence?.adherence_range,
        icon: adherenceGreen,
      },
    ];

    return (
      <PostBody
        getGraphData={getGraphData}
        getPatientData={getPatientData}
        title={title}
        isEdit={data}
        handleChange={getTimeline}
      >
        <InnerWraper>
          <div className='cardHeading capitalize d-flex justify-content-between'>
            <b>{item?.goal?.title}</b>
            <div className='date me-1'>{renderActivity(data?.record_date)}</div>
          </div>
          <div className='tagsHolder mt-2 d-flex justify-content-between'>
            <div className='d-flex flex-wrap'> {renderTags(tagsArr)} </div>
          </div>

          <div className='progressBar mt-2  d-flex align-items-center'>
            <div className='progress w-100 flex-fill'>
              <div
                className='progress-bar w-15'
                role='progressbar'
                aria-valuenow='0'
                style={{
                  width:
                    item.goal.type !== 'logging' ? parseInt((data?.value / data?.target_value) * 100) + '%' : '100%',
                }}
                aria-valuemin='0'
                aria-valuemax={item.value}
              ></div>
            </div>
            {item?.goal?.type == 'measuring' ? (
              <div className='progress-value '>
                {data?.value}/{data?.target_value} {item.goal.measuring_event.measuring_unit}
              </div>
            ) : (
              <div className='progress-value emoji-value d-flex flex-row-reverse align-items-center'>
                {data?.value && getItemVal(data?.value, item.goal.measuring_event.measuring_unit)} {value2}{' '}
                {item?.goal?.measuring_event?.measuring_unit}
              </div>
            )}
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const showDosageTimes = (arr, icon) => {
    return arr.map((item, index) => {
      const objectKeys = Object.keys(item);
      return (
        <div key={index} className={`badge badge-pill me-3 ${item[objectKeys[1]]}`}>
          <img src={item[objectKeys[0]]} className='icon' /> {objectKeys[0]}
        </div>
      );
    });
  };

  const getArrData = (str) => {
    let val = 0;
    if (str) {
      let arr = str?.split('');
      if (Array.isArray(arr)) {
        arr.map((item) => {
          val += +item;
        });
      }
    }
    return val;
  };

  const renderData = (key, data, item) => {
    let objData = {
      user_subscription: { func: renderUserSubscription, title: 'Subscription' },
      // prescription_medicine: { func: renderPrescriptionData, title: "Medical Prescription" },
      patient_goal: { func: renderGoalData, title: 'Goal' },
      article_read_activity: { func: renderArticleReadTime, title: 'Article Read Activity' },
      type: { func: renderTypeData, title: '' },
    };

    if (!item) {
      return;
    }

    if (key in objData) {
      return objData[key].func(data, item, objData[key].title);
    } else {
      return;
    }
  };

  const renderTypeData = (item, key) => {
    let objData = {
      UserSignUp: { func: renderSignupData, title: 'SignUp' },
      AiChat: { func: renderAiChat, title: 'AiChat' },
      AppReferral: { func: renderAppReferral, title: 'AppReferral' },
    };

    if (!item) {
      return;
    }

    if (key in objData) {
      return objData[key].func(item, objData[key].title);
    } else {
      return;
    }
  };

  const renderAiChat = (item, title) => {
    return (
      <PostBody title={title} AiChat={item?.ai_chat} handleExportChat={handleExportChat}>
        <InnerWraper date={`${dateFormat(item.record_date)} · ${timeFormat(item.record_date)}`}>
          <div className='cardHeading mb-2'>
            <b>Summary&nbsp;</b>
          </div>
        </InnerWraper>
        <div className='max-content'>
          <div className='d-flex  capitalize renewalDate badge-pill '>{item?.ai_chat?.summary}</div>
        </div>
      </PostBody>
    );
  };
  const renderAppReferral = (item, title) => {
    return (
      <PostBody title={title}>
        <InnerWraper date={`${dateFormat(item.record_date)} · ${timeFormat(item.record_date)}`}>
          <div className='max-content'>
            <div className='d-flex  capitalize renewalDate badge-pill '>{item?.app_referral?.summary}</div>
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderSignupData = (item, title) => {
    return (
      <PostBody title={title}>
        <InnerWraper date={`${dateFormat(item.record_date)} · ${timeFormat(item.record_date)}`}>
          <div className='cardHeading mb-2'>
            <b>SignUp &nbsp;</b>
          </div>
          <div className='max-content'>
            <div className='d-flex badge capitalize renewalDate badge-pill '>{item.channel}</div>
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderUserSubscription = (data, item, title) => {
    return (
      <PostBody title={title}>
        <InnerWraper date={`${dateFormat(item.created_at)} · ${timeFormat(item.created_at)}`}>
          <div className='cardHeading mb-2'>
            <b>Renewal on &nbsp;</b>
          </div>
          <div className='max-content'>
            <div className='d-flex badge capitalize renewalDate badge-pill '>{dateFormat(item.end_date)}</div>
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const renderArticleReadTime = (data, item, title) => {
    return (
      <PostBody title={title}>
        <InnerWraper date={`${dateFormat(item.start_time)} · ${timeFormat(item.start_time)}`}>
          <div className='cardHeading mb-2 capitalie'>
            <b>{item?.article?.title}&nbsp;</b>
          </div>
          <div className='max-content'>
            <div className='d-flex badge capitalize renewalDate badge-pill'>
              <div> {getReadTime(item.duration)}</div>
            </div>
          </div>
        </InnerWraper>
      </PostBody>
    );
  };

  const handleAddDepartmentClick = () => {
    //open Add dept modal
    setOpenDeptModal(true);
  };

  const handleEditDepartmentClick = () => {
    //open Edit dept modal
    setOpenEditDeptModal(true);
  };

  const handleDeptModalClose = () => {
    setOpenDeptModal(false);
  };

  const handleEditDeptModalClose = () => {
    setSelectedDept();
    setOpenEditDeptModal(false);
  };

  const handleEditClick = (data) => {
    setSelectedDept(data);
    handleEditDepartmentClick();
  };

  const handleDeleteDepartment = (data) => {
    let windoConfirm = window.confirm('Are you sure you want to delete this department?');
    if (windoConfirm) {
      const formData = new FormData();
      formData.append('selected_department_id', data?.id);
      ApiClient.deleteDepartment(id, formData)
        .then((res) => {
          getPatientData();
          dispatch(setAlerts({ patient: { title: 'Department deleted successfully.', type: 'alert-success' } }));
        })
        .catch((e) => {
          toast.error(e.response.data.error);
        });
    }
  };

  return (
    <>
      <AddTimelineTab
        setStatus={setStatus}
        newGoal={newGoal}
        uploadReport={uploadReport}
        addSservice={addSservice}
        newPrescription={newPrescription}
        newConsultation={newConsultation}
        handleAddDepartmentClick={handleAddDepartmentClick}
      />
      <AddDepartmentModal open={openDeptModal} handleClose={handleDeptModalClose} addDepartment={addDept} />
      <EditDepartmentModal
        open={openEditDeptModal}
        handleClose={handleEditDeptModalClose}
        addDepartment={addDept}
        data={selectedDept}
      />
      <Loader show={isLoading} />
      {isNivanCare && (
        <div className='table-responsive mt-4'>
          <table className='table table-bordered'>
            <thead>
              <tr>
                <th className='text-center'>Department</th>
                <th className='text-center'>Doctor</th>
                <th className='text-center'>Status</th>
                <th className='text-center'>{labels?.consultantMode?.label || 'Consultant Mode'}</th>
                <th className='text-center'>Action</th>
              </tr>
            </thead>
            <tbody>
              {selectedDepartments?.length > 0 ? (
                selectedDepartments.map((item, index) => {
                  return (
                    <tr>
                      <td>{item.department.name}</td>
                      <td>{item.admin.full_name}</td>
                      <td>{item.status.title}</td>
                      <td>{modes.find(({ id }) => id == item['consultation_mode'])?.title}</td>
                      <td>
                        <div className='d-flex'>
                          <CommonToolTip
                            placement={'top'}
                            item={
                              <Button className='p-0 ms-2' appearance='link' onClick={() => handleEditClick(item)}>
                                <span class='material-icons-outlined'>edit</span>
                              </Button>
                            }
                            showValue={'Edit'}
                          />
                          <CommonToolTip
                            placement={'top'}
                            item={
                              <Button
                                color='red'
                                className='p-0 ms-2'
                                appearance='link'
                                onClick={() => handleDeleteDepartment(item)}
                              >
                                <span class='material-icons-outlined'>delete</span>
                              </Button>
                            }
                            showValue={'Delete'}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <EmptyView />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      <div className='d-flex'>
        <div className='pt-4 w-100'>
          <div className='cardMilestone'>
            {data &&
              data.map((item, index) => {
                if (index == 0) {
                  let goalDate = item?.patient_goal?.updated_at;

                  if (dateData) {
                    if (dateData < goalDate) {
                      setDateData(goalDate);
                    }
                  }
                }

                return Object.keys(item).map((keys, index) => {
                  return <div key={index}>{renderData(keys, item, item[keys])}</div>;
                });
              })}
          </div>
        </div>
      </div>
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </>
  );
}

export default RenderTimelineTab;
