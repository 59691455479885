import { AdminPanelHeader, SideBar } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import AddPrescriptionForm from 'whealth-core-web/forms/AddPrescriptionForm';
import { CommonAlert, Loader } from './index';
import { profileImage } from './Helper';
import './prescription.scss';
import AddSummaryForm from 'whealth-core-web/forms/AddSummaryForm';

function AddSummary() {
  const location = useLocation();
  const duplicateId = location?.state?.id;
  const navigation = useNavigate();
  const ApiClient = useApiManager();
  const { id, pid } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({ prescription_medicines: [] });
  const [alertType, setAlertType] = useState('alert-success');
  const [prescriptionData, setPrescriptionData] = useState('');

  useEffect(() => {
    if (pid) {
      getPrescriptionData();
    }
  }, [pid]);

  useEffect(() => {
    if (duplicateId) {
      getPrescriptionData(duplicateId);
    }
  }, [duplicateId]);

  const getPrescriptionData = (prescription_id = pid) => {
    setIsLoading(true);
    ApiClient.prescriptionMDTSummerygetSingle(id, prescription_id)
      .then((res) => {
        setPrescriptionData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const updatePrescription = (data) => {
    setIsLoading(true);
    ApiClient.prescriptionMDTSummeryUpdate(id, pid, data)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('MDT Summary updated successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        if (err.response.data?.errors?.non_field_errors || err.response.data.detail) {
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.detail);
        }
      });
  };

  const prescriptionSummeryCreate = (data) => {
    setIsLoading(true);
    ApiClient.prescriptionSummeryCreate(id, data)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('MDT Summary created successfully.');
        setTimeout(() => {
          navigation(-1);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        if (err.response.data?.errors?.non_field_errors || err.response.data.detail) {
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.detail);
        }
      });
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + ' Summary', link: '' },
  ];

  const showPatient = () => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>

        <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Summary</div>
        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}

        <AddSummaryForm
          setErrors={setErrors}
          data={prescriptionData}
          errors={errors}
          onClick={pid ? updatePrescription : prescriptionSummeryCreate}
        />
      </div>
    </>
  );
  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {showPatient()}
      <Loader show={isLoading} />
    </div>
  );
}

export default AddSummary;
