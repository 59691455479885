import React, { useEffect, useState } from 'react';
import { json } from 'react-router-dom';
import { CoreButton, CoreCheckBox, CoreInputBox, CoreTextArea, TagsShow } from 'whealth-core-web/components';

function DepartmentForm(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick, statuses } = props;
  const [jsonValue, setJsonValue] = useState();

  useEffect(() => {
    setJsonValue(valueObj.prescription_schema);
  }, [valueObj]);

  return (
    <div className='medicineForm'>
      <div className='formTitle'> {!isUpdate ? 'Add Department' : 'Update Department'} </div>

      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex w-100 justify-content-between medicineNamerow'>
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.name}
            placeholder='Department Name'
            value={valueObj?.name || ''}
            label='Department Name'
            setValue={(inputVal) => {
              setValueObj({ ...valueObj, name: inputVal });
            }}
            maxLength={255}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreTextArea
          showMSG={error?.prescription_template}
          placeholder='Prescription Template'
          value={valueObj?.prescription_template || ''}
          label='Prescription Template'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, prescription_template: inputVal });
          }}
          rows={3}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreTextArea
          showMSG={error?.prescription_schema}
          placeholder='Prescription Schema'
          value={jsonValue || ''}
          label='Prescription Schema'
          setValue={(inputVal) => {
            setJsonValue(inputVal);
            setValueObj({ ...valueObj, prescription_schema: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <TagsShow
          data={statuses}
          keepSearchTerm={true}
          retuired
          labelStyle={{
            margin: '24px 0 4px 0',
            fontWeight: 'var(--lightWeight)',
          }}
          placeholder='Select Statuses'
          label='Statuses'
          setValue={(data) => {
            setValueObj({ ...valueObj, selected_department_status: data });
          }}
          value={valueObj?.selected_department_status}
          showMSG={error?.department_status}
          msgStyle={{ color: 'red' }}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreCheckBox
          value={valueObj?.default}
          labelStyle={{
            margin: '20px 0 8px 6px',
            fontWeight: 'var(--lightWeight)',
          }}
          onChange={(data) => {
            setValueObj({
              ...valueObj,
              default: !valueObj.default,
            });
          }}
          id={isUpdate ? 'flexCheckDefault1' : 'Multiupdate'}
          htmlFor={isUpdate ? 'flexCheckDefault1' : 'Multiupdate'}
          title='Set as default'
          checked={valueObj?.default}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreCheckBox
          value={valueObj?.enable_medicine}
          labelStyle={{
            margin: '20px 0 8px 6px',
            fontWeight: 'var(--lightWeight)',
          }}
          onChange={(data) => {
            setValueObj({
              ...valueObj,
              enable_medicine: !valueObj.enable_medicine,
            });
          }}
          id={isUpdate ? 'flexCheckDefault2' : 'Multiupdate1'}
          htmlFor={isUpdate ? 'flexCheckDefault2' : 'Multiupdate1'}
          title='Enable Medicine'
          checked={valueObj?.enable_medicine}
        />
      </div>

      <CoreButton
        onClick={() => {
          setValueObj({ ...valueObj, prescription_schema: jsonValue });
          onClick();
          setJsonValue();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default DepartmentForm;
