import { AdminPanelHeader, SideBar } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import UploadReportForm from 'whealth-core-web/forms/UploadReportForm';
import CommonAlert from './CommonAlert';
import { profileImage } from './Helper';
import Loader from './Loader';
import { setPatientActiveTab } from 'redux/Slices';

function UploadRport() {
  const ApiClient = useApiManager();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [valueObj, setValueObj] = useState({});
  const { id, reportid } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setshowAlert] = useState('');
  const [alertType, setAlertType] = useState('alert-success');
  const [error, seterror] = useState({});

  useEffect(() => {
    if (location?.state?.item) {
      setValueObj(location?.state?.item);
    }
  }, [location]);

  useEffect(() => {
    dispatch(setPatientActiveTab('reports'));
  }, []);

  const prepateFormData = (data) => {
    const formData = new FormData();
    data.report && typeof data.report != 'string' && formData.append('report', data.report || '');
    formData.append('title', data.title || '');
    formData.append('description', data.description || '');
    return formData;
  };

  const createReports = () => {
    seterror('');
    setIsLoading(true);
    ApiClient.createPatientReport(id, prepateFormData(valueObj))
      .then((res) => {
        setValueObj({});
        setIsLoading(false);
        setTimeout(() => {
          navigate(`/patient/${id}/dashboard`);
        }, 500);
        setAlertType('alert-success');
        setshowAlert('Report created successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        seterror(err.response.data.errors);
      });
  };

  const updateReports = () => {
    setIsLoading(true);
    ApiClient.updateReports(id, prepateFormData(valueObj), reportid)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setshowAlert('Report updated successfully');
        setTimeout(() => {
          navigate(`/patient/${id}/dashboard`);
        }, 500);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        seterror(err.response.data.errors);
        setshowAlert(err.response.data.errors.report);
      });
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails?.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: reportid ? 'Update Report' : 'Upload Report', link: '' },
  ];
  const showPatient = () => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>
        <div className='addnewform-part'>{reportid ? 'Update Report' : 'Upload Report'}</div>

        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        {showAlert && (
          <div className='mt-3'>
            <CommonAlert isShowAlert={showAlert} setIsShowAlert={setshowAlert} alertType={alertType} />
          </div>
        )}
        {renderReportForm(valueObj, setValueObj)}
      </div>
    </>
  );

  const renderReportForm = (valueObj, setValueObj) => {
    return (
      <div>
        <UploadReportForm
          valueObj={valueObj}
          setValueObj={setValueObj}
          reportId={reportid}
          onClick={reportid ? updateReports : createReports}
          error={error}
        />
      </div>
    );
  };
  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {showPatient()}
      <Loader show={isLoading} />
    </div>
  );
}

export default UploadRport;
