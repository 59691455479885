import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import { Button, DatePicker, Modal } from 'rsuite';
import { CoreDropDown, CoreInputBox, CoreSearchDropDown, Select2DropDown } from 'whealth-core-web/components';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { convertTo12HourFormat, dateFormat, dateFormatV2, formatDateInISO, timeFormat, titlizeString } from '../Helper';
import { setCurrentConsultationStatus } from 'redux/Slices';
import CurrencyInput from 'react-currency-input-field';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function AppointmentForm({
  open,
  handleClose,
  patientsList,
  editSchedule,
  getAllDoctorList,
  setPatientsList,
  isedit,
  parentStatus,
  allDoctorData,
  AppointmentSchedule,
  isNivanCare,
}) {
  const { consultationId } = useParams();
  const [doctors, setDoctors] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedPatient, setSelectedPatient] = useState();
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [errorMsg, setErrorMsg] = useState({});
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedMode, setSelectedMode] = useState();
  const [doctorsList, setDoctorsList] = useState([]);
  const [selectedCurrentLocation, setSelectedCurrentLocation] = useState('');
  const [availableSlots, setAvailableSlots] = useState([]);
  const ApiClient = useApiManager();
  const [currentLocationsList, setCurrentLocationsList] = useState([]);
  const isEditEnabled = useSelector((state) => state?.sliceReducer?.currentConsultationStatus);
  const dispatch = useDispatch();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const paymentWithList = [
    {
      id: 1,
      title: 'care_manager',
      label: 'Care Manager',
    },
    {
      id: 2,
      title: 'clinic',
      label: `${isNivanCare ? 'Hospital' : 'Clinic'}`,
    },
    {
      id: 3,
      title: 'nivaan_clinic',
      label: `Nivaan Clinic`,
    },
  ];
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [singleData, setSingleData] = useState();
  const [billedTo, setBilledTo] = useState('');
  const [isRecordPayment, setIsRecordPayment] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [isPMSDoctor, setIsPMSDoctor] = useState(false);

  const [consultData, setConsultData] = useState({
    title: '',
    user_id: null,
    consult_type: null,
    consult_datetime: null,
    consult_end_datetime: null,
    location_id: '',
    consultant_id: '',
    meeting_link: '',
    payment_type: '',
    payment_mode: '',
    amount: null,
    consultation_status: '',
    patient_attendance_status: null,
    payment_from: null,
    payment_with: null,
    service: '',
    appointment_fee: null,
    payment_with_type: '',
  });
  const [selectPatientName, setSelectPatientName] = useState('');
  const [appointmentFeeError, setAppointmentFeeError] = useState(false);
  const [initialLoad, setInitialLoad] = useState(true);
  useEffect(() => {
    if (editSchedule?.id) {
      setConsultData({
        title: singleData?.title,
        user_id: singleData?.user?.id,
        consult_type: singleData?.consult_type,
        consult_datetime: singleData?.consult_datetime,
        location_id: singleData?.location?.id,
        consultant_id: singleData?.consultant?.id,
        meeting_link: singleData?.meeting_link,
        payment_type: singleData?.payment_type,
        payment_mode: singleData?.payment_mode,
        amount: singleData?.amount,
        consultation_status: singleData?.consultation_status,
        patient_attendance_status: singleData?.patient_attendance_status,
        payment_from: singleData?.payment_from,
        payment_with: singleData?.payment_with,
        service: singleData?.service,
        appointment_fee: singleData?.appointment_fee,
        payment_with_type: singleData?.payment_with_type,
      });
      doctorsList.map((item) => {
        if (item.id == editSchedule?.consultant?.id) {
          setCurrentLocationsList(item.locations);
        }
      });
      setIsRecordPayment(singleData?.payment_type ? true : false);
      setSelectedDate(singleData?.consult_datetime);
      setSelectedPatient(singleData?.user?.id);
      setSelectedCurrentLocation(singleData?.location?.id);
      setSelectedDoctor(singleData?.consultant?.id);
      setSelectedSlot(singleData?.consult_end_datetime);
    }
    patientsList?.map((item) => {
      if (item.id == singleData?.user?.id) {
        setBilledTo(item?.full_name);
      }
    });
    setInitialLoad(false);
  }, [singleData]);
  useEffect(() => {
    doctorsList.map((item) => {
      if (item.id == singleData?.consultant?.id) {
        setCurrentLocationsList(item.locations);
      }
    });
  }, [doctorsList]);
  useEffect(() => {
    if (!consultData?.user_id) {
      setBilledTo('');
      return;
    }
    patientsList?.map((item) => {
      if (item.id == consultData?.user_id) {
        setBilledTo(item?.full_name);
      }
    });
  }, [patientsList, consultData?.payment_with_type, consultData?.user_id]);
  useEffect(() => {
    if (editSchedule?.id) {
      getSingleAppointment();
    }
  }, [editSchedule]);

  const getSingleAppointment = () => {
    // const pages = { page };
    // setIsLoading(true);
    ApiClient.getSingleAppointment(editSchedule?.user?.id, editSchedule?.id)
      .then((res) => {
        setSingleData(res.data);
      })
      .catch((err) => {
        // setIsLoading(false);
        console.log(err);
      });
  };

  const handleRecordPaymenyChange = () => {
    setIsRecordPayment(!isRecordPayment);
  };

  const handlePatientSelect = (value) => {
    setSelectedPatient(value);
  };

  const handleCurrentLocationSelect = (value) => {
    setSelectedCurrentLocation(value);
  };
  const handleClinicNameChange = (withType) => {
    if (currentLocationsList?.length < 1) setConsultData({ ...consultData, payment_with_type: withType });
    if (withType === 'clinic') {
      if (!consultData?.location_id) {
        setConsultData({ ...consultData, payment_with_type: withType });
      }
      currentLocationsList?.map((item) => {
        if (item?.id == consultData?.location_id) {
          // setClinicName(item?.name);
          setConsultData({ ...consultData, payment_with: item?.name, payment_with_type: withType });
        }
      });
    } else setConsultData({ ...consultData, payment_with: '', payment_with_type: withType });
  };
  const handlePaymentWithTypeChange = (withType) => {
    // setConsultData({ ...consultData, payment_with_type: withType });
    // if (withType === 'clinic') {
    handleClinicNameChange(withType);
    // } else {
    //   setConsultData({ ...consultData, payment_with: '' });
    // }
  };
  useEffect(() => {
    getSlots();
  }, [selectedDate, selectedDoctor, selectedCurrentLocation]);

  const getSlots = async () => {
    const date = new Date(selectedDate).toISOString();
    const res = await ApiClient.getSlotsConsultation(date, selectedDoctor, selectedCurrentLocation);
    if (res.status == 200) {
      setAvailableSlots(res.data['available_slots']);
    } else {
      toast.error('Failed to fetch slots.');
    }
  };
  const handleDateChange = (value, value1) => {
    const start = new Date(formatDateInISO(selectedDate).split('T')[0] + 'T' + value);

    const end = new Date(formatDateInISO(selectedDate).split('T')[0] + 'T' + value1);

    setConsultData({
      ...consultData,
      consult_datetime: start,
      consult_end_datetime: end,
    });
    setSelectedSlot(value);
  };

  const consultationTypeList = [
    {
      id: 1,
      title: 'physical',
      label: 'Physical',
    },
  ];

  const paymentTypeList = [
    {
      id: 1,
      title: 'free',
      label: 'Free',
    },
    {
      id: 2,
      title: 'paid',
      label: 'Paid',
    },
    {
      id: 3,
      title: 'partially_paid',
      label: 'Partially Paid',
    },
  ];

  const paymentModeList = [
    {
      id: 1,
      title: 'cash',
      label: 'Cash',
    },
    {
      id: 2,
      title: 'online',
      label: 'Online',
    },
  ];

  const paymentFormList = [
    {
      id: 1,
      title: 'upi',
      label: 'UPI',
    },
    {
      id: 2,
      title: 'pos',
      label: 'PoS',
    },
    {
      id: 3,
      title: 'payment_link',
      label: 'Payment Link',
    },
    {
      id: 4,
      title: 'bank_transfer',
      label: 'Bank Transfer',
    },
  ];

  const careManagerList = [
    {
      id: 1,
      label: 'Riya Kumari',
    },
    {
      id: 2,
      label: 'Nupur Lakhanpal',
    },
    {
      id: 3,
      label: 'Fardeen Mirza',
    },
  ];
  const Details = ({ name, time, date }) => (
    <div>
      <b className='mt-2'>Details</b>
      <p className='mt-0 pt-0 text-capitalize'>
        {name}, {timeFormat(time)}, {dateFormat(date)}
      </p>
    </div>
  );

  useEffect(() => {
    getDoctorsList();
  }, [open]);

  const getDoctorsList = async () => {
    if (allDoctorData.status == 200) {
      let doctorsList = [];
      allDoctorData?.data?.map((item) => {
        let doc = item['doctor'];
        doc.title = item['doctor'].full_name;
        let docLocations = [];
        doc.locations?.map((loc) => {
          docLocations.push({ ...loc, title: loc?.name });
        });
        doc.locations = docLocations;
        doctorsList.push({ ...doc });
      });
      setDoctorsList(doctorsList);
    }
  };

  const consultationDetail = () => {
    ApiClient.consultationDetail(consultationId, selectedPatient)
      .then((res) => {
        let newData = { ...res.data };
        if (res?.data?.location?.id) {
          newData = { ...newData, location_id: res?.data?.location?.id };
        }
        if (res?.data.consultant?.id) {
          newData = { ...newData, consultant_id: res?.data?.consultant?.id };
        }
        setConsultData(newData);
      })
      .catch((err) => {
        console.warn('consultationData', err.response);
      });
  };

  const createConsultation = () => {
    if (!consultData?.appointment_fee && isNivanCare) {
      setAppointmentFeeError(true);
      return;
    }
    setErrors({});
    if (consultData.consult_end_datetime == null || consultData.consult_end_datetime == '') {
      setConsultData((consultData.consult_datetime = null));
    }

    ApiClient.consultationCreate(consultData, selectedPatient)
      .then((res) => {
        setSelectedSlot(null);

        setAlertType('alert-success');
        setIsShowAlert('Consultation Created Successfully');
        handleCancelClick();
        getAllDoctorList();
        AppointmentSchedule();
        const patientDetails = patientsList.find((patient) => patient.id === consultData.user_id);
        const patientName = patientDetails ? patientDetails.full_name : '';
        toast.success(
          <div>
            <p>The appointment has been scheduled.</p>
            <Details name={patientName} time={consultData.consult_datetime} date={consultData.consult_datetime} />
          </div>
        );
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        }
        console.warn('createConsultation', err.response);
      });
  };

  const updateConsultation = () => {
    if (!consultData?.appointment_fee && isNivanCare) {
      setAppointmentFeeError(true);
      return;
    }
    setErrors({});

    ApiClient.consultationEdit(consultData || editSchedule, editSchedule?.user?.id, editSchedule?.id)
      .then((res) => {
        handleCancelClick();
        AppointmentSchedule();
        const patientDetails = patientsList.find((patient) => patient.id === consultData.user_id);
        const patientName = patientDetails ? patientDetails.full_name : '';
        toast.success(
          <div>
            <p>The appointment has been updated.</p>
            <Details name={patientName} time={consultData.consult_datetime} date={consultData.consult_datetime} />
          </div>
        );
      })
      .catch((err) => {
        console.warn('err', err);
        if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        }
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.message);
      });
  };

  const handleDoctorChange = (value) => {
    setSelectedCurrentLocation('');

    doctorsList.map((item) => {
      if (item.id == value) {
        setIsPMSDoctor(item?.department?.name === 'PMS');
        setCurrentLocationsList(item.locations);
      }
    });
    setSelectedDoctor(value);
  };

  const handleCancelClick = () => {
    if (!editSchedule?.id) {
      setSelectedDoctor();
      setSelectedStatus();
      setDoctors([]);
      setSelectedMode();
      setIsRecordPayment(false);
      setConsultData({});
      setAvailableSlots('');
      setSelectedDate(null);
    }
    setErrorMsg({});
    setErrors({});
    dispatch(setCurrentConsultationStatus(''));
    setBilledTo('');
    setAppointmentFeeError(false);
    handleClose();
  };

  const getPatientSearchList = async (data) => {
    const res = await ApiClient.searchPatients(data);
    setPatientsList(res.data);
  };

  const service_list = [
    { id: 'consult', title: 'Consult' },
    { id: 'procedure', title: 'Procedure' },
    { id: 'break', title: 'Break' },
    { id: 'CRP_sessions', title: 'CRP Sessions' },
    { id: 'followup', title: 'Follow Up' },
  ];
  // useEffect(() => {
  //   if (!isNivanCare) return;
  //   if (!initialLoad) {
  //     if (consultData?.payment_with_type === 'clinic') {
  //       handleClinicNameChange(consultData?.location_id);
  //     } else {
  //       setConsultData({ ...consultData, payment_with: '' });
  //     }
  //   }
  // }, [consultData?.payment_with_type, initialLoad]);
  const handleClinicValueChange = (locationId) => {
    if (currentLocationsList?.length < 1) return;
    if (!locationId) {
      if (consultData?.payment_with_type === 'clinic') {
        setConsultData({
          ...consultData,
          payment_with: '',
          location_id: '',
        });
      } else {
        setConsultData({
          ...consultData,
          location_id: '',
        });
      }
    }
    currentLocationsList?.map((item) => {
      if (item?.id == locationId) {
        // setClinicName(item?.name);
        if (consultData?.payment_with_type === 'clinic') {
          setConsultData({
            ...consultData,
            payment_with: item?.name,
            location_id: locationId,
          });
        } else {
          setConsultData({
            ...consultData,
            location_id: locationId,
          });
        }
      }
    });
  };

  return (
    <>
      <Modal backdrop='static' keyboard={false} open={open} onClose={handleCancelClick}>
        <Modal.Header>
          <Modal.Title className='d-flex justify-content-center font-weight-bold'>
            <b>{editSchedule?.id ? 'Update Consultation' : 'Add Consultation'}</b>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='d-flex flex-column '>
            <div className='w-25'>
              <Select2DropDown
                placeholder='Select'
                label='Consultation Type'
                retuired={true}
                showMSG={errors?.consult_type}
                data={consultationTypeList}
                labelStyle={{ fontWeight: 'var(--lightFont)' }}
                labelKey={'label'}
                valueKey={'title'}
                onSelect={(data) => {
                  setConsultData({ ...consultData, consult_type: data });
                }}
                value={consultData?.consult_type}
                msgStyle={{ color: 'red' }}
                onClean={() => {
                  setConsultData({ ...consultData, consult_type: '' });
                }}
                disabled={isEditEnabled !== ''}
              />
            </div>
            <br />
            <div className='w-25'>
              <CoreInputBox
                placeholder='Title'
                label='Title'
                retuired={true}
                showMSG={errors?.title}
                data={''}
                labelStyle={{ fontWeight: 'var(--lightFont)', marginTop: '6px' }}
                setValue={(data) => {
                  setConsultData({ ...consultData, title: data });
                }}
                value={consultData?.title || editSchedule?.id || ''}
                msgStyle={{ color: 'red' }}
                disabled={isEditEnabled !== ''}
              />
            </div>
            <Select2DropDown
              className={'text-capitalize'}
              data={patientsList}
              retuired={true}
              labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
              placeholder={'Select Patient'}
              label={"Patient's Name"}
              onSelect={(data) => {
                setConsultData({ ...consultData, user_id: data });
                handlePatientSelect(data);
              }}
              value={consultData?.user_id}
              showMSG={errors?.user_id}
              msgStyle={{ color: 'red' }}
              valueKey='id'
              labelKey='full_name'
              onSearch={(data) => getPatientSearchList(data)}
              onClean={async () => {
                setConsultData({ ...consultData, user_id: null });
                await getPatientSearchList();
              }}
              disabled={editSchedule?.id}
            />
            <hr />
            <div className='d-flex justify-content-between'>
              <div className='w-50 pr-10 mr-5'>
                <Select2DropDown
                  className={'text-capitalize'}
                  placeholder='Select Doctor'
                  label='Doctor'
                  retuired={true}
                  showMSG={errors?.consultant_id}
                  data={doctorsList}
                  labelStyle={{ fontWeight: 'var(--lightFont)', margin: '4px 0 4px 0' }}
                  onSelect={(data) => {
                    setConsultData({ ...consultData, consultant_id: data });
                    handleDoctorChange(data);
                  }}
                  value={consultData?.consultant_id}
                  msgStyle={{ color: 'red' }}
                  onClean={() => {
                    setConsultData({
                      ...consultData,
                      consultant_id: '',
                      location_id: '',
                      consult_datetime: null,
                      service: '',
                    });
                    setSelectedDate(null);
                    setSelectedSlot(null);
                    setSelectedCurrentLocation();
                  }}
                  disabled={isEditEnabled !== ''}
                />
              </div>
              <div className='w-50 pl-10 ml-5'>
                <Select2DropDown
                  placeholder={isNivanCare ? 'Select Hospitals' : 'Select Clinic'}
                  label={isNivanCare ? 'Select Hospitals' : 'Select Clinic'}
                  retuired={true}
                  showMSG={errors?.location_id}
                  data={currentLocationsList}
                  labelStyle={{ fontWeight: 'var(--lightFont)', margin: '4px 0 4px 0' }}
                  onSelect={(data) => {
                    if (!isNivanCare) {
                      setConsultData({ ...consultData, location_id: data });
                    } else {
                      handleClinicValueChange(data);
                    }
                    handleCurrentLocationSelect(data);
                    setSelectedDate(null);
                    setSelectedSlot(null);
                  }}
                  onClean={() => {
                    if (isNivanCare) {
                      handleClinicValueChange(null);

                      handleCurrentLocationSelect(null);
                    }
                  }}
                  value={consultData?.location_id}
                  labelKey='name'
                  valueKey='id'
                  msgStyle={{ color: 'red' }}
                  disabled={consultData?.consult_type == null || isEditEnabled !== ''}
                />
              </div>
            </div>
            <br />
            {isNivanCare && (
              <div className='d-flex justify-content-between'>
                <div className='w-50 pr-10 mr-5'>
                  <Select2DropDown
                    placeholder='Select Service'
                    label='Service'
                    retuired={false}
                    data={isPMSDoctor ? service_list : service_list.filter((item) => item.id != 'procedure')}
                    labelStyle={{ fontWeight: 'var(--lightFont)', margin: '4px 0 4px 0' }}
                    onSelect={(data) => {
                      setConsultData({ ...consultData, service: data });
                    }}
                    value={consultData?.service}
                    msgStyle={{ color: 'red' }}
                    onClean={() => {
                      setConsultData({ ...consultData, service: '' });
                    }}
                    disabled={
                      consultData?.consultant_id == null || consultData?.consultant_id == '' || isEditEnabled !== ''
                    }
                  />
                </div>
                <div className='w-50 p-2'>
                  {/* <CoreInputBox
                    disabled={consultData?.payment_type === 'free'}
                    validatedecimal={true}
                    maxLength={5}
                    valueType='number'
                    label={'Appointment Fee'}
                    placeholder={'Appointment Fee'}
                    value={consultData?.appointment_fee || ''}
                    setValue={(val) => setConsultData({ ...consultData, appointment_fee: val })}
                  /> */}
                  <label htmlFor='validationCustom02 mt-3 slot-name'>
                    {isNivanCare && <span className='requiredInput'> * </span>}
                    Appointment Fee
                  </label>
                  <CurrencyInput
                    id='validationCustom02'
                    name='input-2'
                    className={`form-control`}
                    label='Appointment Fee'
                    value={consultData?.appointment_fee || ''}
                    msgStyle={{ color: 'red' }}
                    onValueChange={(val) =>
                      setConsultData({
                        ...consultData,
                        appointment_fee: val,
                        amount: isNivanCare
                          ? consultData?.payment_type === 'paid'
                            ? consultData?.appointment_fee
                            : consultData?.amount
                          : consultData?.amount,
                      })
                    }
                    placeholder=''
                    prefix={'₹'}
                    step={1}
                    disabled={consultData?.payment_type === 'free' || isEditEnabled !== ''}
                  />
                  {appointmentFeeError && isNivanCare && (
                    <p style={{ color: 'red' }}>{'Appointment fee is mandatory'}</p>
                  )}
                  <p style={{ color: 'red' }}>{errors?.appointment_fee}</p>
                </div>
              </div>
            )}
            {selectedDoctor && selectedCurrentLocation && (
              <>
                <div className='mt-4'>
                  <label class='inputlabel'>
                    {<span className='requiredInput'> * </span>}
                    {'Select Date'}
                  </label>
                </div>
                <DatePicker
                  format='dd/MM/yyyy'
                  value={selectedDate ? new Date(selectedDate) : null}
                  showMSG={errors?.consult_datetime}
                  msgStyle={{ color: 'red' }}
                  onChange={(newValue) => {
                    const newErrors = errors;
                    newErrors.consult_datetime = [];
                    setErrors(newErrors);
                    console.log('newValue : ', newValue);

                    setSelectedDate(newValue);
                    setConsultData({ ...consultData, consult_datetime: newValue });
                  }}
                  placeholder='Select date'
                  showMeridian
                  className='mt-2'
                  onClean={() => {
                    setSelectedDate(null);
                    setSelectedSlot(null);
                  }}
                  disabled={isEditEnabled !== ''}
                />
                {errors && <div class='inputMsg'>{errors.consult_datetime}</div>}
                {/* {errors && errors?.consult_datetime && availableSlots?.length === 0 && (
                  <div className='inputMsg'>*please change to the date where slots are available.</div>
                )}
                {errors && errors?.consult_datetime && availableSlots?.length > 0 && (
                  <div className='msg mt-2'>*please select available slots</div>
                )} */}
              </>
            )}
            {editSchedule && (
              <b>{`Booked Slot: ${timeFormat(editSchedule?.consult_datetime || new Date())} - ${timeFormat(
                editSchedule?.consult_end_datetime || new Date()
              )}`}</b>
            )}
            {selectedDoctor && selectedDate && selectedCurrentLocation && !isEditEnabled && (
              <>
                <div className='d-flex justify-content-center mt-4' style={{ flexWrap: 'wrap' }}>
                  <b className='mb-2'>
                    {availableSlots?.length > 0 ? (
                      <span style={{ color: 'black' }}>Available Slots</span>
                    ) : (
                      <span style={{ color: 'red' }}>No available slots for the selected date</span>
                    )}{' '}
                  </b>
                </div>
                <div className='d-flex justify-content-center' style={{ flexWrap: 'wrap' }}>
                  {availableSlots?.length > 0 &&
                    availableSlots?.map((item) => (
                      <>
                        <button
                          className='col-sm-3 d-flex justify-content-center pt-2 pb-2 mr-2 mb-2 me-2'
                          style={{
                            borderRadius: '5.25px',
                            background: selectedSlot === item[0] ? '#243B86' : '#DEE5FB',
                            color: selectedSlot === item[0] ? '#fff' : 'black',
                            fontSize: '13px',
                          }}
                          onClick={() => {
                            handleDateChange(item[0], item[1]);
                          }}
                        >
                          {`${item[0]} - ${item[1]}` === '0'
                            ? ''
                            : `${convertTo12HourFormat(item[0])} - ${convertTo12HourFormat(item[1])}`}
                          {/* {`${item[0]} - ${item[1]}`} */}
                        </button>
                      </>
                    ))}
                </div>
              </>
            )}
          </div>
          <div className='d-flex justify-content-center mt-3'>
            <input
              type='checkbox'
              name='payment-cb'
              id='payment-cb'
              value={isRecordPayment}
              onChange={handleRecordPaymenyChange}
              checked={isRecordPayment}
              disabled={!selectedCurrentLocation && !selectPatientName}
            />
            <label className='ms-1' for='vehicle1'>
              {' '}
              Record Payment
            </label>
          </div>
          <hr />
          {isRecordPayment && (
            <div className='d-flex flex-column justify-content-center'>
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '10px',
                  fontWeight: '600px !important',
                  fontSize: '15px !important',
                }}
              >
                Payment Record
              </div>
              <br />
              {isNivanCare && (
                <>
                  <div className='d-flex justify-content-around'>
                    <div className='w-100'>
                      &emsp;&emsp;Appointment Fee:&nbsp;
                      {consultData?.appointment_fee}
                    </div>
                    {/* <div className='w-25' /> */}
                    {/* <div className='w-25' /> */}
                  </div>
                  <br />
                </>
              )}
              <div className='d-flex justify-content-around'>
                <div
                  className='w-25'
                  style={{
                    minWidth: isNivanCare && '150px',
                  }}
                >
                  <Select2DropDown
                    placeholder='Select'
                    label='Type'
                    className='slot-name'
                    showMSG={errors?.payment_type}
                    data={
                      isNivanCare ? paymentTypeList : paymentTypeList.filter((item) => item?.title != 'partially_paid')
                    }
                    labelStyle={{ fontWeight: 'var(--lightFont)' }}
                    onSelect={(data) => {
                      setConsultData({
                        ...consultData,
                        payment_type: data,
                        payment_mode: '',
                        payment_from: '',
                        amount: data === 'paid' ? consultData?.appointment_fee : null,
                        payment_with: '',
                        payment_with_type: '',
                      });
                    }}
                    value={consultData?.payment_type}
                    msgStyle={{ color: 'red' }}
                    valueKey='title'
                    labelKey='label'
                  />
                </div>

                <div
                  className='w-25'
                  style={{
                    minWidth: isNivanCare && '150px',
                  }}
                >
                  <Select2DropDown
                    data={paymentModeList}
                    labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                    placeholder={'Select'}
                    className='slot-name'
                    retuired={isNivanCare}
                    label={'Mode'}
                    onSelect={(data) => {
                      setConsultData({ ...consultData, payment_mode: data, payment_from: '' });
                    }}
                    value={consultData?.payment_mode}
                    showMSG={errors?.payment_mode}
                    msgStyle={{ color: 'red' }}
                    valueKey='title'
                    labelKey='label'
                    disabled={consultData?.payment_type === 'free'}
                  />
                </div>
                <div
                  className='w-25'
                  style={{
                    minWidth: isNivanCare && '150px',
                  }}
                >
                  <Select2DropDown
                    data={paymentFormList}
                    labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                    className='slot-name'
                    placeholder={'Select'}
                    label={'From'}
                    onSelect={(data) => {
                      setConsultData({ ...consultData, payment_from: data });
                    }}
                    value={consultData?.payment_from}
                    showMSG={errors?.payment_from}
                    msgStyle={{ color: 'red' }}
                    valueKey='title'
                    labelKey='label'
                    disabled={consultData?.payment_type === 'free' || consultData?.payment_mode === 'cash'}
                  />
                </div>
              </div>
              <div className='d-flex justify-content-around'>
                <div className='w-25 '>
                  <label htmlFor='validationCustom01 mt-3 slot-name'>
                    {isNivanCare && <span style={{ color: 'red' }}>*&nbsp;</span>}
                    Amount
                  </label>

                  <CurrencyInput
                    id='validationCustom01'
                    name='input-1'
                    className={`form-control`}
                    label='Amount'
                    retuired={isNivanCare}
                    value={
                      isNivanCare
                        ? consultData?.payment_type === 'paid'
                          ? consultData?.appointment_fee
                          : consultData?.amount
                        : consultData?.amount
                    }
                    showMSG={errors?.amount}
                    msgStyle={{ color: 'red' }}
                    onValueChange={(values) => {
                      setConsultData({ ...consultData, amount: values });
                    }}
                    placeholder=''
                    prefix={'₹'}
                    step={1}
                    disabled={
                      consultData?.payment_type === 'free' || (isNivanCare && consultData?.payment_type === 'paid')
                    }
                  />
                  <p style={{ color: 'red' }}>{errors.amount}</p>
                </div>
                <div
                  className='w-25 '
                  style={{
                    minWidth: isNivanCare && '150px',
                  }}
                >
                  {!isNivanCare ? (
                    <CoreInputBox
                      className='slot-name'
                      placeholder=''
                      label='With'
                      showMSG={errors?.payment_with}
                      data={''}
                      labelStyle={{ fontWeight: 'var(--lightFont)', margin: '' }}
                      setValue={(data) => {
                        setConsultData({ ...consultData, payment_with: data });
                      }}
                      value={consultData?.payment_with}
                      msgStyle={{ color: 'red' }}
                      disabled={consultData?.payment_type === 'free'}
                    />
                  ) : (
                    <Select2DropDown
                      data={paymentWithList}
                      labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                      className='slot-name'
                      placeholder={'Select'}
                      label={'Payment With'}
                      valueKey='title'
                      labelKey='label'
                      onSelect={(with_type) => {
                        // setConsultData({ ...consultData, payment_with_type: data });
                        handlePaymentWithTypeChange(with_type);
                      }}
                      onClean={() => {
                        setConsultData({ ...consultData, payment_with_type: '', payment_with: '' });
                      }}
                      value={userData.role !== 'ee' ? consultData?.payment_with_type : 'nivaan_clinic'}
                      // showMSG={errors?.payment_from}
                      // msgStyle={{ color: 'red' }}

                      disabled={consultData?.payment_type === 'free' || userData?.role == 'ee'}
                    />
                  )}
                </div>
                <div
                  className='w-25'
                  style={{
                    minWidth: isNivanCare && '170px',
                  }}
                >
                  <div className='w-25'></div>
                  {consultData?.payment_with_type === 'care_manager' && (
                    <Select2DropDown
                      data={careManagerList}
                      labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                      className='slot-name'
                      placeholder={'Select'}
                      label={'Care Manager'}
                      onSelect={(data) => {
                        setConsultData({ ...consultData, payment_with: data });
                      }}
                      onClean={() => {
                        setConsultData({ ...consultData, payment_with: '' });
                      }}
                      value={consultData?.payment_with}
                      showMSG={errors?.payment_with}
                      // msgStyle={{ color: 'red' }}
                      valueKey='label'
                      labelKey='label'
                    />
                  )}
                </div>
              </div>
              {isNivanCare && (
                <>
                  <br />
                  <div className='d-flex justify-content-around'>
                    <div className='w-100 d-flex'>
                      &emsp;&emsp;Billed To:&nbsp;
                      {userData.role !== 'ee' ? (
                        <div className={'text-capitalize'}>
                          {consultData?.payment_with_type === 'care_manager' ? billedTo : consultData?.payment_with}
                          {consultData?.payment_with_type === 'nivaan_clinic' && billedTo}
                        </div>
                      ) : (
                        <div className={'text-capitalize'}>{titlizeString(billedTo)}</div>
                      )}
                    </div>
                    {/* <div className='w-25' /> */}
                    {/* <div className='w-25' /> */}
                  </div>
                </>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-center mt-4'>
            <Button
              onClick={() => {
                if (editSchedule?.id) {
                  updateConsultation();
                } else {
                  createConsultation();
                }
                if (userData.role == 'ee') {
                  setConsultData({ ...consultData, payment_with_type: 'nivaan_clinic' });
                }
              }}
              appearance='primary'
              style={{ background: '#243B86' }}
              disabled={!selectedSlot}
            >
              Schedule
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AppointmentForm;
