import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { CoreButton, CoreInputBox, Select2DropDown } from './components';
import CommonRenderCheckBox from './components/CommonRenderCheckBox';

function ServiceCategoriesForm(props) {
  const {
    valueObj,
    error,
    isUpdate,
    setValueObj,
    onClick,
    currentPage,
    setTotalPages,
    selectedCatObj,
    setSelectedCatObj,
  } = props;
  const [serviceData, setServiceData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const ApiClient = useApiManager();

  useEffect(() => {
    getServiceList();
  }, [currentPage]);

  const getServicesDropdownSearch = (text) => {
    setIsLoading(true);
    ApiClient.getServiceDropdownSearch(text)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(1);
        setServiceData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getServiceList = () => {
    setIsLoading(true);
    ApiClient.getAppointmentService(currentPage, true)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setServiceData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <div className='medicineForm'>
      <div className='formTitle'>{!isUpdate ? 'Add  Service Category' : 'Update  Service Category'} </div>
      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex w-100 justify-content-between medicineNamerow'>
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.title}
            placeholder='Service Category'
            value={valueObj?.title || ''}
            label='Service Category'
            setValue={(inputVal) => {
              setValueObj({ ...valueObj, title: inputVal });
            }}
            maxLength={255}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
        <div className='daypart-input behaviourpart_box'>
          <Select2DropDown
            onSearch={(text) => getServicesDropdownSearch(text)}
            value={valueObj?.service || ''}
            // data={serviceData}
            data={
              !serviceData.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id
                ? [...serviceData, selectedCatObj]
                : serviceData
            }
            label={'Service '}
            placeholder={'Service '}
            valueKey='id'
            labelKey='title'
            getObjData
            onSelect={(inputVal, obj) => {
              setValueObj({ ...valueObj, service: inputVal });
              setSelectedCatObj(obj);
            }}
            isValidation
            showMSG={error?.service}
            retuired
          />
        </div>
      </div>
      <div style={{ margin: '20px 0' }}>
        <CommonRenderCheckBox
          label='Enable Service Category'
          id={`${isUpdate ? 'labelcheck2' : 'labelcheck'}`}
          htmlFor={`${isUpdate ? 'labelcheck2' : 'labelcheck'}`}
          onChange={(data) => {
            setValueObj({
              ...valueObj,
              enable: !valueObj.enable,
            });
          }}
          checked={valueObj.enable}
        />
      </div>
      <CoreButton
        onClick={() => {
          onClick();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default ServiceCategoriesForm;
