import useApiManager from 'networking/ApiManager';
import React, { useState, useEffect } from 'react';
import { search } from 'res/images';
import { SettingsForm } from 'whealth-core-web/forms';
import { CommonBorderBox, EmptyView, Pagination, CoreInputBox, ListView } from './index';
import './settingsShowList.css';

function DepartmentStatusesView(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  const [status, setStatus] = useState('');
  const [error, seterror] = useState('');
  const [editError, setEditError] = useState('');
  const [departmentStatus, setDepartmentStatus] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    departmentStatusGet();
  }, [currentPage]);

  const departmentStatusDelete = (id) => {
    seterror('');
    setIsLoading(true);
    ApiClient.departmentStatusDelete(id)
      .then((res) => {
        setIsLoading(false);
        departmentStatusGet();
        setIsShowAlert(res.data.message);
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.detail || err.response.data.errors);
        }
        setAlertType('alert-danger');
      });
  };

  const departmentStatusGet = () => {
    setIsLoading(true);
    ApiClient.getAllDepartmentStatues(currentPage)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setIsLoading(false);
        setDepartmentStatus(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const createStatus = () => {
    seterror();
    if (status.trim().length > 0) {
      setIsLoading(true);
      const data = {
        title: status,
      };
      ApiClient.createDepartmentStatus(data)
        .then((res) => {
          setCurrentPage(1);
          setIsLoading(false);
          setStatus('');
          departmentStatusGet();
          setIsShowAlert('Status created successfully');
          setAlertType('alert-success');
        })
        .catch((err) => {
          setIsLoading(false);
          const getUpper = (str) => {
            return str.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
          };
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.detail || err.response.data.errors.title);
        });
    } else {
      seterror('This field is required.');
    }
  };

  const updateData = (data, value) => {
    updateStatus(value, data.id);
  };

  const updateStatus = (status, updateId) => {
    setIsLoading(true);

    const data = {
      title: status,
    };

    ApiClient.updateDepartmentStatus(data, updateId)
      .then((res) => {
        setIsLoading(false);
        departmentStatusGet();
        setStatus('');
        setIsShowAlert('Status updated successfully');
        setAlertType('alert-success');
        console.log(res);
        seterror(false);
      })
      .catch((err) => {
        console.warn('ds', err);
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.detail || err.response.data.errors.title);
        }
        setAlertType('alert-danger');
      });
  };

  const handleClick = () => {
    createStatus();
  };

  return (
    <div>
      <CommonBorderBox className='settingFormOutline'>
        <div className='formTitle'>Add Department Status</div>
        <SettingsForm
          formTitle='#EEF1F3'
          value={status}
          setValue={setStatus}
          label='Status name'
          required
          onClick={handleClick}
          error={error}
        />
      </CommonBorderBox>
      {departmentStatus?.length > 0 ? (
        <ListView
          setEditError={setEditError}
          editError={editError}
          data={departmentStatus}
          updateData={updateData}
          deleteData={departmentStatusDelete}
          currentPage={currentPage}
        />
      ) : (
        <EmptyView hide={isLoading} />
      )}
      <div className='d-flex justify-content-end mt-3'>
        <Pagination
          seterror={seterror}
          data={departmentStatus}
          // length={usersCount?.total_patients}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default DepartmentStatusesView;
