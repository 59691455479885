import { LocalizationProvider, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import React from 'react';
import { DatePicker } from 'rsuite';
import { CoreInputBox } from 'whealth-core-web/components';

function AddTeleConsultationForm(props) {
  let { data, setData, onClick, errors, setPrescriptionCheckBox } = props;
  return (
    <>
      <div className='w-100 p-2'>
        <CoreInputBox
          retuired
          showMSG={errors?.title}
          label={'title'}
          placeholder={'title'}
          value={data?.title || ''}
          setValue={(val) => setData({ ...data, title: val })}
        />
      </div>
      <div className='w-100 p-2'>
        <label>
          <span class='requiredInput'> * </span>Tele Consult Date and time{' '}
        </label>
        {/* <DatePicker
          disabledDate={(date) => {
            if (new Date(date) < new Date().setHours(0, 0, 0)) {
              return true;
            } else {
              return false;
            }
          }}
          // disabled={consultationId}
          placement={'bottomStart'}
          className='w-100'
          format='yyyy-MM-dd HH:mm'
          placeholder={'Date and Time'}
          value={data.consult_datetime ? new Date(data.consult_datetime) : null}
          onChange={(res) => {
            setData({ ...data, consult_datetime: new Date(res).toISOString() });
          }}
          onClean={() => setData({ ...data, consult_datetime: null })}
        /> */}
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <MobileDateTimePicker
            renderInput={(props) => <TextField {...props} />}
            disablePast
            value={data.consult_datetime ? dayjs(data.consult_datetime) : null}
            minDate={data.consult_datetime ? dayjs(data.consult_datetime) : null}
            placeholder={'Create follow-up Consult Date and Time'}
            onChange={(res) => {
              setData({ ...data, consult_datetime: dayjs(res).toISOString() });
            }}
            format='DD/MM/YYYY hh:mm A'
            className='MobileDatePickerinputCommon'
            // label='Consult Date and time'
            // sx={{
            //   width: '100%',
            //   '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
            //     padding: '9.5px 14px',
            //     borderRadius: '10px',
            //   },
            //   '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
            //     padding: '8.5px 14px',
            //   },
            //   '& .MuiInputLabel-root.Mui-focused': { color: '#d9dee2' },
            //   '& .MuiOutlinedInput-root': {
            //     '&:hover > fieldset': { borderColor: '#d9dee2' },
            //     height: '42px',
            //     borderRadius: '10px',
            //   },
            // }}
          />
        </LocalizationProvider>

        {errors?.consult_datetime ? (
          <div className='inputMsg'>{errors?.consult_datetime}</div>
        ) : Object.keys(errors).length > 0 && !Object.keys(errors).includes('title') ? (
          <div className='inputMsg'>{errors}</div>
        ) : null}

        {/* {errors?.title && <div className='inputMsg'>{errors?.title}</div>} */}
      </div>
      <div className='mb-4'>
        <button onClick={onClick} type='button' className='form-control  coreBtn text-white'>
          Save
        </button>
      </div>
    </>
  );
}

export default AddTeleConsultationForm;
