import { Button, DatePicker, Modal } from 'rsuite';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../res/style/CreateMeetingModal.css';
import dayjs from 'dayjs';
import CoreInputBox from '../CoreInputBox';
import CoreTextArea from '../CoreTextArea';

export const CreateMeetingModal = ({ open, handleClose, selectedDate }) => {
  const [title, setTitle] = useState('');
  const [time, setTime] = useState(selectedDate?.start);
  const [participants, setParticipants] = useState();
  const [loading, setLoading] = useState(false);
  const [duration, setDuration] = useState(30);
  const ApiClient = useApiManager();

  const handleSave = async () => {
    try {
      if (title == '' || time == '' || participants == '') {
        toast.error('Please enter all the details.');
      } else {
        const participantsArray = [];
        participants.split(';').map((item) => participantsArray.push({ email: item }));
        const meeting = {
          topic: title,
          start_time: dayjs(selectedDate?.startStr)
            .set('hour', new Date(time).getHours())
            .set('minute', new Date(time).getMinutes())
            .format()
            .split('+')[0],
          end_time: dayjs(selectedDate?.startStr)
            .set('hour', new Date(time).getHours())
            .set('minute', new Date(time).getMinutes())
            .add(duration, 'minute')
            .format()
            .split('+')[0],
          participants: participantsArray,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        setLoading(true);
        const result = await ApiClient.createZohoMeeting(meeting);
        setLoading(false);
        if (result.status == 200) {
          const calendarApi = selectedDate.view.calendar;
          calendarApi.unselect();
          calendarApi.addEvent({
            id: `${result.data.meeting_key}`,
            title: title,
            start: dayjs(selectedDate?.startStr)
              .set('hour', new Date(time).getHours())
              .set('minute', new Date(time).getMinutes())
              .format()
              .split('+')[0],
            end: dayjs(selectedDate?.startStr)
              .set('hour', new Date(time).getHours())
              .set('minute', new Date(time).getMinutes())
              .add(duration, 'minute')
              .format()
              .split('+')[0],
            allDay: false,
          });
          toast.success('Meeting created.');
          handleClose();
        } else {
          toast.error('Failed to schedule meeting.');
        }
      }
    } catch (e) {
      toast.error(e.response.data.message);
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e);
  };

  const handleParticipantsChange = (e) => {
    setParticipants(e);
  };

  const handleTimeChange = (time) => {
    setTime(time);
  };

  const handleDurationChange = (e) => {
    setDuration(e);
  };

  useEffect(() => {
    setTime(selectedDate?.start);
  }, [selectedDate]);

  return (
    <>
      <ToastContainer />
      <Modal backdrop='static' keyboard={false} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Schedule Meeting</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='d-flex flex-column'>
            <p className='fs-6 my-1'>Title</p>
            <CoreInputBox
              className='form-control mb-2'
              type='text'
              placeholder='Title'
              value={title}
              setValue={handleTitleChange}
            />
            <p className='fs-6 my-1'>Participants (Seperate email id's with ';')</p>
            <CoreTextArea
              className='form-control mb-2'
              value={participants}
              setValue={handleParticipantsChange}
            ></CoreTextArea>
            <p className='fs-6 my-1'>Select Time</p>
            <DatePicker
              placement='auto'
              format='HH:mm'
              placeholder='HH:MM'
              editable={false}
              value={time}
              onChange={(value) => handleTimeChange(value)}
            />
            <p className='fs-6 my-1 mt-2'>Duration (in minutes)</p>
            <CoreInputBox
              className='form-control mb-2 w-40'
              type='number'
              min={5}
              placeholder='Duration'
              value={duration}
              setValue={(value) => handleDurationChange(value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSave()} appearance='primary'>
            Save
          </Button>
          <Button onClick={handleClose} appearance='subtle'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
