import React, { useState } from 'react';
import { Modal } from 'rsuite';
import { laptopLink, paymentLink } from 'whealth-core-web/assets/images';
import { dateFormat, timeFormat } from '../Helper';
import CoreTextArea from '../CoreTextArea';
import CommonAlert from '../CommonAlert';

function AppointmentCancel(props) {
  const {
    appointmentDetail,
    cancelModal,
    setCancelModal,
    handleCancelAppointment,
    isShowAlert,
    setIsShowAlert,
    alertType,
  } = props;
  const [overflow, setOverflow] = useState(true);
  const [cancelData, setCancelData] = useState({});

  const renderRecordHeader = () => {
    return (
      <div className='recordPayment-top-box d-flex justify-content-between align-items-start mb-3'>
        <div className=''>
          <div className='head'>Appointment Summary</div>
          <div className='tileName mt-2'>
            {appointmentDetail?.user?.full_name} (
            {appointmentDetail?.user?.gender && appointmentDetail?.user?.gender[0]?.toUpperCase()},{' '}
            {appointmentDetail?.user?.age})
          </div>
          <p>Ph: {appointmentDetail?.user?.phone}</p>
          <p className='mt-2'>
            <strong>
              {dateFormat(appointmentDetail?.consult_datetime)}, {timeFormat(appointmentDetail?.consult_datetime)} -{' '}
              {timeFormat(appointmentDetail?.consult_end_datetime)}
            </strong>{' '}
            <br />
            With {appointmentDetail?.consultant?.full_name} <br />
            {appointmentDetail?.service?.title} <br />
            At Clinic {appointmentDetail?.location?.name} <br />
            <div className='d-flex'>
              Total Fee :
              <span class='material-icons-outlined' style={{ fontSize: '13px', paddingTop: '4px', paddingLeft: '4px' }}>
                currency_rupee
              </span>
              {appointmentDetail?.appointment_fee}
            </div>
          </p>

          {appointmentDetail?.payment_mode && (
            <div className='d-flex flex-column justify-content-start mt-2 '>
              <span className='consultationTitle d-flex'>
                <img src={paymentLink} />
                <div className='d-flex'>
                  Partially Payment of{' '}
                  <span
                    class='material-icons-outlined'
                    style={{ fontSize: '14px', paddingTop: '4px', paddingLeft: '4px' }}
                  >
                    currency_rupee
                  </span>{' '}
                  {appointmentDetail?.amount}
                </div>
              </span>
              <span className='consultationTitle d-flex'>
                <img src={laptopLink} />{' '}
                {appointmentDetail?.payment_mode?.charAt(0)?.toUpperCase() + appointmentDetail?.payment_mode?.slice(1)}
              </span>
            </div>
          )}
        </div>
        {/* <div className='d-flex justify-content-center align-items-center popover-btn'>
              <span class='material-icons-outlined'>mode_edit_outline</span> Edit
            </div> */}
      </div>
    );
  };

  return (
    <Modal
      className='recordPayment'
      backdrop='static'
      overflow={overflow}
      open={cancelModal}
      onClose={() => {
        setCancelData({});
        setCancelModal(false);
      }}
    >
      <Modal.Header>
        <Modal.Title className='text-center modalTitle'>Cancel Appointment</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={`${isShowAlert ? 'mt-5' : ''}`}>
          <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
        </div>
        <div className='appontmentModle-body d-flex flex-column'>
          {renderRecordHeader()}
          <div className='mb-2'>
            <CoreTextArea
              label={'Notes'}
              placeholder={'Notes'}
              value={cancelData?.note || ''}
              setValue={(val) => setCancelData({ ...cancelData, note: val })}
            />
          </div>
          <div className='mb-2 mt-3'>
            <div
              className='d-flex justify-content-between gap-10 schedulButton'
              onClick={() => handleCancelAppointment(appointmentDetail?.id, cancelData, appointmentDetail?.user?.id)}
            >
              <div className='reusableBtnActive  reusableBtn  w-100'>Cancel Appointment</div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AppointmentCancel;
