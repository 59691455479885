import { AdminPanelHeader, SideBar, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { editTheme, trashRed } from 'res/images';
import { Modal } from 'rsuite';
import { MeasuringEventForm, NewArticle } from 'whealth-core-web/forms';
import CommonToolTip from './CommonToolTip';
import CoreDropDown from './CoreDropDown';
import './goalConfigurations.scss';
import { dateFormat, scrollToTop, timeFormat } from './Helper';
import Pagination from './Pagination';

function GoalConfigurations(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  const [createData, setCreateData] = useState({});
  const [data, setData] = useState([]);
  const [updateData, setupdateData] = useState('');
  const [error, seterror] = useState('');
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [logging, setLogging] = useState('');

  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getMeasuringEvent();
  }, [currentPage]);

  const prepateFormData = (data) => {
    const formData = new FormData();
    formData.append('title', data.title || '');
    formData.append('type', data.type || '');
    formData.append('measuring_unit', data.measuring_unit || '');
    data.image && formData.append('image_url', data.image || '');
    return formData;
  };

  const createMeasuringEvent = () => {
    seterror('');
    setIsLoading(true);
    ApiClient.createMeasuringEvent(prepateFormData(createData))
      .then((res) => {
        setIsLoading(false);
        setCurrentPage(1);
        getMeasuringEvent();
        setCreateData({});
        setAlertType('alert-success');
        setIsShowAlert('Measuring event created successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        seterror(err.response.data.errors);
        setIsShowAlert(err.response.data.detail);
        setAlertType('alert-danger');
      });
  };

  const getMeasuringEvent = (type = logging, page = currentPage) => {
    const pages = { page, type };
    setIsLoading(true);
    ApiClient.getMeasuringEvent(pages)
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
        setTotalPages(res.headers['total-pages']);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const updateMeasuringEvent = (data) => {
    setOpen(true);
    setupdateData(data);
    seterror('');
  };

  const updateMeasuringEventData = () => {
    setIsLoading(true);
    ApiClient.updateMeasuringEvent(updateData.id, prepateFormData(updateData))
      .then((res) => {
        setIsLoading(false);
        handleModalClose();
        getMeasuringEvent();
        setAlertType('alert-success');
        setIsShowAlert('Measuring event updated successfully');
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.data?.detail) {
          setIsShowAlert(err.response.data.detail);
          setOpen(false);
        } else {
          seterror(err.response.data.errors);
        }
        setAlertType('alert-danger');
      });
  };

  const handleModalClose = () => {
    setOpen(false);
    setCreateData({});
    setupdateData({});
    seterror('');
  };

  const updateModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          {returnMeasuringEventForm(updateData, setupdateData, true)}
        </Modal>
      </div>
    );
  };

  const typeArr = [
    { title: 'Logging', id: 'logging' },
    { title: 'Measuring', id: 'measuring' },
  ];

  const returnMeasuringEventForm = useCallback(
    (data, setData, updateData) => {
      return (
        <div>
          <MeasuringEventForm
            error={error}
            valueObj={data}
            setValueObj={setData}
            isUpdate={updateData}
            onClick={updateData ? updateMeasuringEventData : createMeasuringEvent}
          />
        </div>
      );
    },
    [createData, updateData, error]
  );

  const deleteData = (id) => {
    seterror('');
    ApiClient.deleteMeasuringEvent(id)
      .then((res) => {
        getMeasuringEvent();
        setIsShowAlert('Measuring event deleted successfully.');
      })
      .catch((err) => {
        setIsShowAlert('Failed to delete Measuring event.');
        setAlertType('alert-danger');
        if (!Array.isArray(Object.values(err.response.data)[0])) {
          setIsShowAlert(Object.values(err.response.data)[0]);
        }
        console.log(err);
      });
  };

  const patination = () => {
    return (
      <Pagination
        seterror={seterror}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };

  const showMeasuringEvents = useMemo(() => {
    return (
      <>
        <div className='w-100 d-flex justify-content-end  eventdropdown'>
          <CoreDropDown
            data={typeArr}
            // showMSG={error?.type}
            placeholder='Select Type'
            value={logging}
            setValue={(data) => {
              getMeasuringEvent(data, 1);
              setCurrentPage(1);
              setLogging(data);
            }}
            // setValue={(data) => console.log('ds', data)}
          />
        </div>
        <table className='w-100'>
          {data.map((item, index) => {
            return (
              <tbody key={index}>
                <tr className='showGoals'>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='img-thumbnail'>
                        <img src={item.image_url} />
                      </div>
                      <div>
                        <div className='GoalsTitle'>{item.title}</div>
                        <small className={`badge capitalize  ${item.type}`}>{item.type}</small>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='userDetailGoal'>
                      <div className='d-flex'>
                        <div className='userDetail-metaDetail'>
                          <div className='metaDetail'>Created by · {item?.admin.full_name}</div>
                          {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
                        </div>
                        <div
                          onClick={() => {
                            updateMeasuringEvent(item);
                          }}
                        >
                          <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                        </div>
                        <div
                          onClick={() => {
                            const windowConfirm = window.confirm('Are you sure you want to delete ?');
                            if (windowConfirm) {
                              deleteData && deleteData(item.id);
                            }
                          }}
                        >
                          <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </>
    );
  }, [data, logging]);

  return (
    <div>
      <div className='commonBorderBox settingFormOutline'>{returnMeasuringEventForm(createData, setCreateData)}</div>
      <div className='table-responsive'>
        <div className='profileQuestion'>{showMeasuringEvents}</div>
      </div>
      {updateModal()}
      <div className='d-flex justify-content-end mt-3'>{patination()}</div>
    </div>
  );
}

export default GoalConfigurations;
