import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import CreateDayPart from './CreateDayPart';
import useApiManager from 'networking/ApiManager';
import Loader from '../Loader';
import CommonToolTip from '../CommonToolTip';
import { addZerosinTime, dateFormat, teamRoles, timeFormat } from '../Helper';
import { editTheme, trashRed } from 'res/images';
import Pagination from '../Pagination';
import { Modal } from 'rsuite';
import { warn } from 'react-bootstrap-typeahead/types/utils';

function DayPart(props) {
  const { setAlertType, setIsShowAlert } = props;
  const initialData = { title: '', fromTime: null, toTime: null };
  const [isLoading, setIsLoading] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [errorMsg, setErrorMsg] = useState({ title: '', toTime: '' });
  const [errorMsgUpdate, setErrorMsgUpdate] = useState({ title: '', toTime: '' });

  const [isEditMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState({});
  const [createData, setCreateData] = useState(initialData);

  const ApiClient = useApiManager();
  useEffect(() => {
    handleGetDayPartList();
  }, [currentPage]);

  const handleGetDayPartList = () => {
    setIsLoading(true);
    let page = { page: currentPage };
    ApiClient.getDayPart(page)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setTableList(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handleResetAllState = () => {
    setEditMode(false);
    setCreateData({});
    setEditData({});
  };

  let createParams = (data) => {
    let obj = {};
    obj.title = data.title;
    obj.start_time = data.fromTime ? hanldeConvert(data.fromTime) : '';
    obj.end_time = data.toTime ? hanldeConvert(data.toTime) : '';
    return obj;
  };

  const handleSaveAction = (obj) => {
    const data = createParams(obj);
    setIsLoading(true);
    ApiClient.createDayPart(data)
      .then((res) => {
        setIsLoading(false);
        setCurrentPage(1);
        setAlertType('alert-success');
        setIsShowAlert('Day Part Created Successfully');
        handleGetDayPartList();
        handleResetAllState();
        setErrorMsg('');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.errors?.non_field_errors);
        setErrorMsg(err.response.data.errors);
      });
  };

  const handleEditAction = (obj) => {
    const data = createParams(obj);
    setIsLoading(true);
    ApiClient.updateDayPart(obj.id, data)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Day Part Updated Successfully');
        handleGetDayPartList();
        setEditMode(false);
        handleResetAllState();
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        if (err.response?.data?.detail || err.response.data?.errors?.non_field_errors) {
          setIsShowAlert(err.response.data.detail || err.response.data.errors?.non_field_errors);
          setEditMode(false);
        }
        setErrorMsgUpdate(err.response.data.errors);
      });
  };

  const hanldeConvert = (time) => {
    let dateTime = '';
    if (typeof time === 'string') {
      let timeArr = time.replace(/\s/g, ':').split(':');
      let amPm = timeArr[timeArr.length - 1].toLowerCase();
      if (amPm == 'pm' || (timeArr[0] == 12 && amPm == 'am')) {
        timeArr[0] = `${Number(timeArr[0]) + 12}`;
      }
      dateTime = timeArr.slice(0, 2).join('');
    } else {
      const { meridiem, hour, minute } = time;
      let hr = `${hour}`;
      if (meridiem.toLowerCase() == 'am') {
        hr = hour > 9 ? `${hour}` : `0${hour}`;
      }
      let mint = minute > 9 ? `${minute}` : `0${minute}`;
      dateTime = `${hr + mint}`;
    }

    return dateTime;
  };

  const formatAMPM = (time) => {
    let hours = null;
    let minutes = null;
    if (time.toString().length === 3) {
      hours = time.toString().slice(0, 1);
      minutes = time.toString().slice(1, 3);
    } else {
      hours = time.toString().slice(0, 2);
      minutes = time.toString().slice(2, 4);
    }
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  const deleteData = (id) => {
    setErrorMsg('');
    setIsLoading(true);
    ApiClient.deleteDayPartTime(id)
      .then((res) => {
        setIsLoading(false);
        handleGetDayPartList();
        setAlertType('alert-success');
        setIsShowAlert('Day part Deleted successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
      });
  };
  const handleEditMode = (item) => {
    item.fromTime = formatAMPM(addZerosinTime(item.start_time));
    item.toTime = formatAMPM(addZerosinTime(item.end_time));
    setEditData(item);
    setErrorMsg({ title: '', toTime: '' });
    setErrorMsgUpdate({ title: '', toTime: '' });
    setEditMode(true);
  };

  const renderForm = (data, setData, action, isEdit) => {
    return (
      <CreateDayPart
        data={data}
        action={action}
        setData={setData}
        errorMsg={errorMsg}
        errorMsgUpdate={errorMsgUpdate}
        setErrorMsgUpdate={setErrorMsgUpdate}
        isEditMode={isEdit}
        setErrorMsg={setErrorMsg}
        btnTitle={isEdit ? 'Update' : 'Save'}
      />
    );
  };

  return (
    <div className='medicine-form'>
      <Loader show={isLoading} />
      <div className='commonBorderBox settingFormOutline mb-4 '>
        <div className='formTitle'>Day Part Form</div>
        {renderForm(createData, setCreateData, handleSaveAction)}
      </div>

      <Modal
        backdrop='static'
        size='md'
        open={isEditMode}
        onClose={() => {
          handleResetAllState();
        }}
      >
        <Modal.Header />
        {renderForm(editData, setEditData, handleEditAction, true)}
      </Modal>

      <div className='table-responsive'>
        <table className='w-100 table'>
          {tableList.map((item) => (
            <tr key={item.id} className='my-3 font-12px'>
              <td colSpan={2}>
                <div className='d-flex mb-1 align-items-center justify-content-between'>
                  <div className='capitalize lightGrayBadge'>
                    <div>
                      <strong> {item.title}</strong>{' '}
                    </div>
                    <div className='badge capitalize lightGrayBadge'>
                      {formatAMPM(addZerosinTime(item.start_time))} - {formatAMPM(addZerosinTime(item.end_time))}
                    </div>
                  </div>
                  <div
                    className='d-flex text-muted justify-content-between align-items-center medicineDataList'
                    style={{ gap: '10px' }}
                  >
                    <div className='capitalize'>
                      <p>
                        Created by · {item?.admin?.full_name} ({teamRoles(item?.admin?.role)})
                      </p>
                      <p>
                        Created at · {dateFormat(item.created_at)} . {timeFormat(item.created_at)}
                      </p>
                    </div>
                    <div className='action-images d-flex'>
                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              handleEditMode(item);
                            }}
                            src={editTheme}
                          />
                        }
                        showValue={'Edit'}
                      />

                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              let windoConfirm = window.confirm('Are you sure you want to delete daypart?');
                              if (windoConfirm) {
                                deleteData(item.id);
                              }
                            }}
                            src={trashRed}
                          />
                        }
                        showValue={'Delete'}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </table>
      </div>
      <Pagination
        seterror={setErrorMsg}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default DayPart;
