import Multiselect from 'multiselect-react-dropdown';
import React, { useEffect, useRef, useState } from 'react';
import { CloseRound } from 'res/images';
import CommonToolTip from './CommonToolTip';
import './tagsShow.css';

function TagsShow(props) {
  const {
    onSearch,
    tagsStyle,
    hideSelectedList,
    placeholder,
    retuired,
    details,
    label,
    msgStyle,
    labelStyle,
    showMSG,
    setValue,
    value,
    data,
    displayValue,
    objRequired,
    emptyRecordMsg,
  } = props;
  const [selectedValues, setSelectedValues] = useState([]);
  const [placeholderText, setPlaceholderText] = useState(placeholder);

  let multiselectRef = useRef();
  useEffect(() => {
    if (Array.isArray(value)) {
      setPlaceholderText(value.length == 0 && placeholder);
    } else {
      setPlaceholderText(placeholder);
    }
  }, [placeholder, value]);

  useEffect(() => {
    setAllSelectedValues();
  }, [value]);

  const setSelectedTag = (data) => {
    let dataArr = [];
    data.map((item, index) => {
      dataArr.push(item.id);
    });
    if (objRequired) {
      setValue(dataArr, data);
    } else {
      setValue(dataArr);
    }
  };

  const setAllSelectedValues = () => {
    const allValues = [];
    data?.map((item, index) => {
      if (value?.includes(item?.id)) {
        allValues.push(item);
      }
    });

    setSelectedValues(allValues);
  };

  return (
    <div className='bodyDivTags'>
      <div className='inputFieldHeader'>
        {label ? (
          <label className='inputlabel' style={labelStyle}>
            {retuired && <span className='requiredInput'> * </span>} {label}
          </label>
        ) : (
          <div />
        )}
        <div className='inputDetailsDiv'>{details}</div>
      </div>
      <Multiselect
        onSearch={onSearch}
        ref={multiselectRef}
        hideSelectedList={hideSelectedList}
        closeOnSelect={false}
        avoidHighlightFirstOption={true}
        selectedValues={selectedValues}
        displayValue={displayValue || 'title'}
        onRemove={setSelectedTag}
        onSelect={setSelectedTag}
        options={data}
        placeholder={placeholderText}
        selectionLimit={100}
        style={tagsStyle}
        emptyRecordMsg={emptyRecordMsg || ''}
        customCloseIcon={
          <CommonToolTip
            placement={'bottom'}
            item={
              <img src={CloseRound} style={{ height: '16px', width: '16px', marginLeft: '4px', cursor: 'pointer' }} />
            }
            showValue={'Remove'}
          />
        }
      />
      {showMSG && (
        <div className='inputMsg' style={msgStyle}>
          {showMSG}
        </div>
      )}
    </div>
  );
}

export default TagsShow;
