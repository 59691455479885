import useApiManager from 'networking/ApiManager';
import React, { useState, useEffect } from 'react';
import { search } from 'res/images';
import { SettingsForm } from 'whealth-core-web/forms';
import { CommonBorderBox, EmptyView, Pagination, CoreInputBox, ListView } from './index';
import './settingsShowList.css';

function ArticleCategoriesView(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  const [category, setCategory] = useState('');
  const [error, seterror] = useState('');
  const [editError, setEditError] = useState('');
  const [articlesCategory, setArticlesCategory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchInput, setSearchInput] = useState('');
  let timeOut;

  useEffect(() => {
    timeOut = setTimeout(() => {
      if (searchInput?.trim()?.length > 2) {
        searchCategory();
      }
    }, 300);
  }, [searchInput]);

  useEffect(() => {
    if (!searchInput || searchInput?.trim()?.length == 0) {
      articleCategoryGet();
    }
  }, [currentPage, searchInput?.trim()?.length == 0]);

  const articleCategoryDelete = (id) => {
    seterror('');
    setIsLoading(true);
    ApiClient.articleCategoryDelete(id)
      .then((res) => {
        setIsLoading(false);
        articleCategoryGet();
        setIsShowAlert(res.data.message);
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.detail || err.response.data.message);
        }
        setAlertType('alert-danger');
      });
  };

  const articleCategoryGet = () => {
    setIsLoading(true);
    ApiClient.articleCategoryGet(currentPage)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setIsLoading(false);
        setArticlesCategory(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const createCategory = () => {
    seterror();
    if (category.trim().length > 0) {
      setIsLoading(true);
      const data = {
        title: category,
      };
      ApiClient.createCategories(data)
        .then((res) => {
          setCurrentPage(1);
          setIsLoading(false);
          setCategory('');
          articleCategoryGet();
          setIsShowAlert('Category created successfully');
          setAlertType('alert-success');
        })
        .catch((err) => {
          setIsLoading(false);
          const getUpper = (str) => {
            return str.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
          };
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.detail || err.response.data.errors.title);
        });
    } else {
      seterror('This field is required.');
    }
  };

  const searchCategory = () => {
    setIsLoading(true);
    ApiClient.searchCategory(searchInput)
      .then((res) => {
        setTotalPages(1);
        setIsLoading(false);
        setArticlesCategory(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const renderSearchInput = () => {
    return (
      <div className='mb-3'>
        <CoreInputBox
          placeholder='Search Article Categories'
          value={searchInput}
          setValue={(text) => {
            clearTimeout(timeOut);
            setSearchInput(text);
          }}
          leftIcon={search}
        />
      </div>
    );
  };

  const updateData = (data, value) => {
    updateCategory(value, data.id);
  };

  const updateCategory = (category, updateId) => {
    setIsLoading(true);

    const data = {
      title: category,
    };

    ApiClient.updateCategories(data, updateId)
      .then((res) => {
        setIsLoading(false);
        articleCategoryGet();
        setCategory('');
        setIsShowAlert('Category updated successfully');
        setAlertType('alert-success');
        console.log(res);
        seterror(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.detail || err.response.data.errors.title);
        }
        setAlertType('alert-danger');
      });
  };

  const handleClick = () => {
    createCategory();
  };

  return (
    <div>
      {renderSearchInput()}
      <CommonBorderBox className='settingFormOutline'>
        <div className='formTitle'>Add Article Categories</div>
        <SettingsForm
          formTitle='#EEF1F3'
          value={category}
          setValue={setCategory}
          label='Category name'
          required
          onClick={handleClick}
          error={error}
        />
      </CommonBorderBox>
      {articlesCategory?.length > 0 ? (
        <ListView
          setEditError={setEditError}
          editError={editError}
          data={articlesCategory}
          updateData={updateData}
          deleteData={articleCategoryDelete}
        />
      ) : (
        <EmptyView hide={isLoading} />
      )}
      <div className='d-flex justify-content-end mt-3'>
        <Pagination
          seterror={seterror}
          data={articlesCategory}
          // length={usersCount?.total_patients}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default ArticleCategoriesView;
