import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CommonAlert from '../CommonAlert';
import { profileImage } from '../Helper';
import Loader from '../Loader';
import AddConsultationForm from 'whealth-core-web/forms/AddConsultationForm';
import AppointmentForm from '../AppointmentVer2/AppointmentForm';
import RecordPayment from '../AppointmentVer2/RecordPayment';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function AddConsultation(props) {
  const { patientId, consultationId } = useParams();
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  let userData = useGetLoginUserDataQuery()?.data?.data || {};

  const ApiClient = useApiManager();
  const navigation = useNavigate();
  const [prescriptionCheckBox, setPrescriptionCheckBox] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [clinicList, setClinicList] = useState([]);
  const [searchClinicList, setSearchClinicList] = useState([]);
  const [searchClinicStr, setSearchClinicStr] = useState('');
  const [recordModal, setRecordModal] = useState(false);
  const [recordErrors, setRecordErrors] = useState({});
  const [isClinicShow, setIsClinicShow] = useState(true);
  const consultLocation = useLocation();
  const [consultData, setConsultData] = useState({
    title: '',
    consult_type: null,
    consult_datetime: null,
    location_id: null,
    consultant_id: '',
    meeting_link: '',
    payment_type: '',
    payment_mode: '',
    amount: null,
    consultation_status: '',
    patient_attendance_status: '',
  });
  const [createField, setCreateField] = useState({
    user_id: parseInt(patientId),
    consult_type: null,
    service_id: null,
    location_id: null,
    consultant_id: null,
    consult_datetime: null,
    consult_end_datetime: null,
    appointment_fee: null,
  });

  useEffect(() => {
    if (!recordModal) {
      setRecordErrors({});
      setConsultData({
        payment_type: null,
        payment_mode: null,
        payment_from: null,
        amount: null,
        patient_attendance_status: null,
        payment_with_id: null,
        payment_with_source: null,
      });
    }
  }, [recordModal]);

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails?.gender, userDetails?.image),
      title: consultationId ? consultData?.user?.full_name : userDetails?.name,
      link: `/patient/${patientId}/dashboard`,
    },
    { title: 'Appointment', link: '' },
  ];

  useEffect(() => {
    if (consultationId) {
      consultationDetail();
    }
  }, [consultationId]);

  useEffect(() => {
    let isClinicDisable = configData?.find((configItem) => configItem?.key == 'departments_clinics');
    if (isClinicDisable?.key) {
      setIsClinicShow(isClinicDisable?.isActive);
    }
  }, [configData]);

  useEffect(() => {
    getPatientData();
  }, []);

  useEffect(() => {
    getLocations();
  }, []);

  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientDetails(patientId)
      .then((res) => {
        setIsLoading(false);
        setConsultData({ ...consultData, user: res.data });
        setPatientDetails(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const consultationDetail = () => {
    ApiClient.getAppointmentDetail(consultationId)
      .then((res) => {
        let appointmentData = res?.data;
        // if (
        //   appointmentData?.location?.id &&
        //   clinicList?.findIndex((item4) => item4?.id == appointmentData?.location?.id) == -1
        // ) {
        //   clinicList.push(appointmentData?.location);
        //   setClinicList([...clinicList]);
        // }
        setConsultData(appointmentData);
        setCreateField({
          user_id: appointmentData?.user?.id,
          consult_type: appointmentData?.consult_type,
          service_id: appointmentData?.service?.id,
          location_id: appointmentData?.location?.id,
          consultant_id: appointmentData?.consultant?.id,
          consult_datetime: appointmentData?.consult_datetime,
          consult_end_datetime: appointmentData?.consult_end_datetime,
          appointment_fee: appointmentData?.appointment_fee,
        });
      })
      .catch((err) => {
        // setIsLoading(false);
      });
  };

  const updateConsultation = () => {
    setErrors({});
    if (!consultLocation.state.check_in) {
      ApiClient.consultationEdit(consultData, patientId, consultationId)
        .then((res) => {
          setAlertType('alert-success');
          setIsShowAlert('Consultation Updated Successfully');
          setTimeout(() => {
            navigation(`/patient/${patientId}/dashboard`);
          }, 2500);
        })
        .catch((err) => {
          console.warn('err', err);
          if (err?.response?.data?.errors) {
            setErrors(err?.response?.data?.errors);
          }
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.message);
        });
    } else {
      ApiClient.consultationUpdate(consultData, patientId)
        .then((res) => {
          setAlertType('alert-success');
          setIsShowAlert('Consultation Updated Successfully');
          setTimeout(() => {
            navigation(`/patient/${patientId}/dashboard`);
          }, 2500);
        })
        .catch((err) => {
          console.warn('err', err);
          if (err?.response?.data?.errors) {
            setErrors(err?.response?.data?.errors);
          }
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.message);
        });
    }
  };

  const createConsultation = () => {
    setErrors({});
    ApiClient.consultationCreate(consultData, patientId)
      .then((res) => {
        setAlertType('alert-success');
        setIsShowAlert('Consultation Created Successfully');
        setTimeout(() => {
          navigation(`/patient/${patientId}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        if (err?.response?.data?.errors) {
          setErrors(err?.response?.data?.errors);
        }
        console.warn('createConsultation', err.response);
      });
  };

  useEffect(() => {
    if (consultData?.location?.id && clinicList?.findIndex((item2) => item2?.id == consultData?.location?.id) == -1) {
      setClinicList([...clinicList, consultData?.location]);
    }
  }, [clinicList, consultData?.id]);

  const getLocations = () => {
    setIsLoading(true);
    ApiClient.getLocations(1)
      .then((res) => {
        setIsLoading(false);
        setClinicList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        // setIsLoading(false);
      });
  };

  const appointmentDetail = (appointmentId) => {
    setIsLoading(true);
    ApiClient.getAppointmentDetail(appointmentId)
      .then((res) => {
        setIsLoading(false);
        setConsultData(res.data);
        setRecordModal(true);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const saveAppointment = (data, isRecord) => {
    setIsLoading(true);
    if (consultationId) {
      ApiClient.updateAppointment(consultationId, data)
        .then((res) => {
          setIsLoading(false);
          setIsShowAlert('Appointment Updated Successfully');
          if (isRecord) {
            appointmentDetail(res?.data?.id);
          } else {
            setTimeout(() => {
              navigation(`/patient/${patientId}/dashboard`);
            }, 2500);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.response?.data?.errors) {
            setErrors(err?.response?.data?.errors);
          }
        });
    } else {
      ApiClient.createAppointment(data)
        .then((res) => {
          setIsLoading(false);
          setIsShowAlert('Appointment Created Successfully');
          if (isRecord) {
            appointmentDetail(res?.data?.id);
          } else {
            setTimeout(() => {
              navigation(`/patient/${patientId}/dashboard`);
            }, 2500);
          }
        })
        .catch((err) => {
          setIsLoading(false);
          if (err?.response?.data?.errors) {
            setErrors(err?.response?.data?.errors);
          }
        });
    }
  };

  const handleRecordPayment = (idAppointment, recordPaymentData) => {
    setIsLoading(true);
    ApiClient.recordPayment(idAppointment, recordPaymentData)
      .then((res) => {
        setIsLoading(false);
        setIsShowAlert('Appointment Created And Payment Recorded Successfully');
        setRecordModal(false);
        setTimeout(() => {
          navigation(`/patient/${patientId}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setRecordErrors(err?.response?.data?.errors);
      });
  };

  const handleClinicSearch = (searchStr) => {
    if (searchStr?.length > 2) {
      setSearchClinicStr(searchStr);
      setIsLoading(true);
      ApiClient.searchLocations(searchStr)
        .then((res) => {
          setIsLoading(false);
          setSearchClinicList(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      setSearchClinicStr('');
      setSearchClinicList([]);
    }
  };

  return (
    <div className='wrapper'>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>
        <div className='addnewform-part'>{consultationId ? 'Update' : 'Create'} Appointment</div>
        <div className='hrLine' />
        {props?.isNivanCare && (
          <>
            <br />
            <div className='d-flex justify-content-around'>
              <div className='capitalize'>Gender : {patientDetails?.gender}</div>
              <div>Age : {patientDetails?.age}</div>
              <div className='capitalize'>
                Insurance Category :{' '}
                {patientDetails?.profile_info?.find((item3) => item3?.key == 'insurance_category')?.answer || 'N/A'}
              </div>
            </div>
          </>
        )}
      </div>
      <div className='px-4 wrapperContent'>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        <AppointmentForm
          createField={createField}
          setCreateField={setCreateField}
          handleClinicSearch={handleClinicSearch}
          allClinic={clinicList}
          searchClinicList={searchClinicList}
          onClick={saveAppointment}
          errors={errors}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          disablePatient
          appointmentID={consultationId}
          appointmentDetail={consultData}
          isClinicEnable={isClinicShow}
        />
        <RecordPayment
          isNivanCare={props?.isNivanCare}
          userData={userData}
          setIsLoading={setIsLoading}
          appointmentDetail={consultData}
          handleRecordPayment={handleRecordPayment}
          open={recordModal}
          setOpen={setRecordModal}
          errors={recordErrors}
          setErrors={setRecordErrors}
          isClinicEnable={isClinicShow}
        />
        {/* <AddConsultationForm
          isNivanCare={props?.isNivanCare ? true : false}
          data={consultData}
          patientId={patientId}
          setPrescriptionCheckBox={setPrescriptionCheckBox}
          setData={setConsultData}
          onClick={consultationId ? updateConsultation : createConsultation}
          errors={errors}
        /> */}
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

export default AddConsultation;
