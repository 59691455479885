import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import { editTheme, search, trashRed } from 'res/images';
import { Modal } from 'rsuite';
import GoalCreationForm from 'whealth-core-web/forms/GoalCreationForm';
import Pagination from './Pagination';
import { dateFormat, scrollToTop, timeFormat } from './Helper';
import CoreDropDown from './CoreDropDown';
import CommonToolTip from './CommonToolTip';
import CoreInputBox from './CoreInputBox';

function GoalConfigurations(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  let initialFormValues = {
    day_parts: [],
    extra_details: {},
    type: 'measuring',
    is_disabled: false,
  };

  const [createData, setCreateData] = useState(initialFormValues);
  const [data, setData] = useState([]);
  const [updateData, setupdateData] = useState('');
  const [error, seterror] = useState('');
  const [goalsData, setGoalsData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [logging, setLogging] = useState('');
  const [searchInput, setSearchInput] = useState('');

  let timeOut;

  useEffect(() => {
    timeOut = setTimeout(() => {
      if (searchInput?.trim()?.length > 2) {
        searchGoals();
      }
    }, 300);
  }, [searchInput]);

  useEffect(() => {
    if (!searchInput || searchInput?.trim()?.length == 0) {
      getGoalsData();
    }
  }, [currentPage, logging, searchInput?.trim()?.length == 0]);

  useEffect(() => {
    getGoalsData();
  }, [currentPage]);

  useEffect(() => {
    getGoalEvent();
  }, []);

  const createGoals = () => {
    seterror('');
    setIsLoading(true);
    let newApiData = { ...createData };
    newApiData.goal_category = newApiData.goal_category ? newApiData.goal_category : 'others';
    ApiClient.createGoalCreation(newApiData)
      .then((res) => {
        setCreateData({ ...initialFormValues });
        setIsLoading(false);
        setCurrentPage(1);
        getGoalsData();
        setAlertType('alert-success');
        setIsShowAlert('Goal created successfully');
      })
      .catch((err) => {
        console.warn('er', err);
        setIsLoading(false);
        seterror(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.errors.non_field_errors);
      });
  };

  const getGoalsData = (type = logging, page = currentPage) => {
    const pages = { page, type };

    setIsLoading(true);
    ApiClient.getGoals(pages)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setGoalsData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getGoalEvent = () => {
    setIsLoading(true);
    ApiClient.getMeasuringEvent()
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const updateGoals = (data) => {
    setOpen(true);
    getGoalsData();
    setupdateData(data);
    seterror('');
  };

  const updateGoalsData = () => {
    setIsLoading(true);
    let newApiData = { ...updateData };
    newApiData.input_value_counter = updateData.input_value_counter || null;
    newApiData.goal_category = newApiData.goal_category ? newApiData.goal_category : 'others';
    ApiClient.updateGoals(updateData.id, newApiData)
      .then((res) => {
        setIsLoading(false);
        handleModalClose();
        getGoalsData();
        setAlertType('alert-success');

        setIsShowAlert('Goal updated successfully');
        console.log(res);
      })
      .catch((err) => {
        console.warn('gr', err);
        setIsLoading(false);
        if (err.response?.data?.detail || err.response.data.errors.non_field_errors) {
          setIsShowAlert(err.response.data.detail || err.response.data.errors.non_field_errors);
          setOpen(false);
        } else {
          seterror(err.response.data.errors);
        }
        setAlertType('alert-danger');
      });
  };

  const handleModalClose = () => {
    setOpen(false);
    setCreateData({ ...initialFormValues });
    setupdateData({});
    seterror('');
  };

  const updateModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          {returnMeasuringGoalForm(updateData, setupdateData, true)}
        </Modal>
      </div>
    );
  };

  const typeArr = [
    { title: 'Logging', id: 'logging' },
    { title: 'Measuring', id: 'measuring' },
  ];

  const returnMeasuringGoalForm = useCallback(
    (formData, setFormData, isUpdata) => {
      return (
        <div>
          <GoalCreationForm
            error={error}
            setError={seterror}
            isUpdate={isUpdata}
            data={data}
            valueObj={formData}
            setValueObj={setFormData}
            onClick={isUpdata ? updateGoalsData : createGoals}
          />
        </div>
      );
    },
    [createData, updateData, data, error]
  );

  const searchGoals = (type = logging, page = currentPage, search_str = searchInput) => {
    const params = { page, type, search_str };
    setIsLoading(true);
    ApiClient.searchGoals(params)
      .then((res) => {
        setTotalPages(1);
        setIsLoading(false);
        setGoalsData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const deleteData = (id) => {
    seterror('');
    setIsLoading(true);
    ApiClient.deleteGoals(id)
      .then((res) => {
        setIsLoading(false);
        getGoalsData();
        setIsShowAlert('Goal deleted successfully.');
      })
      .catch((err) => {
        console.warn(err);
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
      });
  };

  const patination = () => {
    return (
      <Pagination
        seterror={seterror}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };

  const renderSearchInput = () => {
    return (
      <div className='w-100 me-2'>
        <CoreInputBox
          placeholder='Search Goal'
          value={searchInput}
          setValue={(text) => {
            clearTimeout(timeOut);
            setSearchInput(text);
          }}
          leftIcon={search}
        />
      </div>
    );
  };

  const showData = useMemo(() => {
    return (
      <>
        <div className='d-flex justify-content-end '>
          <div className='me-2'>
            <CoreInputBox
              placeholder='Search Goal'
              value={searchInput}
              setValue={(text) => {
                clearTimeout(timeOut);
                setSearchInput(text);
              }}
              leftIcon={search}
            />
          </div>
          <div>
            <CoreDropDown
              data={typeArr}
              placeholder='Select Type'
              value={logging}
              setValue={(data) => {
                getGoalsData(data, 1);
                setCurrentPage(1);
                setLogging(data);
                setSearchInput('');
              }}
            />
          </div>
        </div>
        <div className='d-flex justify-content-center'>{goalsData?.length ? '' : 'No data found'}</div>
        {goalsData.map((item, index) => {
          return (
            <tr key={index} className='showGoals '>
              <td>
                <div className='d-flex align-items-center'>
                  <div className='img-thumbnail'>
                    <img src={item.measuring_event.image_url} />
                  </div>
                  <div>
                    <div className='GoalsTitle'>{item.title}</div>
                    <small className={`badge capitalize  ${item.type}`}>{item.type}</small>
                    <small className={`badge capitalize ms-2 logging ${item.is_disabled}`}>
                      {item.is_disabled ? 'disabled' : 'enable'}
                    </small>
                    <div className='GoalsSubTitle'>
                      {item.value} {item.measuring_event.measuring_unit} | {item.frequency}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div className='userDetailGoal'>
                  <div className='d-flex'>
                    <div className='userDetail-metaDetail'>
                      <div className='metaDetail'>Created by · {item?.admin?.full_name} </div>
                      {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
                    </div>

                    <div
                      onClick={() => {
                        updateGoals(item);
                      }}
                    >
                      <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                    </div>
                    <div
                      onClick={() => {
                        const windowConfirm = window.confirm('Are you sure you want to delete ?');
                        if (windowConfirm) {
                          deleteData(item.id);
                        }
                      }}
                    >
                      <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          );
        })}
      </>
    );
  }, [goalsData, logging, searchInput]);

  return (
    <div>
      <div className='commonBorderBox settingFormOutline'>{returnMeasuringGoalForm(createData, setCreateData)}</div>
      <div className='table-responsive'>
        <div className='mt-4'>
          <table className='w-100'>{showData}</table>
          <div className='d-flex justify-content-end mt-3'>{patination()}</div>
        </div>
      </div>
      {updateModal()}
    </div>
  );
}

export default GoalConfigurations;
