import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import endPoints from "networking/endPoints";
import auth_axios from "networking/AuthAxios";

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: "" }) =>
  async ({ url, method, body, params }) => {
    try {
      const result = await auth_axios({ url: baseUrl + url, method, data: body, params });
      return { data: result };
    } catch (err) {
      return { data: err.response };
    }
  };

export const dashboardSlice = createApi({
  reducerPath: "dashboardData",
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: (headers) => {
      headers.set("Authorization", `Bearer ${localStorage.getItem("token")}`);
      return headers;
    },
  }),
  tagTypes: ["dashboard"],

  endpoints: (builder) => ({
    getDashBoardData: builder.query({
      query: () => {
        return {
          url: endPoints.DASHBOARD,
          method: "get",
        };
      },
    }),

    showAssigndTask: builder.query({
      query: (params) => {
        return {
          url: endPoints.ASSIGNTASK,
          method: "get",
          params: { ...params },
        };
      },
      providesTags: ["dashboard"],
    }),

    completeAssigndTask: builder.mutation({
      query: (params) => {
        let { id, taskId } = params;
        return {
          url: endPoints.TASKSCOMPLETE(id, taskId),
          method: "post",
        };
      },
      invalidatesTags: ["dashboard"],
    }),
  }),
});

export const { useGetDashBoardDataQuery, useShowAssigndTaskQuery, useCompleteAssigndTaskMutation } = dashboardSlice;
