import React, { useEffect, useState } from 'react';
import { editTheme, trashRed } from 'res/images';

import { checked } from 'whealth-core-web/res/images';
import CoreInputBox from './CoreInputBox';
import CoreTextArea from './CoreTextArea';
import { dateFormat, getDateTime, teamRoles, timeFormat } from './Helper';
import './listView.css';
import ReadMoreReadLess from './ReadMoreReadLess';
import CommonToolTip from './CommonToolTip';

function ListView(props) {
  const { data, deleteData, editError, setEditError, updateData, showKey, currentPage } = props;

  const [isUpdate, setIsUpdate] = useState(false);
  const [inputData, setInputData] = useState('');

  const getDate = (date) => {
    const newDate = new Date();
    var date = new Date(date);

    let currentDay = newDate.getDate().toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    var month = date.toLocaleString('default', { month: 'short' });
    var year = date.getFullYear();
    if (currentDay == day) {
      return 'Today ';
    } else {
      return `${day} ${month} ${year}`;
    }
  };

  useEffect(() => {
    setIsUpdate(false);
  }, [currentPage]);

  const showData = () => {
    return (
      <div className={!showKey ? 'tagsView' : 'notesList'}>
        <div className='table-responsive'>
          <table className='w-100 table'>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className='w-100'>
                        <div className='editInput'>
                          {isUpdate == item.id ? (
                            <div className='w-100'>
                              {showKey ? (
                                <CoreTextArea
                                  rows={3}
                                  retuired
                                  showMSG={inputData.length == 0 && editError}
                                  value={inputData}
                                  setValue={setInputData}
                                  msgStyle={{ color: 'red' }}
                                />
                              ) : (
                                <CoreInputBox
                                  msgStyle={{ color: 'red' }}
                                  showMSG={inputData.length == 0 && editError}
                                  value={inputData}
                                  setValue={setInputData}
                                  valueType='text'
                                />
                              )}
                            </div>
                          ) : (
                            <>
                              <div className='tags mb-4 capitalize'>
                                <span className={!showKey && 'badge capitalize lightGrayBadge'}>
                                  {/* {showKey ? item[showKey] : item.title} */}
                                  <ReadMoreReadLess limit={150} data={showKey ? item[showKey] : item.title} />
                                </span>
                              </div>
                            </>
                          )}
                        </div>
                      </td>

                      <td>
                        <div className='listNotsright'>
                          <div className='metaDetails mb-4 me-3'>
                            <div>
                              Created by · {item.admin ? item.admin.full_name : item?.created_by?.full_name} (
                              {teamRoles(item.admin ? item.admin.role : item?.created_by?.role)})
                              <div>
                                Created <span className='lowercase'> at </span>· {dateFormat(item?.created_at)}
                                &nbsp;
                                {timeFormat(item.created_at)}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex tagsViewIcons  mb-4'>
                            {isUpdate == item.id ? (
                              <div
                                onClick={() => {
                                  if (inputData.trim().length > 0) {
                                    setEditError('');
                                    setIsUpdate(false);
                                    updateData && updateData(item, inputData);
                                    setInputData(false);
                                  } else {
                                    setEditError('This field is required.');
                                  }
                                }}
                              >
                                <img src={checked} />
                              </div>
                            ) : (
                              <div
                                onClick={() => {
                                  setIsUpdate(item.id);
                                  setInputData(showKey ? item[showKey] : item.title);
                                }}
                              >
                                <CommonToolTip
                                  placement={'top'}
                                  item={<img onClick={() => setEditError(false)} src={editTheme} />}
                                  showValue={'Edit'}
                                />
                              </div>
                            )}
                            <div
                              onClick={() => {
                                const windowConfirm = window.confirm('Are you sure you want to delete ?');
                                if (windowConfirm) {
                                  deleteData && deleteData(item.id);
                                }
                              }}
                            >
                              <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return <div>{showData()} </div>;
}

export default ListView;
