import { AdminPanelHeader, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AssignteamCard, BodyCard, CommonAlert, Loader, PatientProfile } from 'whealth-core-web/components';
import WeightCard from './WeightCard';
import PatientActivity from './PatientActivity';
import { setLocations, setPatientDetails } from 'redux/Slices';
import { useDispatch, useSelector } from 'react-redux';
import { dateFormatString, profileImage, subtractMonths } from 'whealth-core-web/components/Helper';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import { ZohoCalendar } from 'whealth-core-web/components/ZohoCalendar/ZohoCalendar';

function ShowPatientProfile() {
  const ApiClient = useApiManager();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { patientDetails, updatePatientDetails, alerts } = useSelector((state) => state.sliceReducer);
  const [businessConfig, setBusinessConfig] = useState({});
  const [data, setData] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [graphData, setGraphData] = useState([]);
  const [showWeight, setWeight] = useState(0);
  const [dateValue, setDateValue] = useState(dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)));
  const [callLogNotes, setCallLogNotes] = useState('');

  const getGraphData = () => {
    const queryDate = { date_range: dateValue };
    ApiClient.weightGraph(id, queryDate)
      .then((res) => {
        setGraphData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getPatientData();
  }, [id, updatePatientDetails]);

  useEffect(() => {
    const getBusinessConfig = async () => {
      const res = await ApiClient.getConfig();
      if (res.status == 200) setBusinessConfig(res.data.configuration);
    };
    getBusinessConfig();
  }, []);

  const getPatientData = useCallback(() => {
    setIsLoading(true);
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setIsLoading(false);
        let newuserData = { ...res.data };
        newuserData.status_uuid = res.data.status.uuid;
        let tempLocations = [];
        newuserData?.locations?.map((item) => {
          tempLocations.push(item.id);
        });
        // newuserData?.tempLocations = tempLocations
        const reformattedData = { ...newuserData, locations: tempLocations };
        setData(reformattedData);
        dispatch(setPatientDetails(reformattedData));
        const userDetails = { name: newuserData.full_name, gender: newuserData.gender, image: newuserData.profile_url };
        localStorage.setItem('userDetails', JSON.stringify(userDetails));
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }, [id, updatePatientDetails]);

  useEffect(() => {
    const getData = async () => {
      const res = await ApiClient.getLocations();
      if (res.status == 200) {
        let locationsData = [];
        res.data.map((item) => locationsData.push({ id: item.id, title: item.name }));
        dispatch(setLocations(locationsData));
      }
    };
    try {
      getData();
    } catch (e) {
      console.log(e);
    }
  }, []);

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      title: patientDetails.full_name,
      icon: profileImage(patientDetails.gender, patientDetails.profile_url),
      link: '',
    },
  ];

  const renderDynamicTiles = () => {
    return (
      <>
        <AssignteamCard
          getPatientData={getPatientData}
          searchRole={'doctor,health_coach,health_coach_lead,admin,diet_health_coach,fitness_health_coach'}
          userData={data}
          inputValue={inputValue}
          placeholder={'Assign Doctor/Health Coach'}
          setInputValue={setInputValue}
        />
        <WeightCard
          graphData={graphData}
          setGraphData={setGraphData}
          showWeight={showWeight}
          setWeight={setWeight}
          dateValue={dateValue}
          setDateValue={setDateValue}
          getGraphData={getGraphData}
          userData={data}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <BodyCard data={data} />
      </>
    );
  };

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search a patient by name or mobile number'
        searchMethod='PATIENTSEARCH'
        label='full_name'
        searchId='Patients'
        renderPath='/patient/{source_id}/dashboard' //ShowPatientProfile
        imagePath='profile_url'
      />
    );
  }, []);

  const renderAdminPanelHeader = useMemo(() => {
    return (
      <AdminPanelHeader
        searchBox={renderSearchBox}
        iconTitle='person_outline'
        showTree={showTree}
        title='Patient'
        placeholder='Search Patients'
      />
    );
  }, [patientDetails]);

  const renderDashBoardHeader = useMemo(() => {
    return <DashBoardHeader leftData={[]} />;
  }, []);

  const showPatient = () => (
    <>
      <div className='patientHeader stickyHeader boxShadow'>
        {renderAdminPanelHeader}
        {renderDashBoardHeader}
      </div>
    </>
  );

  const patientBody = () => {
    return (
      <>
        {showPatient()}

        <div className='p-3 wrapperContent'>
          <CommonAlert className='m-2' redux='patient' />
          <div className='d-md-flex flex-wrap patientTabContent'>
            <div className={'w-65'}>
              <PatientProfile setCallLogNotes={setCallLogNotes} data={data} getPatientData={getPatientData} />
              <PatientActivity
                activeNotesCallLog={callLogNotes}
                setCallLogNotes={setCallLogNotes}
                getPatientData={getPatientData}
                getGraphData={getGraphData}
                data={data}
              />
            </div>
            <div className='p-2 w-35'>
              {renderDynamicTiles()}
              {businessConfig?.enable_zoho_meeting && !data?.is_zoho_token_expired && (
                <div className='mt-4 patient-zoho-calendar'>
                  <ZohoCalendar showBookings={true} isPatientView={true} />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div onClick={() => setInputValue('')} className='patient-body wrapper'>
      {patientBody()}

      <Loader show={isLoading} />
      {/* {showFilters()} */}
    </div>
  );
}

export default ShowPatientProfile;
