import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DatePicker } from 'rsuite';
import { CoreCheckBox } from 'whealth-core-web/components';
import { setAlerts } from 'redux/Slices';
import { dateFormatStringSingleDate } from 'whealth-core-web/components/Helper';

const dayConst = ['Morning', 'Afternoon', 'Night'];

function PrescriptionActivityCreate(props) {
  const { data, setLoading, handleSubmit, message, hideTitle } = props;
  const { id } = useParams();
  const ApiClient = useApiManager();
  const [formVal, setFormVal] = useState({});
  const [arrvalues, setArrvalues] = useState([0, 0, 0]);
  const [error, setError] = useState({});
  const [datas, setDatas] = useState('');

  const dispatch = useDispatch();

  useEffect(() => {
    setFormVal({ ...formVal, prescription_medicine_id: data?.id });
    activityLogsOnchange(formVal.dateTime || new Date());
  }, [data]);

  const handleFormSubmit = (event) => {
    event.preventDefault();
    activityLogsCreate();
  };

  const activityLogsCreate = () => {
    setError({});
    setLoading && setLoading(true);
    ApiClient.activityLogsCreate(id, formVal)
      .then((res) => {
        handleSubmit && handleSubmit();
        setLoading && setLoading(false);
        setArrvalues([0, 0, 0]);
        dispatch(
          setAlerts({
            patient: {
              title: message ?? 'Prescription activity created successfully',
              type: 'alert-success',
            },
          })
        );
      })
      .catch((err) => {
        setLoading && setLoading(false);
        setError(err.response.data.errors);
      });
  };

  const activityLogsOnchange = (date) => {
    const timeformat = dateFormatStringSingleDate(date || formVal.dateTime);
    const obj = {
      record_date: timeformat,
      prescription_medicine_id: data.id,
    };
    setError({});
    if (data.id) {
      setLoading && setLoading(true);
      ApiClient.activityLogsOnchange(id, obj)
        .then((res) => {
          setLoading && setLoading(false);
          setArrvalues([0, 0, 0]);
          setDatas(res.data);
        })
        .catch((err) => {
          setLoading && setLoading(false);
          setError(err.response.data.errors);
        });
    }
  };

  let newData = datas ?? data;
  let arrActivity = newData.value?.split('').map((item) => parseInt(item)) || [];
  let arrData = data.dosage?.split('').map((item) => parseInt(item)) || [];
  const handleCheckBoxClick = (val, isChecked, index, arrvalues) => {
    let values = arrvalues;
    if (val && isChecked) {
      values[val] = 1;
    } else if (val && !isChecked) {
      values[val] = 0;
    }
    setArrvalues(values);
    formVal.value = values.join('');
    setFormVal({ ...formVal });
  };

  const handleChecked = (index) => {
    if (arrActivity.length && arrActivity[index]) {
      arrvalues[index] = 1;
      return true;
    } else if (arrvalues[index]) {
      return true;
    }
    return false;
  };
  return (
    <div>
      <form onSubmit={handleFormSubmit} className='p-2 py-4'>
        {!hideTitle && <div className='mb-3 capitalize semiBold h5'>{data?.medicine?.medicine_title_repr}</div>}
        <div className='mb-4'>
          <DatePicker
            disabledDate={(date) => {
              if (date >= new Date()) {
                return true;
              } else {
                return false;
              }
            }}
            format='yyyy-MM-dd HH:mm:ss'
            placeholder='YYYY-MM-DD HH:MM:SS'
            calendarDefaultDate={new Date()}
            defaultValue={formVal.dateTime || Date.now()}
            onChange={(text) => {
              formVal.dateTime = text;
              formVal.record_date = new Date(text).toISOString();
              setFormVal({ ...formVal });
              activityLogsOnchange();
            }}
            ranges={[
              {
                label: 'Now',
                value: new Date(),
              },
            ]}
            style={{ width: '100%' }}
          />
        </div>
        <div className='mb-4'>
          <div className='my-4'>
            <div className='mb-1' style={{ color: 'var(--lightBlack)' }}>
              Time
            </div>
            <div className='mt-1 d-flex flex-wrap'>
              {arrData.map((checkBox, index) => {
                if (!checkBox) {
                  return;
                }
                return (
                  <CoreCheckBox
                    onChange={(val, checked) => handleCheckBoxClick(val, checked, index, arrvalues)}
                    value={index}
                    className='me-4'
                    title={dayConst[index]}
                    id={`patient-activity${index + 1}`}
                    htmlFor={`patient-activity${index + 1}`}
                    disabled={arrActivity[index]}
                    checked={handleChecked(index)}
                  />
                );
              })}
            </div>
            {error?.value && (
              <div className='inputMsg' style={{ color: 'red' }}>
                {error?.value}
              </div>
            )}
          </div>
        </div>
        <div>
          <button type='submit' className='form-control coreBtn text-white'>
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default PrescriptionActivityCreate;
