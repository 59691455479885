import React from 'react';
import { editTheme, search, trashRed } from 'res/images';
import CommonToolTip from '../CommonToolTip';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, teamRoles, timeFormat, titlizeString } from '../Helper';

function CarePlanList(props) {
  const { updateDataModalOpen, tableData, deleteData, searchInput, setSearchInput } = props;

  return (
    <>
      <div className='d-flex mt-4 justify-content-end'>
        <CoreInputBox
          placeholder='All Care Plan Search'
          value={searchInput}
          setValue={(text) => {
            setSearchInput(text);
          }}
          leftIcon={search}
        />
      </div>
      <div className={'tagsView'}>
        <div className='table-responsive'>
          <table className='w-100 table'>
            <tbody>
              <div className='d-flex justify-content-center'>{tableData?.length ? '' : 'No data found'}</div>

              {tableData?.map((item) => (
                <tr>
                  <td className='w-100'>
                    <div className=''>
                      <span class=' capitalize lightGrayBadge'>{item.title}</span>
                      <small
                        className={`badge capitalize ms-2 ${item.status == 'active' ? 'carePlanActive' : 'logging'}`}
                      >
                        {titlizeString(item.status)}
                      </small>
                    </div>
                  </td>
                  <td>
                    <div className='listNotsright'>
                      <div className='metaDetails mb-4 me-3'>
                        <div>
                          Created by · {item?.created_by?.full_name} ({teamRoles(item?.created_by?.role)})
                          <div>
                            Created at · {dateFormat(item.created_at)} . {timeFormat(item.created_at)}
                          </div>
                        </div>
                      </div>
                      <div className='d-flex tagsViewIcons  mb-4'>
                        <div
                          onClick={() => {
                            updateDataModalOpen(item);
                          }}
                        >
                          <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                        </div>
                        <div
                          onClick={() => {
                            const windowConfirm = window.confirm('Are you sure you want to delete ?');
                            if (windowConfirm) {
                              deleteData(item.id);
                            }
                          }}
                        >
                          <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default CarePlanList;
