import React, { useEffect, useRef, useState } from 'react';
import { CloseRound, searchTheme } from 'res/images';
import { CoreInputBox } from './index';
import { clickOnElmOnBodyClick, stoppropagation, titlizeString } from './Helper';
import roles from '../../role';

function CoreSearchDropDown(props) {
  const {
    placeholder,
    hideClose,
    setInputValue,
    inputValue,
    leftIcon,
    rightIcon,
    aria_expanded,
    isRightIconref,
    data,
    inputStyle,
    setShowData,
    setActiveData,
    showData,
    activeData,
    setData,
    onClickItem,
    dropDownStyle,
    showMSG,
    setTimeOutData,
    maxLength,
    searchKey,
    label,
    isAutoSave,
    retuired,
    handleOnFocus,
  } = props;

  let timeOut;

  const dropDownBoxDiv = useRef();
  const rightIconref = useRef();

  const [isApiCall, setIsApiCall] = useState(false);

  useEffect(() => {
    if (isRightIconref) {
      const rightIconrefCurrent = rightIconref.current;
      if (rightIconrefCurrent) {
        rightIconrefCurrent.style = 'cursor:pointer;';
        rightIconrefCurrent.addEventListener('click', () => {
          setInputValue('');
          setData([]);
        });
      }
    }
  }, [document]);

  useEffect(() => {
    if (dropDownBoxDiv) {
      stoppropagation(dropDownBoxDiv.current, 'ref');
    }
    !isAutoSave && clickOnElmOnBodyClick('rightIcon');
  }, [document]);

  useEffect(() => {
    if (setTimeOutData && isApiCall) {
      timeOut = setTimeout(() => {
        if (inputValue?.trim()?.length > 1) {
          setTimeOutData();
        }
      }, 300);
    }
    setIsApiCall(true);
  }, [inputValue]);

  const handleItemClick = (item) => {
    setData([]);
    let setDataIds = new Set([...activeData, item.id]);
    let hasData = showData.find((itemData) => item.id == itemData.id);

    if (!hasData) {
      setShowData([...showData, item]);
    }
    setActiveData([...setDataIds]);
  };

  const dataMap = () => {
    return (
      data &&
      data.map((item, index) => {
        return (
          <div
            key={index}
            onClick={(e) => {
              e.stopPropagation();
              if (onClickItem) {
                onClickItem(item);
              } else {
                handleItemClick(item);
              }
              setIsApiCall(false);
            }}
            className="dropDwonItems d-flex justify-content-between capitalize"
          >
            <div className="dropDwonItems">{item[searchKey] || item.full_name || item.medicine_category}</div>
            {item?.role && (
              <div className="capitalize bold">{roles[item.role] ? roles[item.role] : titlizeString(item.role)}</div>
            )}
          </div>
        );
      })
    );
  };

  const returnSearchDropDown = () => {
    return (
      <div className="dropDownItemCustom">
        <div ref={dropDownBoxDiv}>
          <CoreInputBox
            inputStyle={inputStyle}
            placeholder={placeholder}
            setValue={(data) => {
              timeOut && clearTimeout(timeOut);
              setInputValue(data);
            }}
            value={inputValue}
            leftIcon={leftIcon || searchTheme}
            rightIcon={rightIcon || (!hideClose && CloseRound)}
            aria_expanded={aria_expanded}
            rightIconref={rightIconref}
            showMSG={showMSG}
            maxLength={maxLength}
            label={label}
            retuired={retuired}
            handleOnFocus={handleOnFocus}
          />
        </div>
        {inputValue?.length > 2 && (
          <div style={dropDownStyle} className="dropDownDiv">
            {dataMap()}
          </div>
        )}
      </div>
    );
  };

  return returnSearchDropDown();
}

CoreSearchDropDown.defaultProps = {
  handleOnFocus: () => {},
};

export default CoreSearchDropDown;
