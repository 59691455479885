import React, { useEffect, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import {
  CommonAlert,
  CoreButton,
  CoreInputBox,
  CoreTextArea,
  Select2DropDown,
  TagSelectPicker,
} from 'whealth-core-web/components';
import { useParams } from 'react-router-dom';
import { checkunique, splitArrData, splitArrObjData, valiDateData } from 'whealth-core-web/components/Helper';
import { Modal } from 'rsuite';
import AddAttatchGoalForm from './AddAttatchGoalForm';

function AddQuestionForm(props) {
  const ApiClient = useApiManager();
  const { id, pid, attatchGoalId, goalid } = useParams();
  const [valueObj, setValueObj] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [open, setOpen] = useState(false);
  const [error, seterror] = useState({});
  const [attatchError, setAttatchError] = useState({});

  const [attatchData, setAttatchData] = useState({});
  const [behaviorGoal, setbehaviorGoal] = useState('');
  const [previousQuestions, setPreviousQuestions] = useState([]);
  const [activeBehaviorQuestion, setActiveBehaviorQuestion] = useState(null);
  const [activeBehaviorQuestionaireAnswer, setActiveBehaviorQuestionaireAnswer] = useState(null);

  const [isShowAlert, setIsShowAlert] = useState('');
  const {
    onClick,
    setErrors,
    errors,
    data,
    setIsLoading,
    isEditable,
    onAddQuestion,
    onRemoveQuestion,
    questionCategoryID,
  } = props;

  const initialValues = [
    {
      behaviour_question: '',
    },
    {
      behaviour_question: '',
    },
    {
      behaviour_question: '',
    },
    {
      behaviour_question: '',
    },
    {
      behaviour_question: '',
    },
  ];
  const [attatchGoalShowBtn, setAttatchGoalShowBtn] = useState({
    behaviour_questionnaire_answers: [...initialValues],
  });
  const [attatchGoal, setAttatchGoal] = useState({
    behaviour_questionnaire_answers: [...initialValues],
  });

  const [prescriptionData, setPrescriptionData] = useState({
    behaviour_questionnaire_answers: [...initialValues],
  });

  const [behaviourQuestion, setBehaviourQuestion] = useState([]);
  const [behaviourQuestionPrevious, setBehaviourQuestionPrevious] = useState([]);

  const getPatientGoals = (attatchDatas) => {
    setIsLoading(true);
    ApiClient.getAttatchgoal(attatchDatas)
      .then((res) => {
        setAttatchData(res.data);
        // setAttatchGoal();
        // setPrescriptionData({ ...prescriptionData });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (data) {
      setPrescriptionData({ ...data });
    }
  }, [data]);

  let checkuniqueData = (data, newData) => {
    let tmpData = [];
    data.map((item) => {
      if (item) {
        let newData1 = newData.find((item1) => item1.id == item.id);
        if (!newData1) {
          tmpData.push(item);
        }
      }
    });

    return [...tmpData, ...newData];
  };

  useEffect(() => {
    if (prescriptionData.behaviour_questionnaire_answers?.length && behaviourQuestion?.length) {
      if (Object.keys(prescriptionData.behaviour_questionnaire_answers[0]).length > 1) {
        setQuestionData();
      }
    }
  }, [prescriptionData.behaviour_questionnaire_answers?.length, behaviourQuestion?.length]);

  useEffect(() => {
    if (behaviourQuestionPrevious.length) {
      setBehaviourQuestion(checkuniqueData(behaviourQuestionPrevious, behaviourQuestion));
    }
  }, [behaviourQuestionPrevious]);

  // useEffect(() => {
  //   if (!pid) {
  //     getBehaviourQuestionsPrevious();
  //   }
  // }, []);

  useEffect(() => {
    getBehaviourQuestions();
  }, [questionCategoryID]);

  const setQuestionData = () => {
    let newArr = [];
    prescriptionData?.behaviour_questionnaire_answers.map((item) => {
      newArr.push(item.behaviour_question);
    });
    let newQuestions = checkuniqueData(newArr, behaviourQuestion);
    setBehaviourQuestion(newQuestions);
  };

  const setInitialValues = (data) => {
    setPreviousQuestions(data);
    data.map((item, index) => {
      let item1 = prescriptionData.behaviour_questionnaire_answers[index];
      if (item1) {
        item1.behaviour_question = item.id;
      } else {
        prescriptionData.behaviour_questionnaire_answers[index] = { behaviour_question: item.id };
      }
    });
  };

  const getBehaviourQuestionsPrevious = () => {
    setIsLoading(true);
    ApiClient.questionDataPrevious(id)
      .then((res) => {
        setIsLoading(false);
        setInitialValues(res.data);
        setBehaviourQuestionPrevious(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getBehaviourQuestions = () => {
    setIsLoading(true);
    ApiClient.getBehaviourQuestions({ user_id: id, category_id: questionCategoryID })
      .then((res) => {
        setIsLoading(false);
        setBehaviourQuestion(res?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const createGoals = () => {
    if (!valueObj?.goal_id && attatchData?.id) {
      valueObj.goal_id = attatchData?.id;
    }
    valueObj.user_id = id;
    valueObj.behaviour_question_id = activeBehaviorQuestion?.id;
    valueObj.behaviour_questionnaire_answer_id = activeBehaviorQuestionaireAnswer?.id;
    seterror('');
    setIsLoading(true);
    ApiClient.createPatientGoal(id, valueObj)
      .then((res) => {
        prescriptionData.behaviour_questionnaire_answers[open - 1].patient_goal_id = res?.data?.id;
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Goal Attached successfully');
        setOpen(false);
        setPrescriptionData({ ...prescriptionData });
        setAttatchGoal(res?.data);
        setErrors('');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setAttatchError(err.response.data.errors);
        setIsShowAlert(err.response.data.detail || err.response.data.errors.non_field_errors);
      });
  };

  const deleteGoal = (del, behavior_question_id, showAlert = true) => {
    const params = {
      behaviour_question_id: activeBehaviorQuestion?.id || behavior_question_id?.behaviour_question?.id,
      behaviour_questionnaire_answer_id: activeBehaviorQuestionaireAnswer?.id || behavior_question_id?.id,
    };
    ApiClient.deleteAttatchGoal(id, del, params)
      .then((res) => {
        setIsLoading(false);
        if (showAlert) {
          setAlertType('alert-success');
          setIsShowAlert('Goal Removed Successfully');
        }
        delete prescriptionData.behaviour_questionnaire_answers[open].patient_goal_id;
        setBehaviourQuestion(res?.data);
        setIsShowAlert(res.data.message);
      })
      .catch((err) => {
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          if (!Array.isArray(Object.values(err.response.data)[0])) {
            setIsShowAlert(Object.values(err.response.data)[0]);
          }
        }
        setIsLoading(false);
        setAlertType('alert-danger');
      });
  };

  const removeForm = (index) => {
    let dataObj = prescriptionData;
    dataObj.behaviour_questionnaire_answers?.splice(index, 1);
    errors?.behaviour_questionnaire_answers?.splice(index, 1);
    setErrors({ ...errors });
    setPrescriptionData((prevState) => {
      return { ...prevState, ...dataObj };
    });
  };
  const handleArrChange = (data, key, index, reset) => {
    prescriptionData.behaviour_questionnaire_answers[index][key] = data;
    if (reset) {
      prescriptionData.behaviour_questionnaire_answers[index][reset] = '';
    }
    setPrescriptionData({ ...prescriptionData });
  };

  const handleMultiSelect = (value, key, index) => {
    if (value) {
      value = value.join(' | ');
      handleArrChange(value, key, index);
    }
  };

  const renderTypeFields = (data, error, index, item) => {
    let type = data?.type;
    if (item?.behaviour_question?.type) {
      type = item.behaviour_question.type;
      data = item.behaviour_question;
    }

    const allTypes = {
      string: renderInputBox,
      text: renderTextBox,
      int: renderInputBox,
      date: renderInputBox,
      float: renderInputBox,
      multi_select: renderMultiSelect,
      single_select: renderSingleSelect,
    };

    if (item) {
      if (allTypes[type]) {
        return allTypes[type](error, index, item, type, data);
      } else {
        return renderInputBox(error, index, item, type, data);
      }
    }
  };

  const renderTypeFieldsRecommendation = (data, error, index, item) => {
    let type = data?.type;
    if (item?.behaviour_question?.type) {
      type = item.behaviour_question.type;
      data = item.behaviour_question;
    }

    const allTypes = {
      string: renderInputBoxRecommendation,
      text: renderTextBoxRecommendation,
      int: renderInputBoxRecommendation,
      date: renderInputBoxRecommendation,
      float: renderInputBoxRecommendation,
      multi_select: renderMultiSelectRecommendation,
      single_select: renderSingleSelectrecommendation,
    };

    if (item) {
      if (allTypes[type]) {
        return allTypes[type](error, index, item, type, data);
      } else {
        return renderInputBoxRecommendation(error, index, item, type, data);
      }
    }
  };

  const renderTextBoxRecommendation = (error, index, item, str, data) => {
    return (
      <div>
        <CoreTextArea
          type={str}
          validateNumbers={str == 'integer'}
          validatedecimal={str == 'float'}
          placeholder='Recommendation'
          value={item.recommendation}
          setValue={(data) => {
            handleArrChange(data, 'recommendation', index);
          }}
          rows={2}
        />
      </div>
    );
  };

  const renderInputBoxRecommendation = (error, index, item, str, data) => {
    return (
      <div>
        <CoreInputBox
          type={str}
          validateNumbers={str == 'int'}
          validatedecimal={str == 'float'}
          placeholder='Recommendation'
          value={item.recommendation || ''}
          setValue={(data) => {
            handleArrChange(data, 'recommendation', index);
          }}
        />
      </div>
    );
  };
  const renderSingleSelectrecommendation = (error, index, newItem, _, data) => {
    let arrData = splitArrObjData(data?.options, '|', 'title');

    return (
      <div>
        <Select2DropDown
          retuired
          showMSG={newItem?.behaviour_question.length == 0 && error?.behaviour_question}
          data={arrData}
          labelKey='title'
          valueKey='value'
          placeholder='Recommendation'
          value={newItem.recommendation?.toLowerCase()}
          onSelect={(id, dataObj) => {
            handleArrChange(dataObj.title, 'recommendation', index);
          }}
        />
      </div>
    );
  };

  const renderMultiSelectRecommendation = (error, index, newItem, _, data) => {
    let arrData = splitArrObjData(data?.options, '|', 'title');
    let answerData = splitArrData(newItem.recommendation?.toLowerCase(), '|');

    return (
      <div>
        <TagSelectPicker
          showMSG={errors?.length && errors[index]?.recommendation}
          data={arrData}
          style={{ width: '100%' }}
          labelKey='title'
          valueKey='value'
          placeholder='Recommendation'
          defaultValue={answerData}
          onSelect={(val, titleArr) => {
            handleMultiSelect(titleArr, 'recommendation', index);
          }}
          msgStyle={{ color: 'red' }}
          value={answerData}
        />
      </div>
    );
  };

  const renderTextBox = (error, index, item, str, data) => {
    return (
      <div>
        <CoreTextArea
          type={str}
          validateNumbers={str == 'integer'}
          validatedecimal={str == 'float'}
          placeholder='Answer'
          value={item.answer}
          setValue={(data) => {
            handleArrChange(data, 'answer', index);
          }}
          rows={2}
        />
      </div>
    );
  };

  const renderInputBox = (error, index, item, str, data) => {
    return (
      <div>
        <CoreInputBox
          type={str}
          validateNumbers={str == 'int'}
          validatedecimal={str == 'float'}
          placeholder='Answer'
          value={item.answer || ''}
          setValue={(data) => {
            handleArrChange(data, 'answer', index);
          }}
        />
      </div>
    );
  };
  const renderSingleSelect = (error, index, newItem, _, data) => {
    let arrData = splitArrObjData(data?.options, '|', 'title');

    return (
      <div>
        <Select2DropDown
          retuired
          showMSG={newItem?.behaviour_question.length == 0 && error?.behaviour_question}
          data={arrData}
          labelKey='title'
          valueKey='value'
          placeholder='Answer'
          value={newItem.answer?.toLowerCase()}
          onSelect={(id, objData) => {
            handleArrChange(objData.title, 'answer', index);
          }}
        />
      </div>
    );
  };

  const renderMultiSelect = (error, index, newItem, _, data) => {
    let arrData = splitArrObjData(data?.options, '|', 'title');
    let answerData = splitArrData(newItem.answer?.toLowerCase(), '|');

    return (
      <div>
        <TagSelectPicker
          showMSG={errors?.length && errors[index]?.answer}
          data={arrData}
          style={{ width: '100%' }}
          labelKey='title'
          valueKey='value'
          placeholder='Answer'
          defaultValue={answerData}
          onSelect={(val, titleArr) => {
            handleMultiSelect(titleArr, 'answer', index);
          }}
          msgStyle={{ color: 'red' }}
          value={answerData}
        />
      </div>
    );
  };

  const searchBehaviourQuestion = (query, id) => {
    if (!prescriptionData.behaviour_question) {
      prescriptionData.behaviour_question = [];
    }
    ApiClient.searchBEHAVIOURQUESTION(query).then((res) => {
      prescriptionData.behaviour_question[id] = res.data;
      setPrescriptionData({ ...prescriptionData });
    });
  };

  const handleModalClose = () => {
    setOpen(false);
    setErrors('');
  };

  const updateModal = (behaviorQuestioning) => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          <AddAttatchGoalForm
            setAttatchError={setAttatchError}
            errors={attatchError}
            isShowAlert={isShowAlert}
            setIsShowAlert={setIsShowAlert}
            alertType={alertType}
            open={open}
            behaviorGoal={behaviorGoal}
            attatchData={attatchData}
            valueObj={valueObj}
            data={prescriptionData}
            setValueObj={setValueObj}
            attatchGoalId={attatchGoalId}
            pid={pid}
            behaviourQuestionPrevious={behaviourQuestionPrevious}
            behaviourQuestion={behaviourQuestion}
            attatchGoalShowBtn={attatchGoalShowBtn}
            onClick={createGoals}
          />
        </Modal>
      </div>
    );
  };

  const renderNestingFields = () => {
    return prescriptionData.behaviour_questionnaire_answers?.map((item, index) => {
      let UpdateRegimeGoalId = prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id;
      let UpdateRegimeGoalId2 = prescriptionData.behaviour_questionnaire_answers[index].patient_goal_id;
      let behavior_question_id = prescriptionData.behaviour_questionnaire_answers[index];

      let UpdateDataId = behaviourQuestion[index];
      let newItem = { ...item };

      let newSearchData = behaviourQuestion;

      if (previousQuestions[index]) {
        newSearchData = checkunique([previousQuestions[index]], newSearchData);
      }

      if (prescriptionData?.behaviour_question && prescriptionData?.behaviour_question[index]?.length) {
        newSearchData = checkunique(behaviourQuestion, prescriptionData.behaviour_question[index]);
      }

      if (typeof newItem.behaviour_question == 'object') {
        newItem.behaviour_question = item.behaviour_question.id;
      }
      newSearchData = valiDateData(newSearchData);

      const behaviorQuestioning = newSearchData?.find((question) => question.id == newItem.behaviour_question);
      const error = errors?.behaviour_questionnaire_answers?.find((i) => i.behaviour_question);
      return (
        <div className='behaviourpart-bg w-100' key={'regime-' + index}>
          <div key={index} className='d-flex justify-content-between daypart_box  gap-10'>
            <div className='daypart-input behaviourpart_box'>
              <Select2DropDown
                onSearch={(query) => searchBehaviourQuestion(query, index)}
                retuired
                showMSG={
                  errors?.behaviour_questionnaire_answers &&
                  errors.behaviour_questionnaire_answers[index]?.behaviour_question
                }
                data={
                  questionCategoryID == 'misc'
                    ? newSearchData.filter((item5) => !item5?.category?.id)
                    : newSearchData.filter((item5) => item5?.category?.id == questionCategoryID)
                }
                readOnly={!isEditable}
                UpdateDataId={UpdateDataId}
                labelKey='question'
                valueKey='id'
                key={'regime-question-' + index}
                placeholder='Question'
                value={newItem?.behaviour_question}
                onSelect={(id) => {
                  const windowConfirm =
                    (UpdateRegimeGoalId || UpdateRegimeGoalId2) &&
                    window.confirm('Are you sure you want to change this question ?');
                  if (windowConfirm && (UpdateRegimeGoalId || UpdateRegimeGoalId2)) {
                    handleArrChange(id, 'behaviour_question', index, 'answer');
                    deleteGoal(
                      prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                        ? prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                        : prescriptionData.behaviour_questionnaire_answers[index].patient_goal_id,
                      null,
                      false
                    );
                    prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                      ? delete prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                      : delete prescriptionData.behaviour_questionnaire_answers[index].patient_goal_id;
                  } else {
                    handleArrChange(id, 'behaviour_question', index, 'answer');
                  }
                }}
              />
            </div>
            <div className='daypart-input behaviourpart_box '>
              {renderTypeFields(behaviorQuestioning, error, index, newItem)}
            </div>
            <div className='daypart-input behaviourpart_box'>
              {renderTypeFieldsRecommendation(behaviorQuestioning, error, index, newItem)}
            </div>
            <div className='w-10 d-flex justify-content-end align-items-end add-question-btn'>
              <div className='d-flex day_part-btn'>
                {isEditable && prescriptionData.behaviour_questionnaire_answers.length > 1 && (
                  <>
                    <span
                      onClick={() => {
                        onRemoveQuestion(index);
                      }}
                      class='material-icons pointer'
                    >
                      close
                    </span>
                  </>
                )}

                {behaviorQuestioning?.goal && !pid && (
                  <div className='d-flex addButtonTab justify-content-end'>
                    {!prescriptionData?.behaviour_questionnaire_answers[index]?.patient_goal_id && (
                      <div className='ms-2'>
                        <CoreButton
                          onClick={() => {
                            setbehaviorGoal(behaviorQuestioning?.goal?.title);
                            getPatientGoals(behaviorQuestioning?.goal?.id);
                            setOpen(index + 1);
                            setActiveBehaviorQuestion(behaviorQuestioning);
                            setActiveBehaviorQuestionaireAnswer(newItem);
                          }}
                          title={'Attach goal'}
                        />
                      </div>
                    )}
                    {prescriptionData?.behaviour_questionnaire_answers[index]?.patient_goal_id && (
                      <div className='ms-2'>
                        <CoreButton
                          onClick={() => {
                            deleteGoal(
                              prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                                ? prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                                : prescriptionData.behaviour_questionnaire_answers[index].patient_goal_id
                            );
                            prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                              ? delete prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                              : delete prescriptionData.behaviour_questionnaire_answers[index].patient_goal_id;
                          }}
                          title={'Remove Goal'}
                        />
                      </div>
                    )}
                  </div>
                )}
                {behaviorQuestioning?.goal && pid && (
                  <div className='d-flex addButtonTab justify-content-end'>
                    {(UpdateRegimeGoalId ? !UpdateRegimeGoalId : !UpdateRegimeGoalId2) && (
                      <div className='ms-2'>
                        <CoreButton
                          onClick={() => {
                            setbehaviorGoal(behaviorQuestioning?.goal?.title);
                            getPatientGoals(behaviorQuestioning?.goal?.id);
                            setOpen(index + 1);
                            setActiveBehaviorQuestion(behaviorQuestioning);
                            setActiveBehaviorQuestionaireAnswer(newItem);
                          }}
                          title={'Attach goal'}
                        />
                      </div>
                    )}
                    {(UpdateRegimeGoalId ? UpdateRegimeGoalId : UpdateRegimeGoalId2) && (
                      <div className='ms-2'>
                        <CoreButton
                          onClick={() => {
                            deleteGoal(
                              prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                                ? prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                                : prescriptionData.behaviour_questionnaire_answers[index].patient_goal_id,
                              behavior_question_id
                            );
                            prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                              ? delete prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                              : delete prescriptionData.behaviour_questionnaire_answers[index].patient_goal_id;
                          }}
                          title={'Remove Goal'}
                        />
                      </div>
                    )}
                  </div>
                )}
                {isEditable && prescriptionData.behaviour_questionnaire_answers.length - 1 == index && (
                  <div className='ms-2'>
                    {prescriptionData.behaviour_questionnaire_answers.length - 1 == index && (
                      <CoreButton
                        onClick={() => {
                          // if (!prescriptionData.behaviour_questionnaire_answers) {
                          //   prescriptionData.behaviour_questionnaire_answers = [];
                          // }
                          // if (!errors.behaviour_questionnaire_answers) {
                          //   errors.behaviour_questionnaire_answers = [];
                          // }

                          // prescriptionData.behaviour_questionnaire_answers.push({
                          //   behaviour_question: '',
                          // });
                          // setPrescriptionData({ ...prescriptionData });
                          // errors.behaviour_questionnaire_answers.push({});
                          onAddQuestion();
                        }}
                        title='+ Add'
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='gap-10 d-flex pb-2 ms-2'>
            <div className='d-flex'>
              {behaviorQuestioning?.goal?.id &&
                prescriptionData?.behaviour_questionnaire_answers[index]?.patient_goal_id &&
                !pid && (
                  <div className=''>
                    <strong>Attached goal: {behaviorQuestioning?.goal?.title}</strong>
                  </div>
                )}
            </div>
            <div className='d-flex'>
              {(behaviorQuestioning?.goal?.id &&
              prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                ? prescriptionData.behaviour_questionnaire_answers[index].patient_goal?.id
                : prescriptionData.behaviour_questionnaire_answers[index].patient_goal_id && pid) && (
                <div className=''>
                  <strong>Attached goal: {behaviorQuestioning?.goal?.title}</strong>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    });
  };

  const renderQuestionForm = () => {
    return (
      <div className=''>
        {isShowAlert && !open && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        <div className='pt-2 pb-2'>
          {/* <h6 className='  pt-2 pb-2'>Add Questions</h6> */}
          {renderNestingFields()}
          {/* <CoreButton
            onClick={() => {
              setErrors({ prescription_medicines: [] });
              onClick(prescriptionData);
            }}
            customClass='btn btn-secondary btn-lg'
            title='Save Details'
          /> */}
        </div>
        {updateModal()}
      </div>
    );
  };

  return <div>{renderQuestionForm()}</div>;
}

export default AddQuestionForm;
