import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect } from 'react';
import { useState } from 'react';
import { Modal, Tree } from 'rsuite';
import { search } from 'res/images';

import {
  CommonBorderBox,
  SettingsProfileForm,
  EmptyView,
  ProfileListViewAndEdit,
  Pagination,
  CoreInputBox,
} from './index';
function MobileConfiguration(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  const [profileQuestion, setProfileQuestion] = useState([]);
  const [createData, setCreateData] = useState({});
  const [updateData, setupdateData] = useState({});
  const [error, seterror] = useState('');
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchQuestion, setSearchQuestion] = useState('');

  useEffect(() => {
    if (searchQuestion?.length > 2) {
      searchProfileQuestions(searchQuestion, currentPage);
    } else {
      getProfileQuestions();
    }
  }, [currentPage]);

  const searchProfileQuestions = (searchData, pageNo) => {
    setSearchQuestion(searchData.trim());
    if (searchData?.length > 2) {
      ApiClient.searchProfileQuestionList({ search_str: searchData.trim(), page: pageNo || 1 })
        .then((res) => {
          setTotalPages(res.headers['total-pages']);
          setProfileQuestion(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      getProfileQuestions();
    }
  };

  const getProfileQuestions = () => {
    const pageQry = { page: currentPage };
    ApiClient.getProfileQuestions(pageQry)
      .then((res) => {
        setIsLoading(false);
        setProfileQuestion(res.data);
        setTotalPages(res.headers['total-pages']);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const renderForm = useCallback(
    (updateData, setupdateData) => {
      return (
        <CommonBorderBox className='settingFormOutline'>
          <div className='formTitle'>Add Questions</div>
          <SettingsProfileForm
            error={error}
            valueObj={updateData || createData}
            setValueObj={setupdateData || setCreateData}
            isUpdate={updateData}
            onClick={updateData ? updateQuestionData : createProfileQuestion}
          />
        </CommonBorderBox>
      );
    },
    [error, createData, updateData]
  );

  const profileQuestionDelete = (id) => {
    seterror({});
    setIsLoading(true);
    ApiClient.profileQuestionDelete(id)
      .then((res) => {
        setIsLoading(false);
        getProfileQuestions();
        console.log(res);
        setIsShowAlert(res.data.message);
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.message);
        }
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.message);
        console.log(err);
      });
  };

  const validateData = (createData) => {
    seterror('');
    let errors = {};
    const validation =
      createData?.question?.trim()?.length > 0 &&
      createData?.key?.trim()?.length > 0 &&
      createData?.type?.trim()?.length > 0;

    if (!createData?.question || createData?.question?.trim()?.length == 0) {
      errors = { ...errors, question: 'This field is required.' };
    }
    if (!createData?.key || createData?.key?.trim()?.length == 0) {
      errors = { ...errors, key: 'This field is required.' };
    }
    if (!createData?.type) {
      errors = { ...errors, type: 'This field is required.' };
    }

    if (!validation) {
      seterror(errors);
      return true;
    }
    return false;
  };

  const createProfileQuestion = () => {
    if (validateData(createData)) {
      return;
    }
    setIsLoading(true);
    ApiClient.createProfileQuestion(createData)
      .then((res) => {
        setCreateData({});
        setIsLoading(false);
        setCurrentPage(1);
        getProfileQuestions();
        setIsShowAlert('Profile Question Created successfully');
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        seterror(err.response.data.errors);

        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.detail);
        }
      });
  };

  const updateQuestionData = () => {
    if (validateData(updateData)) {
      return;
    }
    setIsLoading(true);
    ApiClient.updateQuestionData(updateData, updateData.id)
      .then((res) => {
        setCreateData({});
        setupdateData({});
        getProfileQuestions();
        setIsShowAlert('Profile Question updated successfully');
        setAlertType('alert-success');
        console.log(res);
        setIsLoading(false);
        handleModalClose();
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.data?.detail) {
          setIsShowAlert(err.response.data.detail);
          setOpen(false);
        } else {
          seterror(err.response.data.errors);
        }
        setAlertType('alert-danger');
      });
  };

  const updateQuestion = (data) => {
    setOpen(true);
    setupdateData(data);
    seterror('');
  };

  const handleModalClose = () => {
    setOpen(false);
    setCreateData({});
    seterror('');
  };

  const updateModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          {renderForm(updateData, setupdateData)}
        </Modal>
      </div>
    );
  };

  const patination = () => {
    return (
      <Pagination
        seterror={seterror}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };

  return (
    <div>
      {renderForm()}

      <div className='d-flex mt-4 justify-content-end'>
        <CoreInputBox placeholder='All Questions Search' setValue={searchProfileQuestions} leftIcon={search} />
      </div>
      {profileQuestion?.length > 0 ? (
        <ProfileListViewAndEdit
          setEditError={seterror}
          editError={error}
          data={profileQuestion}
          setUpdateData={updateQuestion}
          deleteData={profileQuestionDelete}
        />
      ) : (
        <EmptyView hide={isLoading} />
      )}
      <div className='d-flex justify-content-end mt-3'>{patination()}</div>
      {updateModal()}
    </div>
  );
}

export default MobileConfiguration;
