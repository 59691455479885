import React from 'react';

function StatusBar(props) {
  const { className, icon, status } = props;
  return (
    <div className={` statusBar d-flex align-items-center mt-1 ${className}`}>
      <span class='material-icons-outlined me-1'>{icon}</span> {status}
    </div>
  );
}

export default StatusBar;
