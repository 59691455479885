import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { editIconBlack } from 'res/images';
import { CommonAlert, Loader, EmptyView, Select2DropDown, CoreDropDown } from 'whealth-core-web/components';

import './appointment.css';

import { DatePicker, Dropdown } from 'rsuite';
import {
  convertTo12HourFormat,
  dateFormat,
  timeFormat,
  formatDateInISO,
  timeFormat_v2,
} from 'whealth-core-web/components/Helper';
import StatusCard from 'whealth-core-web/components/StatusCard';
import CommonToolTip from 'whealth-core-web/components/CommonToolTip';
import AddApointmentModal from './AddApointmentModal';
import EditAppointmentModal from './EditAppointmentModal';
import { EditRecordPaymentModal } from './EditRecordPayment';
import { setCurrentConsultationStatus, setCurrentPaymentStatus } from 'redux/Slices';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

function Appointment(props) {
  const ApiClient = useApiManager();
  let userData = useGetLoginUserDataQuery()?.data?.data || {};

  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [openAddApointmentModal, setOpenAddApointmentModal] = useState(false);
  const [openEditApointmentModal, setOpenEditApointmentModal] = useState(false);
  const [openEditRecordPaymentModal, setOpenEditRecordPaymentModal] = useState(false);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [patientsList, setPatientsList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('Status');
  const [allDoctor, setAllDoctor] = useState();
  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const currentDate = new Date();
  const [dateValue, setDateValue] = useState(currentDate);
  const [slotCount, setSlotCount] = useState([]);
  const [docSchedule, setdocSchedule] = useState([]);
  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const [editSchedule, setEditSchedule] = useState('');
  const [allDoctorData, setAllDoctorData] = useState([]);
  const [isDoctorListLoading, setIsDoctorListLoading] = useState(true);
  const location = useLocation();
  console.warn('location', location);
  useEffect(() => {
    if (openAddApointmentModal || openEditApointmentModal || openEditRecordPaymentModal) {
      const getPatientList = async () => {
        try {
          const res = await ApiClient.getPatients();
          if (res.status == 200) {
            setPatientsList(res.data);
          }
        } catch (e) {
          console.log(e);
        }
      };
      getPatientList();
    }
  }, [openAddApointmentModal, openEditApointmentModal, openEditRecordPaymentModal]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (location.state.key == 'dashboard') {
      setOpenAddApointmentModal(true);
    }
  }, []);

  const handleAddAppointmentOpen = (item) => {
    if (!isDoctorListLoading) {
      setOpenAddApointmentModal(true);
      setEditSchedule(item?.item);
    }
  };

  const handleAddAppointmentClose = () => {
    setOpenAddApointmentModal(false);
  };

  const handleEditRecordPaymentOpen = (item) => {
    setOpenEditRecordPaymentModal(true);
    setEditSchedule(item?.item);
    dispatch(setCurrentPaymentStatus(item?.item.payment_type));
  };

  const handleEditRecordPaymentClose = () => {
    setOpenEditRecordPaymentModal(false);
    dispatch(setCurrentPaymentStatus(''));
  };

  const refreshSlotsData = () => {
    setSelectedDoctorId(selectedDoctorId);
  };

  const handleEditAppointmentOpen = (item, status) => {
    setOpenEditApointmentModal(true);
    setEditSchedule(item?.item);
  };

  const handleEditAppointmentClose = () => {
    setOpenEditApointmentModal(false);
  };

  const handlePaymentClose = () => {
    setOpenPaymentModal(false);
  };

  const statusList = [
    {
      id: 'show',
      label: 'Show',
    },
    {
      id: 'no_show',
      label: 'No Show',
    },
  ];

  const handleStatusChange = async (props, value) => {
    setSelectedStatus(value);
    const res = await ApiClient.patientAttendenceStatus(props?.id, props?.user?.id, value?.target?.value);
    if (res.status === 200) {
      const updatedDocSchedule = docSchedule.map((item) =>
        item.id === props.id ? { ...item, selectedStatus: value } : item
      );

      setdocSchedule(updatedDocSchedule);
    }
  };

  useEffect(() => {
    getAllDoctorList().then(() => setIsDoctorListLoading(false));
  }, []);

  const getAllDoctorList = async () => {
    const res = await ApiClient.getAllDoctorList();
    setAllDoctorData(res);
    if (res.status == 200) {
      setAllDoctor(res.data);
      setSelectedDoctorId(res.data[0]?.doctor?.id);
    }
  };

  useEffect(() => {
    const SlotsCount = async () => {
      if (selectedDoctorId && dateValue) {
        const res = await ApiClient.getSlotsCount(new Date(dateValue).toISOString(), selectedDoctorId);
        if (res.status == 200) {
          setSlotCount(res.data);
        }
      }
    };
    SlotsCount();
  }, [dateValue, selectedDoctorId]);

  const AppointmentSchedule = async () => {
    if (selectedDoctorId && dateValue) {
      const res = await ApiClient.getAppointmentSchedule(new Date(dateValue).toISOString(), selectedDoctorId);
      if (res.status === 200) {
        setdocSchedule(res.data);
        setSelectedStatus(res.data?.patient_attendance_status);
      }
    }
  };

  useEffect(() => {
    AppointmentSchedule();
  }, [dateValue, selectedDoctorId]);

  const handleDoctorClick = (doctorId) => {
    setSelectedDoctorId(doctorId);
  };

  const shortenDay = (day) => {
    return day.substring(0, 3);
  };

  const DoctorItem = () => (
    <div className='doctor-list'>
      {allDoctor?.map((doc, index) => (
        <div
          key={index}
          className={`appointment-doctor-item ${selectedDoctorId === doc?.doctor?.id ? 'selected' : ''}`}
          onClick={() => handleDoctorClick(doc?.doctor?.id)}
        >
          <h4 className='text-capitalize'>{doc?.doctor?.full_name}</h4>
          {doc?.locations?.map((locationItem, locationIndex) => (
            <div key={locationIndex} className='doctorClinic'>
              <p style={{ fontWeight: 600, fontSize: '13px' }} className='text-capitalize'>
                {locationItem?.location?.name}
              </p>
              {locationItem?.weeks.map((weekItem, index) => {
                return (
                  <div className='d-flex flex-row' style={{ justifyContent: 'flex-start', flexWrap: 'wrap' }}>
                    <p style={{ fontSize: '11px' }}>{shortenDay(daysOfWeek[weekItem?.week_day])}</p>
                    {weekItem?.slots.map((slot, slotIndex) => {
                      return (
                        <>
                          <p style={{ fontSize: '10px' }}>{`(${convertTo12HourFormat(
                            slot?.start_time
                          )}-${convertTo12HourFormat(slot.end_time)})`}</p>
                        </>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  // const dateFormat = (initialDate) => {
  //   const etOptions = { timeZone: 'IST', hour12: true, hour: 'numeric', minute: 'numeric' };
  //   const etTimeString = new Intl.DateTimeFormat('en-US', etOptions).format(new Date(initialDate));
  //   return etTimeString;
  // };

  const AppointmentSlot = (props) => {
    const [status, setStatus] = useState();

    useEffect(() => {
      if (props?.item?.patient_attendance_status) {
        setStatus(props?.item?.patient_attendance_status);
      }
    }, [props?.item?.patient_attendance_status]);

    const handleStatusChange = async (data) => {
      const res = await ApiClient.patientAttendenceStatus(props?.item?.id, props?.item?.user?.id, data);
      if (res.status === 200) {
        const updatedDocSchedule = docSchedule.map((item) =>
          item.id === props.id ? { ...item, selectedStatus: data } : item
        );
        setStatus(data);
      }
    };
    const filterData = [
      { id: 'show', title: 'Show' },
      { id: 'no_show', title: 'No Show' },
    ];
    console.warn('ds', props.item);
    return (
      <div className='appointment-slot d-flex'>
        <div className='slot-time zoho-date text-start d-flex justify-content-center align-items-center flex-column'>
          <div>
            <div className='appointment-time-slot'>{timeFormat(props?.item?.consult_datetime) || new Date()}</div>
          </div>
          <div className='appointment-time-slot'>to</div>
          <div>
            <div className='appointment-time-slot'>{timeFormat(props?.item?.consult_end_datetime) || new Date()}</div>
          </div>
        </div>
        <div className='slot-info d-flex  justify-content-between w-100'>
          <>
            <div className='d-flex '>
              <div className='slot-name me-4 text-capitalize'>
                {props?.item.user.full_name} ({props?.item?.user?.gender ? props?.item?.user?.gender : ''},{' '}
                {props?.item?.user?.age} yrs)
                <p>{props?.item?.title}</p>
                <p className='slot-name'>
                  Created By :{' '}
                  {props.item.channel == 'web' && props.item.admin == null
                    ? 'Appointment Landing Page'
                    : props?.item?.admin?.full_name}{' '}
                  ({dateFormat(props?.item?.created_at)})
                </p>
                {props?.item?.last_updated_by?.full_name ? (
                  <p className='slot-name'>
                    Updated By :{props?.item?.last_updated_by?.full_name} ({dateFormat(props?.item?.updated_at)})
                  </p>
                ) : null}
              </div>
              <div className='text-capitalize'>
                <p className={`slot-badge slot-badge-${props?.item?.consultation_status}`}>
                  {props?.item?.consultation_status}
                </p>
              </div>
            </div>
            <div className='d-flex justify-column-between'>
              <div className='slot-actions d-flex flex-column'>
                <Dropdown
                  disabled={userData?.role == 'ee' && props?.item?.admin?.id != userData?.id}
                  className='appointmentDropdown pointer '
                  title={!status ? 'Select Status' : status === 'no_show' ? 'No Show' : 'Show      '}
                  // style={{
                  //   height: '40px',
                  //   fontSize: '28px',
                  //   fontWeight: 'bold',
                  //   width: '100%',
                  // }}
                >
                  {filterData?.map((item) => {
                    return (
                      <Dropdown.Item
                        style={{
                          minWidth: '100px',
                        }}
                        onClick={() => {
                          handleStatusChange(item.id);
                        }}
                      >
                        {item.title}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown>
                {/* <CoreDropDown
                  className='px-4'
                  placeholder='Select Status'
                  retuired={false}
                  data={[
                    { id: 'show', title: 'Show' },
                    { id: 'no_show', title: 'No Show    ' },
                  ]}
                  labelStyle={{ fontWeight: 'var(--lightFont)' }}
                  setValue={(data) => {
                    handleStatusChange(data);
                  }}
                  value={status || null}
                  msgStyle={{ color: 'red' }}
                /> */}
                <div className='payment-status'>
                  {status !== 'no_show' && (userData?.role != 'ee' || props?.item?.admin?.id == userData?.id) && (
                    <button
                      onClick={() => {
                        handleEditRecordPaymentOpen(props);
                        AppointmentSchedule();
                      }}
                      style={{
                        width: '120px',
                        border: status === 'show' ? '0.839px solid #656364' : '',
                        color: status === 'show' ? '#656364' : '',
                      }}
                    >
                      {props?.item?.payment_type === null || props?.item?.payment_type === ''
                        ? 'Record Payment'
                        : 'Payment Recorded'}
                      <span className='material-icons shareIcon'>open_in_new</span>
                    </button>
                  )}
                </div>
              </div>
              {status !== 'no_show' && (userData?.role != 'ee' || props?.item?.admin?.id == userData?.id) ? (
                <div style={{ marginLeft: '8px' }}>
                  <CommonToolTip
                    placement={'top'}
                    item={
                      <span
                        style={{ cursor: 'pointer', margin: 1, color: '#243b86' }}
                        onClick={() => {
                          AppointmentSchedule();
                          handleEditAppointmentOpen(props);
                          dispatch(setCurrentConsultationStatus(status ?? ''));
                        }}
                        className='material-icons icon'
                      >
                        edit
                      </span>
                      // <img
                      //   src={editIconBlack}
                      //   className='icon'
                      //   style={{ cursor: 'pointer' }}
                      //   onClick={() => {
                      //     AppointmentSchedule();
                      //     handleEditAppointmentOpen(props, status);
                      //     dispatch(setCurrentConsultationStatus(status ?? ''));
                      //   }}
                      // />
                    }
                    showValue={'Edit'}
                  />
                </div>
              ) : (
                <div style={{ width: '30px' }}></div>
              )}
            </div>
          </>
        </div>
      </div>
    );
  };

  const Appointment = () => {
    const [parentStatus, setParentStatus] = useState();
    return (
      <div className='appointment-part d-flex w-100 justify-content-between'>
        <div className='appointment-tab'>
          <h3
            style={{
              fontWeight: '400',
              fontSize: 16,
            }}
          >
            Doctors
          </h3>
          {DoctorItem()}
        </div>
        <div className='appointment-wrapper  w-100 '>
          <div className=' d-flex justify-content-between'>
            <h3
              style={{
                fontWeight: '400',
                fontSize: 18,
              }}
            >
              Schedule
            </h3>

            <AddApointmentModal
              isNivanCare={props?.isNivanCare ? true : false}
              open={openAddApointmentModal}
              handleClose={handleAddAppointmentClose}
              patientsList={patientsList}
              isedit={false}
              editSchedule={editSchedule?.item}
              openPaymentModal={openPaymentModal}
              closePaymentModal={handlePaymentClose}
              getAllDoctorList={() => getAllDoctorList()}
              allDoctorData={allDoctorData}
              setPatientsList={setPatientsList}
              parentStatus={parentStatus}
              AppointmentSchedule={AppointmentSchedule}
            />
            <EditAppointmentModal
              isNivanCare={props?.isNivanCare ? true : false}
              open={openEditApointmentModal}
              handleClose={handleEditAppointmentClose}
              isedit={true}
              patientsList={patientsList}
              editSchedule={editSchedule}
              openPaymentModal={openPaymentModal}
              closePaymentModal={handlePaymentClose}
              parentStatus={parentStatus}
              allDoctorData={allDoctorData}
              AppointmentSchedule={AppointmentSchedule}
            />
            <EditRecordPaymentModal
              isNivanCare={props?.isNivanCare ? true : false}
              open={openEditRecordPaymentModal}
              handleClose={EditRecordPaymentModal}
              patientsList={patientsList}
              editSchedule={editSchedule}
              openPaymentModal={openPaymentModal}
              closePaymentModal={handleEditRecordPaymentClose}
              refreshSlotsData={refreshSlotsData}
              allDoctorData={allDoctorData}
              AppointmentSchedule={AppointmentSchedule}
            />
            <button className='reusableBtnActive  reusableBtn' onClick={() => handleAddAppointmentOpen()}>
              + Add New Appointment
            </button>
          </div>
          <div className='appointment-schedule'>
            <div className='d-flex justify-content-between mb-2 align-items-center'>
              <div className='d-flex'>
                <div className='slot-tab'>{slotCount.available_slots_count} Open Slots</div>
                <div className='slot-tab'> {slotCount.booked_slots_count} Consults</div>
              </div>
              <div className='appointment-schedule-date d-flex align-items-center'>
                <DatePicker
                  format='dd/MM/yyyy'
                  value={dateValue || new Date()}
                  onChange={(newValue) => setDateValue(new Date(newValue))}
                  placeholder='Select date'
                  showMeridian
                  onClean={() => setDateValue(new Date())}
                />
              </div>
            </div>
            <div className='appointment-schedule-tab'>
              {docSchedule.map((item, index) => (
                <AppointmentSlot item={item} setParentStatus={setParentStatus} />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const showTeam = () => (
    <div className='teamHeader stickyHeader'>
      <div className='headerFiles'>
        <AdminPanelHeader iconTitle='event_available' title='Appointment' />
      </div>
      <div className='hrLine' />
      <div className='teamBody'>
        {isShowAlert && (
          <div className='mt-3'>
            <CommonAlert isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
      </div>
    </div>
  );

  const teamBody = () => {
    return (
      <>
        {showTeam()}
        <div className='px-4 wrapperContent'>
          {Appointment()}
          <div className='d-flex justify-content-end mb-4'></div>
        </div>
      </>
    );
  };

  return (
    <div className='wrapper'>
      {teamBody()}

      <Loader show={isLoading || isDoctorListLoading} />
    </div>
  );
}

export default Appointment;
