import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { upload } from 'res/images';
import { DatePicker } from 'rsuite';
import {
  BorderBtnWithBg,
  CommonBorderBox,
  CoreButton,
  CoreInputBox,
  Select2DropDown,
  TagsShow,
} from 'whealth-core-web/components';
import CommonMaterialUiDatePicker from 'whealth-core-web/components/CommonMaterialUiDatePicker';
import { dateFormatStringSingleDate } from 'whealth-core-web/components/Helper';

function AddPatientForm(props) {
  const { createPatient, errors, patientData, getPatientData } = props;
  const inputRef = useRef();
  const ApiClient = useApiManager();

  const [formData, setFormData] = useState({});
  const [image, setImage] = useState('');
  const [serverImage, setServerImage] = useState('');
  const locations = useSelector((state) => state.sliceReducer.locations);
  const [isNivanCare, setIsNivanCare] = useState(false);
  const [careManagersList, setCareManagersList] = useState([]);
  const [carePlanList, setPlanList] = useState([]);
  const [carePlanOnchangeId, setPlanOnchangeId] = useState('');
  const [isCarePlan, setIsCarePlan] = useState('');

  useEffect(() => {
    getPatientData();
    const getCareManagers = async () => {
      const res = await ApiClient.getCareManagers();
      let tempList = [];
      res.data.map((item, index) => {
        tempList.push({ ...item, title: item.full_name });
      });
      setCareManagersList(tempList);
    };

    const getCarePlans = async () => {
      const res = await ApiClient.getCarePlan();
      let tempList = [];
      res.data.map((item, index) => {
        tempList.push({ ...item, title: item.title });
      });
      setPlanList(tempList);
    };
    getCarePlans();
    getCareManagers();
  }, []);
  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    if (configData) {
      configData.map((item) => {
        if (item.key == 'enable_summary' && item.isActive) {
          setIsNivanCare(item.isActive);
        }
        if (item.key == 'care_plan') {
          setIsCarePlan(item.isActive);
        }
      });
    }
  }, [configData]);
  useEffect(() => {
    setServerImage(patientData?.profile_url);
    setFormData(patientData);
  }, [patientData]);

  const handleChange = (key, val) => {
    let newFormData = { ...formData, [key]: val };
    setFormData({ ...newFormData });
    if (key === 'care_plan_id') {
      let newFormData = { ...formData, [key]: val, c_plan_activation_date: dateFormatStringSingleDate(new Date()) };
      setFormData({ ...newFormData });
    }
  };

  const getCarePlanSearch = async (query) => {
    const res = await ApiClient.getCareSearch(query);
    setPlanList(res.data);
  };
  const genderData = [
    { title: 'Male', id: 'male' },
    { title: 'Female', id: 'female' },
    { title: 'Other', id: 'other' },
  ];

  const CarekitData = [
    { title: 'Yes', id: true },
    { title: 'No', id: false },
  ];
  const inputFieldsData = [
    {
      key: 'full_name',
      placeholder: 'Full Name',

      label: 'Name',
      viewType: 'text',
      maxLength: 120,
      message: errors?.full_name,
      retuired: true,
    },
    {
      key: 'phone',
      label: 'Mobile No.',
      placeholder: '10 Digit Mobile Number',
      viewType: 'text',
      retuired: true,
      maxLength: 10,
      validateNumbers: true,
      showCode: true,
      message: errors?.phone,
    },
    {
      key: 'email',
      placeholder: 'Email Address',
      label: 'Email Address',
      viewType: 'text',
      message: errors?.email,
    },
    {
      key: 'gender',
      placeholder: 'Select Gender',
      label: 'Select Gender',
      viewType: 'dropDown',
      data: genderData,
      // retuired: true,
      message: errors?.gender,
    },
    {
      key: 'address',
      label: 'Residence address',
      placeholder: 'Residence Address',
      viewType: 'text',
      message: errors?.residence_address,
    },
    {
      key: 'city',
      placeholder: 'City',
      label: ' City',
      viewType: 'text',
      message: errors?.city,
    },

    {
      key: 'state',
      placeholder: 'State',
      label: 'state',
      viewType: 'text',
      message: errors?.state,
      maxLength: 50,
    },

    {
      key: 'pincode',
      placeholder: 'Pincode',
      label: 'pincode',
      viewType: 'text',
      message: errors?.pincode,
      maxLength: 6,
      validateNumbers: true,
    },
    {
      key: 'date_of_birth',
      placeholder: 'Date Of Birth',
      label: 'Date Of Birth',
      retuired: true,
      viewType: 'date',
    },

    isNivanCare && {
      key: 'locations',
      placeholder: 'Select Clinic',
      label: 'Clinic',
      viewType: 'tags',
      data: locations,
      multiple: true,
      message: errors?.locations,
    },
    isNivanCare && {
      key: 'care_manager_id',
      placeholder: 'Select Care Managers',
      label: 'Care Manager',
      viewType: 'careDropDown',
      data: careManagersList,
      multiple: true,
      message: errors?.care_manager_id,
    },
    isCarePlan && {
      key: 'care_plan_id',
      placeholder: 'Select Care Plan',
      label: 'Care Plan',
      viewType: 'dropDown',
      data: carePlanList,
      message: errors?.care_plan_id,
    },
    (formData?.care_plan_id || carePlanOnchangeId) &&
      isCarePlan && {
        key: 'c_plan_activation_date',
        placeholder: 'Care Plan Activation Date',
        label: ' Care Plan Activation Date',
        viewType: 'ActivationDate',
        userNotSelectData: 'pastDate',
      },
  ];

  const renderFormData = (showData) => {
    return showData.map((item, index) => {
      const showHTML = item.html;
      delete item.html;
      if (item.viewType == 'text') {
        return (
          <div key={index} className='articleInputDiv d-lg-flex mt-3 ' style={item.singleView && { width: '100%' }}>
            <div className='w-100 d-flex '>
              <div className='w-100 '>
                <CoreInputBox
                  {...item}
                  type={item.viewType}
                  labelStyle={{ fontWeight: 'var(--lightFont)' }}
                  setValue={(data) => handleChange(item.key, data)}
                  value={formData[item.key]}
                  showMSG={item.message}
                  msgStyle={{ color: 'red' }}
                />
              </div>
              {showHTML && showHTML()}
            </div>
          </div>
        );
      }
      if (item.viewType == 'date') {
        return (
          <div className='mt-4 articleInputDiv'>
            <div>
              <label class='inputlabel'>{item.label}</label>
            </div>
            {/* <DatePicker
              disabledDate={(date) => {
                if (new Date(date) > new Date()) {
                  return true;
                } else {
                  return false;
                }
              }}
              onClean={() => {
                let newFormData = { ...formData, ['date_of_birth']: null };
                setFormData({ ...newFormData });
              }}
              style={{ width: '100%' }}
              placement='bottomStart'
              format='yyyy-MM-dd'
              placeholder='YYYY-MM-DD'
              value={formData[item.key] ? new Date(formData[item.key]) : null}
              calendarDefaultDate={formData[item.key] ? new Date(formData[item.key]) : null}
              onSelect={(data) => handleChange(item.key, dateFormatStringSingleDate(data))}
            /> */}
            <CommonMaterialUiDatePicker
              value={formData[item.key] ? formData[item.key] : null}
              onChange={(data) => handleChange(item.key, dateFormatStringSingleDate(data))}
              placeholder={'YYYY-MM-DD'}
            />
          </div>
        );
      } else if (item.viewType == 'ActivationDate') {
        return (
          <div className='mt-3 articleInputDiv'>
            <div>
              <label class='inputlabel'>{item.label}</label>
            </div>
            <DatePicker
              disabledDate={(date) => {
                if (new Date(date) < new Date().setHours(0, 0, 0)) {
                  return true;
                } else {
                  return false;
                }
              }}
              onClean={() => {
                let newFormData = { ...formData, ['c_plan_activation_date']: dateFormatStringSingleDate(new Date()) };
                setFormData({ ...newFormData });
              }}
              style={{ width: '100%' }}
              placement='bottomStart'
              format='yyyy-MM-dd'
              placeholder='YYYY-MM-DD'
              value={formData[item.key] ? new Date(formData[item.key]) : new Date()}
              calendarDefaultDate={formData[item.key] ? new Date(formData[item.key]) : new Date()}
              onSelect={(data) => handleChange(item.key, dateFormatStringSingleDate(data))}
            />
          </div>
        );
      } else if (item.viewType == 'dropDown') {
        return (
          <div key={index} className=' articleInputDiv d-lg-flex mt-3' style={item.singleView && { width: '100%' }}>
            <div className='w-100'>
              <Select2DropDown
                isValidation
                onSearch={(data) => getCarePlanSearch(data)}
                searchable={item.key !== 'gender' && true}
                data={
                  !item.data.find((menu) => formData.care_plan?.id == menu.id) && formData.care_plan?.id
                    ? [...item.data, formData?.care_plan]
                    : item.data
                }
                retuired={item.retuired}
                placeholder={item.placeholder}
                label={item.label}
                onSelect={(data, dataObj) => {
                  handleChange(item.key, data, item);
                }}
                value={formData[item.key] || ''}
                showMSG={item.message}
                msgStyle={{ color: 'red' }}
                valueKey={item.setKey}
              />
            </div>
          </div>
        );
      } else if (item.viewType == 'tags') {
        return (
          <div key={index} className='articleInputDiv'>
            <TagsShow
              details={item.detail}
              tagsStyle={item.tagsStyle}
              data={item.data}
              keepSearchTerm={true}
              retuired={item.retuired}
              labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
              placeholder={item.placeholder}
              label={item.label}
              setValue={(data) => handleChange(item.key, data)}
              value={formData[item.key]}
              showMSG={item.message}
              msgStyle={{ color: 'red' }}
            />
          </div>
        );
      }
    });
  };

  return (
    <div>
      <CommonBorderBox className='d-flex justify-content-between teamBorderBox'>
        <div className='d-flex teamAddForm' style={{ gap: '32px', justifyContent: 'space-between' }}>
          <div className='teamFormDiv'>
            <div className='semiBoldWeight'>Basic Info</div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                createPatient(formData);
              }}
            >
              <div className='teamFormDiv'>
                {renderFormData(inputFieldsData)}
                <CoreButton
                  onClick={() => createPatient(formData)}
                  type='submit'
                  title='Save Details'
                  customDivStyle={{ width: '100%' }}
                />
              </div>
              <button type='submit' className='d-none'></button>
            </form>
          </div>
          <div>
            <div>
              <div className='profilePhotoStyle'>
                <input
                  ref={inputRef}
                  type='file'
                  className='d-none'
                  value=''
                  accept='image/*'
                  onChange={(e) => {
                    let tmpData = { ...formData };
                    tmpData.profile_url = e.target.files[0];
                    setFormData({ ...tmpData });
                    setImage(e.target.files[0]);
                  }}
                />
                {image && <img src={URL.createObjectURL(image)} />}
                {serverImage && !image && <img src={serverImage} />}
                {!image && !serverImage && <div className='w-50'>Drop Profile Photo</div>}
              </div>
            </div>
            <div>
              <BorderBtnWithBg
                onClick={() => {
                  inputRef.current.click();
                }}
                className='uploadPhotoBtn'
                iconImg={upload}
                title='Upload'
              />
            </div>
          </div>
        </div>
      </CommonBorderBox>
      <div></div>
    </div>
  );
}

export default AddPatientForm;
