import React, { useEffect, useState } from 'react';
// import SegmentDataAttributeList from './SegmentDataAttributeList';
import { Select2DropDown, CoreInputBox, CoreDropDown, Loader, TagsShow } from '../index';
import { DatePicker, DateRangePicker } from 'rsuite';
import { dateFormatStringSingleDate } from '../Helper';
import { useGetSegmentAttributesQuery } from 'redux/segmentApis';
import useApiManager from 'networking/ApiManager';

const SegmentDefinitionRuleRow = (props) => {
  const segmentAttributesData = useGetSegmentAttributesQuery();
  const ApiClient = useApiManager();
  let SegmentDataAttributeList = segmentAttributesData?.data?.data || [];

  const {
    segmentAttributeIndex,
    parentRuleIndex,
    segmentAttribute,
    cleanValues,
    attributesCount,
    custom_date,
    errors,
  } = props;

  const [selectedAttribute, setSelectedAttribute] = useState({});
  const [selectedType, setSelectedType] = useState(props?.segmentAttribute?.type);
  const [selectedOperator, setSelectedOperator] = useState({});
  const [value, setValue] = useState(props.value);
  const [value1, setValue1] = useState();
  const [customDate, setcustomDate] = useState(custom_date);
  const [customDateRange, setcustomDateRange] = useState(custom_date);
  const [goalsList, setGoalsList] = useState([]);
  const [searchGoalsList, setSearchGoalsList] = useState([]);
  const [medicinesList, setMedicinesList] = useState([]);
  const [searchMedicinesList, setSearchMedicinesList] = useState([]);
  const [isGoalLoader, setIsGoalLoader] = useState(false);
  const [isMedicineLoader, setIsMedicineLoader] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [masterStatus, setMasterStatus] = useState([]);
  const [searchTags, setSearchTags] = useState('');

  useEffect(() => {
    getGoalsList();
  }, []);

  useEffect(() => {
    getMeicinesList();
  }, []);

  useEffect(() => {
    getStatusList();
  }, []);

  const getStatusList = () => {
    let params = { type: 'patient' };
    ApiClient.allStatus(params)
      .then((res) => {
        let allStatusData = res.data.map((item) => {
          return { ...item, value: item.uuid };
        });
        setMasterStatus(allStatusData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    let attributeType = props?.segmentAttribute?.type;
    if (attributeType?.name) {
      selectedTypeFilter(medicinesList, attributeType);
    } else if (attributeType?.title) {
      selectedTypeFilter(goalsList, attributeType);
    }
  }, [props?.segmentAttribute?.type, goalsList, medicinesList]);

  useEffect(() => {
    if (selectedOperator == 'assigned') setValue(0);
  }, [selectedOperator]);

  useEffect(() => {
    if (cleanValues) {
      clearForm();
    }
  }, [cleanValues]);

  const searchGoalList = async (search_str) => {
    if (search_str?.length > 2) {
      let params = { search_str };
      ApiClient.searchGoals(params)
        .then((res) => {
          const fetchedGoals = res.data;
          setSearchGoalsList(fetchedGoals.filter((goal) => goal.title !== 'Medicine'));
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSearchGoalsList([]);
    }
  };

  const getGoalsList = async () => {
    setIsGoalLoader(true);
    let params = { page: 1 };
    ApiClient.getGoals(params)
      .then((res) => {
        const fetchedGoals = res.data;
        setGoalsList(fetchedGoals.filter((goal) => goal.title !== 'Medicine'));
        if (segmentAttribute?.type_obj?.length && segmentAttribute.type_obj[0]?.title) {
          addNonExitsGoals(segmentAttribute.type_obj, fetchedGoals);
        }
        setIsGoalLoader(false);
      })
      .catch((err) => {
        setIsGoalLoader(false);
        console.log(err);
      });
  };

  const addNonExitsGoals = (allSelectedTags, allArticleTags = goalsList) => {
    allSelectedTags?.forEach((check) => {
      if (allArticleTags.findIndex((item) => item.id == check.id) == -1) {
        allArticleTags.push(check);
      }
    });
    setGoalsList([...allArticleTags]);
  };

  const searchMedicineList = (search_str) => {
    if (search_str?.length > 2) {
      ApiClient.searchMedicines(search_str)
        .then((res) => {
          setSearchMedicinesList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setSearchMedicinesList([]);
    }
  };

  const getMeicinesList = () => {
    setIsMedicineLoader(true);
    let params = { page: 1 };
    ApiClient.getMedicines(params)
      .then((res) => {
        setMedicinesList(res.data);
        if (segmentAttribute?.type_obj?.length && segmentAttribute.type_obj[0]?.medicine_title_repr) {
          addNonExitsData(segmentAttribute.type_obj, res.data);
        }
        setIsMedicineLoader(false);
      })
      .catch((err) => {
        setIsMedicineLoader(false);
        console.log(err);
      });
  };

  useEffect(() => {
    props.segmentAttribute && setValues();
  }, [props.segmentAttribute]);

  const setValues = () => {
    let attribute, operator;
    attribute = SegmentDataAttributeList.find((element) => {
      return element.value === props.segmentAttribute.attribute;
    });

    if (attribute) {
      operator = attribute.opratorOptions.find((element) => {
        return element.value === props.segmentAttribute.operator;
      });
    }

    attribute && setSelectedAttribute(attribute);

    operator && setSelectedOperator(operator);
  };

  useEffect(() => {
    if (props?.segmentAttribute?.value) {
      if (typeof props?.segmentAttribute?.value == 'string' && props?.segmentAttribute?.value?.includes(',')) {
        let values = props.segmentAttribute.value.split(',');
        setValue(values[0]);
        setValue1(values[1]);
      } else {
        setValue(props.segmentAttribute.value);
      }

      if (props?.segmentAttribute?.custom_date) {
        if (props.segmentAttribute.value.includes(',')) {
          let date = props.segmentAttribute.value.split(',');
          date = [new Date(date[0]), new Date(date[1])];
          setValue('specific_date_range');
          setcustomDateRange(date);
        } else {
          setValue('specific_date');
          setcustomDate(props.segmentAttribute.value);
        }
      }
    }
  }, [props?.segmentAttribute?.value]);

  useEffect(() => {
    if (selectedAttribute?.value && selectedOperator?.value) {
      updateSegmentAttribute(selectedAttribute?.value, selectedOperator?.value);
    }
  }, [value, value1]);

  useEffect(() => {
    if (selectedAttribute?.value && selectedOperator?.value) {
      updateSegmentAttribute(selectedAttribute?.value, selectedOperator?.value, customDate, true);
    }
  }, [customDate]);

  useEffect(() => {
    if (selectedAttribute?.value && selectedOperator?.value) {
      updateSegmentAttribute(selectedAttribute?.value, selectedOperator?.value, customDateRange, true, true);
    }
  }, [customDateRange]);

  useEffect(() => {
    if (props.value) {
      if (props.value.includes(',')) {
        let values = props.value.split(',');
        setValue(values[0]);
        setValue1(values[1]);
      } else {
        setValue(props.value);
      }
    }
  }, [props.value]);

  useEffect(() => {
    if (selectedAttribute?.value) {
      updateSegmentAttribute(selectedAttribute?.value, selectedOperator?.value);
    }
  }, [selectedType]);

  const selectedTypeFilter = (oldList, newData) => {
    if (newData?.id) {
      let indexOfSelectedType = oldList.findIndex((item) => item.id === newData?.id);
      if (indexOfSelectedType == -1) {
        setSearchData([newData]);
      } else {
        setSearchData([]);
      }
    }
  };

  const updateSegmentAttribute = (attribute, operator, customDate, isCustom, range) => {
    let values;
    if (range && isCustom && customDate) {
      values = dateFormatStringSingleDate(customDate[0]) + ',' + dateFormatStringSingleDate(customDate[1]);
    } else if (isCustom && customDate) {
      values = customDate;
    } else if (value1) {
      values = value + ',' + value1;
    } else {
      values = value;
    }
    let data = { attribute, operator, value: values };
    if (customDate) {
      data.custom_date = true;
    } else {
      data.custom_date = false;
    }

    if (selectedAttribute?.value != attribute) {
      data = { attribute };
      setSelectedOperator({});
    }

    if (selectedOperator?.value != operator) {
      data = { attribute, operator };
      setValue('');
      setValue1('');
      setcustomDate('');
      setcustomDateRange('');
    }

    if (props.segmentAttribute?.id) {
      data.id = props.segmentAttribute.id;
    }
    data = { ...data, type: selectedType };
    props.updateSegmentAttribute(segmentAttributeIndex, data);
  };

  const clearForm = () => {
    setSelectedAttribute({});
    setSelectedOperator({});
    setValue('');
    setValue1('');
    setcustomDate('');
    setcustomDateRange('');
    setSelectedType('');
  };

  const renderAttributeDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box'>
        <Select2DropDown
          value={selectedAttribute?.value || ''}
          retuired={true}
          data={SegmentDataAttributeList}
          label={'Attribute'}
          placeholder={'Attribute'}
          valueKey='value'
          getObjData
          onSelect={(_, sla) => {
            clearForm();
            setSelectedAttribute(sla);
            setValue(null);
            setValue1(null);
            updateSegmentAttribute(sla.value, selectedOperator?.value);
          }}
          showMSG={
            errors &&
            errors[parentRuleIndex] &&
            errors[parentRuleIndex]?.segment_attributes &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex] &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.attribute
          }
        />
      </div>
    );
  };

  const renderGoalTypeDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box'>
        {/* <Select2DropDown
          value={Number(selectedType)}
          data={searchGoalsList?.length ? searchGoalsList : [...goalsList, ...searchData]}
          label={'Select Goal'}
          placeholder={'Select Goal'}
          isValidation
          onSearch={(data) => searchGoalList(data)}
          valueKey='id'
          getObjData
          retuired={true}
          onSelect={(_, selectedData) => {
            setSelectedType(selectedData.id?.toString() || '');
            let valueData = selectedData?.id ? { id: selectedData?.id, title: selectedData?.title } : {};
            selectedTypeFilter(goalsList, valueData);
            setValue('');
            setValue1('');
            setcustomDate('');
            setcustomDateRange('');
          }}
          showMSG={
            errors &&
            errors[parentRuleIndex] &&
            errors[parentRuleIndex]?.segment_attributes &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex] &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.type
          }
        /> */}
        <TagsShow
          retuired
          objRequired
          displayValue='title'
          detail='Minimum 3 Tags'
          msgStyle={{ color: 'red' }}
          showMSG={
            errors &&
            errors[parentRuleIndex] &&
            errors[parentRuleIndex]?.segment_attributes &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex] &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.type
          }
          data={searchTags?.length > 2 ? [...searchGoalsList, ...goalsList] : goalsList}
          label='Select Goal'
          setValue={(val, obj) => {
            setSearchTags('');
            addNonExitsGoals(obj);
            setSelectedType(val.join(','));
          }}
          value={selectedType?.length ? selectedType.split(',')?.map((item3) => Number(item3)) : []}
          placeholder='Select Goal'
          onSearch={(val) => {
            setSearchTags(val);
            searchGoalList(val);
          }}
        />
      </div>
    );
  };

  const addNonExitsData = (allSelectedTags, allArticleTags = medicinesList) => {
    allSelectedTags?.forEach((check) => {
      if (allArticleTags.findIndex((item) => item.id == check.id) == -1) {
        allArticleTags.push(check);
      }
    });
    setMedicinesList([...allArticleTags]);
  };

  const renderSelectMedicineDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box'>
        <TagsShow
          retuired
          objRequired
          displayValue='medicine_title_repr'
          detail='Minimum 3 Tags'
          msgStyle={{ color: 'red' }}
          showMSG={
            errors &&
            errors[parentRuleIndex] &&
            errors[parentRuleIndex]?.segment_attributes &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex] &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.type
          }
          data={searchTags?.length > 2 ? [...searchMedicinesList, ...medicinesList] : medicinesList}
          label='Select Medicine'
          setValue={(val, obj) => {
            setSearchTags('');
            addNonExitsData(obj);
            setSelectedType(val.join(','));
          }}
          value={selectedType?.length ? selectedType.split(',')?.map((item3) => Number(item3)) : []}
          placeholder='Select Medicine'
          onSearch={(val) => {
            // alert();
            setSearchTags(val);
            searchMedicineList(val);
          }}
        />
        {/* <Select2DropDown
          value={Number(selectedType)}
          data={searchMedicinesList?.length ? searchMedicinesList : [...medicinesList, ...searchData]}
          label={'Select Medicine'}
          placeholder={'Select Medicine'}
          isValidation
          onSearch={(data) => searchMedicineList(data)}
          valueKey='id'
          labelKey='medicine_title_repr'
          getObjData
          retuired={true}
          onSelect={(_, selectedData) => {
            setSelectedType(selectedData.id?.toString() || '');
            let valueData = selectedData?.id ? { id: selectedData?.id, name: selectedData?.name } : {};
            selectedTypeFilter(medicinesList, valueData);
            setValue('');
            setValue1('');
            setcustomDate('');
            setcustomDateRange('');
          }}
          showMSG={
            errors &&
            errors[parentRuleIndex] &&
            errors[parentRuleIndex]?.segment_attributes &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex] &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.type
          }
        /> */}
      </div>
    );
  };

  const renderOperatorDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box'>
        <Select2DropDown
          retuired={true}
          value={selectedOperator?.value || ''}
          data={selectedAttribute.opratorOptions}
          label={'Operator'}
          placeholder={'Operator'}
          valueKey='value'
          getObjData
          // retuired={true}
          onSelect={(_, slo) => {
            setSelectedOperator(slo);
            setValue('');
            setValue1('');
            setcustomDate('');
            setcustomDateRange('');
            updateSegmentAttribute(selectedAttribute?.value, slo.value);
          }}
          showMSG={
            errors &&
            errors[parentRuleIndex] &&
            errors[parentRuleIndex]?.segment_attributes &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.operator
          }
        />
      </div>
    );
  };

  const renderValueDropdown = (options) => {
    return (
      <div className='daypart-input behaviourpart_box'>
        <Select2DropDown
          value={value}
          data={options}
          label={'Value'}
          placeholder={'Value'}
          valueKey='value'
          getObjData
          retuired={true}
          onSelect={(_, selectedValue) => {
            setValue(selectedValue?.value);
            setValue1('');
            setcustomDate('');
            setcustomDateRange('');
          }}
          showMSG={
            errors &&
            errors[parentRuleIndex] &&
            errors[parentRuleIndex]?.segment_attributes &&
            errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.value
          }
        />
      </div>
    );
  };

  const renderInputField = (inputType = '', label, placeholder, selectedValue, setValue, error) => {
    return (
      <div className='daypart-input behaviourpart_box'>
        <CoreInputBox
          label={label}
          maxLength={+selectedOperator?.limit}
          validateZero={selectedOperator?.validate_zero}
          checkMax={+selectedOperator?.max}
          placeholder={placeholder}
          value={selectedValue}
          retuired={true}
          setValue={(val) => {
            setValue && setValue(val);
            // updateSegmentAttribute(selectedAttribute.value, selectedOperator.value, val);
          }}
          showMSG={error}
          validateNumbers={inputType.includes('integer') || selectedOperator?.validate_numbers}
          validatedecimal={selectedOperator?.validate_float}
          showHint={selectedOperator?.hint}
        />
      </div>
    );
  };

  const renderInputDateRangeField = (val, setVal) => {
    let defaultValue;
    let err =
      errors &&
      errors[parentRuleIndex] &&
      errors[parentRuleIndex]?.segment_attributes &&
      errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.value;

    if (value && value1) {
      defaultValue = [new Date(value), new Date(value1)];
    }

    return (
      <div>
        <div>
          <label class='inputlabel'>
            <span className='requiredInput'> * </span> Specific value
          </label>
        </div>
        <DateRangePicker
          defaultValue={val ?? defaultValue}
          showOneCalendar
          ranges={[]}
          onChange={(date) => {
            if (setVal) {
              setVal(date);
            } else {
              setValue(dateFormatStringSingleDate(date[0]));
              setValue1(dateFormatStringSingleDate(date[1]));
            }
            // updateSegmentAttribute(selectedAttribute.value, selectedOperator.value, date);
          }}
          placement='autoVerticalStart'
          placeholder='Select Date'
          style={{ width: '100%' }}
          format='yyyy-MM-dd'
          calendarDefaultDate={new Date()}
        />
        {err && <div class='inputMsg'>{err}</div>}
      </div>
    );
  };

  const renderInputDateField = (val, setVal) => {
    let defaultValue;
    if (val) {
      defaultValue = new Date(val);
    } else if (value) {
      let date = '';
      date = new Date(value);
      if (date != 'Invalid Date') {
        defaultValue = date;
      }
    }

    let err =
      errors &&
      errors[parentRuleIndex]?.segment_attributes &&
      errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.value;

    return (
      <div style={{ position: 'relative' }}>
        <div>
          <label class='inputlabel'>
            <span className='requiredInput'> * </span> Specific value
          </label>
        </div>
        <DatePicker
          defaultValue={defaultValue}
          preventOverflow
          placeholder='Value'
          format='yyyy-MM-dd'
          onChange={(date) => {
            if (setVal) {
              setVal(dateFormatStringSingleDate(date));
            } else {
              setValue(dateFormatStringSingleDate(date));
            }
            // updateSegmentAttribute(selectedAttribute.value, selectedOperator.value, date);
          }}
          placement='autoVerticalStart'
          calendarDefaultDate={new Date()}
          onOk={(date) => {
            if (setVal) {
              setVal(dateFormatStringSingleDate(date));
            } else {
              setValue(dateFormatStringSingleDate(date));
            }
            // updateSegmentAttribute(selectedAttribute.value, selectedOperator.value, date);
          }}
          style={{ width: '100%', padding: 0, margin: 0 }}
        />
        {err && <div class='inputMsg'>{err}</div>}
      </div>
    );
  };

  const renderInputIntegerRangeField = (inputType) => {
    let valueerr =
      errors &&
      errors[parentRuleIndex]?.segment_attributes &&
      errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.value;
    let valueerr2 =
      errors &&
      errors[parentRuleIndex]?.segment_attributes &&
      errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.value;
    if (valueerr && valueerr.includes(',')) {
      [valueerr, valueerr2] = [valueerr?.split(',')[0], valueerr?.split(',')[1]];
    }

    return (
      <>
        {renderInputField(inputType, 'Min', 'Min', value, setValue, valueerr)}
        {renderInputField(inputType, 'Max', 'Max', value1, setValue1, valueerr2)}
      </>
    );
  };

  const renderValueFields = () => {
    let valueerr =
      errors &&
      errors[parentRuleIndex] &&
      errors[parentRuleIndex]?.segment_attributes &&
      errors[parentRuleIndex]?.segment_attributes[segmentAttributeIndex]?.value;

    let isPatientMaster = false;
    if (selectedAttribute.value == 'patient_master_status') {
      isPatientMaster = true;
    }
    if (!!selectedAttribute?.valueOptions || isPatientMaster) {
      return renderValueDropdown(isPatientMaster ? masterStatus : selectedAttribute.valueOptions);
    } else if (!!selectedOperator?.valueOptions) {
      return renderValueDropdown(selectedOperator.valueOptions);
    } else if (selectedOperator?.inputType === 'integer') {
      return renderInputField(selectedOperator.inputType, 'Value', 'Value', value, setValue, valueerr);
    } else if (selectedOperator?.inputType === 'date_range') {
      return renderInputDateRangeField();
    } else if (selectedOperator?.inputType === 'date') {
      return renderInputDateField();
    } else if (selectedOperator?.inputType === 'integer_range') {
      return renderInputIntegerRangeField(selectedOperator.inputType);
    } else if (selectedOperator?.inputType === 'text') {
      return renderInputField(selectedOperator.inputType, 'Value', 'Value', value, setValue, valueerr);
    }
  };

  const renderAttributes = () => {
    return (
      <div
        id={'segment-attribute-' + parentRuleIndex + '' + segmentAttributeIndex}
        className='d-flex justify-content-between w-100 gap-10 mb-3 pb-3 addsegment daypart_box segment-box'
      >
        {renderAttributeDropdown()}
        {selectedAttribute && selectedAttribute.value == 'goals' && renderGoalTypeDropdown()}
        {selectedAttribute && selectedAttribute.value == 'medicine' && renderSelectMedicineDropdown()}
        {selectedAttribute && selectedAttribute.value && renderOperatorDropdown()}
        {selectedAttribute?.value &&
          selectedAttribute.value !== 'medicine' &&
          selectedOperator &&
          selectedOperator.value &&
          selectedOperator.value !== 'assigned' &&
          selectedOperator.value !== 'not_assigned' &&
          selectedOperator.value !== 'assigned_any' &&
          selectedOperator.value !== 'not_assigned_any' &&
          renderValueFields()}
        {value == 'specific_date' && renderInputDateField(customDate, setcustomDate)}
        {value == 'specific_date_range' && renderInputDateRangeField(customDateRange, setcustomDateRange)}
        {attributesCount > 1 ? (
          <div className='d-flex justify-content-end align-items-end add-question-btn'>
            <div
              className='day_part-btn'
              onClick={() => {
                const confirmdelete = window.confirm('Are you sure you want to remove?');
                if (confirmdelete) {
                  props.removeAttribute(segmentAttributeIndex);
                }
              }}
            >
              <span className='material-icons pointer'>clear</span>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  return (
    <div>
      {renderAttributes()}
      <Loader show={isGoalLoader || isMedicineLoader} />
    </div>
  );
};

export default SegmentDefinitionRuleRow;
