import React, { useMemo } from "react";
import "./inputBox.css";

function CoreDropDown(props) {
  const {
    setValue,
    value,
    placeholder,
    label,
    selectStyle,
    msgStyle,
    labelStyle,
    showMSG,
    retuired,
    data,
    showKey,
    nestedKey,
    setKey,
    disabled,
  } = props;

  const onChangeValue = (data) => {
    let val = data.target.value;
    setValue(val);
  };

  const optionsMap = () => {
    return data.map((item, index) => {
      return (
        <option key={index} value={setKey ? item[setKey] : item.id}>
          {showKey ? (nestedKey ? item[showKey][nestedKey] : item[showKey]) : item.title}
        </option>
      );
    });
  };

  return (
    <div>
      {label && (
        <label className="inputlabel" style={labelStyle}>
          {retuired && <span className="requiredInput"> * </span>} {label}
        </label>
      )}
      <select
        disabled={disabled}
        style={selectStyle}
        onChange={onChangeValue}
        value={value}
        required
        className="form-select coreInput capitalize pointer mb-1"
        aria-label="Default select"
      >
        <option value="" disabled selected>
          {placeholder}
        </option>
        {data && optionsMap()}
      </select>
      {showMSG && (
        <div className="inputMsg" style={msgStyle}>
          {showMSG}
        </div>
      )}
    </div>
  );
}

export default CoreDropDown;
