import React from 'react';
import './commonBorderBox.css';

function CommonBorderBox(props) {
  const { children, className, onClick, style } = props;
  return (
    <div onClick={onClick} style={style} className={`commonBorderBox ${className}`}>
      {children}
    </div>
  );
}

export default CommonBorderBox;
