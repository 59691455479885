import React, { useState, useEffect } from 'react';

import RenderHealthtab from 'whealth-core-web/components/RenderHealthtab';
import RenderTimelineTab from 'whealth-core-web/components/RenderTimelineTab';
import RenderGoalTab from 'whealth-core-web/components/RenderGoalTab';
import { RowLinks } from 'whealth-core-web/components';
import { useLocation, useNavigate } from 'react-router-dom';

import RenderPrescriptionTab from 'whealth-core-web/components/RenderPrescriptionTab';

function GraphCard(props) {
  const { id } = useNavigate();
  const { data } = props;

  const [status, setStatus] = useState('timeline');
  const renderHeaderLinks = () => {
    const headerData = [
      {
        title: `Timeline `,
        link: 'timeline',
      },
      {
        title: `Health Profile `,
        link: 'health profile',
      },
      {
        title: `Prescription`,
        link: 'prescription',
      },
      {
        title: `Goal`,
        link: 'goal',
      },
      {
        title: `Reports`,
        link: 'reports',
      },
      {
        title: `Notes`,
        link: 'notes',
      },
    ];

    return <RowLinks data={headerData} onClickHandle={setStatus} />;
  };

  const renderTabs = () => {
    if (status === 'health profile') {
      return <RenderHealthtab setStatus={setStatus} />;
    }
    if (status === 'timeline') {
      return <RenderTimelineTab setStatus={setStatus} />;
    }
    if (status === 'goal') {
      return <RenderGoalTab data={data} setStatus={setStatus} />;
    }
    if (status === 'prescription') {
      return <RenderPrescriptionTab data={data} setStatus={setStatus} />;
    }
  };

  return (
    <>
      {renderHeaderLinks()}
      {renderTabs()}
    </>
  );
}

export default GraphCard;
