import React from 'react';
import './emptyView.scss';

function EmptyView(props) {
  const { title, hide } = props;
  if (hide) {
    return;
  } else {
    return (
      <div className="emptyView my-3">
        <div>{title || 'No data found'}</div>
      </div>
    );
  }
}

export default EmptyView;
