import React, { useEffect, useState } from 'react';
import { CoreButton, CoreDropDown, CoreInputBox } from 'whealth-core-web/components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useApiManager from 'networking/ApiManager';

function AddSummaryForm(props) {
  const { onClick, setErrors, errors, data } = props;
  const location = useLocation();

  const { id, pid } = useParams();

  const [overallSummary, setOverallSummary] = useState('');
  const [specialityName, setSpecialityName] = useState('');
  const [noOfSessions, setNoOfSessions] = useState();
  const [advice, setAdvice] = useState();
  const [selectedRecommendation, setSelectedRecommendation] = useState('advised_procedure');
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    if (data) {
      setDepartments(data.prescriptions);
      let first = Object.keys(data.recommedation).find((item) => typeof data.recommedation[item] == 'object');
      if (first) {
        setSelectedRecommendation(first);
        setNoOfSessions(data?.recommedation[first]?.no_of_sessions);
        setSpecialityName(data?.recommedation[first]?.speciality_name);
      }
      setAdvice(data?.recommedation?.advised_procedure?.procedure_name);
      setOverallSummary(data?.mdt_summary);
    }
  }, [data]);

  const prefillSummary = location?.state?.item;
  const ApiClient = useApiManager();

  useEffect(() => {
    if (!pid) {
      getSummeryData();
    }
  }, []);

  const getSummeryData = () => {
    let params = { status: 'published' };
    ApiClient.prescriptionList(id, params)
      .then((res) => {
        setDepartments(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const recommendation_options = [
    {
      title: 'Care Plan Structure',
      id: 'care_plan_structure',
    },
    {
      title: 'Advised Procedure',
      id: 'advised_procedure',
    },
  ];

  useEffect(() => {
    const setSummaryData = (item) => {
      setOverallSummary(item.overallSummary ?? '');
      setSpecialityName(item?.specialityName ?? '');
      setNoOfSessions(item?.noOfSessions);
      setAdvice(item?.advice);
      setSelectedRecommendation(item?.selectedReccomendation);
    };
    prefillSummary && setSummaryData(prefillSummary);
  }, prefillSummary);

  const handleSave = () => {
    const summary = {
      mdt_summary: overallSummary,
      recommedation: selectedRecommendation,
    };

    summary.recommedation = {
      [selectedRecommendation]: {},
    };

    if (selectedRecommendation == 'care_plan_structure') {
      summary.recommedation[selectedRecommendation].speciality_name = specialityName || '';
      summary.recommedation[selectedRecommendation].no_of_sessions = noOfSessions || '';
    } else {
      summary.recommedation[selectedRecommendation].procedure_name = advice || '';
    }

    onClick(summary);
  };

  const renderData = (data, key) => {
    if (data && Array.isArray(data)) {
      return data.map((item, index) => {
        if (item.name == key) {
          return (
            <pre style={{ whiteSpace: 'pre-wrap' }} key={index} className="mb-0 prefont_family">
              {item.answer}
            </pre>
          );
        }
      });
    }
    return;
  };

  const renderTableMap = () => {
    if (departments && Array.isArray(departments)) {
      return departments.map((item, index) => {
        if (item.department && Object.keys(item.data).length) {
          return (
            <tr key={index}>
              <td>{item?.department?.name}</td>
              <td>{renderData(item?.data, 'diagnosis')}</td>
              <td>{renderData(item?.data, 'advice')}</td>
            </tr>
          );
        } else {
          return;
        }
      });
    }
  };

  const renderFormDetails = () => {
    let first =
      errors?.recommedation &&
      Object.keys(errors.recommedation).find((item) => typeof errors.recommedation[item] == 'object');

    return (
      <>
        <div className="card mt-3">
          <div className="card-body">
            <h5 className="card-title pt-2 pb-2">MDT Summary Details</h5>
            <div className="d-flex mt-4 align-items-center justify-content-center">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Speciality Name</th>
                    <th scope="col" style={{ width: '40%' }}>
                      Area of concern
                    </th>
                    <th scope="col" style={{ width: '40%' }}>
                      Advice
                    </th>
                  </tr>
                </thead>

                {departments && Array.isArray(departments) && departments.length ? (
                  renderTableMap()
                ) : (
                  <tr>
                    <td colspan="3" className="text-center p-4">
                      No record found
                    </td>
                  </tr>
                )}
              </table>
            </div>

            <div className="mb-3">
              <CoreInputBox
                retuired
                showMSG={errors?.mdt_summary}
                labelStyle={{
                  margin: '0 0 8px 0px',
                  fontWeight: 'var(--lightWeight)',
                }}
                label="Overall MDT Summary"
                value={overallSummary}
                setValue={(data) => {
                  setOverallSummary(data);
                }}
              />
            </div>

            <div className="mb-3">
              <CoreDropDown
                label="Recommendation"
                retuired={true}
                data={recommendation_options}
                labelStyle={{ fontWeight: 'var(--lightFont)' }}
                setValue={(data) => {
                  setSelectedRecommendation(data);
                  setSpecialityName('');
                  setNoOfSessions('');
                  setAdvice('');
                }}
                value={selectedRecommendation}
                msgStyle={{ color: 'red' }}
              />
            </div>

            {selectedRecommendation && selectedRecommendation == 'care_plan_structure' ? (
              <>
                <div className="mb-3">
                  <CoreInputBox
                    showMSG={
                      errors?.recommedation &&
                      errors?.recommedation[first] &&
                      errors?.recommedation[first]?.speciality_name
                    }
                    labelStyle={{
                      margin: '0px 0 8px 0px',
                      fontWeight: 'var(--lightWeight)',
                    }}
                    label="Speciality Name"
                    retuired
                    value={specialityName}
                    setValue={(data) => {
                      setSpecialityName(data);
                    }}
                  />
                </div>
                <div className="mb-3">
                  <CoreInputBox
                    validateNumbers
                    showMSG={
                      errors?.recommedation &&
                      errors?.recommedation[first] &&
                      errors?.recommedation[first]?.no_of_sessions
                    }
                    labelStyle={{
                      margin: '0px 0 8px 0px',
                      fontWeight: 'var(--lightWeight)',
                    }}
                    label="Number of Sessions"
                    retuired
                    type={'number'}
                    value={noOfSessions}
                    setValue={(data) => {
                      setNoOfSessions(data);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className="mb-3">
                <CoreInputBox
                  showMSG={errors?.recommedation && errors?.recommedation?.advised_procedure?.procedure_name}
                  labelStyle={{
                    margin: '0px 0 8px 0px',
                    fontWeight: 'var(--lightWeight)',
                  }}
                  label="Advised Procedure"
                  retuired
                  value={advice}
                  setValue={(data) => {
                    setAdvice(data);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <CoreButton
          onClick={() => {
            setErrors({});
            handleSave();
          }}
          customClass="btn btn-secondary btn-lg"
          title="Save Details"
        />
      </>
    );
  };

  return <div className="prescription-form">{renderFormDetails()}</div>;
}

export default AddSummaryForm;
