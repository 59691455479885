import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import Select2DropDown from './common/Select2DropDown';
import CoreButton from './CoreButton';
import CoreDropDown from './CoreDropDown';
import CoreInputBox from './CoreInputBox';

function SettingBehaviourForm(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick } = props;
  const [suggestionList, setSuggestionList] = useState([]);
  const [categoriesData, setCategoriesData] = useState([]);

  const ApiClient = useApiManager();

  useEffect(() => {
    searchGoals();
    getCategories();
  }, []);

  const searchGoals = (searchStr) => {
    let params = { search_str: searchStr };
    ApiClient.getGoals(params)
      .then((res) => {
        setSuggestionList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCategories = (searchStr) => {
    ApiClient.getBehaviourQuestionCategories()
      .then((res) => {
        setCategoriesData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const typeArr = [
    { title: 'String', id: 'string' },
    { title: 'Text', id: 'text' },
    { title: 'Integer', id: 'int' },
    { title: 'Date', id: 'date' },
    { title: 'Float', id: 'float' },
    { title: 'Multi Select', id: 'multi_select' },
    { title: 'Single Select', id: 'single_select' },
  ];

  return (
    <div>
      <CoreInputBox
        retuired
        showMSG={error?.question}
        placeholder="Question"
        value={valueObj.question || ''}
        label="Question"
        setValue={(data) => {
          setValueObj({ ...valueObj, question: data });
        }}
        msgStyle={{ color: 'red' }}
        maxLength={255}
      />

      <div style={{ margin: '20px 0' }}>
        <CoreDropDown
          retuired
          data={typeArr}
          showMSG={error?.type}
          placeholder="Type"
          value={valueObj.type || ''}
          label="Type"
          setValue={(data) => {
            setValueObj({ ...valueObj, type: data });
          }}
          msgStyle={{ color: 'red' }}
        />
      </div>

      {(valueObj.type == 'multi_select' || valueObj.type == 'single_select') && (
        <div style={{ margin: '20px 0' }}>
          <CoreInputBox
            retuired
            showMSG={error?.options}
            placeholder="Please use 'I' to seperate different options. Ex. Option1|Option2|Option3"
            value={valueObj.options || ''}
            label="Options"
            setValue={(data) => {
              setValueObj({ ...valueObj, options: data });
            }}
            msgStyle={{ color: 'red' }}
          />
        </div>
      )}
      <div style={{ margin: '20px 0' }}>
        <Select2DropDown
          onSearch={(str) => {
            searchGoals(str);
          }}
          label="Attach Goal"
          data={suggestionList}
          style={{ width: '100%' }}
          labelKey="title"
          valueKey="id"
          defaultValue={valueObj?.goal_id}
          value={valueObj?.goal_id || ''}
          placeholder="Attach goal"
          msgStyle={{ color: 'red' }}
          onSelect={(data) => {
            setValueObj({ ...valueObj, goal_id: data });
          }}
        />
      </div>

      <div style={{ margin: '20px 0' }}>
        <Select2DropDown
          onSearch={(str) => {
            searchGoals(str);
          }}
          label="Category"
          data={categoriesData}
          style={{ width: '100%' }}
          defaultValue={valueObj?.category_id}
          value={valueObj?.category_id || ''}
          placeholder="Category"
          msgStyle={{ color: 'red' }}
          showMSG={error?.category_id}
          onSelect={(data) => {
            setValueObj({ ...valueObj, category_id: data });
          }}
        />
      </div>

      <div className="d-flex">
        <div className="form-check">
          <input
            className="form-check-input pointer"
            checked={valueObj.enable_mobile}
            onChange={(data) => {
              setValueObj({ ...valueObj, enable_mobile: !valueObj.enable_mobile });
            }}
            type="checkbox"
            id={isUpdate ? 'flexCheckDefault1' : 'update'}
          />
        </div>
        <label className="pointer" htmlFor={isUpdate ? 'flexCheckDefault1' : 'update'}>
          Display On Mobile
        </label>
      </div>

      <CoreButton onClick={() => onClick()} title={isUpdate ? 'Update' : 'Save'} />
    </div>
  );
}

export default SettingBehaviourForm;
