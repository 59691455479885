import React, { useEffect, useState } from 'react';
import { TagsShow, ChipsTagsView, CoreInputBox } from './index';

function GenderFilterView(props) {
  const { filterData, setFilterData } = props;
  const [activeData, setActiveData] = useState([]);

  useEffect(() => {
    let stringActiveData = activeData?.toString();
    let obj = { gender: stringActiveData };
    setFilterData(obj);
  }, [activeData]);

  useEffect(() => {
    setActiveData(new Array());
  }, []);

  useEffect(() => {
    const tempActiveData = filterData?.gender?.split(',');
    setActiveData(tempActiveData || []);
  }, [filterData]);

  const gender = [
    { title: 'Male', id: 'male' },
    { title: 'Female', id: 'female' },
    { title: 'Other', id: 'other' },
  ];

  const renderHeading = () => {
    return <div className="filterHeadings mt-3">Gender</div>;
  };

  const renderChips = () => {
    return (
      <div>
        <div className="mt-3" />
        <ChipsTagsView setActiveTags={setActiveData} activeTags={activeData} data={gender} />
      </div>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default GenderFilterView;
