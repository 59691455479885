import React, { useState } from "react";
import CommonBorderBox from "../CommonBorderBox";
import CoreInputBox from "../CoreInputBox";
import { checkIsUrl } from "../Helper";
import CoreButton from "../CoreButton";
function BannerConfigCreation(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick, getUrlForMedia } = props;
  const [customAlert, setCustomAlert] = useState("");

  const removeFiles = () => {
    setValueObj({ ...valueObj, media: "" });
    valueObj.content_url && setValueObj({ ...valueObj, content_url: "" });
  };

  const MAX_FILE_SIZE = 8 * 1000000; // 1 MB
  const handleFileChange = (data) => {
    const file = data[0];
    if (file?.size > MAX_FILE_SIZE) {
      setCustomAlert("File size must be less than 8 mb.");
    } else {
      setCustomAlert(false);
    }
  };

  const renderMedia = () => {
    if (valueObj.content_url && checkIsUrl(valueObj.content_url)) {
      let newUrl = valueObj.content_url
        .replace("watch?v=", "embed/")
        .replace("youtu.be", "www.youtube.com/embed")
        .split("&")[0];
      return (
        <div className="mx-auto mt-3 d-flex justify-content-center">
          {valueObj.asset_type !== "image" ? (
            <div style={{ position: "relative", textAlign: "center" }}>
              {valueObj.asset_type == "video" ? (
                <video width="320" height="240" autoplay loop controls>
                  <source src={valueObj.content_url} type="video/mp4" />
                </video>
              ) : (
                <iframe
                  width="320"
                  height="240"
                  src={newUrl}
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              )}
              <span onClick={removeFiles} class="material-icons-outlined cancelbtn ">
                cancel
              </span>
            </div>
          ) : (
            <div style={{ position: "relative", textAlign: "center" }}>
              <img width="320" height="240" src={valueObj.content_url} />
              <span onClick={removeFiles} class="material-icons-outlined cancelbtn">
                cancel
              </span>
            </div>
          )}
        </div>
      );
    }
  };

  let customClassName = customAlert ? "disabledOtpBtn" : "";

  const renderCheckBox = () => {
    return (
      <div className="mt-1 d-flex flex-wrap ">
        <div className="mt-3 d-flex flex-wrap">
          <div className="form-check ">
            <input
              className={"form-check-input"}
              style={{ cursor: "pointer" }}
              checked={valueObj.status == "enable"}
              onChange={(data) => {
                if (valueObj.status == "enable") {
                  valueObj.status = "disable";
                } else {
                  valueObj.status = "enable";
                }
                valueObj.status = setValueObj({ ...valueObj });
              }}
              type="checkbox"
              id={isUpdate ? "form-check" : "flexCheckDefault1"}
            />
          </div>
          <label className="pointer" htmlFor={isUpdate ? "form-check" : "flexCheckDefault1"}>
            &nbsp; Enable For Mobile
          </label>
        </div>
      </div>
    );
  };
  const renderVideo = () => {
    let fileError = valueObj.media?.length !== 0;
    return (
      <>
        {!valueObj.content_url && (
          <div style={{ margin: "20px 0" }}>
            <CoreInputBox
              value={""}
              label="File"
              setValue={(data) => {
                setValueObj({ ...valueObj, media: data[0] });
                handleFileChange(data);
              }}
              inputStyle={{ height: "unset", paddingLeft: "unset" }}
              msgStyle={{ color: "red" }}
              type="file"
            />
            {customAlert && fileError && <div className="inputMsg mt-3">{customAlert}</div>}
          </div>
        )}
        {!valueObj.media && !valueObj.image_url && !valueObj.content_url && (
          <div className="text-center w-100 mt-4">
            <b> OR </b>
          </div>
        )}
        {!valueObj.media && !valueObj.image_url && (
          <div className="App w-100" style={{ margin: "20px 0" }}>
            <CoreInputBox
              retuired
              id={"file-input-video"}
              type="url"
              showMSG={error.content_url}
              value={valueObj.content_url || ""}
              label="Content Url"
              placeholder="Content Url"
              setValue={(data) => {
                setValueObj({ ...valueObj, content_url: data });
              }}
            />
          </div>
        )}
        {renderMedia()}
        {valueObj.media?.type == "video/mp4" ? (
          <div>
            <div className="mt-3 d-flex justify-content-start">
              <div className="mt-3 d-flex justify-content-start ">
                {valueObj.media && (
                  <div style={{ position: "relative" }}>
                    <video width="320" height="240" src={URL.createObjectURL(valueObj.media)} controls />
                    <span onClick={removeFiles} class="material-icons-outlined cancelbtn imgcancelbtn">
                      cancel
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-start">
            {valueObj?.media && (
              <div style={{ position: "relative" }}>
                <img className="showImageStyle" src={URL.createObjectURL(valueObj?.media)} />
                <span onClick={removeFiles} class="material-icons-outlined cancelbtn imgcancelbtn">
                  cancel
                </span>
              </div>
            )}
          </div>
        )}
      </>
    );
  };
  return (
    <CommonBorderBox>
      <div style={{ margin: "20px 0" }}>
        <CoreInputBox
          showMSG={error?.title}
          placeholder="Title"
          value={valueObj.title || ""}
          label="title"
          setValue={(data) => {
            setValueObj({ ...valueObj, title: data });
          }}
          msgStyle={{ color: "red" }}
          retuired
        />
        {renderVideo()}
        <div style={{ margin: "20px 0" }}>
          <CoreInputBox
            validateZero={true}
            validatedecimal
            showMSG={error?.priority}
            placeholder="Priority"
            value={valueObj.priority || ""}
            label="Priority"
            setValue={(data) => {
              setValueObj({ ...valueObj, priority: data });
            }}
            msgStyle={{ color: "red" }}
          />
        </div>
        {renderCheckBox()}
        {(valueObj?.media || valueObj?.video || valueObj.content_url) && (
          <div className="d-flex">
            {!valueObj.content_url && (
              <CoreButton
                customClass={customClassName}
                onClick={customClassName ? "" : getUrlForMedia}
                type="submit"
                title={isUpdate ? "Update" : "Save"}
                customDivStyle={{ width: "100%" }}
              />
            )}
          </div>
        )}
      </div>
      {!valueObj.media && (
        <CoreButton
          onClick={onClick}
          type="submit"
          title={isUpdate ? "Update" : "Save"}
          customDivStyle={{ width: "100%" }}
        />
      )}
    </CommonBorderBox>
  );
}

export default BannerConfigCreation;
