import React, { useState } from 'react';
import { useGetSegmentDataQuery } from 'redux/segmentApis';
import { CommonAlert, Loader, Pagination } from 'whealth-core-web/components';
import { SegmentListItem } from '..';
import { useLocation } from 'react-router-dom';

function SegmentList() {
  const [alertMsg, setAlertMsg] = useState({});
  const { state } = useLocation();
  window.history.replaceState({}, 'segments');
  const [currentPage, setCurrentPage] = useState(state?.currentPage || 1);
  const segmentList = useGetSegmentDataQuery(currentPage, { refetchOnMountOrArgChange: true });

  const renderSegmentListItems = () => {
    return segmentList.data.data.map((item, index) => {
      return (
        <SegmentListItem
          key={item.id}
          setAlerts={setAlertMsg}
          currentPage={currentPage}
          segment={item}
          segmentIndex={index}
          refetchApi={segmentList}
        />
      );
    });
  };

  const renderSegmentList = () => {
    if (segmentList?.data?.data) {
      if (Array.isArray(segmentList?.data?.data)) {
        return (
          <div className='table-responsive'>
            <table class='table table-striped'>
              <thead>
                <tr>
                  <th scope='col'>Sr. no.</th>
                  <th scope='col'>Title</th>
                  <th scope='col'>Count</th>
                  <th scope='col'>Created By</th>
                  <th scope='col' className='text-center'>
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>{renderSegmentListItems()}</tbody>
            </table>
          </div>
        );
      } else {
        return <div className='m-4 text-center'>No data found!</div>;
      }
    }
  };

  return (
    <div className='segment-list'>
      <CommonAlert
        isShowAlert={alertMsg?.message}
        className='mt-1'
        setIsShowAlert={() => {
          setAlertMsg({});
        }}
        alertType={alertMsg?.type}
      />
      {renderSegmentList()}
      <Loader show={segmentList.isFetching} />

      <Pagination
        totalPages={segmentList?.data?.headers['total-pages']}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
}

export default SegmentList;
