import React, { useEffect, useState } from 'react';
import {
  Select2DropDown,
  CoreCheckBox,
  CoreInputBox,
  CoreDropDown,
  CoreButton,
  ReusableButton,
  CoreTextArea,
} from '../index';
import { checkunique } from 'whealth-core-web/components/Helper';
import { closeIcon, searchIcon } from 'res/images';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import CoreRadioBox from '../CoreRadioBox';

function PrescriptionMedicines(props) {
  const {
    prescriptionData,
    setPrescriptionData,
    errors,
    setErrors,
    medicineList,
    removeForm,
    searchMedicines,
    handleArrChange,
    createMedicines,
    handleMealTime,
    initialValues,
    handleCheckBoxClick,
    selectedCheckboxes,
    setSelectedCheckboxes,
  } = props;

  const [mandatoryMedicine, setMandatoryMedicine] = useState(false);
  const [mandatoryMedicineField, setMandatoryMedicineField] = useState(true);
  const getCockpitData = useGetCockpitDataQuery();

  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    configData?.map((item) => {
      if (item.key == 'mandatory_medicine' && item.isActive) {
        setMandatoryMedicine(true);
        setMandatoryMedicineField(true);
      }
    });
  }, configData);

  const typeArray = [
    { title: 'Daily', id: 'daily' },
    { title: 'Weekly', id: 'weekly' },
    { title: 'Monthly', id: 'monthly' },
    { title: 'Yearly', id: 'yearly' },
  ];

  const mealTimeDuration = [{ title: 'hour' }, { title: 'minute' }];

  const handleAsNeededClick = (isChecked, index) => {
    prescriptionData.prescription_medicines[index].is_optional = isChecked;
    if (isChecked) {
      prescriptionData.prescription_medicines[index].duration = 0;
      prescriptionData.prescription_medicines[index].dosage = '000';
      prescriptionData.prescription_medicines[index].meal_time = '';
      prescriptionData.prescription_medicines[index].meal_duration_unit = null;
      prescriptionData.prescription_medicines[index].meal_duration_value = null;
      prescriptionData.prescription_medicines[index].frequency = null;
    } else {
      prescriptionData.prescription_medicines[index].duration = 0;
      prescriptionData.prescription_medicines[index].dosage = '000';
    }
    setPrescriptionData({ ...prescriptionData });
  };

  return prescriptionData.prescription_medicines.map((item, index) => {
    let defaultData;
    let allData = medicineList;
    if (item?.medicine?.id) {
      defaultData = item?.medicine;
      allData = [...allData, defaultData];
    }
    if (prescriptionData?.searchData && prescriptionData?.searchData[index]) {
      allData = checkunique(medicineList, prescriptionData?.searchData[index]);
    }

    let arrvalues = item?.dosage?.split('') || [0, 0, 0];
    const numbers = arrvalues.map(Number);
    const sum = numbers.reduce((total, num) => total + num, 0);
    let marginBotton = prescriptionData.prescription_medicines.length - 1 != index && 'mb-4';

    const renderCloseIcon = () => {
      if (!mandatoryMedicine || prescriptionData.prescription_medicines.length > 1) {
        return (
          <div className='justify-content-end ' key={index}>
            <div className='pointer' onClick={() => removeForm(index)}>
              <img src={closeIcon} />
            </div>
          </div>
        );
      }
    };

    return (
      <div className={`card mt-3 ${marginBotton}`} key={index}>
        <div className='card-body'>
          <div className='d-flex justify-content-between align-items-center'>
            <h5 className='card-title pt-2 pb-2'>Add Medicine</h5>
            {/* {renderCloseIcon()} */}
          </div>

          <div className='d-md-flex prescription-box'>
            <div className='w-100 mb-3'>
              <>
                <div className='searchBarInput'>
                  <Select2DropDown
                    isValidation
                    getObjData
                    onSearch={(query) => searchMedicines(query, index)}
                    label='Medicine Name'
                    data={allData}
                    style={{ width: '100%' }}
                    labelKey='medicine_title_repr'
                    valueKey='id'
                    placeholder='Search Medicine Name'
                    value={prescriptionData.prescription_medicines[index].medicine_id}
                    msgStyle={{ color: 'red' }}
                    retuired={mandatoryMedicineField}
                    onSelect={(data, dataObj) => {
                      handleArrChange(data, 'medicine_id', index);
                      dataObj?.iscreate && createMedicines(index);
                    }}
                  />

                  <div className='searchbarIcon mt-3'>
                    <img src={searchIcon} />
                  </div>
                </div>
                <div className='inputMsg'>
                  {errors &&
                    errors.prescription_medicines &&
                    errors.prescription_medicines[index] &&
                    errors.prescription_medicines[index].medicine_id}
                </div>
              </>
            </div>

            {!item?.is_optional && (
              <div className='w-100 d-flex'>
                <div className='w-100 mb-3'>
                  <CoreInputBox
                    maxLength={3}
                    validateZero
                    setErrors={setErrors}
                    retuired={mandatoryMedicineField}
                    inputStyle={{
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                    }}
                    labelStyle={{ fontWeight: 'var(--lightWeight)' }}
                    label='Course Duration'
                    placeholder='Course Duration '
                    value={item.duration || ''}
                    showMSG={
                      errors &&
                      errors.prescription_medicines &&
                      errors.prescription_medicines[index] &&
                      errors.prescription_medicines[index].duration
                    }
                    setValue={(data) => {
                      handleArrChange(data, 'duration', index);
                    }}
                    validateNumbers
                  />
                  {errors.length && <div className='inputMsg'>{errors}</div>}
                </div>
                <div className='mt-4'>
                  <div className='unitBox'>Days</div>
                </div>
              </div>
            )}

            {!item?.is_optional && (
              <div className='w-100 mb-3'>
                <CoreDropDown
                  retuired={mandatoryMedicineField}
                  showMSG={
                    errors &&
                    errors.prescription_medicines &&
                    errors.prescription_medicines[index] &&
                    errors.prescription_medicines[index].frequency
                  }
                  data={typeArray}
                  labelStyle={{
                    fontWeight: 'var(--lightWeight)',
                  }}
                  label='Frequency'
                  placeholder='How many times '
                  value={item.frequency}
                  setValue={(data) => {
                    handleArrChange(data, 'frequency', index);
                  }}
                />
              </div>
            )}
          </div>
          <div className='d-md-flex prescription-box'>
            <div className='w-100'>
              <div className='w-100 mb-3'>
                <div className='d-flex '>
                  {mandatoryMedicineField && <span className='requiredInput me-1'> * </span>}
                  <div style={{ color: 'var(--lightBlack)' }}>Regimen</div>
                </div>
                <div className='mt-2'>
                  <CoreCheckBox
                    disabled={item?.is_optional}
                    onChange={(val, checked) => handleCheckBoxClick(val, checked, index, arrvalues)}
                    value={0}
                    className={'me-4'}
                    title='Morning'
                    id={`flexCheckDefault${index}`}
                    checked={!!parseInt(item.dosage[0])}
                    htmlFor={`flexCheckDefault${index}`}
                  />
                  <CoreCheckBox
                    onChange={(val, checked) => handleCheckBoxClick(val, checked, index, arrvalues)}
                    disabled={item?.is_optional}
                    value={1}
                    className={'me-4'}
                    title='Afternoon'
                    checked={!!parseInt(item.dosage[1])}
                    id={`flexCheckDefault1${index}`}
                    htmlFor={`flexCheckDefault1${index}`}
                  />

                  <CoreCheckBox
                    onChange={(val, checked) => handleCheckBoxClick(val, checked, index, arrvalues)}
                    value={2}
                    disabled={item?.is_optional}
                    className={'me-4'}
                    title='Night'
                    checked={!!parseInt(item.dosage[2])}
                    id={`flexCheckDefault2${index}`}
                    htmlFor={`flexCheckDefault2${index}`}
                  />

                  <CoreCheckBox
                    onChange={(val, checked) => handleAsNeededClick(checked, index)}
                    disabled={item.dosage[0] == 1 || item.dosage[1] == 1 || item.dosage[2] == 1}
                    title='As Needed'
                    className={'me-4'}
                    checked={!!item.is_optional}
                    id={`flexCheckDefault3${index}`}
                    htmlFor={`flexCheckDefault3${index}`}
                  />
                </div>
                <div className='inputMsg'>
                  {errors?.prescription_medicines &&
                    errors?.prescription_medicines[index] &&
                    errors?.prescription_medicines[index].dosage}
                </div>
              </div>
            </div>
            <div className='w-100 mb-3'>
              {!item?.is_optional && (
                <div className='w-100'>
                  <div>
                    <div style={{ color: 'var(--lightBlack)' }}>Meal Time</div>
                    <div className=' mt-2 '>
                      <CoreRadioBox
                        value={'before_meal'}
                        checked={item.meal_time == 'before_meal'}
                        onChange={(val) => handleMealTime(val, index)}
                        id={`before${index}`}
                        htmlFor={`before${index}`}
                        className={`me-4 `}
                        title='Before Meal'
                        name={`mealTime${index}`}
                      />
                      <CoreRadioBox
                        checked={item.meal_time == 'with_meal'}
                        onChange={(val) => handleMealTime(val, index)}
                        value={'with_meal'}
                        id={`with${index}`}
                        htmlFor={`with${index}`}
                        className={`me-4`}
                        title='With Meal'
                        name={`mealTime${index}`}
                      />
                      <CoreRadioBox
                        checked={item.meal_time == 'after_meal'}
                        onChange={(val) => handleMealTime(val, index)}
                        value={'after_meal'}
                        id={`after${index}`}
                        htmlFor={`after${index}`}
                        className={`me-4`}
                        title='After Meal'
                        name={`mealTime${index}`}
                      />
                    </div>

                    {item.meal_time !== 'with_meal' && (
                      <>
                        <label className='inputlabel mt-3 '>
                          {item.meal_duration_value?.length > 0 && !item.meal_duration_unit && (
                            <span className='requiredInput'> * </span>
                          )}
                          {'Specify Time'}
                        </label>
                        <div className='d-flex spacifictime-box w-100'>
                          <CoreInputBox
                            maxLength={2}
                            disabled={item.meal_time !== 'before_meal' && item.meal_time !== 'after_meal'}
                            validateNumbers
                            retuired={mandatoryMedicine}
                            value={item.meal_duration_value || ''}
                            setValue={(data) => {
                              handleArrChange(data, 'meal_duration_value', index);
                              if (data.length == '0') {
                                prescriptionData.prescription_medicines[index].meal_duration_unit = '';
                              }
                            }}
                            data={mealTimeDuration}
                            placeholder='Time'
                          />
                          <div className='specify-time-unit'></div>
                          <Select2DropDown
                            searchable={false}
                            valueKey='title'
                            labelKey='title'
                            disabled={!item.meal_duration_value}
                            retuired={mandatoryMedicine}
                            value={item.meal_duration_unit || ''}
                            onSelect={(data) => {
                              handleArrChange(data, 'meal_duration_unit', index);
                            }}
                            data={mealTimeDuration}
                            placeholder='Unit'
                          />
                        </div>
                        <div className='inputMsg ms-3'>
                          {errors?.prescription_medicines &&
                            errors?.prescription_medicines[index] &&
                            (errors?.prescription_medicines[index].meal_duration_value ||
                              errors?.prescription_medicines[index].meal_duration_unit)}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className='w-100 mb-3'>
              <CoreTextArea
                rows={5}
                prescriptionLabel
                label='Note'
                placeholder='Instruction'
                value={item.note}
                setValue={(data) => {
                  handleArrChange(data, 'note', index);
                }}
              />
            </div>
          </div>
        </div>

        {!mandatoryMedicine || prescriptionData.prescription_medicines.length > 1 ? (
          <div className='p-3'>
            <ReusableButton onClick={() => removeForm(index)} outline title='Remove' />
          </div>
        ) : null}

        {/* <div className="medicinegoalremovebtn">
          <div className="medicinegoalremovebtntext">Remove</div>
        </div> */}
      </div>
    );
  });
}

export default PrescriptionMedicines;
