import React, { useEffect, useState } from "react";
import { TagsShow, ChipsTagsView, CoreInputBox } from "./index";

function BMIFilter(props) {
  const { filterData, setFilterData } = props;
  const [activeData, setActiveData] = useState([]);

  useEffect(() => {
    let obj = { bmi_range: activeData };
    setFilterData(obj);
  }, [activeData]);

  useEffect(() => {
    setActiveData(new Array());
  }, []);

  useEffect(() => {
    const tempActiveData = filterData?.bmi_range;
    setActiveData(tempActiveData || []);
  }, [filterData]);

  const bmiRange = [
    { title: "27-30", id: "27-30" },
    { title: "30-32", id: "30-32" },
    { title: "32-35", id: "32-35" },
    { title: "35-40", id: "35-40" },
    { title: "40+", id: "40-100" },
  ];

  const renderHeading = () => {
    return <div className="filterHeadings mt-3">BMI Group</div>;
  };

  const renderChips = () => {
    return (
      <div>
        <div className="mt-3" />
        <ChipsTagsView singleChips={true} setActiveTags={setActiveData} activeTags={activeData} data={bmiRange} />
      </div>
    );
  };

  return (
    <div>
      {renderHeading()}
      {renderChips()}
    </div>
  );
}

export default BMIFilter;
