import useApiManager from "networking/ApiManager";
import React, { useEffect } from "react";
import CommonAlert from "../CommonAlert";
import { toast } from "react-toastify";
import Loader from "../Loader";

export const ZohoCallback = () => {
  const ApiClient = useApiManager();

  useEffect(async () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.toString().split("&").length > 1) {
      try {
        const res = await ApiClient.setZohoCreds({
          code: queryParams.get("code") ?? "",
          location: queryParams.get("location") ?? "",
        });
        if (res.status == 200) {
          toast.success("Zoho connection successfull.")
          window.location.href = "/";
        }
      } catch (e) {
        //show failure toast message
        toast.error("Error connecting Zoho")
        console.log(e);
        window.location.href = "/";
      }
    } else {
      window.location.href = "/";
    }
  }, []);

  return <div className="ms-4"><Loader show={true}/></div>;
};
