import React, { useState } from 'react';
import CommonBorderBox from '../CommonBorderBox';
import CoreButton from '../CoreButton';
import CoreInputBox from '../CoreInputBox';
import TimeKeeper from 'react-timekeeper';

function CreateDayPart(props) {
  const {
    errorMsg,
    handleDone,
    setErrorMsg,
    btnTitle,
    data,
    setData,
    action,
    errorMsgUpdate,
    setErrorMsgUpdate,
    isEditMode,
  } = props;

  const [showFrom, setShowFrom] = useState(false);
  const [showTo, setShowTo] = useState(false);

  return (
    <CommonBorderBox>
      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={isEditMode ? errorMsgUpdate?.title : errorMsg?.title}
          placeholder='Name'
          value={data?.title || ''}
          label='Name'
          setValue={(val) => {
            setData({ ...data, title: val });
            setErrorMsg({ ...errorMsg, title: '' });
          }}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>
      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex w-100 justify-content-between medicineNamerow'>
        <div
          className='w-100 daypart-time'
          onBlur={() => {
            setShowFrom(false);
          }}
        >
          <CoreInputBox
            validateNumbers
            showMSG={isEditMode ? errorMsgUpdate?.start_time : errorMsg?.start_time}
            placeholder='From'
            value={data?.fromTime?.formatted12 || data?.fromTime || ''}
            label='From'
            handleOnFocus={() => {
              setShowFrom(true);
              setShowTo(false);
              setErrorMsg({ ...errorMsg, toTime: '' });
            }}
            msgStyle={{ color: 'red' }}
            retuired
          />

          {showFrom && (
            <TimeKeeper
              time={data?.fromTime?.formatted12 || data?.fromTime}
              switchToMinuteOnHourSelect
              closeOnMinuteSelect={false}
              onDoneClick={() => {
                setShowFrom(false);
              }}
              onChange={(time) => {
                setData({ ...data, fromTime: time });
              }}
            />
          )}
        </div>
        <div
          className='w-100 daypart-time'
          onBlur={() => {
            setShowTo(false);
          }}
        >
          <CoreInputBox
            validateNumbers
            showMSG={isEditMode ? errorMsgUpdate?.end_time : errorMsg?.end_time}
            placeholder='To'
            value={data.toTime?.formatted12 || data.toTime || ''}
            label='To'
            msgStyle={{ color: 'red' }}
            retuired
            handleOnFocus={() => {
              setShowTo(true);
              setShowFrom(false);
              setErrorMsg({ ...errorMsg, toTime: '' });
            }}
          />
          {showTo && (
            <TimeKeeper
              time={data?.toTime?.formatted12 || data?.toTime}
              switchToMinuteOnHourSelect
              closeOnMinuteSelect={false}
              onDoneClick={(time) => {
                setShowTo(false);
              }}
              onChange={(time) => {
                setData({ ...data, toTime: time });
                console.log(time);
              }}
            />
          )}
        </div>
      </div>
      <CoreButton
        onClick={() => {
          action(data);
        }}
        title={btnTitle}
      />
    </CommonBorderBox>
  );
}

export default CreateDayPart;
