import React from 'react';
import AppointmentForm from './AppointmentForm';

function AddApointmentModal({
  open,
  handleClose,
  patientsList,
  editSchedule,
  getAllDoctorList,
  setPatientsList,
  isedit,
  parentStatus,
  allDoctorData,
  AppointmentSchedule,
  isNivanCare,
}) {
  return (
    <AppointmentForm
      isNivanCare={isNivanCare}
      open={open}
      handleClose={handleClose}
      patientsList={patientsList}
      editSchedule={editSchedule}
      getAllDoctorList={() => getAllDoctorList()}
      setPatientsList={setPatientsList}
      isedit={isedit}
      allDoctorData={allDoctorData}
      parentStatus={parentStatus}
      AppointmentSchedule={AppointmentSchedule}
    />
  );
}

export default AddApointmentModal;
