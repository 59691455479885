import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RenderGraph, Loader } from './index';
import { dateFormatString, subtractMonths } from 'whealth-core-web/components/Helper';
import { Pagination } from 'whealth-core-web/components';
import { searchIcon } from 'res/images';
import CoreInputBox from './CoreInputBox';

function RenderHealthtab(props) {
  const ApiClient = useApiManager();
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [goalsData, setGoalsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchInput, setSearchInput] = useState('');

  let timeOut;

  useEffect(() => {
    timeOut = setTimeout(() => {
      if (searchInput?.trim()?.length > 2) {
        searchGoals();
      }
    }, 300);
  }, [searchInput]);

  useEffect(() => {
    if (!searchInput || searchInput?.trim()?.length == 0) {
      getGoalsData();
    }
  }, [currentPage, searchInput?.trim()?.length == 0]);

  useEffect(() => {
    getGoalsData();
  }, [id, currentPage]);

  const getGoalsData = () => {
    const query = {
      date_range: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)),
      page: currentPage,
    };
    setIsLoading(true);
    ApiClient.getPatientgoals(id, false, query)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setGoalsData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const itrateGoalsData = useMemo(() => {
    if (goalsData) {
      return goalsData.map((item, index) => {
        return (
          <div key={index} className='col-12 col-md-12 col-lg-12 col-xl-6'>
            <RenderGraph setIsLoading={setIsLoading} data={item} />
          </div>
        );
      });
    }
  }, [goalsData]);

  const stops = [
    <>
      <stop key={1} stopColor='someColor1' offset='5%' />
      <stop key={2} stopColor='someColor2' offset='50%' />
      <stop key={3} stopColor='someColor3' offset='85%' />
    </>,
  ];

  const searchGoals = (
    search_str = searchInput,
    date_range = dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1))
  ) => {
    const params = { search_str, date_range };
    setIsLoading(true);
    ApiClient.searchPatientGoals(id, params)
      .then((res) => {
        setTotalPages(1);
        setIsLoading(false);
        setGoalsData(res?.data[0]?.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <div className='pt-4'>
        <div className=' d-flex  mb-3 justify-content-end'>
          <CoreInputBox
            placeholder='Search Goal'
            value={searchInput}
            setValue={(text) => {
              clearTimeout(timeOut);
              setSearchInput(text);
            }}
            leftIcon={searchIcon}
            inputStyle={{ display: 'flex', justifyContent: 'end' }}
          />
        </div>
        <div className='d-flex justify-content-center'>{itrateGoalsData?.length ? '' : 'No data found'}</div>
        <div className='row'>{itrateGoalsData}</div>

        <div className='d-flex flex-sm-column flex-md-column flex-lg-row'>
          {/* <div className="card w-100 m-1">
            <div className="card-body">
              <div class="d-flex">
                <div className="flex-grow-1">
                  <div className="graph-card-title red-title">
                    <img className="graph-icon" src={heart} /> Blood Pressure{' '}
                  </div>
                  <div className="align-self-start">
                    Energy level has increased <br />
                    significantly
                  </div>
                </div>
                <div className="ms-auto">
                  <h5 className="card-title">99</h5>
                </div>
                <div className="graph-unit m-1">ms</div>
              </div>
              <div className="d-flex">
                <Chart chartType="ColumnChart" data={data} options={options} />
              </div>
              <div className="d-flex justify-content-center">
                <DayWeekMonthView />
              </div>
            </div>
          </div> */}
          {/* <div className="card w-100 m-1">
            <div className="card-body">
              <div class="d-flex">
                <div className="flex-grow-1">
                  <div className="graph-card-title red-title">
                    <img className="graph-icon" src={heart} /> Heart Rate Variability
                  </div>
                  <div className="align-self-start">
                    Energy level has increased <br />
                    significantly
                  </div>
                </div>
                <div className="ms-auto">
                  <h5 className="card-title">99</h5>
                </div>
                <div className="graph-unit m-1">ms</div>
              </div>
              <div className="d-flex">
                <Chart chartType="ColumnChart" data={data} options={options} />
              </div>
              <div className="d-flex justify-content-center">
                <DayWeekMonthView />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <Loader show={isLoading} />
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </>
  );
}

export default RenderHealthtab;
