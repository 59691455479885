import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './editor.css';

function Editor(props) {
  const { retuired, labelStyle, placeholder, label, setValue, value, msgStyle, showMSG } = props;

  // console.log(value);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'strike', 'underline'],
      [
        { font: [] },
        {
          color: [],
        },
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        { align: 'center' },
        { align: 'right' },
        { align: 'justify' },
      ],
      ['link'],
      ['clean'],
    ],
  };

  return (
    <div className="editorStyle">
      <div className="editorLabelStyle">
        {retuired && <span>*</span>}
        {label}
      </div>
      <ReactQuill theme="snow" value={value} onChange={setValue} modules={modules} />
      {showMSG && (
        <div className="inputMsg" style={msgStyle}>
          {showMSG}
        </div>
      )}
    </div>
  );
}

export default Editor;
