import React, { useState, useEffect } from 'react';
import FullCalendar, { formatDate } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { CreateMeetingModal } from 'whealth-core-web/components/ZohoCalendar/CreateMeetingModal';
import { EditMeetingModal } from 'whealth-core-web/components/ZohoCalendar/EditMeetingModal';
import { toast } from 'react-toastify';
import useApiManager from 'networking/ApiManager';
import '../../assets/style/ZohoCalendar.css';
import { useParams } from 'react-router-dom';
import { Button, Loader } from 'rsuite';
import CoreButton from '../CoreButton';
import { addTimeInDate, dateFormat, timeFormat } from '../Helper';
import AlertMessage from '../AlertMessage';

export const ZohoCalendar = ({ isPatientView, patientId, showBookings }) => {
  const [openCreateEventModal, setOpenCreateEventModal] = useState(false);
  const [openEditEventModal, setOpenEditEventModal] = useState(false);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [bookingListTab, setBookingListTab] = useState(true);
  const [adminAvailability, setAdminAvalability] = useState([]);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [editBookingData, setEditBookingData] = useState({});
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDateEvent, setSelectedDateEvent] = useState([]);

  const ApiClient = useApiManager();
  const { id } = useParams();

  const getMeetings = async () => {
    let params = { date: 'month' };
    const meetings = isPatientView
      ? await ApiClient.getZohoMeetingsForPatient(id, params)
      : await ApiClient.getZohoMeetings();
    if (meetings.status == 200) {
      const events = [];
      meetings?.data?.map((item) => {
        const event = {
          join_link: item.booking_url ?? '',
          title: item.booking_title ?? '',
          start: new Date(item.start_time).toISOString(),
          end: new Date(item.end_time).toISOString(),
          id: item.booking_id,
          editable: item.editable,
        };
        events.push(event);
      });
      setCurrentEvents(events);
      let dateSelected = selectedDate?.startStr ? new Date(selectedDate?.startStr) : new Date();
      setSelectedDate({ startStr: dateSelected });
      setSelectedDateEvent(
        events?.length ? events?.filter((item) => new Date(item?.start).getDate() === dateSelected.getDate()) : []
      );
    } else {
      toast.error(meetings?.response?.data?.message || 'Unable to fetch ZOHO Bookings.');
    }
  };

  const getAdminAvailability = (dateForAvailability) => {
    let dateSelected = new Date(dateForAvailability).toISOString();
    ApiClient.getZohoMeetingsAvailability(dateSelected)
      .then((availability) => {
        setAdminAvalability(availability.data);
      })
      .catch((err) => {
        console.warn('err', err);
        setAlertType('alert-danger');
        setIsShowAlert(err?.response?.data?.message || 'Unable to fetch Slots');
        setAdminAvalability(['Slots Not Available']);
        // toast.error('Unable to fetch.');
      });
  };

  const handleDateClick = async (selected) => {
    setSelectedDate(selected);
    setSelectedTimeSlot('');
    setBookingListTab(true);
    setSelectedDateEvent(
      currentEvents?.filter((item) => new Date(item?.start).getDate() === new Date(selected?.startStr).getDate())
    );
    if (isPatientView) {
      getAdminAvailability(selected?.startStr);
    }
    // } else {
    //   setOpenCreateEventModal(true);
    // }
  };

  const handleEventClick = (selected) => {
    setSelectedDate(selected);
    setSelectedEvent(selected);
    setOpenEditEventModal(true);
  };

  const closeCreateEventModal = () => {
    setOpenCreateEventModal(false);
  };

  const closeEditEventModal = () => {
    setOpenEditEventModal(false);
  };

  useEffect(() => {
    let todayDate = new Date();
    if (isPatientView) {
      getAdminAvailability(todayDate);
    }
    getMeetings();
  }, [id]);

  const handleTimeSlots = (timeslot) => {
    setSelectedTimeSlot(timeslot);
  };

  const handleCreateMeeting = () => {
    if (isLoading) {
      return;
    }
    if (!selectedDate?.startStr || !selectedTimeSlot) {
      setAlertType('alert-danger');
      setIsShowAlert('Select all the fields');
      return;
    }
    setIsLoading(true);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let params = { timezone, from_time: addTimeInDate(selectedDate?.startStr, selectedTimeSlot) };
    ApiClient.createZohoBooking(params, id)
      .then((res) => {
        getAdminAvailability(selectedDate?.startStr);
        setAlertType('alert-success');
        setIsShowAlert('Meeting Created Successfully');
        getMeetings();
        setBookingListTab(true);
        setSelectedTimeSlot('');
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('err', err);
        setAlertType('alert-danger');
        setIsShowAlert(err?.response?.data?.message || 'Unable to create! Try again');
        // toast.error('Unable to fetch.');
      });
  };

  const handleUpdateMeeting = () => {
    if (isLoading) {
      return;
    }
    if (!selectedDate?.startStr || !selectedTimeSlot) {
      setAlertType('alert-danger');
      setIsShowAlert('Select all the fields');
      return;
    }
    setIsLoading(true);
    let params = { from_time: addTimeInDate(selectedDate?.startStr, selectedTimeSlot) };
    let editBookingID = editBookingData?.id.replace('#', '');
    ApiClient.updateZohoBooking(params, editBookingID)
      .then((res) => {
        getAdminAvailability(selectedDate?.startStr);
        setAlertType('alert-success');
        setIsShowAlert('Meeting Resheduled Successfully');
        getMeetings();
        setBookingListTab(true);
        setSelectedTimeSlot('');
        setIsLoading(false);
        console.warn('res', res.data);
      })
      .catch((err) => {
        setAlertType('alert-danger');
        setIsShowAlert(err?.response?.data?.message || 'Unable to Reshedule! Try again');
        console.warn('err', err);
        setIsLoading(false);
      });
  };

  const handleEditClick = (item) => {
    setEditBookingData(item);
    setBookingListTab(false);
  };

  return (
    <>
      <div className='calendar-Part card'>
        <div className='zoho-calendar-box'>
          <div className='cardbody'>
            {/* <h5 className='mt-4'>Zoho Calendar</h5> */}
            <div className={'calendarDiv zoho-patient-calendar'}>
              <FullCalendar
                height={'50vh'}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin]}
                headerToolbar={
                  // showBookings
                  //   ?
                  {
                    left: 'title',
                    right: 'prev,next',
                  }
                  // : {
                  //     left: 'title, prev,next',
                  //     right: isPatientView
                  //       ? 'dayGridMonth,listMonth'
                  //       : 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
                  //   }
                }
                initialView='dayGridMonth'
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                select={handleDateClick}
                eventClick={handleEventClick}
                initialEvents={currentEvents}
                events={currentEvents}
              />
            </div>
          </div>
          {/* {showBookings ? ( */}
          <div className='zoho-calendar-content mt-3'>
            {isPatientView && (
              <div className='d-flex justify-content-center gap-10 zoho-tab mb-4'>
                <Button
                  onClick={() => {
                    setEditBookingData({});
                    setBookingListTab(true);
                  }}
                  appearance={bookingListTab ? 'primary' : 'default'}
                >
                  Booking List
                </Button>
                <Button
                  onClick={() => {
                    setEditBookingData({});
                    setBookingListTab(false);
                  }}
                  appearance={!bookingListTab ? 'primary' : 'default'}
                >
                  Available Slots
                </Button>
              </div>
            )}
            <AlertMessage show={isShowAlert} isShowAlert={setIsShowAlert} type={alertType} />
            {/* {(bookingListTab && selectedDateEvent?.length) ||
            (!bookingListTab && adminAvailability?.length && adminAvailability[0] != 'Slots Not Available') ? ( */}
            <div className='zoho-date text-start'>
              <strong>{dateFormat(selectedDate?.startStr || new Date())}</strong>
            </div>
            {/* ) : null} */}
            {!bookingListTab && adminAvailability?.length && adminAvailability[0] != 'Slots Not Available' ? (
              <div className='mb-2 mt-2 text-start'>
                <b>Select Timeslot for {editBookingData?.id ? 'Reshedule' : 'Create'} Booking</b>
              </div>
            ) : null}
            {bookingListTab ? (
              <div className='mt-3 meetings-list'>
                {selectedDateEvent?.length ? (
                  selectedDateEvent?.map((item, index) => {
                    return (
                      <div className='meetings-box'>
                        <a target='_blank' href={item.join_link}>
                          <span className='material-icons-outlined'>fiber_manual_record</span> {timeFormat(item?.start)}
                          {' - '}
                          {timeFormat(item?.end)} {item.title}
                        </a>
                        {item?.editable && isPatientView ? (
                          <span onClick={() => handleEditClick(item)} className='material-icons-outlined edit-btn'>
                            edit
                          </span>
                        ) : null}
                      </div>
                    );
                  })
                ) : (
                  <div className='text-center mb-4'>Bookings Not Available</div>
                )}
                {/* <Button className='zoho-list-more'>+ 31 More</Button> */}
              </div>
            ) : (
              <>
                <div className='mt-3 availabel-slots gap-10 '>
                  {adminAvailability?.length && adminAvailability[0] != 'Slots Not Available' ? (
                    <>
                      {adminAvailability?.map((item, index) => {
                        return (
                          <button
                            value={item}
                            onClick={() => handleTimeSlots(item)}
                            key={index}
                            type='button'
                            className={selectedTimeSlot == item ? 'selected-timeslot ms-3 mb-1 mt-2' : 'ms-3 mb-1 mt-2'}
                          >
                            {item}
                          </button>
                        );
                      })}
                    </>
                  ) : (
                    <div className='text-center mb-4'>Slots Not Available</div>
                  )}
                </div>
                {adminAvailability?.length && adminAvailability[0] != 'Slots Not Available' ? (
                  <CoreButton
                    title={isLoading ? <Loader show={isLoading} /> : editBookingData?.id ? 'Update' : 'Save'}
                    onClick={editBookingData?.id ? handleUpdateMeeting : handleCreateMeeting}
                  />
                ) : null}
              </>
            )}
          </div>
        </div>
        {/* ) : null} */}
      </div>
      <CreateMeetingModal open={openCreateEventModal} handleClose={closeCreateEventModal} selectedDate={selectedDate} />
      <EditMeetingModal
        open={openEditEventModal}
        handleClose={closeEditEventModal}
        selectedDate={selectedDate}
        event={selectedEvent}
      />
    </>
  );
};
