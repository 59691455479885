import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddTeleConsultationForm from 'whealth-core-web/forms/AddTeleConsultationForm';
import CommonAlert from '../CommonAlert';
import { profileImage } from '../Helper';
import Loader from '../Loader';

function AddTeleConsultation() {
  let userDetails = localStorage.getItem('userDetails');
  const ApiClient = useApiManager();
  const navigation = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [consultData, setConsultData] = useState({
    title: '',
    consult_datetime: null,
  });
  userDetails = JSON.parse(userDetails);
  const { id, pid } = useParams();
  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + ' ' + 'Tele Consultation', link: '' },
  ];

  const createConsultation = () => {
    setIsLoading(true);
    setErrors({});
    ApiClient.teleConsultationCreate(consultData, id)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Tele Consultation Created Successfully');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err?.response?.data?.message) {
          setErrors(err?.response?.data?.message);
        } else {
          setErrors(err?.response?.data?.errors);
        }
      });
  };

  const showPatient = () => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>

        <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Tele Consultation</div>
        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}

        <AddTeleConsultationForm
          patientId={id}
          errors={errors}
          data={consultData}
          setData={setConsultData}
          onClick={createConsultation}
        />
      </div>
    </>
  );
  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {showPatient()}
      <Loader show={isLoading} />
    </div>
  );
}

export default AddTeleConsultation;
