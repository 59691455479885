import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AddDietplanForm from 'whealth-core-web/forms/AddDietplanForm';
import CommonAlert from '../CommonAlert';
import { profileImage } from '../Helper';
import Loader from '../Loader';

function AddDietPlan() {
  const [isLoading, setIsLoading] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [dietPlan, setDietPlan] = useState('');
  const [errors, setErrors] = useState({ diet_plans: [] });
  const [templateList, setTemplateList] = useState([]);
  const [templateValue, setTemplateValue] = useState();
  const [searchTemplateList, setSearchTemplateList] = useState([]);
  const [dietTemplate, setDietTemplate] = useState({});

  const [isShowAlert, setIsShowAlert] = useState(false);
  const { id, pid } = useParams();
  const ApiClient = useApiManager();
  const navigate = useNavigate();

  useEffect(() => {
    if (pid) {
      getDietPlanData();
    }
  }, [pid]);

  useEffect(() => {
    getDietTemplatesList();
  }, []);

  useEffect(() => {
    if (!pid && templateValue) {
      getTemplateQuestion();
    }
  }, [templateValue]);

  const searchDietTemplate = (data) => {
    setSearchTemplateList([]);
    if (data?.length) {
      ApiClient.searchDietTemplateList({ search_str: data })
        .then((res) => {
          setSearchTemplateList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getDietTemplatesList = () => {
    setIsLoading(true);
    ApiClient.getDietTemplateList()
      .then((res) => {
        setTemplateList(res.data);
        let defaultTemplate = res.data.findIndex((item) => item.default);
        if (defaultTemplate != -1 && !pid) {
          setTemplateValue(res.data[defaultTemplate].id);
        }

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getTemplateQuestion = () => {
    setIsLoading(true);
    ApiClient.getDietTemplate(templateValue)
      .then((res) => {
        let updateDietData = res.data;
        setDietTemplate({
          id: updateDietData.id,
          title: updateDietData.title,
          guidelines: updateDietData.guidelines,
          default: updateDietData.default,
          diet_plans: updateDietData.diet_plan_day_parts.map((dayPart) => {
            return {
              day_part_id: dayPart.day_part.id,
              notification_time: dayPart.notification_time,
              recipe_link: dayPart.recipe_link,
              diet_plan_food_items: dayPart.diet_plan_food_items.map((foodItem) => {
                return {
                  food: foodItem.food,
                  quantity: foodItem.quantity,
                  recipe_link: foodItem.recipe_link,
                  twins: foodItem.twins,
                };
              }),
            };
          }),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getDietPlanData = () => {
    setIsLoading(true);
    ApiClient.getDietPlanTemplateList(id, false, pid)
      .then((res) => {
        let updateDietData = res.data;
        setDietTemplate({
          id: updateDietData.id,
          title: updateDietData.title,
          guidelines: updateDietData.guidelines,
          default: updateDietData.default,
          diet_plans: updateDietData.diet_plan_day_parts.map((dayPart) => {
            return {
              day_part_id: dayPart.day_part.id,
              notification_time: dayPart.notification_time,
              recipe_link: dayPart.recipe_link,
              diet_plan_food_items: dayPart.diet_plan_food_items.map((foodItem) => {
                return {
                  food: foodItem.food,
                  quantity: foodItem.quantity,
                  recipe_link: foodItem.recipe_link,
                  twins: foodItem.twins,
                };
              }),
            };
          }),
        });
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const modifyParams = (data) => {
    let newData = { ...data };
    data.diet_plans.map((item, index) => {
      if (typeof item.day_part_id == 'object') {
        item.day_part_id = item.day_part.id;
      }
    });

    return newData;
  };

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);
  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + 'Diet Plan', link: '' },
  ];
  const showPatient = () => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>

        <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Diet Plan</div>
        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}

        <AddDietplanForm
          errors={errors}
          setErrors={setErrors}
          dietTemplate={dietTemplate}
          setDietTemplate={setDietTemplate}
          templateList={templateList}
          setTemplateList={setTemplateList}
          templateValue={templateValue}
          setTemplateValue={setTemplateValue}
          searchTemplateList={searchTemplateList}
          setSearchTemplateList={setSearchTemplateList}
          searchDietTemplate={searchDietTemplate}
          setIsLoading={setIsLoading}
          pid={pid}
          id={id}
        />
      </div>
    </>
  );
  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {showPatient()}
      <Loader show={isLoading} />
    </div>
  );
}

export default AddDietPlan;
