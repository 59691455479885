import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CoreButton, CoreDropDown, CoreInputBox, RenderCheckBoxes, Select2DropDown } from 'whealth-core-web/components';
import CommonDayPart from 'whealth-core-web/components/DayPart/CommonDayPart';

function AddGoalForm(props) {
  const { id, goalid } = useParams();
  const { valueObj, error, isUpdate, setValueObj, isLoading, setIsLoading, onClick } = props;
  const ApiClient = useApiManager();
  const [goalsData, setGoalsData] = useState({});
  const [measuringUnit, setMeasuringUnit] = useState('');
  const location = useLocation();
  const [searchData, setSearchData] = useState({});
  const [goalTitleSearch, setGoalTitleSearch] = useState('');
  console.warn('location', location.state.goalEnable);
  const [suggestionList, setSuggestionList] = useState([]);
  const [selectedCatObj, setSelectedCatObj] = useState({});
  const isMedicineGoal = !!goalsData?.goal?.medicinal_goal;
  const navigate = useNavigate();
  useEffect(() => {
    if (Object.keys(searchData).length > 0 && Object.keys(goalsData).length > 0) {
      if (searchData.id != goalsData?.goal?.id) {
        searchData.goal_id = searchData.id;
        setValueObj({ ...searchData });
        setMeasuringUnit(searchData?.measuring_event?.measuring_unit);
      } else {
        goalsData.goal_id = goalsData?.goal?.id;
        setValueObj({ ...goalsData });
        setMeasuringUnit(goalsData?.goal?.measuring_event?.measuring_unit);
      }
    } else if (Object.keys(searchData).length > 0) {
      searchData.goal_id = searchData.id;
      setValueObj({ ...searchData });
      setMeasuringUnit(searchData?.measuring_event?.measuring_unit);
    } else {
      searchData.goal_id = '';
      setValueObj({ ...searchData });
      setMeasuringUnit('');
    }
  }, [searchData]);

  useEffect(() => {
    if (Object.keys(goalsData).length > 0) {
      goalsData.goal_id = goalsData.goal?.id;
      setValueObj({ ...goalsData });
      setMeasuringUnit(goalsData?.goal?.measuring_event?.measuring_unit);
    }
  }, [goalsData]);

  const typeArray = [
    { title: 'Daily', id: 'daily' },
    { title: 'Weekly', id: 'weekly' },
    { title: 'Monthly', id: 'monthly' },
    { title: 'Yearly', id: 'yearly' },
  ];

  useEffect(() => {
    handleSetSuggestionList();
  }, []);

  useEffect(() => {
    if (goalid) {
      getPatientGoals();
    }
  }, [goalid]);

  const renderDuration = () => {
    if (isMedicineGoal) {
      return (
        <div className='w-100 ' style={{ margin: '20px 0 ' }}>
          <CoreInputBox
            disabled={goalsData.status == 'enable'}
            data={goalsData}
            showMSG={error?.duration}
            value={valueObj?.duration || ''}
            placeholder='Duration'
            label='Duration'
            validateNumbers
            setValue={(data) => {
              setValueObj({ ...valueObj, duration: data });
            }}
            maxLength={255}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
      );
    } else {
      return;
    }
  };

  const renderValuefield = () => {
    if (isMedicineGoal) {
      return (
        <div className='w-100 ' style={{ margin: '20px 0 ' }}>
          <div className='d-flex'>
            <span className='requiredInput me-1'> * </span>
            <div className='mb-2'>Value</div>
          </div>
          <RenderCheckBoxes
            data={{
              value: valueObj.value,
              targetValue: '111',
              returnValue: valueObj,
              setreturnValue: setValueObj,
              enable: true,
              disabled: goalsData.status == 'enable',
            }}
          />
          {error?.value && <div className='text-danger'>{error?.value}</div>}
        </div>
      );
    } else {
      return (
        <div className='w-100 d-flex' style={{ margin: '20px 0 ' }}>
          <div className='w-100'>
            <CoreInputBox
              validateNumbers
              // disabled={goalsData.status == 'enable'}
              inputStyle={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              data={goalsData}
              showMSG={error?.value}
              value={valueObj?.value || ''}
              placeholder='Value'
              label='Value'
              setValue={(data) => {
                setValueObj({ ...valueObj, value: data });
              }}
              maxLength={255}
              msgStyle={{ color: 'red' }}
              retuired
            />
          </div>
          <div className='mt-4'>
            <div className='unitBox capitalize'>{measuringUnit ? measuringUnit : 'Unit'}</div>
          </div>
        </div>
      );
    }
  };

  const renderTitle = (item) => {
    if (item.is_assigned) {
      item.title = (
        <div className='d-flex justify-content-start align-items-center w-100'>
          {item.title}{' '}
          <div className='d-flex justify-content-start ms-3'>
            <span className='badge capitalize lightGrayBadge'>{item.is_assigned && 'Assigned'}</span>
            <span className='badge capitalize enable ms-2'>{item.patient_goal_status}</span>
          </div>
        </div>
      );
    }
  };

  const handleSetSuggestionList = () => {
    ApiClient.getGoals({ page: 1, user_id: id, is_disabled: false, page_size: 5 })
      .then((res) => {
        setSuggestionList(res.data);
        let tmpData = res.data;
        tmpData.map((item) => {
          return renderTitle(item);
        });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getPatientGoals = () => {
    setIsLoading(true);
    ApiClient.getPatientgoals(id, goalid)
      .then((res) => {
        setGoalsData(res.data);
        setIsLoading(false);
        if (res?.data?.goal) {
          setSelectedCatObj(res?.data?.goal);
          if (res?.data?.goal.is_assigned) {
            return renderTitle(res?.data?.goal);
          }
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const searchGoals = (search) => {
    const params = { search_str: search, user_id: id, is_disabled: false };
    setGoalTitleSearch(search);
    if (search?.length > 2) {
      setIsLoading(true);
      ApiClient.searchGoals(params)
        .then((res) => {
          setIsLoading(false);
          let tmpData = res.data;

          tmpData.map((item) => {
            if (item.is_assigned) {
              return renderTitle(item);
            }
          });
          setSuggestionList(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    } else {
      handleSetSuggestionList();
    }
  };
  console.warn('searchData', searchData);
  return (
    <div className='measuringForm'>
      <div style={{ display: 'flex', flexDirection: 'column' }} className='d-flex w-100 flexmeasuringForm'>
        <div className='w-100'>
          <div className='d-flex'>
            <span className='requiredInput me-1'> * </span>
            <div className='mb-2'>Goal Name</div>
          </div>
          <div>
            <Select2DropDown
              isValidation
              disabled={(isMedicineGoal || goalsData.status == 'enable') && !location.state.goalEnable}
              onSearch={(search) => searchGoals(search)}
              placement='auto'
              data={
                !suggestionList.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id
                  ? [...suggestionList, selectedCatObj]
                  : suggestionList
              }
              value={valueObj.goal_id}
              labelKey='title'
              valueKey='id'
              placeholder={'Search Goal'}
              getObjData
              retuired
              onSelect={(data, dataObj) => {
                console.warn('data', dataObj);
                if (dataObj.is_assigned)
                  navigate(`/patient/${id}/goals/${dataObj.patient_goal_id}/edit`, {
                    state: {
                      goalEnable: 'true',
                    },
                  });
                if (dataObj.id) {
                  setSearchData({ ...dataObj });
                }
                setSelectedCatObj(dataObj);
                setValueObj({ ...valueObj, goal_id: data });
              }}
            />
          </div>
          {error?.goal_id && (
            <div className='inputMsg' style={{ color: 'var(--dustRed)' }}>
              {error?.goal_id}
            </div>
          )}
        </div>
      </div>

      <div className='d-md-flex  ' style={{ gap: '15px' }}>
        {valueObj?.goal?.type != 'logging' && searchData.type != 'logging' && renderValuefield()}

        <div
          className={`w-100 ${goalsData.status == 'enable' ? 'frequency_cursor' : ''}`}
          style={{ margin: '20px 0 ' }}
        >
          <CoreDropDown
            disabled={goalsData.status == 'enable' || valueObj?.is_assigned == true}
            data={typeArray}
            showMSG={error?.frequency}
            placeholder='Frequency'
            value={valueObj?.frequency || ''}
            label='Frequency'
            setValue={(data) => {
              setValueObj({ ...valueObj, frequency: data });
            }}
            retuired
            msgStyle={{ color: 'red' }}
          />
        </div>
      </div>

      {renderDuration()}

      <div style={{ margin: '0 0 20px 0' }}>
        <CoreInputBox
          data={goalsData}
          showMSG={error?.note}
          placeholder='Note'
          value={valueObj?.note || ''}
          label='Note'
          setValue={(data) => {
            setValueObj({ ...valueObj, note: data });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>

      <CommonDayPart valueObj={valueObj} setValueObj={setValueObj} error={error} />

      <CoreButton onClick={onClick} title={goalid ? 'Update details' : 'Save Details'} />
    </div>
  );
}

export default AddGoalForm;
