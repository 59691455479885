import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { DatePicker } from 'rsuite';
import { AssignteamCard, CoreButton, CoreInputBox, Select2DropDown } from 'whealth-core-web/components';
import SearchBox from 'whealth-core-web/components/common/SearchBox';
import CommonRenderCheckBox from 'whealth-core-web/components/CommonRenderCheckBox';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { MobileDateTimePicker } from '@mui/x-date-pickers';
import CurrencyInput from 'react-currency-input-field';

function AddConsultationForm(props) {
  const { consultationId } = useParams();
  const ApiClient = useApiManager();
  let { data, setData, onClick, errors, setPrescriptionCheckBox, isNivanCare } = props;
  const getCockpitData = useGetCockpitDataQuery();
  const consultLocation = useLocation();

  let configData = getCockpitData?.data?.data?.business_config ? getCockpitData?.data?.data?.business_config : [];

  const [clinicList, setClinicList] = useState([]);
  const [paymentCockpit, setPaymentCockpit] = useState(false);
  const [searchClinicList, setSearchClinicList] = useState([]);
  const [followUp, setFollowUp] = useState(false);
  const [patientAttandenceStatus, setPatientAttandenceStatus] = useState('');
  const [searchData, setSearchData] = useState([]);
  const [selectedCatObj, setSelectedCatObj] = useState({});
  const [btn, setBtn] = useState('');
  const [isPMSDoctor, setIsPMSDoctor] = useState(false);
  const [billedTo, setBilledTo] = useState('');
  const minDate = dayjs('2023-01-01');
  const [appointmentFeeError, setAppointmentFeeError] = useState(false);

  useEffect(() => {
    configData?.map((item) => {
      if (item.key == 'enable_payment_from' && item.isActive) setPaymentCockpit(true);
    });
  }, [configData, consultationId]);
  const getPatientList = async () => {
    try {
      const res = await ApiClient.getPatients();
      if (res.status == 200) {
        let temp = res?.data;
        temp?.map((item) => {
          if (item.id == data?.user?.id) {
            setBilledTo(item?.full_name);
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    // getPatientList();
    setBilledTo(data?.user?.full_name);
  }, [data?.payment_with_type]);
  useEffect(() => {
    ApiClient.getLocations(1).then((res) => {
      let clinicData = res.data;
      setClinicList([...clinicData, ...clinicList]);
    });
  }, []);

  useEffect(() => {
    searchAdminProfiles();
  }, []);

  useEffect(() => {
    addExtraInList(data?.location);
  }, [data?.location]);
  useEffect(() => {
    setIsPMSDoctor(data?.consultant?.department?.name === 'PMS');
  }, [data?.consultant]);
  const addExtraInList = (extraObj, oldArr = clinicList) => {
    if (data?.location?.id && oldArr.findIndex((item2) => item2.id == extraObj.id) == -1) {
      oldArr.push(extraObj);
      setClinicList([...oldArr]);
    }
  };

  const searchClinic = (search_str) => {
    if (search_str?.length > 2) {
      ApiClient.searchLocations(search_str).then((res) => {
        setSearchClinicList(res.data);
      });
    } else {
      setSearchClinicList([]);
    }
  };

  let consultationType = [];
  let paymentTypes = [{ title: 'Free', id: 'free' }];
  configData.forEach((element) => {
    if (element.key == 'physical_consult_type' && (element.isActive || data?.consult_type == 'physical')) {
      consultationType.push({ title: 'Physical', id: 'physical' });
    }
    if (element.key == 'virtual_consult_type' && (element.isActive || data?.consult_type == 'virtual')) {
      consultationType.push({ title: 'Virtual', id: 'virtual' });
    }
    if (element.key == 'paid_payment_method' && (element.isActive || data.payment_type == 'paid')) {
      paymentTypes.push({ title: 'Paid', id: 'paid' });
      if (isNivanCare) paymentTypes.push({ title: 'Partially Paid', id: 'partially_paid' });
    }
  });
  const service_list = [
    { id: 'consult', title: 'Consult' },
    { id: 'procedure', title: 'Procedure' },
    { id: 'break', title: 'Break' },
    { id: 'CRP_sessions', title: 'CRP Sessions' },
    { id: 'followup', title: 'Follow Up' },
  ];
  const searchAdminProfiles = (query) => {
    let qry = {
      role: 'doctor,health_coach,health_coach_lead,diet_health_coach,fitness_health_coach',
      remove_inactive_admins: true,
    };
    if (query?.length) {
      qry.search_str = query;
    }
    ApiClient.getSearchTeam(qry)
      .then((res) => {
        setSearchData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleClinicNameChange = (withType) => {
    if (clinicList?.length < 1) setData({ ...data, payment_with_type: withType });
    if (withType === 'clinic') {
      if (!data?.location_id) {
        setData({ ...data, payment_with_type: withType });
      }
      clinicList?.map((item) => {
        if (item?.id == data?.location_id) {
          // setClinicName(item?.name);
          setData({ ...data, payment_with: item?.name, payment_with_type: withType });
        }
      });
    } else setData({ ...data, payment_with: '', payment_with_type: withType });
  };
  const renderSearchBox = (data, setData) => {
    const handleClick = (id, obj) => {
      setData({ ...data, consultant_id: id });
      setSelectedCatObj(obj);
      setIsPMSDoctor(obj?.department?.name === 'PMS');
    };

    return (
      <>
        <div>
          <Select2DropDown
            disabled={
              (consultationId && consultLocation.state.check_in) ||
              (consultationId && data.patient_attendance_status == 'show')
            }
            isValidation
            onSearch={searchAdminProfiles}
            showMSG={errors?.consultant_id}
            label='Consulting Healthcare Specialist'
            data={
              !searchData.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id
                ? [...searchData, selectedCatObj]
                : searchData
            }
            style={{ width: '100%' }}
            labelKey='full_name'
            valueKey='id'
            placeholder='Consulting Healthcare Specialist'
            value={data.consultant_id || ''}
            onSelect={handleClick}
            msgStyle={{ color: 'red' }}
            retuired
          />
          {isNivanCare && data?.consultant_id && (
            <Select2DropDown
              disabled={
                (consultationId && consultLocation.state.check_in) ||
                (consultationId && data.patient_attendance_status == 'show')
              }
              placeholder='Select Service'
              label='Service'
              retuired={false}
              data={isPMSDoctor ? service_list : service_list.filter((item) => item.id != 'procedure')}
              labelStyle={{ fontWeight: 'var(--lightFont)', margin: '4px 0 4px 0' }}
              onSelect={(currData) => {
                setData({ ...data, service: currData });
              }}
              value={data?.service}
              msgStyle={{ color: 'red' }}
              onClean={() => {
                setData({ ...data, service: '' });
              }}
            />
          )}
        </div>
      </>
    );
  };

  return (
    <div className='consultation-form mb-4'>
      <div className='w-100 p-2'>
        <CoreInputBox
          disabled={
            (consultationId && consultLocation.state.check_in) ||
            (consultationId && data.patient_attendance_status == 'show')
          }
          validSpecialChar
          label={'Title'}
          value={data.title || ''}
          setValue={(val) => setData({ ...data, title: val })}
          retuired
          showMSG={errors.title}
          placeholder={'Title'}
        />
      </div>
      <div className='w-100 p-2'>
        <Select2DropDown
          disabled={
            (consultationId && consultLocation.state.check_in) ||
            (consultationId && data.patient_attendance_status == 'show')
          }
          retuired
          label={'Consultation Type'}
          value={data.consult_type || ''}
          data={consultationType}
          showMSG={errors.consult_type}
          onSelect={(val) =>
            setData({
              ...data,
              consult_type: val == '' ? null : val,
              location_id: null,
              meeting_link: '',
            })
          }
        />
      </div>
      {data.consult_type == 'physical' ? (
        <div className='w-100 p-2'>
          <Select2DropDown
            disabled={
              (consultationId && consultLocation.state.check_in) ||
              (consultationId && data.patient_attendance_status == 'show')
            }
            retuired
            label={`${isNivanCare ? 'Hospital' : 'Clinic'}`}
            value={data.location_id || ''}
            data={searchClinicList?.length ? searchClinicList : clinicList}
            isValidation
            onSearch={(val) => searchClinic(val)}
            labelKey={'name'}
            showMSG={errors.location_id}
            onSelect={(val, obj) => {
              if (clinicList.findIndex((check) => check.id == obj.id) == -1 && val != '') {
                clinicList.push(obj);
                setClinicList([...clinicList]);
              }
              setData({ ...data, location_id: val == '' ? null : val });
            }}
          />
        </div>
      ) : null}
      {data.consult_type == 'virtual' ? (
        <div className='w-100 p-2'>
          <CoreInputBox
            disabled={consultationId && data.patient_attendance_status == 'show'}
            label={'Meeting Link'}
            placeholder={'Meeting Link'}
            value={data.meeting_link || ''}
            showMSG={errors.meeting_link}
            setValue={(val) => setData({ ...data, meeting_link: val })}
          />
        </div>
      ) : null}
      <div className='w-100 p-2'>
        <div className='dropDownItemCustom'>{renderSearchBox(data, setData)}</div>
      </div>
      <div className='p-2 '>
        <label>
          <span class='requiredInput'> * </span>Consult Date and time{' '}
        </label>
        <div>
          {/* <DatePicker
            disabledDate={(date) => {
              if (new Date(date) < new Date().setHours(0, 0, 0)) {
                return true;
              } else {
                return false;
              }
            }}
            disabled={consultationId && consultLocation.state.check_in}
            placement={'bottomStart'}
            className='w-100'
            format='dd-MM-yyyy HH:mm'
            placeholder={'Consult Date and time'}
            value={data.consult_datetime ? new Date(data.consult_datetime) : null}
            onChange={(res) => {
              setData({
                ...data,
                consult_datetime: new Date(res).toISOString(),
              });
            }}
            onClean={() => setData({ ...data, consult_datetime: null })}
          /> */}
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDateTimePicker
              renderInput={(props) => <TextField {...props} />}
              // disablePast
              disabled={
                (consultationId && consultLocation.state.check_in) ||
                (consultationId && data.patient_attendance_status == 'show')
              }
              minDate={minDate}
              value={data.consult_datetime ? dayjs(data.consult_datetime) : null}
              onChange={(res) => {
                setData({
                  ...data,
                  consult_datetime: res ? res.toISOString() : null,
                });
              }}
              // MobileDatePickerinput
              onClear={() => setData({ ...data, consult_datetime: null })}
              format='DD/MM/YYYY hh:mm A'
              className={`${
                (consultationId && consultLocation?.state?.check_in) ||
                (consultationId && data.patient_attendance_status == 'show')
                  ? 'MobileDatePickerinput'
                  : 'MobileDatePickerinputCommon'
              }`}
              // label='Consult Date and time'
              sx={{
                width: '100%',
                '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                  padding: '9.5px 14px',
                  borderRadius: '10px',
                },
                '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                  ...((consultationId && consultLocation?.state?.check_in) ||
                  (consultationId && data.patient_attendance_status == 'show')
                    ? {
                        padding: '10px 14px',
                        backgroundColor: '#e9ecef',
                        fontSize: '14px',
                        borderRadius: '10px',
                      }
                    : {
                        padding: '8.5px 14px',
                      }),
                },
                '& .MuiInputLabel-root.Mui-focused': { color: '#d9dee2' },
                '& .MuiOutlinedInput-root': {
                  '&:hover > fieldset': { borderColor: '#d9dee2' },
                  height: '42px',
                  borderRadius: '10px',
                },
                '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled':
                  {
                    '-webkit-text-fill-color': '#1a2128',
                  },
                '&css-1x5jdmq-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled':
                  {
                    '-webkit-text-fill-color': '#1a2128',
                  },
                '& .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#d9dee2',
                },
              }}
            />
          </LocalizationProvider>
        </div>
        {errors?.consult_datetime ? <div class='inputMsg'>{errors?.consult_datetime}</div> : null}
      </div>
      {isNivanCare && (
        <div className='w-100 p-2'>
          <label htmlFor='validationCustom02 mt-3 slot-name editorLabelStyle'>
            <span class='requiredInput'> * </span>Appointment Fee
          </label>
          <CurrencyInput
            // maxLength={5}
            id='validationCustom02'
            name='input-2'
            className={`form-control`}
            label='Appointment Fee'
            value={data?.appointment_fee || ''}
            msgStyle={{ color: 'red' }}
            onValueChange={(val) => setData({ ...data, appointment_fee: val })}
            placeholder=''
            prefix={'₹'}
            showMSG={errors?.appointment_fee}
            step={1}
            disabled={
              (consultationId && consultLocation.state.check_in) ||
              (consultationId && data.patient_attendance_status == 'show')
            }
          />
          {appointmentFeeError && isNivanCare && <p style={{ color: 'red' }}>{'Appointment fee is mandatory'}</p>}

          <p style={{ color: 'red' }}>{errors?.appointment_fee}</p>
        </div>
      )}
      {consultationId && consultLocation.state.check_in && (
        <div className='w-100 p-2'>
          <Select2DropDown
            retuired
            label={'Patient Attendance'}
            data={[
              { title: 'Show', id: 'show' },
              { title: 'No Show', id: 'no_show' },
            ]}
            showMSG={errors.patient_attendance_status}
            value={data.patient_attendance_status || ''}
            onSelect={(val) => {
              setPatientAttandenceStatus(val);
              setData({
                ...data,
                patient_attendance_status: val,
                payment_type: '',
                payment_mode: '',
                amount: null,
                payment_from: '',
                payment_with: '',
              });
            }}
          />
        </div>
      )}

      {consultationId &&
        consultLocation.state.check_in &&
        (patientAttandenceStatus == 'show' || data.patient_attendance_status == 'show') && (
          <div className='w-100 p-2'>
            <Select2DropDown
              retuired
              label={'Payment Type'}
              data={paymentTypes}
              showMSG={errors.payment_type}
              value={data.payment_type || ''}
              onSelect={(val) =>
                setData({
                  ...data,
                  payment_type: val,
                  payment_mode: '',
                  amount: isNivanCare ? (val === 'paid' ? data?.appointment_fee : null) : null,
                })
              }
            />
          </div>
        )}

      {consultationId && consultLocation.state.edit && data.patient_attendance_status == 'show' && (
        <div className='w-100 p-2'>
          <Select2DropDown
            label={'Payment Type'}
            retuired
            data={paymentTypes}
            showMSG={errors.payment_type}
            value={data.payment_type || ''}
            onSelect={(val) =>
              setData({
                ...data,
                payment_type: val,
                payment_mode: '',
                amount: isNivanCare ? (val === 'paid' ? data?.appointment_fee : null) : null,
              })
            }
          />
        </div>
      )}

      {data?.payment_type == 'paid' || (isNivanCare && data?.payment_type == 'partially_paid') ? (
        <>
          <div className='w-100 p-2'>
            <Select2DropDown
              retuired
              label={'Payment Mode'}
              data={[
                { title: 'Cash', id: 'cash' },
                { title: 'Online', id: 'online' },
              ]}
              showMSG={errors.payment_mode}
              value={data.payment_mode || ''}
              onSelect={(val) => setData({ ...data, payment_mode: val })}
            />
          </div>
          {consultationId && paymentCockpit && data.payment_mode == 'online' && (
            <div className='w-100 p-2'>
              {/* UPI, PoS, Payment Link, Bank Transfer */}
              <Select2DropDown
                retuired={!isNivanCare}
                showMSG={errors?.payment_from}
                label={'Payment From'}
                data={[
                  { title: 'UPI', id: 'upi' },
                  { title: 'PoS', id: 'pos' },
                  { title: 'Payment Link', id: 'payment_link' },
                  { title: 'Bank Transfer', id: 'bank_transfer' },
                ]}
                value={data.payment_from || ''}
                onSelect={(val) => setData({ ...data, payment_from: val })}
              />
            </div>
          )}
          {consultationId && paymentCockpit && data?.payment_mode && (
            <>
              <div className='w-100 p-2'>
                {!isNivanCare ? (
                  <CoreInputBox
                    retuired
                    showMSG={errors?.payment_with}
                    label={'Payment With'}
                    placeholder={'Payment With'}
                    value={data?.payment_with || ''}
                    setValue={(val) => setData({ ...data, payment_with: val })}
                  />
                ) : (
                  <Select2DropDown
                    data={[
                      {
                        id: 1,
                        title: 'care_manager',
                        label: 'Care Manager',
                      },
                      {
                        id: 2,
                        title: 'clinic',
                        label: `${isNivanCare ? 'Hospital' : 'Clinic'}`,
                      },
                      {
                        id: 3,
                        title: 'nivaan_clinic',
                        label: 'Nivaan Clinic',
                      },
                    ]}
                    labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                    className='slot-name'
                    placeholder={'Select'}
                    label={'Payment With'}
                    valueKey='title'
                    labelKey='label'
                    onSelect={(with_type) => {
                      // setConsultData({ ...consultData, payment_with_type: with_type });
                      handleClinicNameChange(with_type);
                    }}
                    onClean={() => {
                      setData({ ...data, payment_with_type: '' });
                    }}
                    value={data?.payment_with_type || ''}
                    // showMSG={errors?.payment_from}
                    // msgStyle={{ color: 'red' }}

                    // disabled={!isEditEnabled || consultData?.payment_type === 'free'}
                  />
                )}
              </div>
              {isNivanCare && data?.payment_with_type === 'care_manager' && (
                <div className='w-100 p-2'>
                  <Select2DropDown
                    data={[
                      {
                        id: 1,
                        label: 'Riya Kumari',
                      },
                      {
                        id: 2,
                        label: 'Nupur Lakhanpal',
                      },
                      {
                        id: 3,
                        label: 'Fardeen Mirza',
                      },
                    ]}
                    labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                    className='slot-name'
                    placeholder={'Select'}
                    label={'Care Manager'}
                    onSelect={(currData) => {
                      setData({ ...data, payment_with: currData });
                    }}
                    onClean={() => {
                      setData({ ...data, payment_with: '' });
                    }}
                    value={data?.payment_with}
                    // disabled={!isEditEnabled || consultData?.payment_type === 'free'}
                    showMSG={errors?.payment_with}
                    // msgStyle={{ color: 'red' }}
                    valueKey='label'
                    labelKey='label'
                  />
                </div>
              )}
            </>
          )}
          {!isNivanCare ? (
            <div className='w-100 p-2'>
              <CoreInputBox
                retuired
                validateNumbers
                validateZero
                validatedecimal
                label={'Amount'}
                placeholder={'Amount'}
                showMSG={errors.amount}
                value={data?.amount?.toString() || ''}
                setValue={(val) => setData({ ...data, amount: val != '' ? Number(val) : null })}
              />
            </div>
          ) : (
            <>
              <div className='w-100 p-2'>
                <label htmlFor='validationCustom01 mt-3 slot-name'>
                  <span style={{ color: 'red' }}>*&nbsp;</span>
                  Amount
                </label>
                <CurrencyInput
                  id='validationCustom01'
                  name='input-1'
                  className={`form-control`}
                  label='Amount'
                  value={data?.amount}
                  showMSG={errors?.amount}
                  msgStyle={{ color: 'red' }}
                  onValueChange={(values) => {
                    setData({ ...data, amount: values });
                  }}
                  placeholder=''
                  prefix={'₹'}
                  step={1}
                  disabled={data?.payment_type === 'free' || data?.payment_type === 'paid'}
                />
                <p style={{ color: 'red' }}>{errors.amount}</p>
              </div>
              {data?.payment_with_type && (
                <div className='w-100 p-2 d-flex'>
                  Billed to:&nbsp;
                  <div className={'text-capitalize'}>
                    {data?.payment_with_type === 'care_manager' ? billedTo : data?.payment_with}
                    {data?.payment_with_type === 'nivaan_clinic' && data.user.full_name}
                  </div>
                </div>
              )}
            </>
          )}
        </>
      ) : null}

      {/* {consultationId && (
        <div className='w-100 p-2'>
          <Select2DropDown
            label={'Consultation Status'}
            data={[
              { title: 'Active', id: 'active' },
              { title: 'Overdue', id: 'overdue' },
              { title: 'Closed', id: 'closed' },
            ]}
            value={data.consultation_status || ''}
            onSelect={(val) => setData({ ...data, consultation_status: val })}
          />
        </div>
      )} */}

      {consultationId && consultLocation.state.check_in && (
        <div className='w-100 p-2'>
          <div className='d-flex  form-check '>
            <input
              style={{ cursor: 'pointer' }}
              className='form-check-input'
              type='checkbox'
              value={''}
              id='flexCheckDefault'
              onChange={(event) => setFollowUp(event.target.checked)}
            />
            <label style={{ cursor: 'pointer' }} className='ms-2 form-check-label' htmlFor='flexCheckDefault'>
              Create follow-up consultation
            </label>
          </div>
        </div>
      )}

      {followUp == true && (
        <div className='p-2'>
          <label>
            <span class='requiredInput'> * </span>Create follow-up Consult Date and Time{' '}
          </label>
          <div className='w-100'>
            {/* <DatePicker
              disabledDate={(date) => {
                if (new Date(date) < new Date().setHours(0, 0, 0)) {
                  return true;
                } else {
                  return false;
                }
              }}
              placement={'bottomStart'}
              className='w-100'
              format='dd-MM-yyyy HH:mm'
              placeholder={'Create follow-up Consult Date and Time'}
              value={data.next_consultation_datetime ? new Date(data.next_consultation_datetime) : null}
              onChange={(res) => {
                setData({
                  ...data,
                  next_consultation_datetime: new Date(res).toISOString(),
                });
                setBtn(new Date(res).toISOString());
              }}
              onClean={() => setData({ ...data, next_consultation_datetime: null })}
            /> */}
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker
                renderInput={(props) => <TextField {...props} />}
                disablePast
                // value={data.consult_datetime ? dayjs(data.consult_datetime) : null}
                minDate={data.consult_datetime ? dayjs(data.consult_datetime) : null}
                placeholder={'Create follow-up Consult Date and Time'}
                onChange={(res) => {
                  setData({
                    ...data,
                    next_consultation_datetime: dayjs(res).toISOString(),
                  });
                  setBtn(dayjs(res).toISOString());
                }}
                onClear={() => setData({ ...data, consult_datetime: null })}
                format='DD/MM/YYYY hh:mm A'
                className='MobileDatePickerinputCommon'
                // label='Consult Date and time'
                // sx={{
                //   width: '100%',
                //   '& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input': {
                //     padding: '9.5px 14px',
                //     borderRadius: '10px',
                //   },
                //   '& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input': {
                //     padding: '8.5px 14px',
                //     fontSize: '14px',
                //     borderRadius: '10px',
                //   },
                //   '& .MuiInputLabel-root.Mui-focused': { color: '#d9dee2' },
                //   '& .MuiOutlinedInput-root': {
                //     '&:hover > fieldset': { borderColor: '#d9dee2' },
                //     height: '42px',
                //     borderRadius: '10px',
                //   },
                // }}
              />
            </LocalizationProvider>
          </div>
          {errors?.next_consultation_datetime ? <div class='inputMsg'>{errors?.next_consultation_datetime}</div> : null}
        </div>
      )}
      <div className='mb-4'>
        <button
          onClick={() => {
            if (!data?.appointment_fee && isNivanCare) {
              setAppointmentFeeError(true);
              return;
            }
            setAppointmentFeeError(false);
            onClick();
          }}
          disabled={(!btn || data.next_consultation_datetime == null) && followUp}
          type='button'
          className='form-control  coreBtn text-white'
        >
          {consultationId && consultLocation.state.check_in == 'check_in' ? 'Check-in' : 'Save'}
        </button>
      </div>
    </div>
  );
}

export default AddConsultationForm;
