import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import AddServiceForm from './AddServiceForm';
import { CommonAlert, Loader, Select2DropDown } from './components';
import { profileImage } from './components/Helper';

function AddService() {
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [patientDetails, setPatientDetails] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [serviceData, setServiceData] = useState([]);
  const [serviceDropdown, setServiceDropdown] = useState('');
  const [doctorDropdown, setDoctorDropdown] = useState('');
  const [careManagerDropdown, setCareManagerDropdown] = useState('');
  const [clinicDropdown, setClinicDropdown] = useState('');
  const [serviceCategoryValue, setServiceCategoryValue] = useState('');
  const [serviceCategoryData, setServiceCategoryData] = useState([]);
  const [serviceDoctorData, setServiceDoctorData] = useState([]);
  const [careManagersList, setCareManagersList] = useState([]);
  const [selectedCatObj, setSelectedCatObj] = useState({});
  const [serviceDropdownVal, setServiceDropdownVal] = useState('');
  const [statusValue, setStatusValue] = useState('scheduled');
  const [serviceSchemaData, setServiceSchemaData] = useState('');
  const [dynamicServiceData, setDynamicServiceData] = useState({});
  const locations = useSelector((state) => state.sliceReducer.locations);

  const ApiClient = useApiManager();
  const navigation = useNavigate();
  const { id, pid } = useParams();
  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  useEffect(() => {
    getServiceList();
    getServiceCategories(serviceDropdown);
    getServicesDoctor();
  }, []);

  useEffect(() => {
    setClinicDropdown('');
    setServiceSchemaData({});
    setDoctorDropdown('');
    setCareManagerDropdown('');
    if (dynamicServiceData?.service_schema) {
      setServiceSchemaData({ service_schema: dynamicServiceData?.service_schema });
    }
  }, [serviceDropdownVal]);

  useEffect(() => {
    if (pid) {
      getServiceData();
      getServicesDoctor(dynamicServiceData?.service?.id, dynamicServiceData?.location?.id);
      getServicesClinic(clinicDropdown);
    }
  }, [pid, dynamicServiceData?.service?.id, dynamicServiceData?.location?.id]);

  useEffect(() => {
    if (dynamicServiceData?.service?.id && !serviceDropdown) {
      setServiceDropdown(dynamicServiceData?.service?.id);
    }
    if (dynamicServiceData?.service_category?.id) {
      setServiceCategoryValue(dynamicServiceData?.service_category?.id);
    }
    if (dynamicServiceData?.location?.id && !serviceDropdown) {
      setClinicDropdown(dynamicServiceData?.location?.id);
    }
    if (dynamicServiceData?.doctor?.id && !serviceDropdown) {
      setDoctorDropdown(dynamicServiceData?.doctor?.id);
    }
    if (dynamicServiceData?.care_manager?.id && !serviceDropdown) {
      setCareManagerDropdown(dynamicServiceData?.care_manager?.id);
    }
    if (dynamicServiceData?.status) {
      setStatusValue(dynamicServiceData?.status);
    }

    if (dynamicServiceData?.data && !serviceDropdownVal) {
      setServiceSchemaData({ service_schema: dynamicServiceData?.data });
    }
  }, [dynamicServiceData?.data, dynamicServiceData?.service_category?.id, dynamicServiceData?.data]);

  const getServicesDoctor = (serviceId, locationId) => {
    setIsLoading(true);
    let params = { service_id: serviceId, location_id: locationId };
    ApiClient.getServiceDoctor()
      .then((res) => {
        setIsLoading(false);
        setServiceDoctorData(res.data);
        setErrors({});
      })
      .catch((err) => {
        setDoctorDropdown('');
        setIsLoading(false);
        setErrors(err.response.data);
        console.log(err);
      });
  };

  const getServicesDropdownSearch = (text) => {
    setIsLoading(true);
    ApiClient.getServiceDropdownSearch(text)
      .then((res) => {
        setIsLoading(false);
        setServiceData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getPatientData();
  }, []);

  const getServicesClinic = (HospitalId) => {
    setIsLoading(true);
    ApiClient.getCareManagersByClinic(HospitalId)
      .then((res) => {
        setIsLoading(false);
        setCareManagersList(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getServiceList = () => {
    setIsLoading(true);
    ApiClient.getAppointmentService(1, true)
      .then((res) => {
        setServiceData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getServiceCategories = (serviceId) => {
    setIsLoading(true);
    let params = { service_id: serviceId, enable: true };
    ApiClient.getServiceCategories(params)
      .then((res) => {
        setIsLoading(false);
        setServiceCategoryData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getServiceData = (prescription_id = pid) => {
    setIsLoading(true);
    ApiClient.singleDataService(id, prescription_id)
      .then((res) => {
        setIsLoading(false);
        if (!!Object.entries(res.data?.data).length) {
          let tmpData = res.data;
          // tmpData.service.service_schema = res.data.data;
          tmpData.isUpdate = true;
          tmpData.emptySchema = res.data.service;
          setDynamicServiceData(tmpData);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const serviceDataCreate = (data) => {
    setIsLoading(true);
    ApiClient.patientServiceCreate(data, id)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Service created successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
      });
  };

  const updateServiceData = (data) => {
    setIsLoading(true);
    ApiClient.updateServiceData(id, pid, data)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Prescription updated successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        if (err.response.data?.errors?.non_field_errors || err.response.data?.errors?.details) {
          setAlertType('alert-danger');
          setIsShowAlert(Object.values(err.response.data?.errors?.non_field_errors || err.response.data.errors)[0]);
        }
      });
  };

  const getPatientData = () => {
    setIsLoading(true);
    ApiClient.getPatientDetails(id)
      .then((res) => {
        setIsLoading(false);
        setPatientDetails(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const statusValueData = [
    {
      title: 'Scheduled',
      id: 'scheduled',
    },
    {
      title: 'Scheduled Due',
      id: 'scheduled-due',
    },
    {
      title: 'Done',
      id: 'done',
    },
    {
      title: 'Dropped',
      id: 'dropped',
    },
  ];
  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + ' ' + 'Service', link: '' },
  ];
  const showService = () => (
    <>
      <div className='articleHeader stickyHeader '>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>
        <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Service</div>
        <div class='hrLine'></div>
        <>
          <br />
          <div className='d-flex justify-content-around'>
            <div className='capitalize'>Gender : {patientDetails?.gender}</div>
            <div>Age : {patientDetails?.age}</div>
            <div className='capitalize'>
              Insurance Category :{' '}
              {patientDetails?.profile_info?.find((item3) => item3?.key == 'insurance_category')?.answer || 'N/A'}
            </div>
          </div>
        </>
      </div>
      <div className='px-4 wrapperContent'>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        <div className=' mt-3'>
          <div className='mt-3'>{renderServiceDropdown()}</div>
          {serviceDropdown && <div className='mt-3'>{renderServiceCategoryDropdown()}</div>}
          {serviceCategoryValue && <div className='mt-3'>{renderClinicDropdown()}</div>}
          {serviceCategoryValue && <div className='mt-3'>{renderDoctorDropdown()}</div>}
          {serviceCategoryValue && <div className='mt-3'>{renderCareManagerDropdown()}</div>}

          {(pid || serviceDropdown) && (
            <div className='mt-3'>
              <Select2DropDown
                retuired
                label='status'
                data={statusValueData}
                value={statusValue}
                onClean={() => setStatusValue(null)}
                showMSG={errors?.status}
                onSelect={(id) => setStatusValue(id)}
              />
            </div>
          )}
          {serviceCategoryData.length ? (
            <div className='mt-3'>
              <AddServiceForm
                selectedCatObj={selectedCatObj}
                setSelectedCatObj={setSelectedCatObj}
                careManagerDropdown={careManagerDropdown}
                doctorDropdown={doctorDropdown}
                clinicDropdown={clinicDropdown}
                patientId={id}
                errors={errors}
                serviceSchemaData={serviceSchemaData}
                statusValue={statusValue}
                onClick={pid ? updateServiceData : serviceDataCreate}
                dynamicServiceData={dynamicServiceData}
                setDynamicServiceData={setDynamicServiceData}
                serviceDropdown={serviceDropdown}
                serviceCategoryValue={serviceCategoryValue}
                pid={pid}
              />
            </div>
          ) : (
            <div className='card mb-3 m-3' style={{ textAlign: 'center' }}>
              <div className='card-body'> No Schema Found</div>
            </div>
          )}
        </div>
      </div>
    </>
  );

  const renderServiceDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          onSearch={(text) => getServicesDropdownSearch(text)}
          showMSG={errors.service}
          value={serviceDropdown}
          data={
            !serviceData.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id
              ? [...serviceData, selectedCatObj]
              : serviceData
          }
          isValidation
          label={'Select Service'}
          placeholder={'Select Service'}
          valueKey='id'
          labelKey='title'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            getServiceCategories(serviceId);
            setServiceDropdown(serviceId);
            setServiceSchemaData(serviceObj);
            setDynamicServiceData(serviceObj);
            setSelectedCatObj(serviceObj);
            setServiceDropdownVal(serviceId);
          }}
        />
      </div>
    );
  };

  const renderServiceCategoryDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors.service_category}
          value={serviceCategoryValue}
          data={serviceCategoryData}
          label={'Service Category'}
          placeholder={'Service Category'}
          valueKey='id'
          labelKey='title'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setServiceCategoryValue(serviceId);
          }}
        />
      </div>
    );
  };

  const renderDoctorDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors.doctor || errors.message}
          value={doctorDropdown}
          data={serviceDoctorData}
          label={'Select Doctor'}
          placeholder={'Select Doctor'}
          valueKey='id'
          labelKey='full_name'
          getObjData
          retuired
          onSelect={(serviceId, serviceObj) => {
            setDoctorDropdown(serviceId);
          }}
        />
      </div>
    );
  };

  const renderClinicDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors.location}
          value={clinicDropdown}
          data={locations}
          label={'Select Hospital'}
          placeholder={'Select Hospital'}
          valueKey='id'
          labelKey='title'
          getObjData
          retuired
          onSelect={(locationId) => {
            setClinicDropdown(locationId);
            getServicesClinic(locationId);
          }}
        />
      </div>
    );
  };

  const renderCareManagerDropdown = () => {
    return (
      <div className='daypart-input behaviourpart_box w-100'>
        <Select2DropDown
          showMSG={errors.care_manager}
          value={careManagerDropdown}
          data={careManagersList}
          label={'Select CareManager'}
          placeholder={'Select CareManager'}
          valueKey='id'
          labelKey='full_name'
          getObjData
          retuired
          onSelect={(serviceId) => {
            setCareManagerDropdown(serviceId);
          }}
        />
      </div>
    );
  };

  return (
    <div className='wrapper'>
      {showService()}
      <Loader show={isLoading} />
    </div>
  );
}

export default AddService;
