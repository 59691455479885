export { default as nameLogo } from './nameLogo.svg';
export { default as plainLogo } from './plainLogo.svg';
export { default as plainLogoPng } from './plainLogo.png';
export { default as loginBodyBg } from './login/loginBodyBg.png';
export { default as dashbord } from './dashbord.svg';
export { default as Line } from './Line.svg';
export { default as smlLine } from './smlLine.svg';
export { default as Comment } from './comment.svg';
export { default as deleteIcon } from './deleteIcon.svg';
export { default as editIcon } from './editIcon.svg';
export { default as eyeView } from './eyeView.svg';
export { default as menuLight } from './menuLight.svg';
export { default as adherenceGreen } from './adherenceGreen.svg';
export { default as notifications } from './notifications.svg';
export { default as paper } from './paper.svg';
export { default as carePlan } from './carePlan.svg';
export { default as adherenceH } from './adherenceH.svg';
export { default as search } from './search.svg';
export { default as userIcon } from './userIcon.svg';
export { default as filters } from './filters.svg';
export { default as userGroup } from './userGroup.svg';
export { default as settings } from './settings.svg';
export { default as activePaper } from './activePaper.svg';
export { default as activeSettings } from './activeSettings.svg';
export { default as activeDashboard } from './activeDashboard.svg';
export { default as activeUser } from './activeUser.svg';
export { default as activeUserGroup } from './activeUserGroup.svg';
export { default as sideMenuSwitcher } from './sideMenuSwitcher.svg';
export { default as sideMenuSwitcherExp } from './sideMenuSwitcherExp.svg';
export { default as unpublishEye } from './unpublishEye.svg';
export { default as editIconBlack } from './editIconBlack.svg';
export { default as adherenceRed } from './adherenceRed.svg';
export { default as ArrowRight } from './ArrowRight.svg';
export { default as eyeViewWhite } from './eyeViewWhite.svg';
export { default as CloseRound } from './CloseRound.svg';
export { default as folder } from './folder.svg';
export { default as addIconWhite } from './addIconWhite.svg';
export { default as reload } from './reload.svg';
export { default as upload } from './upload.svg';
export { default as redTrashIcon } from './redTrashIcon.svg';
export { default as reloadBlack } from './reloadBlack.svg';
export { default as whiteEyeIconClose } from './whiteEyeIconClose.svg';
export { default as whiteEyeIcon } from './whiteEyeIcon.svg';
export { default as linkIcon } from './linkIcon.svg';
export { default as checkmark } from './checkmark.svg';
export { default as trashRed } from './trashRed.svg';
export { default as editTheme } from './editTheme.svg';
export { default as warningBadge } from './warningBadge.svg';
export { default as logOut } from './login/logOut.svg';
export { default as femaleUser } from './femaleUser.png';
export { default as maleUser } from './maleUser.png';
export { default as arrowRight } from './ArrowRight.svg';
export { default as arrowLeft } from './ArrowLeft.svg';
export { default as profile } from './profile.png';
export { default as infoIcon } from './infoIcon.svg';
export { default as whatsapp } from './whatsapp.png';
export { default as closeIcon } from './closeIcon.svg';
export { default as CloseWhiteTransParent } from './CloseWhiteTransParent.png';
export { default as userImage } from './userImage.png';
export { default as eclips } from './eclips.png';
export { default as email } from './email.png';
export { default as searchLight } from './searchLight.svg';
export { default as searchTheme } from './searchTheme.svg';
export { default as body } from './body.png';
export { default as heart } from './heart.png';
export { default as Energy } from './Energy.png';
export { default as waterLevel } from './waterLevel.png';
export { default as reloadExtend } from './reloadExtend.png';
export { default as add } from './add.svg';
export { default as uploadgoal } from './uploadgoal.png';
export { default as right } from './right.png';
export { default as blueRight } from './blueRight.png';
export { default as Star } from './Star.png';
export { default as checkupMitter } from './checkupMitter.svg';
export { default as checkupIconB } from './checkupIconB.svg';
export { default as checkupIconG } from './checkupIconG.svg';
export { default as checkupIconY } from './checkupIconY.svg';
export { default as checkupIconBl } from './checkupIconBl.svg';
export { default as CloseSmall } from './CloseSmall.svg';
export { default as checkmarkSm } from './checkmarkSm.svg';
export { default as checkmarkBlue } from './checkmarkBlue.svg';
export { default as checkmarkBlackSM } from './checkmarkBlackSM.svg';
// export { default as breadcrumbUser } from "./breadcrumbUser.svg";
// export { default as breadcrumbSettings } from "./breadcrumbSettings.svg";
// export { default as breadcrumbDashboard } from "./breadcrumbDashboard.svg";
// export { default as breadcrumbTeam } from "./breadcrumbTeam.svg";
// export { default as breadcrumbArticle } from "./breadcrumbArticle.svg";
export { default as starSmall } from './starSmall.svg';
export { default as editIconY } from './editIconY.svg';
export { default as profielSettings } from './profielSettings.svg';
export { default as profielLogout } from './profielLogout.svg';
export { default as Eclipse } from './Eclipse.svg';
export { default as loginpageLogo } from './loginpageLogo.svg';
export { default as moodVal10 } from './moodVal10.png';
export { default as moodVal20 } from './moodVal20.png';
export { default as moodVal30 } from './moodVal30.png';
export { default as moodVal40 } from './moodVal40.png';
export { default as moodVal50 } from './moodVal50.png';
export { default as moodVal60 } from './moodVal60.png';
export { default as moodVal70 } from './moodVal70.png';
export { default as energy10 } from './energy10.png';
export { default as energy20 } from './energy20.png';
export { default as energy30 } from './energy30.png';
export { default as energy40 } from './energy40.png';
export { default as searchIcon } from './searchIcon.svg';
export { default as uploaded } from './uploaded.svg';
export { default as blackUpload } from './blackUpload.svg';
export { default as calenderIcon } from './calender-icon.svg';
export { default as pdfIcon } from './pdfIcon.svg';
export { default as vitalsIcon1 } from './vitals-icon1.svg';
export { default as vitalsIcon2 } from './vitals-icon2.svg';
export { default as vitalsIcon3 } from './vitals-icon3.svg';
export { default as vitalsIcon4 } from './vitals-icon4.svg';
export { default as vitalsIcon5 } from './vitals-icon5.svg';
export { default as dotsthree } from './dotsthree.svg';
export { default as addNew } from './addNew.svg';
export { default as enableCircle } from './enableCircle.svg';
export { default as disableCircle } from './disableCircle.svg';
export { default as edit } from './edit.svg';
export { default as draft } from './draft.svg';
export { default as adherenceIcon } from './adherenceIcon.svg';
export { default as crmIcon } from './crmIcon.svg';
export { default as goalenableIcon } from './goalenableIcon.svg';
export { default as goaldisableIcon } from './goaldisableIcon.svg';
export { default as laptopcrmIcon } from './laptopcrmIcon.svg';
export { default as mobilecrmIcon } from './mobilecrmIcon.svg';
export { default as refreshIcon } from './iconRefresh.svg';
export { default as aiChat } from './ai-chat.svg';
export { default as aiChatBot } from './bot.svg';
export { default as aiChatUser } from './user-new.svg';
