import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editIcon, editIconBlack, editIconY, uploaded } from 'res/images';
import CommonToolTip from '../CommonToolTip';
import { dateFormat, profileImage, teamRoles, timeFormat } from '../Helper';
import { Modal, Button, ButtonToolbar, Placeholder } from 'rsuite';

function DietplanList(props) {
  const {
    dietplanList,
    dietPlanDelete,
    statusPublish,
    getPdf,
    openModalPdf,
    openModalPdfDownload,
    handlePublishAndPrint,
    closeModalPdf,
    pdfModal,
    pdfUrl,
    dietPlanClone,
    closeModalPdfDownload,
    pdfModalDownload,
  } = props;
  const [showFullContent, setShowFullContent] = useState(false);
  const [modelItemId, setModalItemId] = useState('');
  const [modelItemIndex, setModalItemIndex] = useState('');

  const { id } = useParams();
  const navigate = useNavigate();

  const formatAMPM = (time) => {
    let hours = null;
    let minutes = null;
    if (time.toString().length === 3) {
      hours = time.toString().slice(0, 1);
      minutes = time.toString().slice(1, 3);
    } else if (time.toString().length < 3) {
      minutes = time.toString().slice(0, 3);
    } else {
      hours = time.toString().slice(0, 2);
      minutes = time.toString().slice(2, 4);
    }

    let ampm = hours >= 12 && parseInt(hours) <= 23 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    let strTime = hours + ':' + (time.toString() < 10 ? '0' : '') + minutes + ' ' + ampm;
    return strTime;
  };

  const handleToggleContent = () => {
    setShowFullContent(!showFullContent);
  };

  const trimContent = (content, maxLength) => {
    return content && content.length > maxLength ? `${content.substring(0, maxLength)}...` : content;
  };

  const renderDietlist = (item, index) => {
    return (
      <div className='cardDataBorder w-100'>
        <div className='d-flex justify-content-between'>
          <div className='headingMilestone d-flex justify-content-between'>
            <div>
              <b>Assigned On &nbsp; &nbsp;</b>
              {dateFormat(item.created_at)} · {timeFormat(item.created_at)}
            </div>
            <div className='d-block  ms-2 '>
              <span className={`badge bg-info  capitalize ${item.status}`}>{item.status}</span>
            </div>
          </div>

          <div className='memberShipDetails' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <div
              className='mb-1 ms-2 pointer'
              onClick={() => {
                let windoConfirm = window.confirm('Are you sure you want to delete this Diet Plan');
                if (windoConfirm) {
                  dietPlanDelete(item.id);
                }
              }}
            >
              <div className='pt-1'>
                <CommonToolTip
                  placement={'top'}
                  item={<span class='material-icons-outlined'>delete_outline</span>}
                  showValue={'Delete'}
                />
              </div>
            </div>

            <div
              className='mb-1 ms-2 pointer'
              onClick={() => {
                dietPlanClone(item.id);
              }}
            >
              <CommonToolTip
                placement={'top'}
                item={
                  <div className='pt-1'>
                    <span className='material-icons-outlined' style={{ color: '#757575' }}>
                      content_copy
                    </span>
                  </div>
                }
                showValue={'Duplicate'}
              />
            </div>

            <>
              {item.status == 'published' && (
                <div
                  className='mb-2 ms-2 pointer'
                  onClick={() => {
                    openModalPdfDownload(item);
                    setModalItemId(item);
                    setModalItemIndex(index);
                  }}
                >
                  <CommonToolTip placement={'top'} item={<img src={uploaded} />} showValue={'Download Pdf'} />
                </div>
              )}
              {item.status == 'draft' && (
                <div
                  onClick={() => {
                    navigate(`/patient/${id}/dietPlan/${item.id}/edit`);
                  }}
                  className='mb-2 ms-2 pointer'
                >
                  <CommonToolTip
                    placement={'top'}
                    item={<img src={editIconBlack} className='icon' style={{ cursor: 'pointer' }} />}
                    showValue={'Edit'}
                  />
                </div>
              )}
              {item.status !== 'published' && (
                <>
                  <div
                    onClick={() => {
                      openModalPdf(item);
                    }}
                    className='ms-2 pointer'
                  >
                    <CommonToolTip
                      placement={'top'}
                      item={<span class='material-icons-outlined'>visibility</span>}
                      showValue={'View'}
                    />
                  </div>

                  <CommonToolTip
                    placement={'top'}
                    item={
                      <div className='mb-2 ms-2 pointer'>
                        <div className='form-check form-switch toggleBtn pt-1'>
                          <input
                            className='form-check-input'
                            style={{ cursor: 'pointer' }}
                            checked={item.status == 'published'}
                            onChange={(e) => {
                              const windowConfirm = window.confirm(
                                `Are you sure,you want to ${
                                  item.status == 'published' ? 'unpublish' : 'publish'
                                } Diet Plan?`
                              );
                              if (windowConfirm && item.status !== 'published') {
                                statusPublish(item);
                              }
                            }}
                            type='checkbox'
                            role='switch'
                          />
                        </div>
                      </div>
                    }
                    showValue={` ${item.status == 'published' ? 'Unpublish' : 'Publish'} `}
                  />
                </>
              )}
            </>
          </div>
        </div>

        <div className='d-flex w-100 justify-content-between'>
          <div class='d-flex mt-1 mb-3'>
            <div class='me-2'>
              <img
                className='rounded coachImage float-start img-fluid'
                src={profileImage(item?.admin?.gender, item?.admin?.profile_url)}
              />
            </div>
            <div class=''>
              <h5 class='coachtext capitalize'>{item.admin ? item?.admin?.full_name : 'System'}</h5>
              <p class='zeefitness capitalize'>{teamRoles(item?.admin?.role)}</p>
            </div>
          </div>
        </div>
        <div style={{ marginBottom: '10px' }}>
          <span>
            <b>Diet plan : </b>
            <b>{item.title}</b>
          </span>
        </div>
        {item?.diet_plan_day_parts?.map((item, index) => {
          return (
            <div>
              {/* <div>
            <b className='capitalize'> {'Rishabh'} </b>
          </div> */}
              {/* <div>
            <b>{item?.behaviour_question_category?.title ? 'Category :' : ''}</b>&nbsp;
            {item?.behaviour_question_category?.title}
          </div> */}
              <div style={{ marginTop: '10px' }}>
                <span style={{ paddingRight: '10px' }}>
                  <b>Day Part : </b>
                  <b>{item?.day_part?.title}</b>
                </span>
                <span style={{ paddingLeft: '10px' }}>
                  <b>Time : </b>
                  <b>{formatAMPM(item.notification_time)}</b>
                </span>
              </div>

              <div className='table-responsive diet-regime'>
                <table className='w-100 table'>
                  <thead>
                    <tr>
                      <th>Sr.No.</th>
                      <th>Food</th>
                      <th>Quantity</th>
                      <th>Recipe Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item?.diet_plan_food_items?.map((foodItem, foodIndex) => {
                      return (
                        <tr>
                          <td style={{ width: '50px' }}>{foodIndex + 1}</td>
                          <td style={{ width: '350px' }}>{foodItem?.food}</td>
                          <td style={{ width: '100px' }}>{foodItem?.quantity}</td>
                          <td>
                            {foodItem?.recipe_link ? (
                              <a href={foodItem.recipe_link} target='_blank' rel='noopener noreferrer'>
                                Link
                              </a>
                            ) : (
                              'N/A'
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          );
        })}
        <div>
          <span>
            <p>
              <th>Guidelines :</th>
            </p>
            <pre>
              <td>{showFullContent ? item?.guidelines : trimContent(item?.guidelines, 200)}</td>
            </pre>
          </span>
        </div>
        {item?.guidelines && item.guidelines.length > 200 && (
          <button onClick={handleToggleContent}>{showFullContent ? 'Show Less' : 'Show More'}</button>
        )}
      </div>
    );
  };
  return (
    <>
      <div>
        {dietplanList.map((item, index) => {
          return renderDietlist(item, index);
        })}
      </div>

      <Modal
        backdrop='static'
        size='lg'
        open={pdfModalDownload ? pdfModalDownload : pdfModal}
        onClose={pdfModalDownload ? closeModalPdfDownload : closeModalPdf}
      >
        <Modal.Header>
          <Modal.Title>Diet Plan View</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <iframe
            src={pdfUrl + '#toolbar=0'}
            onContextMenu='return false;'
            width={'100%'}
            height={window.innerHeight}
          />
        </Modal.Body>
        <Modal.Footer className='mt-4'>
          <Button
            onClick={(index) => {
              pdfModalDownload ? getPdf(modelItemId, modelItemIndex) : handlePublishAndPrint();
            }}
            appearance='primary'
          >
            {pdfModalDownload ? 'Download' : 'Publish'}
          </Button>
          <Button onClick={pdfModalDownload ? closeModalPdfDownload : closeModalPdf} appearance='defualt'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DietplanList;
