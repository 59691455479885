import axios from 'axios';

const authAPI = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
});

authAPI.interceptors.request.use((request) => {
  request.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return request;
});

const handle401Error = async () => {
  await axios
    .post(process.env.REACT_APP_API_ENDPOINT + 'token/refresh/', {
      refresh: localStorage.getItem('refreshToken'),
    })
    .then((res) => {
      localStorage.setItem('token', res.data.access);
      window.location.reload();
    })
    .catch((err) => {
      localStorage.clear();
      window.location.href = '/';
    });
};

authAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response?.status == 401) {
      return handle401Error();
    } else {
      return Promise.reject(error);
    }
  }
);

export default authAPI;
