import React, { useEffect, useMemo, useRef, useState } from "react";
import { logo, signIn } from "whealth-core-web/res/images";
import "./otpInput.css";

function OtpInput(props) {
  const { onClick, number, resendOtp, showBtn, sendOtp } = props;
  const [timerVal, setTimerVal] = useState(60);
  const [otpValue, setOtpValue] = useState("");
  const divRef = useRef();

  useEffect(() => {
    validateInputs();
  }, []);

  useEffect(() => {
    const timer = setInterval(function () {
      setTimerVal(timerVal - 1);
    }, 1000);

    if (timerVal == 0) {
      clearInterval(timer);
    }

    return () => {
      clearInterval(timer);
    };
  }, [timerVal]);

  const validateInputs = () => {
    var otp_inputs = divRef.current.childNodes;

    var mykey = "0123456789".split("");
    otp_inputs.forEach((_) => {
      _.addEventListener("keyup", handle_next_input);
    });

    function handle_next_input(event) {
      let current = event.target;
      let index = parseInt(current.classList[1].split("__")[1]);
      current.value = event.key;

      if (event.keyCode == 8 && index > 1) {
        current.previousElementSibling.focus();
      }

      if (index < 6 && mykey.indexOf("" + event.key + "") != -1) {
        var next = current.nextElementSibling;
        next.focus();
      }

      if (index == 6) {
        current.blur();
      }

      var finalKey = "";
      for (let { value } of otp_inputs) {
        finalKey += value;
      }
      setOtpValue(finalKey);
    }
    return handle_next_input;
  };

  const otpInputs = useMemo(() => {
    return (
      <div className="otp-input-fields" ref={divRef}>
        <input type="number" autoFocus className="otp__digit field__1" />
        <input type="number" className="otp__digit field__2" />
        <input type="number" className="otp__digit field__3" />
        <input type="number" className="otp__digit field__4" />
        <input type="number" className="otp__digit field__5" />
        <input type="number" className="otp__digit field__6" />
      </div>
    );
  }, []);

  const resendOTPClass = timerVal == 0 ? "activeOtpBtn" : "inactiveOtpBtn";
  const renderTimer = useMemo(() => {
    let getMinutes = parseInt(timerVal / 60, 10);
    let getSeconds = parseInt(timerVal % 60, 10);
    getSeconds = getSeconds > 9 ? getSeconds : `0${getSeconds}`;
    return (
      <div className="timerDiv">
        <div className="timerText">
          {getMinutes}:{getSeconds} Sec |
        </div>
        <div
          onClick={() => {
            if (!timerVal) {
              coearInputValue();
              setOtpValue("");
              sendOtp();
              setTimerVal(60);
            }
          }}
          className={resendOTPClass}
        >
          Resend OTP
        </div>
      </div>
    );
  }, [timerVal]);

  const handleBTNClick = () => {
    onClick(otpValue);
  };

  const coearInputValue = () => {
    var otp_inputs = divRef.current.childNodes;
    otp_inputs.forEach((input) => {
      input.value = "";
    });
    otp_inputs[0]?.focus();
  };

  return (
    <div>
      <div className="otpText">
        <div>
          OTP Send on +91 &nbsp;{number}
          <span onClick={resendOtp} className="otpEditTextColor">
            &nbsp;(Edit){" "}
          </span>
        </div>
        <div></div>
        <div> {renderTimer}</div>
      </div>

      {otpInputs}
      {showBtn && showBtn("Sign In", handleBTNClick, signIn)}
      {/* <div
        onClick={() => {
          coearInputValue();
          setOtpValue("");
          sendOtp();
        }}
        className="resendOtpText"
      >
        Resend OTP
      </div> */}
    </div>
  );
}

export default OtpInput;
