import React from 'react';
import { Toggle } from 'rsuite';
import { CoreButton, CoreInputBox, CoreTextArea } from './components';
import CommonRenderCheckBox from './components/CommonRenderCheckBox';

function AppoinmentServiceForm(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick, statuses, careManagersList, open } = props;

  return (
    <div className='medicineForm'>
      <div className='formTitle'>{!isUpdate ? 'Add  Service' : 'Update  Service'} </div>

      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex w-100 justify-content-between medicineNamerow'>
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.title}
            placeholder='Service Title'
            value={valueObj?.title || ''}
            label='Service Title'
            setValue={(inputVal) => {
              setValueObj({ ...valueObj, title: inputVal });
            }}
            maxLength={255}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
      </div>
      <div style={{ margin: '20px 0' }}>
        <CoreTextArea
          showMSG={error?.service_schema}
          placeholder='Service Schema'
          value={valueObj.service_schema}
          label='Service Schema'
          setValue={(inputVal) => {
            // setJsonValue(inputVal);
            setValueObj({ ...valueObj, service_schema: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.description}
          placeholder='Description'
          value={valueObj?.description || ''}
          label='Description'
          setValue={(inputVal) => {
            setValueObj({ ...valueObj, description: inputVal });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
        />
      </div>
      <div style={{ margin: '20px 0' }}>
        <CommonRenderCheckBox
          label='Enable Service'
          id={`${isUpdate ? 'labelcheck1' : 'labelcheck'}`}
          htmlFor={`${isUpdate ? 'labelcheck1' : 'labelcheck'}`}
          onChange={(data) => {
            setValueObj({
              ...valueObj,
              enable: !valueObj.enable,
            });
          }}
          checked={valueObj.enable}
        />
      </div>

      <CoreButton
        onClick={() => {
          onClick();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default AppoinmentServiceForm;
