import { AdminPanelHeader } from 'components';
import React, { useEffect, useMemo, useState } from 'react';

import {
  ArticleCategoriesView,
  ArticleTagsView,
  CommonAlert,
  RowLinks,
  Loader,
  MobileConfiguration,
  GoalConfigurations,
  Medicine,
  NotesCategories,
} from 'whealth-core-web/components';
import Adherence from 'whealth-core-web/components/Adherence/Adherence';
import Auditlog from 'whealth-core-web/components/Auditlog';
import BehaviorQuestions from 'whealth-core-web/components/BehaviorQuestions';
import ClinicsSettingsView from 'whealth-core-web/components/ClinicsSettingsView';
import Config from 'whealth-core-web/components/Config';
import DayPart from 'whealth-core-web/components/DayPart/DayPart';
import DepartmentStatusesView from 'whealth-core-web/components/DepartmentStatusesView';
import DepartmentsSettingsView from 'whealth-core-web/components/DepartmentsSettingsView';
import GoalCreation from 'whealth-core-web/components/GoalCreation';
import ListCard from 'whealth-core-web/components/ListCard';
import BannerConfig from 'whealth-core-web/components/patient/BannerConfig';
import ResourceStatus from 'whealth-core-web/components/ResourceStatus/ResourceStatus';
import NotesTemplate from 'whealth-core-web/components/NotesTemplate/NotesTemplate';
import CarePlanCreation from 'whealth-core-web/components/CarePlan/CarePlanCreation';
import DietTemplate from 'whealth-core-web/components/DietTemplate/DietTemplate';
import AppointmentServiceSettingView from 'whealth-core-web/AppointmentServiceSettingView';
import ServiceCategoryView from 'whealth-core-web/ServiceCategoryView';

function Settings() {
  const [status, setStatus] = useState('categoriesTegs');
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  const [isHide, setIsHide] = useState(false);
  const [activeVerticalLink, setActiveVerticalLink] = useState('article_categories');

  const headerData = [
    {
      title: 'Manage Categories / Tags',
      link: 'categoriesTegs',
    },
    {
      title: 'Manage Departments / Clinics',
      link: 'departmentsClinics',
      condition: 'departments_clinics',
    },
    {
      title: 'Manage Services',
      link: 'manage_service',
      condition: 'service',
    },
    {
      title: 'Mobile Configurations',
      link: 'mobileConf',
    },
    {
      title: 'Global Configurations',
      link: 'goalEvents',
    },
    {
      title: 'Audit',
      link: 'auditlog',
      hideDiv: true,
    },
  ];

  useEffect(() => {
    setActiveVerticalLink(getActiveTabLink().activeTab);
  }, [status]);

  const categoriesTagsVerticleLinksData = [
    { title: 'Article Categories', link: 'article_categories' },
    { title: 'Article Tags', link: 'article_tags' },
    { title: 'Notes Categories', link: 'notes_categories' },
    { title: 'Notes Templates', link: 'notes_template' },
  ];

  const departmentsClinicsVerticalLinksData = [
    { title: 'Department Statuses', link: 'department_statuses' },
    { title: 'Departments', link: 'departments' },
    { title: 'Clinics', link: 'clinics' },
  ];
  const manageServiceLinkData = [
    { title: 'Services', link: 'appointment_service', condition: 'enable_appointment_service' },
    { title: 'Service Categories', link: 'prc_list', condition: 'enable_PRC' },
  ];

  const MobileConfigurationLinksData = [
    {
      title: 'Profile Questions',
      link: 'profile_questions',
    },
    {
      title: 'Regime Questions',
      link: 'regime_questions',
      condition: 'enable_regime_question',
    },
    {
      title: 'Banner Config',
      link: 'banner_config',
      condition: 'enable_banner',
    },
    {
      title: 'Diet Templates',
      link: 'diet_template',
      condition: 'enable_diet_question',
    },
  ];
  const goalsLinksData = [
    { title: 'Measuring Events', link: 'measuring_event' },
    { title: 'Goal Creations', link: 'goal_creation' },
    { title: 'Care Plan', link: 'care_plan' },
    { title: 'Medicine Config', link: 'medicine' },
    { title: 'Business Config', link: 'config' },
    { title: 'Adherence', link: 'adherence' },
    { title: 'Day Part', link: 'dayPart' },
    { title: 'Master Status', link: 'masterStatus' },
  ];

  const getActiveTabLink = () => {
    let activeTab, func, objData;
    if (status == 'mobileConf') {
      objData = MobileConfigurationLinksData;
      activeTab = MobileConfigurationLinksData[0].link;
      func = returnMobileConfiguration;
    } else if (status == 'departmentsClinics') {
      objData = departmentsClinicsVerticalLinksData;
      activeTab = departmentsClinicsVerticalLinksData[0].link;
      func = renderdepartmentsClinicsVerticalLinksData;
    } else if (status == 'manage_service') {
      objData = manageServiceLinkData;
      activeTab = manageServiceLinkData[0].link;
      func = renderManageService;
    } else if (status == 'auditlog') {
      func = renderAuditLog;
    } else if (status == 'categoriesTegs') {
      objData = categoriesTagsVerticleLinksData;
      activeTab = categoriesTagsVerticleLinksData[0].link;
      func = renderTagsCategoryData;
    } else {
      objData = goalsLinksData;
      activeTab = goalsLinksData[0].link;
      func = renderGoalConfigurations;
    }
    return { activeTab, func, objData };
  };

  const renderAuditLog = () => {
    return <Auditlog setIsLoading={setIsLoading} isLoading={isLoading} />;
  };

  const renderGoalConfigurations = () => {
    switch (activeVerticalLink) {
      case 'measuring_event':
        return (
          <div className='settingsShowData'>
            <GoalConfigurations
              setAlertType={setAlertType}
              setIsShowAlert={setIsShowAlert}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          </div>
        );
      case 'goal_creation':
        return (
          <div className='settingsShowData'>
            <GoalCreation
              isLoading={isLoading}
              setAlertType={setAlertType}
              setIsShowAlert={setIsShowAlert}
              setIsLoading={setIsLoading}
            />
          </div>
        );

      case 'care_plan':
        return (
          <div className='settingsShowData'>
            <CarePlanCreation />
          </div>
        );

      case 'medicine':
        return (
          <div className='settingsShowData'>
            <Medicine
              isLoading={isLoading}
              setAlertType={setAlertType}
              setIsShowAlert={setIsShowAlert}
              setIsLoading={setIsLoading}
            />
          </div>
        );
      case 'config':
        return (
          <div className='settingsShowData '>
            <Config
              isLoading={isLoading}
              setAlertType={setAlertType}
              setIsShowAlert={setIsShowAlert}
              setIsLoading={setIsLoading}
            />
          </div>
        );
      case 'adherence':
        return (
          <div className='settingsShowData '>
            <Adherence isLoading={isLoading} setAlertType={setAlertType} setIsShowAlert={setIsShowAlert} />
          </div>
        );
      case 'dayPart':
        return (
          <div className='settingsShowData '>
            <DayPart isLoading={isLoading} setAlertType={setAlertType} setIsShowAlert={setIsShowAlert} />
          </div>
        );
      case 'masterStatus':
        return (
          <div className='settingsShowData '>
            <ResourceStatus isLoading={isLoading} setAlertType={setAlertType} setIsShowAlert={setIsShowAlert} />
          </div>
        );
    }
  };

  const renderTagsCategoryData = () => {
    if (activeVerticalLink == 'article_categories') {
      return (
        <div className='settingsShowData'>
          <ArticleCategoriesView
            title={'Artical'}
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      );
    } else if (activeVerticalLink == 'notes_categories') {
      return (
        <div className='settingsShowData'>
          <NotesCategories
            title={'Notes'}
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      );
    } else if (activeVerticalLink == 'notes_template') {
      return (
        <div className='settingsShowData'>
          <NotesTemplate
            isLoading={isLoading}
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
          />
        </div>
      );
    } else if (activeVerticalLink === 'article_tags') {
      return (
        <div className='settingsShowData'>
          <ArticleTagsView
            isLoading={isLoading}
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
          />
        </div>
      );
    }
  };

  const renderdepartmentsClinicsVerticalLinksData = () => {
    if (activeVerticalLink == 'department_statuses') {
      return (
        <div className='settingsShowData'>
          <DepartmentStatusesView
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      );
    } else if (activeVerticalLink == 'departments') {
      return (
        <div className='settingsShowData'>
          <DepartmentsSettingsView
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      );
    } else if (activeVerticalLink == 'clinics') {
      return (
        <div className='settingsShowData'>
          <ClinicsSettingsView
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      );
    }
  };
  const renderManageService = () => {
    if (activeVerticalLink == 'appointment_service') {
      return (
        <div className='settingsShowData'>
          <AppointmentServiceSettingView
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      );
    } else if (activeVerticalLink == 'prc_list') {
      return (
        <div className='settingsShowData'>
          <ServiceCategoryView
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      );
    }
  };

  const returnMobileConfiguration = () => {
    if (activeVerticalLink == 'profile_questions') {
      return (
        <div className='settingsShowData'>
          <MobileConfiguration
            isLoading={isLoading}
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
          />
        </div>
      );
    } else if (activeVerticalLink == 'regime_questions') {
      return (
        <div className='settingsShowData'>
          <BehaviorQuestions
            isLoading={isLoading}
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
          />
        </div>
      );
    } else if (activeVerticalLink == 'banner_config') {
      return (
        <div className='settingsShowData'>
          <BannerConfig
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          />
        </div>
      );
    } else if (activeVerticalLink == 'diet_template') {
      return (
        <div className='settingsShowData'>
          <DietTemplate
            isLoading={isLoading}
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
          />
        </div>
      );
    }
  };

  const renderActiveLinkData = useMemo(() => {
    return getActiveTabLink().func();
  }, [activeVerticalLink, status]);

  const renderHeader = useMemo(() => {
    return (
      <div className='headerFiles'>
        <AdminPanelHeader hideSearch iconTitle='settings' title='Settings' />
      </div>
    );
  }, []);

  const handleClick = (item) => {
    setStatus(item.link);
    setIsHide(item.hideDiv);
  };

  const showArticles = () => (
    <>
      <div className='articleHeader stickyHeader'>
        {renderHeader}
        <div className='hrLine mb-3' />
        <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
        <RowLinks data={headerData} onClickHandle={handleClick} />
      </div>
      <div className='px-4 wrapperContent'>
        <div className='settingsBody'>
          <div className='d-flex cardsGap'>
            {!isHide && (
              <ListCard
                activeLink={activeVerticalLink}
                setActiveLink={setActiveVerticalLink}
                listData={getActiveTabLink().objData}
              />
            )}
            {renderActiveLinkData}
          </div>
        </div>
      </div>
    </>
  );

  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {showArticles()}
      <Loader show={isLoading} />
    </div>
  );
}

export default Settings;
