import React, { useEffect, useState } from 'react';
import { closeIcon } from 'res/images';
import { CoreCheckBox } from './index';
import './filterModal.scss';

function GoalFilterModal(props) {
  const { getData, data } = props;
  const [activeStatus, setActiveData] = useState([]);
  const [activeAdherence, setActiveAdherence] = useState([]);

  useEffect(() => {
    setActiveData(data.status.split(','));
    setActiveAdherence(data.adherence.split(','));
  }, [data]);

  const filterHeader = () => {
    return (
      <div className='filterHeader'>
        <div>Apply Filter</div>
        <div className='' data-bs-dismiss='offcanvas' aria-label='Close'>
          <img src={closeIcon} />
        </div>
      </div>
    );
  };

  const filterButtons = () => {
    return (
      <div className='footerButtons'>
        {/* <div
          className='button clearBtn'
          onClick={() => {
            setFilterData('');
            getData({});
            setClearFilterData(!clearFiltersData);
            clickOnElmOnBodyClick('datePickerContainer', 'rs-picker-toggle-clean', 'class');
          }}
        >
          Clear All
        </div> */}
        <div
          onClick={() => {
            let status = activeStatus?.join(',');
            let adherence = activeAdherence?.join(',');
            getData({ status, adherence });
          }}
          className='button applyBtn'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          Apply Filter
        </div>
      </div>
    );
  };

  const renderFooter = () => {
    return (
      <div className='renderFooter'>
        <div className='hrLine' />
        <div>{filterButtons()}</div>
      </div>
    );
  };

  const goalStatus = [
    { title: 'Draft', id: 'draft' },
    { title: 'Enable', id: 'enable' },
    { title: 'Disable', id: 'disable' },
  ];

  const adherenceData = [
    { title: 'Very Low', id: 'Very Low' },
    { title: 'Low', id: 'Low' },
    { title: 'Medium', id: 'Medium' },
    { title: 'High', id: 'High' },
    { title: 'Very High', id: 'Very High' },
  ];

  const renderModal = () => {
    return (
      <div className='filterModal'>
        <div
          className='offcanvas offcanvas-end'
          tabIndex='-1'
          id='offcanvasRight1'
          aria-labelledby='offcanvasRightLabel'
        >
          <div className='whiteBackground'>
            {filterHeader()}
            <div className='hrLine' />
            <div className='filterHeaderData'>
              <div className='filterHeadings mt-3'>Goal Status</div>
              <div className='ms-3'>
                {goalStatus?.map((item) => {
                  return (
                    <CoreCheckBox
                      id={item.id}
                      htmlFor={item.id}
                      checked={activeStatus.includes(item.id)}
                      className={'me-4'}
                      title={item.title}
                      onChange={(val, checked) => {
                        if (!activeStatus.includes(item.id)) {
                          activeStatus.push(item.id);
                        } else {
                          activeStatus.splice(activeStatus.indexOf(item.id), 1);
                        }
                        setActiveData([...activeStatus]);
                      }}
                    />
                  );
                })}
              </div>
              <div className='filterHeadings mt-3'>Goal Adherence</div>
              <div className='ms-3'>
                {adherenceData?.map((item) => {
                  return (
                    <CoreCheckBox
                      id={item.id.replace(' ', '_')}
                      htmlFor={item.id.replace(' ', '_')}
                      checked={activeAdherence.includes(item.id)}
                      className={'me-4'}
                      title={item.title}
                      onChange={(val, checked) => {
                        if (!activeAdherence.includes(item.id)) {
                          activeAdherence.push(item.id);
                        } else {
                          activeAdherence.splice(activeAdherence.indexOf(item.id), 1);
                        }
                        setActiveAdherence([...activeAdherence]);
                      }}
                    />
                  );
                })}
              </div>
            </div>
            {renderFooter()}
          </div>
        </div>
      </div>
    );
  };

  return renderModal();
}

export default GoalFilterModal;
