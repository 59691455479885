import React from 'react';

function CommonRenderCheckBox(props) {
  const { checked, onChange, label, id, htmlFor } = props;
  return (
    <div className='mt-1 d-flex flex-wrap '>
      <div className=' d-flex flex-wrap'>
        <div className='form-check '>
          <input
            className='form-check-input'
            style={{ cursor: 'pointer' }}
            checked={checked}
            onChange={onChange}
            type='checkbox'
            id={id}
          />
        </div>
        <label className='pointer' htmlFor={htmlFor}>
          {label}
        </label>
      </div>
    </div>
  );
}

export default CommonRenderCheckBox;
