import React, { useEffect, useState } from 'react';
import { SegmentDefinitionRuleRow } from '..';
import { CoreDropDown } from '../index';

const SegmentDefinitionRule = (props) => {
  const { ruleIndex, removeSegmentPart, errors, removeSegmentRule } = props;
  const [segmentAttributes, setSegmentAttributes] = useState([]);
  const [joiner, setJoiner] = useState(props.joiner || 'and');
  const [clearFrom, setClearForm] = useState(false);

  if (segmentAttributes.length === 0) {
    setSegmentAttributes([{ key: new Date().getTime() }]);
  }

  useEffect(() => {
    if (props.segmentAttributes) {
      setSegmentAttributes(props.segmentAttributes);
    }
  }, [props.segmentAttributes]);

  useEffect(() => {
    if (props.joiner) {
      setJoiner(props.joiner);
    }
  }, [props.joiner]);

  const renderSegmentAttributes = () => {
    return segmentAttributes.map((segmentAttribute, segmentAttributeIndex) => {
      return (
        <SegmentDefinitionRuleRow
          key={segmentAttribute.key || segmentAttribute.id}
          errors={errors}
          cleanValues={clearFrom}
          segmentAttribute={segmentAttribute}
          parentRuleIndex={ruleIndex}
          segmentAttributeIndex={segmentAttributeIndex}
          removeAttribute={removeSegmentAttribute}
          updateSegmentAttribute={updateSegmentAttribute}
          attributesCount={segmentAttributes.length}
        />
      );
    });
  };

  const addSegmentAttribute = () => {
    var newsa = segmentAttributes.slice();
    newsa.push({ key: new Date().getTime() });
    setSegmentAttributes(newsa);
    updateSegmentPart(joiner, newsa);
  };

  const removeSegmentAttribute = (indexToRemove) => {
    var newsa = segmentAttributes.slice();
    delete newsa[indexToRemove];
    let filteredSegmentParts = newsa.filter((item) => item);
    setSegmentAttributes(filteredSegmentParts);
    updateSegmentPart(joiner, filteredSegmentParts);

    removeSegmentRule(indexToRemove, ruleIndex);
  };

  const updateSegmentAttribute = (indexToUpdate, data) => {
    var newsa = segmentAttributes.slice();
    newsa = newsa.map((item, index) => {
      return index === indexToUpdate ? data : item;
    });
    setSegmentAttributes(newsa);
    updateSegmentPart(joiner, newsa);
  };

  const updateSegmentPart = (joiner, segmentparts) => {
    let data = { joiner: joiner, id: props.segmentPartId, segment_attributes: segmentparts };
    props.updateSegmentPart(ruleIndex, data);
  };

  const renderJoinerDropdown = () => {
    return (
      <div className='mx-3 w-25'>
        <CoreDropDown
          setValue={(val) => {
            setJoiner(val);
            updateSegmentPart(val, segmentAttributes);
          }}
          placeholder='operator'
          value={joiner}
          data={[
            { title: 'And', id: 'and' },
            { title: 'OR', id: 'or' },
            // { title: 'Not', id: 'not' },
          ]}
        />
      </div>
    );
  };

  return (
    <>
      <div key={ruleIndex} id={'segment-rule-' + ruleIndex} className='card m-3'>
        <div className='card-body'>
          <div className='mb-4 d-flex align-items-center justify-content-between'>
            <span />
            <div
              className='pointer'
              onClick={() => {
                const confirmdelete = window.confirm('Are you sure you want to delete?');
                if (confirmdelete) {
                  setClearForm(!clearFrom);
                  setTimeout(() => {
                    removeSegmentPart(ruleIndex);
                    setClearForm(false);
                  }, 30);
                }
              }}
            >
              <span className='material-icons'>delete</span>
            </div>
          </div>

          <div className='segment-box mb-4 pb-0'>
            {renderSegmentAttributes()}
            <div className='d-flex justify-content-end w-100 '>
              <div className='d-flex' onClick={() => addSegmentAttribute()}>
                {/* <div className='w-100 form-control  coreBtn text-white undefined mt-0 add-attribute-btn mb-0 d-flex'>
                  + Add Attribute
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {props.showJoiner && renderJoinerDropdown()}
    </>
  );
};

export default SegmentDefinitionRule;
