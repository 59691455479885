import React from 'react';
import PatientActivityTab from './PatientActivityTab';

function PatientActivity(props) {
  const { data, getGraphData, getPatientData, activeNotesCallLog, setCallLogNotes } = props;

  return (
    <>
      <div className='d-flex'>
        <div className='card border-0 rounded-0 m-2 w-100 patientAction'>
          <div className='card-body'>
            <div className='pt-3'>
              {
                <PatientActivityTab
                  setCallLogNotes={setCallLogNotes}
                  activeNotesCallLog={activeNotesCallLog}
                  getPatientData={getPatientData}
                  getGraphData={getGraphData}
                  data={data}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PatientActivity;
