import React from 'react';
import './coreBtn.css';

function CoreButton(props) {
  const { onClick, title, icon, customClass, customDivStyle, customStyle } = props;
  return (
    <div style={customDivStyle}>
      <div onClick={onClick} className={`form-control  coreBtn text-white ${customClass}`} style={customStyle}>
        {icon && <img src={icon} className="btnImage" />}
        {title}
      </div>
    </div>
  );
}

export default CoreButton;
