import {
  maleUser,
  femaleUser,
  checkmarkBlackSM,
  CloseSmall,
  energy10,
  energy20,
  energy30,
  energy40,
  moodVal10,
  moodVal20,
  moodVal30,
  moodVal40,
  moodVal50,
  moodVal60,
  moodVal70,
  mobilecrmIcon,
  laptopcrmIcon,
  crmIcon,
} from 'res/images';
import roles from '../../role.json';

export function dateFormat(date, year = 'numeric', month = 'short', day = 'numeric') {
  const options = { year, month, day };
  if (new Date(date) == 'Invalid Date') return null;
  return new Date(date).toLocaleDateString(undefined, options);
}

export const dateFormatV2 = (initialDate) => {
  const etOptions = { timeZone: 'GMT', hour12: true, hour: 'numeric', minute: 'numeric' };
  const etTimeString = new Intl.DateTimeFormat('en-US', etOptions).format(new Date(initialDate));
  return etTimeString;
};

export const convertTo12HourFormat = (timeString) => {
  var timeComponents = timeString.split(':');
  var hours = parseInt(timeComponents[0], 10);
  var minutes = parseInt(timeComponents[1], 10);
  var seconds = parseInt(timeComponents[2], 10);

  var period = hours >= 12 ? 'pm' : 'am';

  hours = hours % 12 || 12;

  var time12Hour = hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ' ' + period;

  return time12Hour;
};

export function timeFormat(time) {
  const options = { hour: '2-digit', minute: '2-digit' };
  return new Date(time).toLocaleTimeString('en-US', options);
}

export function timeFormat_v2(time) {
  const date = new Date(time);
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');
  const period = hours >= 12 ? 'PM' : 'AM';
  const formattedHours = hours % 12 || 12; // Convert to 12-hour format
  return `${formattedHours}:${minutes} ${period}`;
}

export function formatDateInISO(dateString) {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}

export function dateTimeFormat(dateTime) {
  if (new Date(dateTime) === 'Invalid Date') return null;

  const dateOptions = { year: 'numeric', month: 'short', day: 'numeric', timeZone: 'IST' };
  const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true, timeZone: 'IST' };

  const formattedDate = new Date(dateTime).toLocaleDateString(undefined, dateOptions);
  const formattedTime = new Date(dateTime).toLocaleTimeString('en-US', timeOptions);

  return `${formattedDate} ${formattedTime}`;
}

export function monthDiff(d1, d2) {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
}

export const handleChannel = (title) => {
  const showChannel = {
    mobile: { title: 'Mobile', icon: mobilecrmIcon },
    dashboard: { title: 'Dashboard', icon: laptopcrmIcon },
    crm: { title: 'CRM', icon: crmIcon },
  };
  if (showChannel[title]) {
    return showChannel[title];
  } else {
    return {
      title: title,
      icon: crmIcon,
    };
  }
};

export function addTimeInDate(date, time) {
  let noTimeDate = date ? new Date(date) : new Date();
  let splitTime = time.split(':');
  let hours = splitTime[0];
  if (splitTime[1].split(' ')[1] == 'PM') {
    hours = (Number(hours) + 12).toString();
  }
  let minutes = splitTime[1].split(' ')[0];
  let dateWithTime = noTimeDate.setHours(hours, minutes);
  let dateTimeIso = new Date(dateWithTime).toISOString();
  return dateTimeIso;
}

export function timeAgo(input) {
  var DURATION_IN_SECONDS = {
    epochs: ['year', 'month', 'week', 'day', 'hour', 'minute'],
    year: 31536000,
    month: 2592000,
    day: 86400,
    week: 604800,
    hour: 3600,
    minute: 60,
  };

  function getDuration(seconds) {
    let epoch, interval;

    for (let i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
      epoch = DURATION_IN_SECONDS.epochs[i];
      interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
      if (interval >= 1) {
        return {
          interval: interval,
          epoch: epoch,
        };
      }
    }
  }

  function timeSince(date) {
    var seconds = Math.floor((new Date() - new Date(date)) / 1000);
    var duration = getDuration(seconds);
    var suffix = duration?.interval > 1 || duration?.interval === 0 ? 's' : '';
    if (duration) {
      return duration?.interval + ' ' + duration?.epoch + suffix;
    } else {
      return '1 minute';
    }
  }

  return timeSince(input);
}

export const showArrayValues = (arr, str) => {
  let newArr = [];
  arr.filter(function (elm) {
    if (elm) {
      newArr.push(elm);
    }
  });

  return newArr.join(str);
};

export const titlizeString = (str) => {
  return str?.replaceAll('_', ' ');
};

const genderImage = {
  male: maleUser,
  female: femaleUser,
  '': maleUser,
  undefined: maleUser,
  other: maleUser,
};

export function profileImage(gender, url) {
  if (url) {
    return url;
  } else if (gender) {
    return genderImage[gender];
  } else {
    return genderImage.male;
  }
}

export function s3Url(name, img) {
  const S3_URL = process.env.REACT_APP_S3_URL;
  if (S3_URL) {
    return S3_URL + name;
  } else {
    return img;
  }
}

const parseDayTime = (str, checkIcon) => {
  let checkIsnan = isNaN(str);
  {
    if (!checkIsnan) {
      str = str.slice(0, 3);
      let strArr = str?.split('');
      const checkValues = { 0: CloseSmall, 1: checkIcon ?? checkmarkBlackSM };
      const checkIsActive = { 0: '', 1: 'milestoneAchived' };

      let timeString = [{ Morning: '' }, { Afternoon: '' }, { Night: '' }];
      if (str) {
        strArr.map((item, index) => {
          let objectKeys = Object.keys(timeString[index])[0];
          timeString[index][objectKeys] = checkValues[item];
          timeString[index].isActive = checkIsActive[item];
        });
        return timeString;
      }
      return timeString;
    }
  }
};

export function dayTime(str, checkIcon) {
  return parseDayTime(str, checkIcon);
}

export const showMedicineTimeString = (str) => {
  if (str) {
    let strArr = str.split('');
    let obj = {
      0: 'Morning',
      1: 'Afternoon',
      2: 'Night',
    };
    let newArr = [];
    strArr.map((item, index) => {
      let intItem = parseInt(item);
      if (!!intItem) {
        newArr.push(obj[index]);
      }
    });
    return newArr;
  }
  return [];
};

export function subtractMonths(month, date = new Date()) {
  if (month == 1) {
    date.setDate(date.getDate() - 29);
  } else {
    date.setMonth(date.getMonth() - month);
  }
  return date.toISOString();
}

export const subtractWeeks = (days, date) => {
  let dt = new Date();
  dt.setDate(dt.getDate() - days);
  dt.toLocaleString();
  return new Date(dt).toISOString();
};

export const getDateTime = (date) => {
  const newDate = new Date();
  var date = new Date(date);

  let currentDay = newDate.getDate().toString().padStart(2, '0');
  var day = date.getDate().toString().padStart(2, '0');
  var month = date.toLocaleString('default', { month: 'short' });
  var year = date.getFullYear();
  if (currentDay == day) {
    return 'Today ' + date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  } else {
    return `${day} ${month} ${year}`;
  }
};

const getDate = (date) => {
  let nDate = new Date(date).toDateString();
  nDate = new Date(nDate);
  return nDate;
};

export const filterDateBaseData = (data, key, startDate = new Date(), endDate = new Date()) => {
  let enDate = getDate(endDate);
  let strDate = getDate(startDate);

  let newData = [];
  data.map((item) => {
    let recordDate = getDate(item[key]);
    if (enDate <= recordDate && strDate >= recordDate) {
      newData.push(item);
    }
  });

  return newData;
};

export const iconImages = () => {
  return {
    mood: {
      10: moodVal10,
      20: moodVal20,
      30: moodVal30,
      40: moodVal40,
      50: moodVal50,
      60: moodVal60,
      70: moodVal70,
    },
    energy: {
      10: energy10,
      40: energy20,
      80: energy30,
      100: energy40,
    },
  };
};

export const avrageDataByDate = (data) => {
  let objData = {};
  let dataSet = new Set();
  let newArr = [];

  if (Array.isArray(data)) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    data.map((item) => {
      let date = new Date(item.record_date).toLocaleDateString(undefined, options);
      if (objData[date]) {
        objData[date] = { ...objData[date], count: objData[date].count + 1 };
        objData[date] = { ...objData[date], date: item.record_date };
        objData[date].value = parseInt(+item.value + +objData[date].value);
      } else {
        objData[date] = { ...objData[date], date: item.record_date };
        objData[date] = { ...objData[date], value: +item.value };
        objData[date] = { ...objData[date], count: 1 };
      }
      dataSet.add(date);
    });
  }

  [...dataSet].map((item) => {
    let data = {};

    let val = objData[item].value / objData[item].count;
    objData[item].value = val.toFixed(1);

    data[item] = objData[item];
    newArr.push(data);
  });

  return newArr;
};

export const addDataByDate = (data) => {
  let objData = {};
  let dataSet = new Set();
  let newArr = [];

  if (Array.isArray(data)) {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    data.map((item) => {
      let date = new Date(item.record_date).toLocaleDateString(undefined, options);
      if (objData[date]) {
        objData[date] = { ...objData[date], date: item.record_date };
        objData[date].value = parseInt(+item.value + +objData[date].value);
      } else {
        objData[date] = { ...objData[date], date: item.record_date };
        objData[date] = { ...objData[date], value: +item.value };
      }
      dataSet.add(date);
    });
  }

  [...dataSet].map((item) => {
    let data = {};
    data[item] = objData[item];
    newArr.push(data);
  });

  return newArr;
};

export let timeMinutesSeconds = (dateString) => {
  const dateObject = new Date(dateString);
  const minutes = dateObject.getMinutes();
  const seconds = dateObject.getSeconds();
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;
  return `${formattedMinutes}:${formattedSeconds}`;
};

let localFormatDate = (datestr) => {
  let date = new Date(datestr);
  let year = date.getFullYear();
  let month = date.getMonth();
  month += 1;
  let day = date.getDate();
  return `${day}/${month}/${year}`;
};

let localFormatDateSingle = (datestr) => {
  let date = new Date(datestr);
  let year = date.getFullYear();
  let month = date.getMonth();
  month += 1;
  let day = date.getDate();
  return `${year}-${month}-${day}`;
};

export const dateFormatString = (startDate, endDate = new Date()) => {
  let strDate = localFormatDate(startDate);
  let eDate = localFormatDate(endDate);
  return strDate + '-' + eDate;
};
export const dateFormatStringSingleDate = (startDate) => {
  let strDate = localFormatDateSingle(startDate);
  return strDate;
};

export const prepareGraphData = (data) => {
  let graphData = data;
  if (!graphData || graphData.length == 0) {
    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let currentMonth = new Date().getMonth();
    graphData = [
      ['Month', 'Value'],
      [monthNames[currentMonth], 0],
    ];
  } else {
    let filterPlots = graphData.filter((item, index) => index == 0 || item[1]);
    if (filterPlots.length == 1) {
      graphData[graphData.length - 1][1] = 0;
    }
  }
  return graphData;
};

export const genrageFilterParams = (data, qryObject, removekeys) => {
  let setObjectValue = (key, val) => {
    if (val) {
      qryObject[key] = val;
    }
  };

  Object.keys(data).map((item) => {
    if (item == 'bmi_range' && data[item].length) {
      setObjectValue('bmi_range', data[item][0]?.id);
      return;
    }

    if (item == 'date' && data[item].length) {
      let startDate = new Date(data[item][0]).toISOString();
      let endDate = new Date(data[item][1]).toISOString();
      setObjectValue('start_date', startDate);
      setObjectValue('end_date', endDate);
      setObjectValue('date', 'custom');
    }

    if (item == 'pmsDate' && data[item].length) {
      let startDate = new Date(data[item][0]).toISOString();
      let endDate = new Date(data[item][1]).toISOString();
      setObjectValue('pms_start_date', startDate);
      setObjectValue('pms_end_date', endDate);
    }
    if (item == 'prescriptionDate' && data[item].length) {
      let startDate = new Date(data[item][0]).toISOString();
      let endDate = new Date(data[item][1]).toISOString();
      setObjectValue('pms_prescription_start_date', startDate);
      setObjectValue('pms_prescription_end_date', endDate);
    }

    if (item == 'pmsConsultDate' && data[item].length) {
      let startDate = new Date(data[item][0]).toISOString();
      let endDate = new Date(data[item][1]).toISOString();
      setObjectValue('pms_consult_start_date', startDate);
      setObjectValue('pms_consult_end_date', endDate);
    }

    if (removekeys.includes(item)) {
      return;
    }

    setObjectValue(item, data[item]);
  });
};

export const checkObjectLength = (filterData, ignoreKeys) => {
  let value = Object.keys(filterData).map((item) => {
    if (Array.isArray(filterData[item])) {
      if (filterData[item].length) {
        if (ignoreKeys.includes(item)) {
          return false;
        }
        return true;
      } else {
        return false;
      }
    } else if (typeof filterData[item] == 'string') {
      if (filterData[item].length > 0) {
        return true;
      } else {
        return false;
      }
    }
  });

  if (value.includes(true)) {
    return true;
  }
  return false;
};

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
};

export const handleOnBodyClick = (func, arg) => {
  document.body.addEventListener('click', (e) => {
    e.stopPropagation();
    func && func(arg);
  });
};
export const stoppropagation = (id, ref) => {
  let dataContainer;
  if (ref) {
    dataContainer = id;
  } else {
    dataContainer = document.getElementById(id);
  }

  dataContainer.addEventListener('click', (e) => {
    e.stopPropagation();
  });
};

export const clickOnElmOnBodyClick = (elmId, innerElm, isInnerClass) => {
  let outerElm = document.getElementById(elmId);
  let targetElm = outerElm;
  if (innerElm) {
    if (isInnerClass) {
      let elm = document.getElementsByClassName(innerElm)[0];
      targetElm = elm;
    } else {
      let elm = document.getElementById(elmId);
      targetElm = elm;
    }
  }

  document.body.addEventListener('click', (e) => {
    e.stopPropagation();
    targetElm && targetElm.click();
  });
};

export const replaceWithRole = (sentance) => {
  let senArr = sentance?.split(' ');
  let allRolesArr = Object.keys(roles);
  let newStringArr = senArr.map((item, index) => {
    if (allRolesArr.includes(item.toLowerCase())) {
      return teamRoles(item);
    } else {
      return item;
    }
  });
  return newStringArr.join(' ');
};

export const teamRoles = (role) => {
  let roleStr = role?.toLowerCase();
  if (roles[roleStr]) {
    return roles[roleStr];
  } else {
    return titlizeString(role);
  }
};

export const getReadTime = (time) => {
  if (time) {
    let seconds = time % 60;
    let minutes = Math.floor(time / 60);
    let hours = Math.floor(minutes / 60);
    if (hours) {
      minutes = minutes % 60;
    }

    if (seconds) {
      seconds += ' sec ';
    } else {
      seconds = '';
    }

    if (minutes) {
      minutes += ' min ';
    } else {
      minutes = '';
    }

    if (hours) {
      hours += ' hr ';
    } else {
      hours = '';
    }

    return hours + minutes + seconds;
  } else {
    return '';
  }
};

export const showAmPm = (time) => {
  let time1, format;
  if (time >= 12) {
    time1 = time - 12;
    if (time1 == 0) {
      time1 = 12;
    }
    format = ' PM';
  } else {
    format = ' AM';
    time1 = time;
    if (time1 == 0) {
      time1 = 12;
    }
  }
  if (time1 < 10) {
    let num = Number(time1);
    time1 = '0' + num;
  }

  return time1 + format;
};

export const addZerosinTime = (time) => {
  let strtime = String(time);
  let strtime2 = strtime;
  let zeros = 4 - strtime.length;
  if (zeros > 0) {
    strtime2 = new Array(zeros).fill('0').join('') + strtime;
  }
  return strtime2;
};

export const sumVal = (val) => {
  if (val) {
    val = String(val);
    let newVal = val.split('').reduce((a, b) => +a + +b);
    return newVal;
  } else {
    return '';
  }
};

export const getGoalValuePercent = (val1, val2) => {
  if (val1 && val2) {
    let newval1 = sumVal(val1);
    let newval2 = sumVal(val2);
    let persent = (newval1 / newval2) * 100;
    return Math.floor(persent);
  } else {
    return 0;
  }
};

export const splitArrData = (str, split) => {
  let arr = [];
  if (str && typeof str == 'string') {
    str = str.split(split);
    str.map((item) => {
      if (item && item.trim().length) {
        arr.push(item.trim());
      }
    });
  }

  return arr;
};

export const splitArrObjData = (str, split, key) => {
  let arr = [];
  if (str && typeof str == 'string') {
    str = str.split(split);
    str.map((item) => {
      if (item && item.trim().length) {
        let obj = { [key]: item.trim(), value: item.trim().toLowerCase() };
        arr.push(obj);
      }
    });
  }

  return arr;
};

export const checkIsUrl = (string) => {
  try {
    return Boolean(new URL(string));
  } catch (e) {
    return false;
  }
};

export let checkunique = (data = [], newData = []) => {
  let tmpData = [];
  data.map((item) => {
    let newData1 = newData.find((item1) => item1.id == item.id);
    if (!newData1) {
      tmpData.push(item);
    }
  });

  return [...tmpData, ...newData];
};

export const CarePlanNotification = (data) => {
  const today = new Date();
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);
  const res = { todayData: [], yesterdayData: [], allNotifications: [] };
  {
    data.map((item) => {
      if (dateFormatStringSingleDate(item.created_at) == dateFormatStringSingleDate(today)) {
        res['todayData'].push(item);
      } else if (dateFormatStringSingleDate(item.created_at) == dateFormatStringSingleDate(yesterday)) {
        res['yesterdayData'].push(item);
      } else {
        res['allNotifications'].push(item);
      }
    });
  }
  return res;
};

export const valiDateData = (data = []) => {
  let newData = [];
  data.map((item) => {
    if (item && typeof item == 'object') {
      newData.push(item);
    }
  });
  return newData;
};

export const showData = {
  settings: {
    mobileConf: {
      title: 'behaviour_questions',
      show: false,
    },
    show: true,
  },
  patient: {
    title: 'behaviour_questions',
    show: false,
  },
};
export const pdfData = (pdfData) => {
  let blobURL = URL.createObjectURL(new Blob([pdfData], { type: 'application/pdf' }));
  let iframe = document.createElement('iframe'); //load content in an iframe to print later
  document.body.appendChild(iframe);
  iframe.style.display = 'none';
  iframe.src = blobURL;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
};

export const exportToCsv = (data, fileName) => {
  const csvContent = 'data:text/csv;charset=utf-8,' + data;
  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const pdfDataDirectDownload = (pdfData, fileName) => {
  const blob = new Blob([pdfData], { type: 'application/pdf' });
  const blobURL = URL.createObjectURL(blob);

  const a = document.createElement('a');
  a.href = blobURL;
  a.download = fileName || 'document.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);

  // Clean up the blob URL
  URL.revokeObjectURL(blobURL);
};

export const handleResult = (result, msg, setShowAlert, setAlertType, serErrors, helperIndex, navigate, key) => {
  if (result?.data?.status) {
    if (result?.data?.status >= 200 && result?.data?.status <= 300) {
      setShowAlert && setShowAlert(msg);
      setAlertType && setAlertType('alert-success');
      if (navigate) {
        setTimeout(() => {
          navigate();
        }, 2500);
      }
    } else {
      let alertmsg = result?.data?.data[key] ?? result?.data?.data?.errors?.title ?? result?.data?.data?.errors.detail;

      if (result?.data?.status === 413) {
        alertmsg = 'image size is too large';
      } else if (result?.data?.status === 500) {
        alertmsg = 'Internal server Error';
      } else {
        serErrors && serErrors(result?.data?.data?.errors);
      }
      setAlertType && setAlertType('alert-danger');
      // setShowAlert && setShowAlert(alertmsg);
    }
  }
};

export const formulationData = () => {
  return [
    { title: 'Tablet', id: 'tablet' },
    { title: 'Capsule', id: 'capsule' },
    { title: 'Injection', id: 'injection' },
    { title: 'Syrup', id: 'syrup' },
    { title: 'Cream / Ointment', id: 'cream/ointment' },
    { title: 'Spray', id: 'spray' },
    { title: 'Solution', id: 'solution' },
    { title: 'Gel', id: 'gel' },
    { title: 'Powder', id: 'powder' },
    { title: 'Granules', id: 'granules' },
    { title: 'Drops', id: 'drops' },
    { title: 'Patch', id: 'patch' },
    { title: 'Device', id: 'device' },
  ];
};
