import React from "react";
import "./listCard.css";
import { useGetCockpitDataQuery } from "redux/cockpitSlice";

function ListCard(props) {
  const { cartTitle, listData, deleteData, activeLink, setActiveLink } = props;

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;

  const listDataMap = () => {
    return listData?.map((item, index) => {
      let disableReturn = false;

      if (configData && Array.isArray(configData)) {
        configData.map((confItem) => {
          if (confItem.key == item.condition) {
            disableReturn = !confItem.isActive;
          }
        });
      } else if (item.condition) {
        return;
      }

      if (disableReturn) {
        return;
      }

      const className = item.link == activeLink ? "custom-list-group-active" : "";
      return (
        <div key={index}>
          <li className={`custom-list-group-item ${className}`} onClick={() => setActiveLink(item.link)}>
            <div className="listItemTitle">{item.title}</div>
          </li>
        </div>
      );
    });
  };

  return (
    <div className="cardsParentDiv">
      <div className="cardTag">
        <ul className="custom-list-group">{listDataMap()}</ul>
      </div>
    </div>
  );
}

export default ListCard;
