import React from 'react';
import { editTheme, trashRed } from 'res/images';
import CommonToolTip from '../CommonToolTip';
import { dateFormat, teamRoles, timeFormat } from '../Helper';

function AdherenceList(props) {
  const { tableList, updateDataModalOpen, deleteData } = props;
  return (
    <table className='w-100 table'>
      {tableList.map((item) => (
        <tr key={item.id} className='my-3 font-12px'>
          <td colSpan={2}>
            <div className='d-flex mb-1 align-items-center justify-content-between'>
              <div className='capitalize lightGrayBadge'>
                <div>
                  <strong> {item.title}</strong>{' '}
                </div>
                <div className='badge capitalize lightGrayBadge'>
                  {item.start_range}% - {item.end_range}%
                </div>
              </div>
              <div
                className='d-flex text-muted justify-content-between align-items-center medicineDataList'
                style={{ gap: '10px' }}
              >
                <div className='capitalize'>
                  <p>
                    Created by · {item?.admin?.full_name} ({teamRoles(item?.admin?.role)})
                  </p>
                  <p>
                    Created at · {dateFormat(item.created_at)} . {timeFormat(item.created_at)}
                  </p>
                </div>
                <div className='action-images d-flex'>
                  <CommonToolTip
                    placement={'top'}
                    item={
                      <img
                        onClick={() => {
                          updateDataModalOpen(item);
                        }}
                        src={editTheme}
                      />
                    }
                    showValue={'Edit'}
                  />

                  <CommonToolTip
                    placement={'top'}
                    item={
                      <img
                        onClick={() => {
                          let windoConfirm = window.confirm('Are you sure you want to delete adherence?');
                          if (windoConfirm) {
                            deleteData(item.id);
                          }
                        }}
                        src={trashRed}
                      />
                    }
                    showValue={'Delete'}
                  />
                </div>
              </div>
            </div>
          </td>
        </tr>
      ))}
    </table>
  );
}

export default AdherenceList;
