import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AdminPanelHeader, DashBoardHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import { filters, search } from 'res/images';
import { Dropdown } from 'rsuite';
import CoreInputBox from '../CoreInputBox';
import { dateFormat, exportToCsv, timeFormat, titlizeString } from '../Helper';
import OrdersDetail from './OrdersDetail';
import Loader from '../Loader';
import SearchBox from '../common/SearchBox';
import Pagination from '../Pagination';
import CommonAlert from '../CommonAlert';
function Orders() {
  const ApiClient = useApiManager();

  const [orderList, setOrderList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [searchStr, setSearchStr] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [alert, setAlert] = useState({});
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [orderedDetail, setOrderedDetail] = useState({});

  const value = useRef('');

  const headerData = [
    {
      title: 'Overview',
      link: 'Orders',
    },
  ];

  useEffect(() => {
    if (!openDetailModal) searchOrders();
  }, [searchStr, currentPage, openDetailModal]);

  const searchOrders = () => {
    setIsLoading(true);
    let params = { page: currentPage, search_str: searchStr };
    ApiClient.searchOrders(params)
      .then((res) => {
        setIsLoading(false);
        if (value.current === params.search_str) {
          setTotalPages(res.headers['total-pages']);
          setOrderList(res.data);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('getOrders', err.response);
      });
  };

  const exportCsv = () => {
    setIsLoading(true);
    ApiClient.exportOrderCsv()
      .then((res) => {
        exportToCsv(res.data, `Med_order_${dateFormat(new Date())}-${timeFormat(new Date())}.csv`);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.warn('exportCsv', err.response);
      });
  };

  const renderSearchBox = () => {
    return (
      <div className='w-100'>
        <CoreInputBox
          placeholder='Search a Order by Patent name or Order ID'
          setValue={(val) => {
            setCurrentPage(1);
            value.current = val;
            setSearchStr(val);
          }}
          value={searchStr}
        />
      </div>
    );
  };

  const renderHeader = () => {
    return (
      <div className='headerFiles'>
        <AdminPanelHeader hideSearch iconTitle='shopping_cart' title='Orders' searchBox={renderSearchBox()} />
      </div>
    );
  };

  const data = [
    ['Task', 'Hours per Day'],
    ['Partial', 84],
    ['Cancelled', 10],
    ['Pending', 58],
    ['Completed', 80],
    // CSS-style declaration
  ];

  const options = {
    pieHole: 0.7,
    is3D: false,
    pieSliceText: 'none',
    className: 'chart-headerData',
    legend: { position: 'none' },
    colors: ['#F3D27E', '#BFBDBD', '#68AA52', '#D01C1C'],
  };

  const leftSideData = [
    {
      title: 'Apply Filter',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filters,
    },
  ];
  const rightSideData = [
    {
      title: 'Sort By',
      className: 'themeColor boldFont',
      showBtn: true,
      icon: filters,
    },
  ];
  const renderDashBoardHeader = useMemo(() => {
    return <DashBoardHeader leftData={leftSideData} rightData={rightSideData} />;
  }, []);

  const handleDetail = (orderDetail) => {
    let newOrderDetail = orderList[orderDetail];
    setOrderedDetail(newOrderDetail);
    setOpenDetailModal(true);
  };
  const renderSearchInput = () => {
    return (
      <div className='mb-3'>
        <CoreInputBox
          placeholder='Search '
          // value={searchInput}
          // setValue={(text) => {
          //   clearTimeout(timeOut);
          //   setSearchInput(text);
          // }}
          leftIcon={search}
        />
      </div>
    );
  };

  const orderStatus = [
    {
      title: 'Pending',
      id: 'to_order',
    },
    {
      title: 'Order Placed',
      id: 'order_placed',
    },
    {
      title: 'Cancelled',
      id: 'cancelled',
    },
    {
      title: ' Partially Ordered',
      id: 'ordered_partially',
    },
  ];

  const SortBy = () => (
    <Dropdown title='Sort By' className='sortBy-btn ms-3' placement='bottomEnd'>
      <Dropdown.Item>Newest to oldest</Dropdown.Item>
      <Dropdown.Item>Amt (High to Low)</Dropdown.Item>
      <Dropdown.Item>Amt (Low to High)</Dropdown.Item>
    </Dropdown>
  );
  const showOrders = () => (
    <>
      <div className='articleHeader stickyHeader'>
        {renderHeader()}
        <div className='hrLine mb-3' />
        {/* <RowLinks data={headerData} /> */}
      </div>
      <div className='px-4 wrapperContent'>
        {/* <div className='d-flex justify-content-between w-100  mb-4'>
          <div className='d-flex orderChart justify-content-between  align-items-center mt-3'>
            <Chart chartType='PieChart' width='200px' height='200px' className='d-flex' data={data} options={options} />
            <div className=' flex-row'>
              <div className='d-flex w-100 mb-3'>
                <div className='order-chart-card'>
                  Pending <br /> <span>30</span>
                </div>
                <div className='order-chart-card card-green'>
                  Completed <br /> <span>80</span>
                </div>
              </div>
              <div className='d-flex'>
                <div className='order-chart-card card-yellow'>
                  Partial <br /> <span>40</span>
                </div>
                <div className='order-chart-card card-gray'>
                  Cancelled <br /> <span>80</span>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column justify-content-between'>
            {renderSearchInput()}
            <div className='d-flex justify-content-end'>
              {renderDashBoardHeader}
              <div className=''>{SortBy()}</div>
            </div>
          </div>
        </div> */}
        <div className='d-flex justify-content-end mb-4'>
          <div
            onClick={() => {
              exportCsv();
            }}
            className='reusableBtnActive  reusableBtn '
          >
            Export
          </div>
        </div>
        {alert.type == 'alert-success' && (
          <CommonAlert className='mt-3' setIsShowAlert={setAlert} isShowAlert={alert.message} alertType={alert.type} />
        )}
        <div className='table-responsive order-table'>
          <table class='table'>
            <thead>
              <tr>
                <th scope='col'>Order ID</th>
                <th scope='col'>Date</th>
                <th scope='col'>Patient</th>
                <th scope='col'>Address</th>
                <th scope='col'>Ph. Number</th>
                <th scope='col'>Amount</th>
                <th scope='col'>Status</th>
                <th scope='col'>Details</th>
              </tr>
            </thead>
            <tbody>
              {orderList?.length || isLoading ? (
                orderList.map((item, index) => {
                  return (
                    <tr key={item.order_id}>
                      <td>{item.order_id}</td>
                      <td width={120}>{dateFormat(item.created_at) + ' ' + timeFormat(item.created_at)}</td>
                      <td>
                        <strong>{item?.user?.full_name}</strong>
                      </td>
                      <td width={300}>{item.shipping_address}</td>
                      <td>{item?.user?.phone}</td>
                      <td width={150}>₹ {parseFloat(item.fulfilled_total?.toFixed(2))}</td>
                      <td>
                        <span className={`align-items-center badge capitalize active ${item.ehr_order_status}`}>
                          {/* {titlizeString(
                            item.ehr_order_status == 'to_order'
                              ? 'pending'
                              : item.ehr_order_status == 'ordered_partially'
                              ? 'Partially Ordered'
                              : item.ehr_order_status
                          )} */}

                          {orderStatus.find((item2) => item2.id == item.ehr_order_status)?.title}
                        </span>
                      </td>
                      <td>
                        <div
                          onClick={() => {
                            handleDetail(index);
                          }}
                        >
                          <span class='material-icons'>open_in_full</span>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={8} className='text-center'>
                    {' '}
                    No data found
                  </td>
                </tr>
              )}

              {/* <tr>
                <td>1234</td>
                <td width={120}>17 Oct 2023 10:54 AM</td>
                <td>
                  <strong>Suraj Singh</strong>
                </td>
                <td width={300}>E-507 Sed ut perspiciatis unde omnis Sed ut perspiciatis unde omnis</td>
                <td>9876543210</td>
                <td>₹ 2345</td>
                <td>
                  <span className=' align-items-center badge capitalize health_coach'>Pending</span>
                </td>
                <td>
                  <div onClick={handleDetail}>
                    <span class='material-icons'>open_in_full</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>1234</td>
                <td width={120}>17 Oct 2023 10:54 AM</td>
                <td>
                  <strong>Suraj Singh</strong>
                </td>
                <td width={300}>E-507 Sed ut perspiciatis unde omnis Sed ut perspiciatis unde omnis</td>
                <td>9876543210</td>
                <td>₹ 2345</td>
                <td>
                  <span className=' align-items-center badge capitalize draft'>Partially Ordered</span>
                </td>
                <td>
                  <div onClick={handleDetail}>
                    <span class='material-icons'>open_in_full</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td>1234</td>
                <td width={120}>17 Oct 2023 10:54 AM</td>
                <td>
                  <strong>Suraj Singh</strong>
                </td>
                <td width={300}>E-507 Sed ut perspiciatis unde omnis Sed ut perspiciatis unde omnis</td>
                <td>9876543210</td>
                <td>₹ 2345</td>
                <td>
                  <span className=' align-items-center badge capitalize lightGrayBadge'>Partially Ordered</span>
                </td>
                <td>
                  <div onClick={handleDetail}>
                    <span class='material-icons'>open_in_full</span>
                  </div>
                </td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </div>
      <div className='d-flex justify-content-end mb-4'>
        <Pagination
          data={orderList}
          length={orderList?.count}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </>
  );
  return (
    <div className='wrapper'>
      <Loader show={isLoading} />
      {showOrders()}
      <OrdersDetail
        setAlert={setAlert}
        alert={alert}
        openDetailModal={openDetailModal}
        setOpenDetailModal={setOpenDetailModal}
        orderedDetail={orderedDetail}
        setOrderedDetail={setOrderedDetail}
        getOrders={searchOrders}
      />
    </div>
  );
}

export default Orders;
