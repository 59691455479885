import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { editTheme, trashRed } from 'res/images';
import { Modal } from 'rsuite';
import { CommonBorderBox, EmptyView, Pagination } from './components';
import CommonToolTip from './components/CommonToolTip';
import { dateFormat, teamRoles, timeFormat } from './components/Helper';
import ServiceCategoriesForm from './ServiceCategoriesForm';

function ServiceCategoryView(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType } = props;
  const [prcData, setPrcData] = useState('');
  const [updatePrcData, setupdatePrcData] = useState('');
  const [error, seterror] = useState('');
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [listData, setListData] = useState([]);
  const [selectedCatObj, setSelectedCatObj] = useState({});
  const ApiClient = useApiManager();

  useEffect(() => {
    getPRClist();
  }, [currentPage]);

  const getPRClist = () => {
    setIsLoading(true);
    ApiClient.getPRC(currentPage)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setListData(res.data);
        setPrcData({});
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleModalClose = () => {
    setOpen(false);
    setPrcData({});
    setupdatePrcData({});
    seterror('');
  };

  const createPRC = () => {
    seterror([]);
    setIsLoading(true);
    const body = {
      title: prcData.title,
      enable: prcData.enable,
      service: prcData.service || '',
    };
    ApiClient.createPRC(body)
      .then((res) => {
        setIsLoading(false);
        setCurrentPage(1);
        getPRClist();
        setAlertType('alert-success');
        setIsShowAlert('Service Category Created Successfully.');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        if (err.response.data.detail) {
          setIsShowAlert(err.response.data.detail);
        } else {
          seterror(err.response.data.errors);
        }
        console.log(err);
      });
  };

  const updatePRC = () => {
    const body = {
      title: updatePrcData.title,
      enable: updatePrcData.enable,
      service: updatePrcData.service || '',
    };
    ApiClient.updatPRCDATA(updatePrcData?.id, body)
      .then((res) => {
        setAlertType('alert-success');
        setIsShowAlert('Service Category Updated Successfully.');
        getPRClist();
        handleModalClose();
      })
      .catch((err) => {
        setAlertType('alert-danger');
        if (err.response?.data?.detail) {
          setIsShowAlert(err.response.data.detail);
          setOpen(false);
        } else if (err?.response?.data?.errors) {
          seterror(err.response.data.errors);
        } else {
          seterror(err.response.data);
        }
      });
  };

  const deleteData = (id) => {
    seterror([]);
    const windowConfirm = window.confirm('Are you sure you want to delete ?');
    if (windowConfirm) {
      ApiClient.deletePRC(id)
        .then((res) => {
          setAlertType('alert-success');
          setIsShowAlert('Service Category Deleted Successfully.');
          getPRClist();
        })
        .catch((err) => {
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.detail || err.response.data.message);
          console.log(err);
        });
    }
  };

  const updateModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          {renderServiceCategory(updatePrcData, setupdatePrcData, true)}
        </Modal>
      </div>
    );
  };

  const updateDataModalOpen = (data) => {
    setOpen(true);
    setupdatePrcData({ ...data, service: data?.service?.id });
    seterror('');
  };

  const listDataMap = () => {
    return listData.map((item, index) => {
      return (
        <tr key={index} className='my-3 font-12px'>
          <table className='table'>
            <tr>
              <td colSpan={2}>
                <div className='d-flex mb-1 align-items-center justify-content-between'>
                  <div className='align-items-flex-start'>
                    <div className='medicinename'> {item.title}</div>
                    <div className='badge lightGrayBadge mt-1'>{item.description}</div>
                  </div>
                  <div
                    className='d-flex text-muted justify-content-between align-items-center medicineDataList'
                    style={{ gap: '10px' }}
                  >
                    <div>
                      Created by · {item.admin ? item.admin.full_name : item?.created_by?.full_name} (
                      {teamRoles(item.admin ? item.admin.role : item?.created_by?.role)})
                      <div>
                        Created <span className='lowercase'> at </span>· {dateFormat(item?.created_at)}
                        &nbsp;
                        {timeFormat(item.created_at)}
                      </div>
                    </div>
                    <div className='action-images d-flex'>
                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              updateDataModalOpen(item);
                              setSelectedCatObj(item.service);
                            }}
                            src={editTheme}
                          />
                        }
                        showValue={'Edit'}
                      />

                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              deleteData(item.id);
                            }}
                            src={trashRed}
                          />
                        }
                        showValue={'Delete'}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </tr>
      );
    });
  };

  const patination = () => {
    return (
      <Pagination
        seterror={seterror}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };
  const showDepartmentsList = () => {
    return <>{listDataMap()}</>;
  };
  const renderEmptyView = () => {
    return <EmptyView title='No data found' />;
  };

  const renderServiceCategory = (data, setData, isUpdate) => {
    return (
      <div className='commonBorderBox settingFormOutline mb-4'>
        <ServiceCategoriesForm
          error={error}
          selectedCatObj={selectedCatObj}
          setSelectedCatObj={setSelectedCatObj}
          currentPage={currentPage}
          setListData={setListData}
          valueObj={data}
          setValueObj={setData}
          isUpdate={isUpdate}
          setTotalPages={setTotalPages}
          onClick={isUpdate ? updatePRC : createPRC}
        />
      </div>
    );
  };

  return (
    <div className='medicine-form'>
      <CommonBorderBox>{renderServiceCategory(prcData, setPrcData)}</CommonBorderBox>
      <div className='table-responsive'>
        <table className='w-100'>{listData?.length > 0 ? showDepartmentsList() : renderEmptyView()}</table>
      </div>

      <div className='d-flex justify-content-end mt-3'>{patination()}</div>
      {updateModal()}
    </div>
  );
}

export default ServiceCategoryView;
