import React, { useEffect, useState } from 'react';
import CommonToolTip from '../CommonToolTip';
import { Button, Modal } from 'rsuite';
import WebEngageForm from './WebEngageForm';
import useApiManager from 'networking/ApiManager';
import { titlizeString } from '../Helper';
import CommonAlert from '../CommonAlert';

function WebEngageView(props) {
  const { editTheme, status, activeVerticalLink, fcm } = props;
  const [open, setOpen] = React.useState(false);
  const [backdrop] = React.useState('static');
  const [createData, setCreateData] = useState({});
  const [size, setSize] = React.useState();
  const ApiClient = useApiManager();
  const [webListData, setWebListData] = useState([]);
  const [webListid, setWebListId] = useState('');
  const [webListUpdate, setWebUpdateList] = useState('');
  const [singleData, setSingleData] = useState('');

  const [errors, setErrors] = useState('');
  const [title, setTitle] = useState('');
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');

  const handleOpen = (value) => {
    setSize(value);
    setOpen(true);
  };
  useEffect(() => {
    webListid && getSingleDataWebEngage();
  }, [webListid, fcm]);

  useEffect(() => {
    getGWebEngage();
  }, [activeVerticalLink, fcm]);

  const getGWebEngage = () => {
    // setIsLoading(true);
    if (fcm) {
      ApiClient.getFcmData(activeVerticalLink)
        .then((res) => {
          // setIsLoading(false);
          setWebListData(res.data);
        })
        .catch((err) => {
          // setIsLoading(false);
        });
    } else {
      ApiClient.getWebEngageData(activeVerticalLink)
        .then((res) => {
          // setIsLoading(false);
          setWebListData(res.data);
        })
        .catch((err) => {
          // setIsLoading(false);
        });
    }
  };

  const updateDataModalOpen = (data) => {
    const newData = data?.notification_messages?.find((item) => item.channel == 'sms');
    setCreateData({ ...newData });
  };

  const createWebEngage = () => {
    // setIsLoading(true);
    const newData = { ...createData };
    newData.channel = createData?.channel ? createData.channel : 'sms';
    if (fcm) {
      ApiClient.createFcm(activeVerticalLink, webListid, newData)
        .then((res) => {
          setOpen(false);
          getGWebEngage();
          setAlertType('alert-success');
          setIsShowAlert('WebEngage Created successfully');
          // setIsLoading(false);
        })
        .catch((err) => {
          setErrors(err.response.data);
          // setIsLoading(false);
          // setAlertType('alert-danger');
          // if (err.response.status == 500) {
          //   setIsShowAlert('Internal server error');
          // } else {
          //   setIsShowAlert(err.response.data.detail);
          // }
        });
    } else {
      ApiClient.createWebEngage(activeVerticalLink, webListid, newData)
        .then((res) => {
          setOpen(false);
          getGWebEngage();
          setAlertType('alert-success');
          setIsShowAlert('WebEngage Created successfully');
          // setIsLoading(false);
        })
        .catch((err) => {
          setErrors(err.response.data);
          // setIsLoading(false);
          // setAlertType('alert-danger');
          // if (err.response.status == 500) {
          //   setIsShowAlert('Internal server error');
          // } else {
          //   setIsShowAlert(err.response.data.detail);
          // }
        });
    }
  };

  const getSingleDataWebEngage = () => {
    // setIsLoading(true);
    if (fcm) {
      ApiClient.getSingleFcmData(activeVerticalLink, webListid)
        .then((res) => {
          setTitle(res.data.event_title);
          // setIsLoading(false);
          setSingleData(res.data);
        })
        .catch((err) => {
          // setIsLoading(false);
        });
    } else {
      ApiClient.getSingleWebEngageData(activeVerticalLink, webListid)
        .then((res) => {
          setTitle(res.data.event_title);
          // setIsLoading(false);
          setSingleData(res.data);
        })
        .catch((err) => {
          // setIsLoading(false);
        });
    }
  };

  const renderList = (item) => {
    const newItem = item?.notification_messages?.filter((element) => element?.is_enable);
    return (
      <div className='table-responsive w-100'>
        <table className='w-100 table'>
          <tr>
            <td className='w-100 capitalize'>
              {titlizeString(item.event_title)}
              <div class='editInput px-0'>
                <span
                  className={`badge ${
                    newItem && newItem.some((e) => e.channel === 'sms') ? 'bg-info' : 'lightGrayBadge'
                  } capitalize published`}
                >
                  SMS
                </span>
                <span
                  className={`badge ${
                    newItem && newItem.some((e) => e.channel === 'email') ? 'bg-info' : 'lightGrayBadge'
                  } capitalize published`}
                >
                  Email
                </span>
                <span
                  className={`badge ${
                    newItem && newItem.some((e) => e.channel === 'whatsapp') ? 'bg-info' : 'lightGrayBadge'
                  } capitalize published`}
                >
                  Whatsapp
                </span>
                <span
                  className={`badge ${
                    newItem && newItem.some((e) => e.channel === 'push') ? 'bg-info' : 'lightGrayBadge'
                  } capitalize published`}
                >
                  Push
                </span>
                <span
                  className={`badge capitalize ${
                    newItem && newItem.some((e) => e.channel === 'web_push') ? 'bg-info' : 'lightGrayBadge'
                  }  `}
                >
                  Web Push
                </span>
              </div>
            </td>
            <td>
              <div
                onClick={() => {
                  handleOpen('md');
                  setWebListId(item?.id);
                  updateDataModalOpen(item);
                  setWebUpdateList(item);
                }}
                className='pointer-auto'
              >
                <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
              </div>
            </td>
          </tr>
        </table>
      </div>
    );
  };

  return (
    <>
      <div className='d-flex  align-items-start'>
        <div className='settingsShowData webEngage'>
          <div className='mt-2'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
          <div class='formTitle pb-3'>{activeVerticalLink == 'patient' ? 'Patient' : 'Admin'}</div>
          <span>{webListData.length ? '' : 'No Data Found'}</span>
          <div className='tagsView pt-0 align-items-start'>
            {webListData?.map((item) => {
              return renderList(item);
            })}
            <Modal size={size} backdrop={backdrop} keyboard={false} open={open} onClose={() => setOpen(false)}>
              <Modal.Header>
                <Modal.Title>
                  <div class='formTitle border-0 mb-0'>{title}</div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <WebEngageForm
                  webListUpdate={webListUpdate}
                  errors={errors}
                  createData={createData}
                  setCreateData={setCreateData}
                />
              </Modal.Body>
              <Modal.Footer>
                <div
                  onClick={createWebEngage}
                  appearance='primary'
                  className='form-control  coreBtn text-white undefined'
                >
                  Save
                </div>
                {/* <Button onClick={handleClose} appearance='subtle'>
            Cancel
          </Button> */}
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
}

export default WebEngageView;
