import React, { useEffect, useState } from 'react';
import { Panel, PanelGroup } from 'rsuite';
import Select2DropDown from '../common/Select2DropDown';
import TagSelectPicker from '../common/TagSelectPicker';
import CoreInputBox from '../CoreInputBox';
import CoreTextArea from '../CoreTextArea';
import { splitArrData, splitArrObjData } from '../Helper';
import Loader from '../Loader';

function Accordian(props) {
  const { behaviourQuetionData, setCategoryQuestions, categoryQuestions, isLoading, setIsLoading } = props;

  useEffect(() => {
    if (behaviourQuetionData && behaviourQuetionData[0]?.behaviour_questionnaire_answers) {
      const initialCategoryQuestions = {};

      behaviourQuetionData[0]?.behaviour_questionnaire_answers.forEach((question) => {
        const category = question?.category?.title || question?.behaviour_question?.category?.title || 'Miscellaneous';
        if (!initialCategoryQuestions[category]) {
          initialCategoryQuestions[category] = {
            questions: [question],
            errors: [],
          };
        } else {
          initialCategoryQuestions[category].questions.push(question);
        }
      });

      setCategoryQuestions(initialCategoryQuestions);
    }
  }, [behaviourQuetionData]);

  const handleArrChange = (data, key, index, reset, categories) => {
    const updatedCategoryQuestions = { ...categoryQuestions };
    updatedCategoryQuestions[categories].questions[index][key] = data;
    if (reset) {
      categoryQuestions.behaviour_questionnaire_answers[index][reset] = '';
    }
    setCategoryQuestions({ ...categoryQuestions });
  };

  const handleMultiSelect = (value, key, index, categories) => {
    if (value) {
      value = value.join(' | ');
      handleArrChange(value, key, index, false, categories);
    }
  };
  const renderTypeFieldsRecommendation = (data, error, index, item, categories) => {
    let type = item?.behaviour_question?.type;
    const allTypes = {
      string: renderInputBoxRecommendation,
      text: renderTextBoxRecommendation,
      int: renderInputBoxRecommendation,
      date: renderInputBoxRecommendation,
      float: renderInputBoxRecommendation,
      multi_select: renderMultiSelectRecommendation,
      single_select: renderSingleSelectrecommendation,
    };
    if (item) {
      if (allTypes[type]) {
        return allTypes[type](error, index, data, type, item, categories);
      }
    }
  };

  const renderInputBoxRecommendation = (error, index, item, str, data, categories) => {
    return (
      <div className='w-100' style={{ position: 'relative' }}>
        <CoreInputBox
          type={str}
          validateNumbers={str == 'int'}
          validatedecimal={str == 'float'}
          placeholder='Recommendation'
          value={categoryQuestions[categories]?.questions[index]?.recommendation || ''}
          setValue={(dataIn) => {
            handleArrChange(dataIn, 'recommendation', index, false, categories);
          }}
        />
      </div>
    );
  };

  const renderTextBoxRecommendation = (error, index, item, str, data, categories) => {
    return (
      <div className='w-100'>
        <CoreTextArea
          type={str}
          validateNumbers={str == 'integer'}
          validatedecimal={str == 'float'}
          placeholder='Recommendation'
          value={categoryQuestions[categories]?.questions[index]?.recommendation}
          setValue={(data) => {
            handleArrChange(data, 'recommendation', index, false, categories);
          }}
          rows={2}
        />
      </div>
    );
  };

  const renderSingleSelectrecommendation = (error, index, newItem, _, data, categories) => {
    let arrData = splitArrObjData(data?.behaviour_question?.options, '|', 'title');
    return (
      <div className='w-100' style={{ position: 'relative' }}>
        <div className='mt-3 ' />
        <Select2DropDown
          container={document.getElementById('clinic-dropdown-container')}
          retuired
          // showMSG={newItem?.behaviour_question.length == 0 && error?.behaviour_question}
          data={arrData}
          labelKey='title'
          valueKey='value'
          placeholder='Recommendation'
          value={categoryQuestions[categories]?.questions[index]?.recommendation?.toLowerCase() || ''}
          onSelect={(id, dataObj) => {
            handleArrChange(dataObj?.title, 'recommendation', index, false, categories);
          }}
          onClean={() => {
            // Handle the clean/reset action here
            handleArrChange('', 'recommendation', index, false, categories);
          }}
          style={{ position: 'relative', width: '100%' }}
        />
        <div id='clinic-dropdown-container' style={{ position: 'absolute', bottom: '0px' }}></div>
      </div>
    );
  };

  const renderMultiSelectRecommendation = (error, index, newItem, _, data, categories) => {
    let arrData = splitArrObjData(data?.behaviour_question?.options, '|', 'title');
    let answerData = splitArrData(categoryQuestions[categories]?.questions[index]?.recommendation?.toLowerCase(), '|');
    return (
      <div className='w-100' style={{ position: 'relative' }}>
        <TagSelectPicker
          container={document.getElementById('multi-dropdown-container')}
          data={arrData}
          labelKey='title'
          valueKey='value'
          placeholder='Recommendation'
          defaultValue={answerData}
          onSelect={(val, titleArr) => {
            handleMultiSelect(titleArr, 'recommendation', index, categories);
          }}
          msgStyle={{ color: 'red' }}
          value={answerData}
          style={{ position: 'relative', width: '100%' }}
        />
        <div id='multi-dropdown-container' style={{ position: 'absolute', bottom: '0px' }}></div>
      </div>
    );
  };

  return (
    <>
      <PanelGroup accordion defaultActiveKey={0} bordered>
        {Object.keys(categoryQuestions).map((category, index) => {
          return (
            <Panel header={`${category}`} eventKey={index} id={'panel' + index}>
              <div className='accordion-part mb-3'>
                {categoryQuestions[category].questions.map((item, questionIndex) => {
                  return (
                    <>
                      {item?.behaviour_question?.question && (
                        <div className='mb-2'>
                          <strong>
                            {questionIndex + 1}. {item?.behaviour_question?.question}
                          </strong>
                        </div>
                      )}
                      <div className='d-flex mb-4 '>
                        {/* <div className='progress-tiles info-box w-50'>
                          <span>10% average weightloss</span>
                          over the patient’s life Falls in the bottom 25% of all patients
                        </div> */}
                        {renderTypeFieldsRecommendation(false, false, questionIndex, item, category)}
                      </div>
                    </>
                  );
                })}
              </div>
            </Panel>
          );
        })}
      </PanelGroup>
    </>
  );
}

export default Accordian;
