import React, { useMemo, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { redTrashIcon, reloadBlack, upload } from 'res/images';
import BorderBtnWithBg from './BorderBtnWithBg';
import './dragAndDropFiles.css';

function DragAndDropFiles(props) {
  const {
    showLocalFile,
    removeImage,
    accept,
    setServerImage,
    fileTypes,
    setFile,
    retuired,
    label,
    maxFileSize,
    serverImage,
    msgStyle,
    showMSG,
    hideDeleteBTN,
    setFileSizeError,
    fileSizeError,
  } = props;

  const [customAlert, setCustomAlert] = useState('');

  const handleChange = (file) => {
    if (file.size > maxFileSize * 1000000) {
      setFileSizeError(fileSizeError);
      setCustomAlert(fileSizeError);
    } else {
      setFile(file);
      setCustomAlert(false);
    }
  };

  const removeImageLocal = () => {
    setFile('');
    removeImage && removeImage();
  };

  const replaceImage = () => {
    setFile('');
    setTimeout(() => {
      document.getElementById('input-drag-and-drop-file-select').click();
    }, 300);
  };

  const showImageFile = useMemo(() => {
    if (!showLocalFile && serverImage) {
      return <img src={serverImage} className='dropViewUploadedImage' />;
    } else if (showLocalFile) {
      return <img src={URL.createObjectURL(showLocalFile)} className='dropViewUploadedImage' />;
    }
  }, [props]);

  return (
    <div className='dropOuterView'>
      <div className='labelText'>
        {retuired && <span>*</span>}
        {label}
      </div>

      {showLocalFile || serverImage ? (
        <div className='dropView'>
          {showImageFile}
          {(showLocalFile || serverImage) && (
            <div className='dragDropButtons'>
              <input
                className='d-none'
                accept={accept}
                type='file'
                onChange={(data) => handleChange(data.target.files[0])}
                value=''
                id='input-drag-and-drop-file-select'
              />
              {/* <div>
                <BorderBtnWithBg
                  iconImg={reloadBlack}
                  title="Replace"
                  className="articleRemoveIcon articleRemoveIconNoBorder"
                  onClick={replaceImage}
                />
              </div> */}
              {!hideDeleteBTN && (
                <div className='articleRemoveIcona'>
                  <BorderBtnWithBg
                    onClick={removeImageLocal}
                    iconImg={redTrashIcon}
                    className='articleRemoveIcon'
                    title='Remove'
                  />
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <FileUploader
          label='Drop files directly here or browse from your device'
          handleChange={handleChange}
          types={fileTypes}
          // maxSize={maxFileSize}
        >
          <div className='dropView'>
            <div className='dropIcon'>
              <img src={upload} />
              <div className='uploadText'>Upload files</div>
            </div>
            <div className='dragText'>
              Drop files directly here or <span>browse</span> from your device
            </div>
          </div>
        </FileUploader>
      )}
      {(showMSG || customAlert) && (
        <div className='inputMsg' style={msgStyle}>
          {showMSG || customAlert}
        </div>
      )}
    </div>
  );
}

export default DragAndDropFiles;
