import React, { useEffect } from 'react';
import { TagPicker } from 'rsuite';
import '../inputBox.css';

function TagSelectPicker(props) {
  const {
    placeholder,
    label,
    selectStyle,
    msgStyle,
    labelStyle,
    showMSG,
    retuired,
    data,
    onSelect,
    valueKey,
    onSearch,
    labelKey,
    defaultValue,
    style,
    defaultData,
    value,
    container,
  } = props;

  let timeout = '';

  useEffect(() => {
    if (defaultData && typeof defaultData == 'object') {
      let verifyData = data.find((item) => item[valueKey] == defaultData[valueKey]);
      if (!verifyData) {
        data.unshift(defaultData);
      }
    }
  }, [defaultData]);

  const handleSelectValue = (arr) => {
    let selectedArray = data.filter((item) => arr?.includes(item[valueKey]));
    let titleOfArray = selectedArray.map((option) => option[labelKey]);
    onSelect(arr, titleOfArray);
  };

  return (
    <div>
      {label && (
        <label className='inputlabel' style={labelStyle}>
          {retuired && <span className='requiredInput'> * </span>} {label}
        </label>
      )}

      <TagPicker
        container={container}
        data={data}
        onClean={() => {
          onSelect && handleSelectValue([]);
        }}
        placement='autoVerticalStart'
        onChange={handleSelectValue}
        value={value || []}
        // onSelect={handleSelectValue}
        valueKey={valueKey}
        labelKey={labelKey}
        defaultValue={defaultValue}
        onSearch={(str) => {
          if (onSearch && str?.trim()?.length > 2) {
            if (timeout) {
              clearTimeout(timeout);
            }

            timeout = setTimeout(() => {
              onSearch(str);
            }, 300);
          } else {
            onSearch('');
          }
        }}
        style={{ ...style, ...selectStyle }}
        placeholder={placeholder}
      />
      {showMSG && (
        <div className='inputMsg' style={msgStyle}>
          {showMSG}
        </div>
      )}
    </div>
  );
}

TagSelectPicker.defaultProps = {
  data: [],
  valueKey: 'id',
  labelKey: 'title',
  style: { width: '100%' },
  msgStyle: { color: 'red' },
};

export default TagSelectPicker;
