import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { setAlerts } from 'redux/Slices';
import { Button, DatePicker, Modal } from 'rsuite';
import { CoreDropDown, CoreInputBox, CoreSearchDropDown, Select2DropDown } from 'whealth-core-web/components';

import { useLocation, useParams } from 'react-router-dom';
import CurrencyInput from 'react-currency-input-field';
import { useGetLoginUserDataQuery } from 'redux/apiSlice';

export const EditRecordPaymentModal = ({
  open,
  handleClose,
  editSchedule,
  closePaymentModal,
  getAllDoctorList,
  refreshSlotsData,
  allDoctorData,
  AppointmentSchedule,
  patientsList,
  isNivanCare,
}) => {
  const { consultationId } = useParams();
  const [doctors, setDoctors] = useState([]);
  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState(editSchedule?.user?.id);
  const [selectedDoctor, setSelectedDoctor] = useState();
  const [errorMsg, setErrorMsg] = useState({});
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedMode, setSelectedMode] = useState();
  const [doctorsList, setDoctorsList] = useState([]);
  const ApiClient = useApiManager();
  const [currentLocationsList, setCurrentLocationsList] = useState([]);
  const [errors, setErrors] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [isShowAlert, setIsShowAlert] = useState(false);
  const newCurrentPaymentStatus = useSelector((state) => state?.sliceReducer?.currentPaymentStatus);
  const [consultData, setConsultData] = useState({
    title: '',
    patientId: null,
    consult_type: null,
    consult_datetime: null,
    consult_end_datetime: null,
    location_id: null,
    consultant_id: '',
    meeting_link: '',
    payment_type: '',
    payment_mode: '',
    amount: null,
    consultation_status: '',
    patient_attendance_status: null,
    payment_from: null,
    payment_with: null,
    appointment_fee: null,
    payment_with_type: '',
  });
  const [clinicName, setClinicName] = useState('');
  let userData = useGetLoginUserDataQuery()?.data?.data || {};
  const [initialLoad, setInitialLoad] = useState(true);
  const [billedTo, setBilledTo] = useState('');

  useEffect(() => {
    setConsultData({
      title: editSchedule?.title,
      patientId: editSchedule?.user?.id,
      consult_type: editSchedule?.consult_type,
      consult_datetime: editSchedule?.consult_datetime,
      location_id: editSchedule?.location?.id,
      consultant_id: editSchedule?.consultant?.id,
      meeting_link: editSchedule?.meeting_link,
      payment_type: editSchedule?.payment_type,
      payment_mode: editSchedule?.payment_mode,
      amount: editSchedule?.amount,
      consultation_status: editSchedule?.consultation_status,
      patient_attendance_status: editSchedule?.patient_attendance_status,
      payment_from: editSchedule?.payment_from,
      payment_with: editSchedule?.payment_with,
      appointment_fee: editSchedule?.appointment_fee,
      payment_with_type: editSchedule?.payment_with_type,
    });
    setInitialLoad(false);
    doctorsList.map((item) => {
      if (item.id == editSchedule?.consultant?.id) {
        setCurrentLocationsList(item.locations);
      }
    });
    patientsList?.map((item) => {
      if (item.id == editSchedule?.user?.id) {
        setBilledTo(item?.full_name);
      }
    });
    setIsEditEnabled(editSchedule?.payment_type === null || editSchedule?.payment_type === '');
  }, [editSchedule]);
  useEffect(() => {
    doctorsList.map((item) => {
      if (item.id == editSchedule?.consultant?.id) {
        setCurrentLocationsList(item.locations);
      }
    });
  }, [doctorsList]);
  useEffect(() => {
    patientsList?.map((item) => {
      if (item.id == editSchedule?.user?.id) {
        setBilledTo(item?.full_name);
      }
    });
  }, [patientsList, consultData?.payment_with_type]);
  // useEffect(() => {
  //   if (!isNivanCare) return;
  //   if (!initialLoad) {
  //     if (consultData?.payment_with_type === 'clinic') {
  //       handleClinicNameChange(consultData?.location_id);
  //     } else {
  //       setConsultData({ ...consultData, payment_with: '' });
  //     }
  //   }
  // }, [consultData?.payment_with_type, initialLoad]);
  const handleClinicNameChange = (withType) => {
    if (currentLocationsList?.length < 1) return;
    if (withType === 'clinic') {
      currentLocationsList?.map((item) => {
        if (item?.id == consultData?.location_id) {
          // setClinicName(item?.name);
          setConsultData({ ...consultData, payment_with: item?.name, payment_with_type: withType });
        }
      });
    } else setConsultData({ ...consultData, payment_with: '', payment_with_type: withType });
  };
  const consultationTypeList = [
    {
      id: 1,
      title: 'physical',
    },
  ];

  const paymentTypeList = [
    {
      id: 1,
      title: 'free',
      label: 'Free',
    },
    {
      id: 2,
      title: 'paid',
      label: 'Paid',
    },
    {
      id: 3,
      title: 'partially_paid',
      label: 'Partially Paid',
    },
  ];

  const paymentModeList = [
    {
      id: 1,
      title: 'cash',
      label: 'Cash',
    },
    {
      id: 2,
      title: 'online',
      label: 'Online',
    },
  ];
  const careManagerList = [
    {
      id: 1,
      label: 'Riya Kumari',
    },
    {
      id: 2,
      label: 'Nupur Lakhanpal',
    },
    {
      id: 3,
      label: 'Fardeen Mirza',
    },
  ];
  const paymentFormList = [
    {
      id: 1,
      title: 'upi',
      label: 'UPI',
    },
    {
      id: 2,
      title: 'pos',
      label: 'PoS',
    },
    {
      id: 3,
      title: 'payment_link',
      label: 'Payment Link',
    },
    {
      id: 4,
      title: 'bank_transfer',
      label: 'Bank Transfer',
    },
  ];
  const paymentWithList = [
    {
      id: 1,
      title: 'care_manager',
      label: 'Care Manager',
    },
    {
      id: 2,
      title: 'clinic',
      label: 'Clinic',
    },
  ];
  useEffect(() => {
    getDoctorsList();
  }, [open]);

  const Details = ({ name, price, mode }) => (
    <div>
      <b className='mt-2'>Details</b>
      <p className='mt-0 pt-0 text-capitalize'>
        {name}, {`₹ ${price}(${mode})`}
      </p>
    </div>
  );

  const getDoctorsList = () => {
    if (allDoctorData.status == 200) {
      let doctorsList = [];
      allDoctorData?.data?.map((item) => {
        let doc = item['doctor'];
        doc.title = item['doctor'].full_name;
        let docLocations = [];
        item.locations?.map((loc) => {
          docLocations.push({ ...loc?.location, title: loc?.location?.name, weeks: loc?.weeks });
        });
        doc.locations = docLocations;
        doctorsList.push({ ...doc });
      });
      setDoctorsList(doctorsList);
    }
  };

  const consultationDetail = () => {
    ApiClient.consultationDetail(consultationId, selectedPatient)
      .then((res) => {
        let newData = { ...res.data };
        if (res?.data?.location?.id) {
          newData = { ...newData, location_id: res?.data?.location?.id };
        }
        if (res?.data.consultant?.id) {
          newData = { ...newData, consultant_id: res?.data?.consultant?.id };
        }
        setConsultData(newData);
      })
      .catch((err) => {
        console.warn('consultationData', err.response);
      });
  };

  const updateConsultation = () => {
    if (isEditEnabled) {
      setErrors({});

      ApiClient.consultationEdit(consultData, editSchedule?.user?.id, editSchedule?.id)
        .then((res) => {
          closePaymentModal();
          AppointmentSchedule();
          const patientDetails = patientsList.find((patient) => patient.id === consultData.user_id);
          const patientName = patientDetails ? patientDetails.full_name : '';

          if (newCurrentPaymentStatus == null || newCurrentPaymentStatus == '') {
            toast.success(
              <div>
                <p>The Payment has been recorded.</p>
                <Details
                  name={editSchedule?.user?.full_name}
                  price={consultData?.amount || '0'}
                  mode={consultData?.payment_mode || ''}
                />
              </div>
            );
          } else {
            toast.success(
              <div>
                <p>The Payment has been updated.</p>
                <Details
                  name={editSchedule?.user?.full_name}
                  price={consultData?.amount || '0'}
                  mode={consultData?.payment_mode || 'Free'}
                />
              </div>
            );
          }

          getAllDoctorList();
          refreshSlotsData();
        })

        .catch((err) => {
          console.warn('err', err);
          if (err?.response?.data?.errors) {
            setErrors(err?.response?.data?.errors);
          }
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.message);
        });
      // handleClose();
      setIsEditEnabled(false);
    } else {
      setIsEditEnabled(true);
    }
  };

  const handleCancelClick = () => {
    if (newCurrentPaymentStatus == null || newCurrentPaymentStatus == '') {
      setConsultData({});
    }
    setErrors({});
    setIsEditEnabled(false);
    closePaymentModal();
  };
  const handlePaymentWithTypeChange = (withType) => {
    // setConsultData({ ...consultData, payment_with_type: withType });
    // if (withType === 'clinic') {
    handleClinicNameChange(withType);
    // } else {
    //   setConsultData({ ...consultData, payment_with: '' });
    // }
  };
  return (
    <>
      <ToastContainer />
      <Modal backdrop='static' keyboard={false} open={open} onClose={handleCancelClick}>
        <Modal.Header></Modal.Header>

        <Modal.Body>
          <div className='d-flex flex-column justify-content-center'>
            <div
              style={{
                textAlign: 'center',
                marginBottom: '10px',
                fontWeight: '600px !important',
                fontSize: '15px !important',
              }}
            >
              Payment Record
            </div>
            {isNivanCare && (
              <div className='d-flex justify-content-around'>
                <div className='w-100'>
                  &emsp;&emsp;Appointment Fee:&nbsp;
                  {consultData?.appointment_fee}
                </div>
                {/* <div className='w-25' /> */}
                {/* <div className='w-25' /> */}
              </div>
            )}
            <br />
            <div className='d-flex justify-content-around'>
              <div
                className='w-25'
                style={{
                  minWidth: isNivanCare && '150px',
                }}
              >
                <Select2DropDown
                  placeholder='Select'
                  label='Type'
                  className='slot-name'
                  data={
                    isNivanCare ? paymentTypeList : paymentTypeList.filter((item) => item?.title != 'partially_paid')
                  }
                  labelStyle={{ fontWeight: 'var(--lightFont)' }}
                  onSelect={(data) => {
                    setConsultData({
                      ...consultData,
                      payment_type: data,
                      payment_mode: '',
                      payment_from: '',
                      amount: data === 'paid' ? consultData?.appointment_fee : null,
                      payment_with: '',
                    });
                  }}
                  value={consultData?.payment_type}
                  msgStyle={{ color: 'red' }}
                  valueKey='title'
                  labelKey='label'
                  disabled={!isEditEnabled}
                />
              </div>

              <div
                className='w-25'
                style={{
                  minWidth: isNivanCare && '150px',
                }}
              >
                <Select2DropDown
                  data={paymentModeList}
                  labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                  placeholder={'Select'}
                  className='slot-name'
                  label={'Mode'}
                  onSelect={(data) => {
                    setConsultData({ ...consultData, payment_mode: data, payment_from: '' });
                  }}
                  retuired={isNivanCare}
                  value={consultData?.payment_mode}
                  showMSG={errors?.payment_mode}
                  msgStyle={{ color: 'red' }}
                  valueKey='title'
                  labelKey='label'
                  disabled={!isEditEnabled || consultData?.payment_type === 'free'}
                />
              </div>
              <div
                className='w-25'
                style={{
                  minWidth: isNivanCare && '150px',
                }}
              >
                <Select2DropDown
                  data={paymentFormList}
                  className='slot-name'
                  labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                  placeholder={'Select'}
                  label={'From'}
                  onSelect={(data) => {
                    setConsultData({ ...consultData, payment_from: data });
                  }}
                  value={consultData?.payment_from}
                  showMSG={errors?.payment_from}
                  msgStyle={{ color: 'red' }}
                  valueKey='title'
                  labelKey='label'
                  disabled={
                    !isEditEnabled || consultData?.payment_type === 'free' || consultData?.payment_mode === 'cash'
                  }
                />
              </div>
            </div>
            <br />
            <div className='d-flex justify-content-around'>
              <div
                className='w-25 '
                style={{
                  minWidth: isNivanCare && '150px',
                }}
              >
                <label htmlFor='validationCustom01 mt-3 slot-name'>
                  {isNivanCare && <span style={{ color: 'red' }}>*&nbsp;</span>}
                  Amount
                </label>
                <CurrencyInput
                  id='validationCustom01'
                  name='input-1'
                  className={`form-control`}
                  label='Amount'
                  value={consultData?.amount}
                  showMSG={errors?.amount}
                  msgStyle={{ color: 'red' }}
                  onValueChange={(values) => {
                    setConsultData({ ...consultData, amount: values });
                  }}
                  placeholder=''
                  prefix={'₹'}
                  step={1}
                  disabled={
                    isNivanCare
                      ? !isEditEnabled || consultData?.payment_type === 'free' || consultData?.payment_type === 'paid'
                      : !isEditEnabled || consultData?.payment_type === 'free'
                  }
                />
                <p style={{ color: 'red' }}>{errors.amount}</p>
              </div>
              <div
                className='w-25 '
                style={{
                  minWidth: isNivanCare && '150px',
                }}
              >
                {!isNivanCare ? (
                  <CoreInputBox
                    className='slot-name'
                    placeholder=''
                    label='With'
                    showMSG={errors?.payment_with}
                    data={''}
                    labelStyle={{ fontWeight: 'var(--lightFont)', margin: '' }}
                    setValue={(data) => {
                      setConsultData({ ...consultData, payment_with: data });
                    }}
                    value={consultData?.payment_with}
                    msgStyle={{ color: 'red' }}
                    disabled={!isEditEnabled || consultData?.payment_type === 'free'}
                  />
                ) : (
                  userData?.role != 'ee' && (
                    <Select2DropDown
                      data={paymentWithList}
                      labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                      className='slot-name'
                      placeholder={'Select'}
                      label={'Payment With'}
                      valueKey='title'
                      labelKey='label'
                      onSelect={(with_type) => {
                        // setConsultData({ ...consultData, payment_with_type: with_type });
                        handlePaymentWithTypeChange(with_type);
                      }}
                      onClean={() => {
                        setConsultData({ ...consultData, payment_with_type: '', payment_with: '' });
                      }}
                      value={consultData?.payment_with_type || ''}
                      // showMSG={errors?.payment_from}
                      // msgStyle={{ color: 'red' }}

                      disabled={!isEditEnabled || consultData?.payment_type === 'free'}
                    />
                  )
                )}
              </div>
              <div
                className='w-25'
                style={{
                  minWidth: '150px',
                }}
              >
                <div className='w-25'></div>
                {consultData?.payment_with_type === 'care_manager' && (
                  <Select2DropDown
                    data={careManagerList}
                    labelStyle={{ margin: '', fontWeight: 'var(--lightFont)' }}
                    className='slot-name'
                    placeholder={'Select'}
                    label={'Care Manager'}
                    onSelect={(data) => {
                      setConsultData({ ...consultData, payment_with: data });
                    }}
                    onClean={() => {
                      setConsultData({ ...consultData, payment_with: '' });
                    }}
                    value={consultData?.payment_with}
                    disabled={!isEditEnabled || consultData?.payment_type === 'free'}
                    showMSG={errors?.payment_with}
                    // msgStyle={{ color: 'red' }}
                    valueKey='label'
                    labelKey='label'
                  />
                )}
              </div>
            </div>
            {userData?.role != 'ee' && isNivanCare && (
              <>
                <br />
                <div className='d-flex justify-content-around'>
                  <div className='w-100 d-flex'>
                    &emsp;&emsp;Billed To:&nbsp;
                    {/* {consultData?.payment_with} */}
                    <div className={'text-capitalize'}>
                      {consultData?.payment_with_type === 'care_manager' ? billedTo : consultData?.payment_with}
                    </div>
                  </div>
                  {/* <div className='w-25' /> */}
                  {/* <div className='w-25' /> */}
                </div>
              </>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='d-flex justify-content-center mt-4'>
            <Button
              onClick={updateConsultation}
              appearance='primary'
              style={{ background: '#243B86', alignItems: 'center', display: 'flex' }}
              disabled={editSchedule?.id && consultData?.payment_type === ''}
            >
              <span className='material-icons' style={{ margin: 1, fontSize: '13px !important' }}>
                edit
              </span>
              {!isEditEnabled ? 'Edit' : 'Update'}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
