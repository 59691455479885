import React from 'react';
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function CommonMaterialUiDatePicker(props) {
  const { placeholder, onChange, value, onClear, minDate, label, disablePast, disableFuture, disabled } = props;
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MobileDatePicker
          disabled={disabled}
          renderInput={(props) => <TextField {...props} />}
          value={dayjs(value)}
          disableFuture={disableFuture}
          disablePast={disablePast}
          minDate={minDate}
          placeholder={placeholder}
          onChange={onChange}
          onClear={onClear}
          format='YYYY-MM-DD'
          label={label}
          className='MobileDatePickerinputCommon'
        />
      </LocalizationProvider>
    </div>
  );
}

export default CommonMaterialUiDatePicker;
