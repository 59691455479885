import useApiManager from "networking/ApiManager";
import React, { useEffect, useMemo, useState } from "react";
import { searchLight } from "res/images";
import CoreInputBox from "./CoreInputBox";
import CoreSearchDropDown from "./CoreSearchDropDown";

function MedicineSearch(props) {
  const {
    setValue,
    showMSG,
    isShowSearch,
    value,
    handleOnFocus,
    medicineList,
  } = props;

  const [data, setData] = useState([]);
  const [apiCall, setapiCall] = useState(true);
  const [inputValue, setinputValue] = useState(value);
  const ApiClient = useApiManager();

  useEffect(() => {
    setinputValue(value);
  }, [value]);

  useEffect(() => {
    if (!apiCall) {
      setapiCall(true);
    }
  }, [apiCall]);

  useEffect(() => {
    setData(medicineList);
  }, [medicineList.length]);

  const handleItemClick = (item) => {
    setValue(item);
    setinputValue(item.name);
    setData([]);
    setapiCall(!apiCall);
  };

  const searchMedicines = (inputValue) => {
    ApiClient.searchMedicines(inputValue)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderSearh = useMemo(() => {
    return (
      <CoreSearchDropDown
        {...props}
        setTimeOutData={() => {
          if (apiCall && isShowSearch && inputValue?.length > 2) {
            searchMedicines(inputValue);
          }
        }}
        inputValue={inputValue}
        inputStyle={{ height: "39px" }}
        data={data}
        setData={setData}
        setInputValue={(text) => {
          setinputValue(text);
        }}
        onClickItem={handleItemClick}
        isRightIconref
        isAutoSave
        dropDownStyle={{ left: 0, right: 0 }}
        searchKey="name"
        handleOnFocus={handleOnFocus}
      />
    );
  }, [data, showMSG, inputValue]);

  return (
    <div className="mb-3" style={{ position: "relative" }}>
      {renderSearh}
    </div>
  );
}

export default MedicineSearch;
