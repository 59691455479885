import React, { useEffect, useState } from 'react';
import { editTheme, trashRed } from 'res/images';

import { checked } from 'whealth-core-web/res/images';
import CoreInputBox from './CoreInputBox';
import CoreTextArea from './CoreTextArea';
import { dateFormat, teamRoles, timeFormat } from './Helper';
import './listView.css';
import ReadMoreReadLess from './ReadMoreReadLess';
import CommonToolTip from './CommonToolTip';
import { Button, Modal } from 'rsuite';
import Select2DropDown from './common/Select2DropDown';
import useApiManager from 'networking/ApiManager';
import { useParams } from 'react-router-dom';
import CommonAlert from './CommonAlert';

function NotesListView(props) {
  const {
    data,
    deleteData,
    setIsShowAlert,
    errors,
    editError,
    setEditError,
    updateData,
    isShowAlert,
    alertType,
    showKey,
    currentPage,
    categories,
    handleSearchCategory,
    contentId,
    setIsLoading,
  } = props;

  const [isUpdate, setIsUpdate] = useState({});
  const [inputData, setInputData] = useState('');
  const [titleReal, setTitleReal] = useState('');
  const [categoriesTemplate, setCategoriesTemplate] = useState([]);
  const [noteCategory, setNoteCategory] = useState('');
  const [selectedCatObj, setSelectedCatObj] = useState({});
  const [errorsUpdate, setErrorsupdate] = useState('');
  const [content, setContent] = useState('');

  const { id } = useParams();

  const ApiClient = useApiManager();
  useEffect(() => {
    setIsUpdate(false);
  }, [currentPage]);

  useEffect(() => {
    if (inputData?.length > 0) {
      setErrorsupdate(false);
    }
  }, [inputData]);
  const handleCancelClick = () => {
    setIsUpdate({});
  };

  const getCategoriesByTemplateUpdate = (data) => {
    setIsLoading(true);
    ApiClient.getCategoriesTampletNotes(id, data.id)
      .then((res) => {
        setIsLoading(false);
        getCategoriesByTemplate(res?.data?.category);
        // setCategoriesTemplate(res.data.template_titles);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getCategoriesByTemplate = (val) => {
    if (Object.keys(val).length === 0) {
      setCategoriesTemplate([]);
      setTitleReal('');
    } else {
      setIsLoading(true);
      ApiClient.notesCategoryGetByTemplate(val.id)
        .then((res) => {
          setIsLoading(false);
          setCategoriesTemplate(res.data);
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err);
        });
    }
  };
  const showData = () => {
    return (
      <div className={!showKey ? 'tagsView' : 'notesList'}>
        <div className='table-responsive'>
          <table className='w-100 table'>
            <tbody>
              {data &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td className='w-100'>
                        <div className='editInput'>
                          <div className='tags notes-list-text'>
                            <pre className={!showKey && 'badge capitalize lightGrayBadge'}>
                              {showKey ? item[showKey] : item.title}
                              {/* <ReadMoreReadLess limit={150} data={showKey ? item[showKey] : item.title} /> */}
                            </pre>
                          </div>
                        </div>
                        <div className='mb-4 mt-1'>
                          <span class='badge capitalize lightGrayBadge'>{item?.category?.title}</span>
                        </div>
                        <div className='mb-4 mt-1'>
                          <span class='badge capitalize lightGrayBadge'>{item?.title}</span>
                        </div>
                      </td>
                      <td>
                        <div className='listNotsright'>
                          <div className='metaDetails mb-4 me-3'>
                            <div>
                              Created by · {item.admin ? item.admin.full_name : item?.created_by?.full_name} (
                              {teamRoles(item.admin ? item.admin.role : item?.created_by?.role)})
                              <div>
                                Created <span className='lowercase'> at </span>· {dateFormat(item?.created_at)}
                                &nbsp;
                                {timeFormat(item.created_at)}
                              </div>
                            </div>
                          </div>
                          <div className='d-flex tagsViewIcons  mb-4'>
                            <div
                              onClick={() => {
                                getCategoriesByTemplateUpdate(item);
                                setIsUpdate(item);
                                setNoteCategory(item?.category?.id);
                                setSelectedCatObj(item?.category);
                                setInputData(showKey ? item[showKey] : item.title);
                                setTitleReal(item.title);
                              }}
                            >
                              <CommonToolTip
                                placement={'top'}
                                item={<img onClick={() => setEditError(false)} src={editTheme} />}
                                showValue={'Edit'}
                              />
                            </div>
                            <div
                              onClick={() => {
                                const windowConfirm = window.confirm('Are you sure you want to delete ?');
                                if (windowConfirm) {
                                  deleteData && deleteData(item.id);
                                }
                              }}
                            >
                              <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>

          <Modal backdrop='static' keyboard={false} open={isUpdate?.id ? true : false} onClose={handleCancelClick}>
            <Modal.Header>{/* <Modal.Title>Add Department</Modal.Title> */}</Modal.Header>
            <Modal.Body>
              {isShowAlert && (
                <div className='mt-4'>
                  <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
                </div>
              )}
              <Select2DropDown
                data={
                  !categories.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id
                    ? [...categories, selectedCatObj]
                    : categories
                }
                placeholder='Select Category'
                label='Select Category'
                labelStyle={{ fontWeight: 'var(--lightFont)' }}
                value={noteCategory}
                labelKey={'title'}
                valueKey={'id'}
                getObjData
                onSelect={(val, data) => {
                  setNoteCategory(val);
                  setSelectedCatObj(data);
                  getCategoriesByTemplate(data);
                  setContent('');
                  setInputData('');
                }}
                isValidation
                onSearch={(srcVal) => {
                  handleSearchCategory(srcVal);
                }}
                onClean={() => {
                  setNoteCategory('');
                  setSelectedCatObj('');
                  setCategoriesTemplate([]);
                  setTitleReal('');
                  setContent('');
                  setInputData('');
                }}
              />

              <div className='mt-2'>
                <Select2DropDown
                  data={categoriesTemplate}
                  placeholder='Select Template'
                  label='Select Template'
                  // retuired={true}
                  labelStyle={{ fontWeight: 'var(--lightFont)' }}
                  value={titleReal}
                  labelKey={'title'}
                  valueKey={'title'}
                  showMSG={errors?.category_id}
                  getObjData
                  onSelect={(val, obj) => {
                    setTitleReal(val);
                    setContent(obj.content ? obj.content : '');
                    setInputData(obj.content ? obj.content : '');
                  }}
                  isValidation
                  onSearch={(val) => {
                    if (val) {
                      setTitleReal(val);
                    }
                  }}
                  onClean={() => {
                    setTitleReal('');
                    setContent('');
                    setInputData('');
                  }}
                />
              </div>
              <div className='mt-2'>
                <CoreTextArea
                  rows={5}
                  label='Content'
                  retuired
                  showMSG={errorsUpdate?.content}
                  value={inputData || ''}
                  setValue={setInputData}
                  msgStyle={{ color: 'red' }}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() =>
                  updateData(inputData, titleReal, isUpdate?.id, noteCategory, setIsUpdate, setErrorsupdate)
                }
                appearance='primary'
              >
                Save
              </Button>
              <Button onClick={() => handleCancelClick()} appearance='subtle'>
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  };

  return <div>{showData()} </div>;
}

export default NotesListView;
