import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { CommonBorderBox, CoreButton, CoreDropDown, CoreInputBox, Select2DropDown } from 'whealth-core-web/components';
import panelRoles from '../../../../src/role.json';
import { teamRoles } from '../Helper';
function CarePlanForm(props) {
  const { error, actionNestedData, setActionNestedData, initialValuesTouchPoint, handleSaveAction } = props;
  const ApiClient = useApiManager();
  const [dropDownData, setDropdownData] = useState([]);
  const [notificationContentId, setNotificationContentId] = useState('');

  const roles = Object.keys(panelRoles)
    .filter((item) => item !== 'doctor_pms')
    .map((item) => {
      return {
        id: item,
        title: teamRoles(item),
      };
    });

  useEffect(() => {
    getCarePlanTemplate();
  }, []);

  const getCarePlanTemplate = () => {
    ApiClient.getCarePlanDropDownData().then((res) => {
      setDropdownData(res.data);
    });
  };

  const handleArrChange = (data, key, index, actionIndex, isAction, isOutside) => {
    if (key && isOutside) {
      actionNestedData[key] = data;
    }
    const newTouchpointsData = [...actionNestedData.touch_points];
    if (newTouchpointsData[index]) {
      if (isAction) {
        newTouchpointsData[index].care_plan_actions[actionIndex][key] = data || '';
      } else {
        newTouchpointsData[index][key] = data || '';
      }
    }
    setActionNestedData({ ...actionNestedData, touch_points: newTouchpointsData });
  };
  const removeForm = (index, actionIndex, isAction) => {
    let newTouchpointsData = [...actionNestedData.touch_points];
    if (newTouchpointsData[index]) {
      if (isAction) {
        let newActionsData = [...newTouchpointsData[index].care_plan_actions];
        newActionsData.splice(actionIndex, 1);
        newTouchpointsData[index].care_plan_actions = newActionsData;
      } else {
        newTouchpointsData.splice(index, 1);
      }
    }
    setActionNestedData({ ...actionNestedData, touch_points: [...newTouchpointsData] });
  };
  console.warn('dsdss', error);
  const renderTouchPointsNestedPart = (item, index) => {
    console.warn('sdd', index);
    return (
      <CommonBorderBox className='w-100 settingFormOutline' key={index}>
        <div>TouchPoint {index + 1}</div>
        <div className='behaviourpart-bg w-100 mb-2 pb-4'>
          <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex justify-content-between daypart_box pb-0 '>
            <div className='daypart-input behaviourpart_box w-100 regimetemplate-part'>
              <CoreInputBox
                retuired
                placeholder='Name'
                label='Name'
                value={item.name}
                setValue={(data) => {
                  handleArrChange(data, 'name', index);
                }}
                showMSG={error?.touch_points && error?.touch_points[index]?.name}
              />
            </div>
            <div className='daypart-input behaviourpart_box w-100 regimetemplate-part'>
              <CoreInputBox
                placeholder='Summary'
                label='Summary'
                value={item.summary}
                setValue={(data) => {
                  handleArrChange(data, 'summary', index);
                }}
              />
            </div>
            <div className='daypart-input behaviourpart_box w-100'>
              <Select2DropDown
                showMSG={
                  error?.touch_points && error?.touch_points[index]?.owner == '"" is not a valid choice.'
                    ? 'This field is required.'
                    : ''
                }
                retuired
                data={roles}
                valueKey='id'
                labelKey='title'
                placeholder='Owner'
                label='Owner'
                value={item.owner}
                onSelect={(data = null) => {
                  handleArrChange(data, 'owner', index);
                }}
              />
            </div>
          </div>
          <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex justify-content-between daypart_box pb-0 '>
            <div className='daypart-input behaviourpart_box w-100 regimetemplate-part'>
              <Select2DropDown
                showMSG={
                  error?.touch_points && error?.touch_points[index]?.frequency == '"" is not a valid choice.'
                    ? 'This field is required.'
                    : ''
                }
                retuired
                data={dropDownData.frequency_data}
                valueKey='id'
                labelKey='title'
                placeholder='Frequency'
                label='Frequency'
                value={item.frequency}
                onSelect={(data) => {
                  handleArrChange(data, 'frequency', index);
                }}
              />
            </div>
            <div className='daypart-input behaviourpart_box w-100 regimetemplate-part'>
              <Select2DropDown
                showMSG={error?.touch_points && error?.touch_points[index]?.start_date_offset}
                retuired
                data={dropDownData.start_date_data}
                placeholder='Start Date'
                label='Start Date'
                value={item.start_date_offset}
                valueKey='id'
                labelKey='title'
                onSelect={(data) => {
                  handleArrChange(data, 'start_date_offset', index);
                }}
              />
            </div>
            {item?.care_plan_actions?.length == 0 && (
              <div className='w-10 mt-4 d-flex justify-content-end add-question-regime-btn'>
                <div className='d-flex day_part-btn '>
                  <div className='ms-2'>
                    <CoreButton
                      onClick={() => {
                        let newTouchpoints = [...actionNestedData.touch_points];
                        let newActions = [...item.care_plan_actions];
                        newActions.push({
                          ...initialValuesTouchPoint.care_plan_actions,
                        });
                        newTouchpoints[index].care_plan_actions = newActions;
                        setActionNestedData({ ...actionNestedData, touch_points: [...newTouchpoints] });
                      }}
                      title='Add Actions'
                    />
                  </div>
                </div>
              </div>
            )}
          </div>

          {item.care_plan_actions?.length ? renderNestedInputFields(item.care_plan_actions, index) : null}
        </div>
        <div className='w-10 mt-4 d-flex justify-content-end mb-3'>
          <div className=''>
            <div className='d-flex day_part-btn '>
              {actionNestedData.touch_points.length > 0 && (
                <div>
                  <CoreButton onClick={() => removeForm(index)} title='remove' />
                </div>
              )}
              {actionNestedData.touch_points.length - 1 == index && (
                <div className='ms-2'>
                  <CoreButton
                    onClick={() => {
                      let newTouchpoints = [...actionNestedData.touch_points];
                      newTouchpoints.push({
                        ...initialValuesTouchPoint,
                      });
                      setActionNestedData({ ...actionNestedData, touch_points: newTouchpoints });
                    }}
                    title='Add Touchpoint'
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </CommonBorderBox>
    );
  };
  console.warn('errorssss', error);
  const renderNestedInputFields = (care_plan_actions, touch_pointsIndex) => {
    return (
      <div>
        {care_plan_actions?.map((item, index) => (
          <div
            style={{ margin: '20px 0', gap: '10px' }}
            className='d-flex justify-content-between daypart_box pb-0'
            key={index}
          >
            <div className='daypart-input behaviourpart_box w-100 regimetemplate-part'>
              <Select2DropDown
                retuired
                showMSG={
                  error?.touch_points &&
                  error?.touch_points[touch_pointsIndex]?.care_plan_actions &&
                  error?.touch_points[0]?.care_plan_actions[0]?.actiontitle
                }
                data={dropDownData.action_type}
                value={item.actiontitle}
                onSelect={(data) => {
                  handleArrChange(data, 'actiontitle', touch_pointsIndex, index, true);
                  setNotificationContentId(data);
                }}
                labelKey='title'
                valueKey='id'
                placeholder='Action Type'
                label='Action Type'
              />
            </div>
            <div className='daypart-input behaviourpart_box w-100 regimetemplate-part'>
              <CoreInputBox
                retuired={notificationContentId}
                showMSG={
                  error?.touch_points &&
                  error?.touch_points[touch_pointsIndex]?.care_plan_actions &&
                  error?.touch_points[touch_pointsIndex]?.care_plan_actions[index]?.instruction
                }
                value={item.instruction}
                setValue={(data) => {
                  handleArrChange(data, 'instruction', touch_pointsIndex, index, true);
                }}
                placeholder='Notification Content'
                label='Notification Content'
              />
            </div>
            <div className='w-10 mt-4 d-flex justify-content-end '>
              <div className=''>
                <div className='d-flex day_part-btn '>
                  <span onClick={() => removeForm(touch_pointsIndex, index, true)} className='material-icons pointer'>
                    close
                  </span>
                  {care_plan_actions.length - 1 == index && (
                    <div className='ms-2'>
                      <CoreButton
                        onClick={() => {
                          care_plan_actions.push({
                            ...initialValuesTouchPoint.care_plan_actions,
                          });
                          setActionNestedData({ ...actionNestedData });
                        }}
                        title='Add Actions'
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className='medicineForm'>
      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex w-100 justify-content-between medicineNamerow'>
        <div className='w-100'>
          <CoreInputBox
            value={actionNestedData.title || ''}
            setValue={(data) => {
              handleArrChange(data, 'title', null, null, false, true); // Pass 'duration' as the fieldToUpdate parameter
            }}
            retuired
            showMSG={error?.title}
            placeholder='Name'
            label='Name'
          />
        </div>

        <div className='w-100  d-flex'>
          <div className='w-100 mb-3'>
            <Select2DropDown
              retuired
              data={dropDownData.week_month_duration}
              placeholder='Duration'
              label='Duration'
              onSelect={(data) => {
                handleArrChange(data, 'duration', null, null, false, true);
              }}
              showMSG={error?.duration}
              value={actionNestedData.duration || ''}
            />
          </div>
          <div className='ms-2 w-100'>
            <CoreDropDown
              data={dropDownData.statuses}
              placeholder='Plan Status'
              label='Plan Status'
              onSelect={(data) => {}}
              setValue={(data) => {
                handleArrChange(data, 'status', null, null, false, true);
              }}
              value={actionNestedData.status}
            />
          </div>
        </div>
      </div>
      {actionNestedData.touch_points?.map((item, index) => renderTouchPointsNestedPart(item, index))}
      {actionNestedData.touch_points.length == 0 && (
        <div className=''>
          <CoreButton
            onClick={() => {
              let newTouchpoints = [...actionNestedData.touch_points];
              newTouchpoints.push({
                ...initialValuesTouchPoint,
              });
              setActionNestedData({ ...actionNestedData, touch_points: newTouchpoints });
            }}
            title='Add Touchpoint'
          />
          {/* <div>
            <CoreButton onClick={() => removeForm(removeLengthIndex)} title='remove' />
          </div> */}
        </div>
      )}
      <CoreButton onClick={handleSaveAction} title={'Save'} />
    </div>
  );
}

export default CarePlanForm;
