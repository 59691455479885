import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { CommonAlert, CoreButton, CoreInputBox, CoreTextArea, Select2DropDown } from 'whealth-core-web/components';
import CommonDayPart from 'whealth-core-web/components/DayPart/CommonDayPart';
import { useNavigate, useParams } from 'react-router-dom';

function AddDietplanForm(props) {
  const ApiClient = useApiManager();
  const navigation = useNavigate();
  const [isShowAlert, setIsShowAlert] = useState('');
  const [open, setOpen] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');
  let initialFormValues = {
    diet_plans: [{ day_part_id: '', notification_time: '', recipe_link: '', regime: '' }],
  };
  const [valueObj, setValueObj] = useState(initialFormValues);
  const {
    onClick,
    setErrors,
    errors,
    error,
    data,
    setIsLoading,
    isEditable,
    dietTemplate,
    setDietTemplate,
    templateList,
    setTemplateList,
    templateValue,
    setTemplateValue,
    searchTemplateList,
    setSearchTemplateList,
    searchDietTemplate,
    pid,
    id,
  } = props;

  const [dayPartData, setDayPartData] = useState([]);

  useEffect(() => {
    if (data) {
      setValueObj({ ...data });
    }
  }, [data]);

  useEffect(() => {
    getDayPart();
    handleOnFocus();
  }, []);

  const handleOnFocus = () => {
    let params = {
      page: 1,
    };
    ApiClient.getDayParts(params)
      .then((res) => {
        let tmpData = res.data;
        tmpData.map((item) => {
          item.title += ` (${formatAMPM(item.start_time)} - ${formatAMPM(item.end_time)})`;
        });
        setDayPartData(tmpData);
      })
      .catch((err) => console.log(err));
  };

  const createDiet = () => {
    const requestBody = {
      id: dietTemplate.id,
      title: dietTemplate.title,
      guidelines: dietTemplate.guidelines,
      diet_plan_day_parts: dietTemplate.diet_plans.map((item) => ({
        notification_time: item.notification_time,
        day_part_id: item.day_part_id,
        diet_plan_food_items: item.diet_plan_food_items,
      })),
    };

    setIsLoading(true);
    setErrors({});
    ApiClient.DietPlanCreate(requestBody, id)
      .then((res) => {
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
        setAlertType('alert-success');
        setIsShowAlert('Diet plan created successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data?.errors?.non_field_errors);
      });
  };

  const updateDiet = (dietTemplate) => {
    // let params = modifyParams({ ...data });
    const requestBody = {
      id: dietTemplate.id,
      title: dietTemplate.title,
      guidelines: dietTemplate.guidelines,
      diet_plan_day_parts: dietTemplate.diet_plans.map((item) => ({
        notification_time: item.notification_time,
        day_part_id: item.day_part_id,
        diet_plan_food_items: item.diet_plan_food_items,
      })),
    };
    setIsLoading(true);
    ApiClient.DietPlanUpdate(id, pid, requestBody)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Diet Plan updated successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data?.errors?.non_field_errors);
      });
  };

  const createPublishDiet = () => {
    const requestBody = {
      // id: dietTemplate.id,
      title: dietTemplate.title,
      default: dietTemplate.default,
      guidelines: dietTemplate.guidelines,
      diet_plan_day_parts: dietTemplate.diet_plans.map((item) => ({
        notification_time: item.notification_time,
        day_part_id: item.day_part_id,
        diet_plan_food_items: item.diet_plan_food_items,
      })),
    };

    ApiClient.DietPlanCreatePublish(requestBody, id)
      .then((res) => {
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Diet plan created & published successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data?.errors?.non_field_errors);
      });
  };

  const updatePublishDiet = (dietTemplate) => {
    // let params = modifyParams({ ...data });
    const requestBody = {
      id: dietTemplate.id,
      title: dietTemplate.title,
      guidelines: dietTemplate.guidelines,
      diet_plan_day_parts: dietTemplate.diet_plans.map((item) => ({
        notification_time: item.notification_time,
        day_part_id: item.day_part_id,
        diet_plan_food_items: item.diet_plan_food_items,
      })),
    };
    setIsLoading(true);
    ApiClient.DietPlanUpdatePublish(id, pid, requestBody)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Diet Plan updated & published successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data?.errors?.non_field_errors);
      });
  };

  const itrateTimeFromDayPart = (id) => {
    let singleData = dayPartData.find((item) => item.id == id);
    let startTime = singleData?.start_time;
    let endTime = singleData?.end_time;
    let timeArr = createArrayFromTime(startTime, endTime);

    return timeArr;
  };

  const formatAMPM = (time) => {
    let hours = null;
    let minutes = null;
    if (time.toString().length === 3) {
      hours = time.toString().slice(0, 1);
      minutes = time.toString().slice(1, 3);
    } else if (time.toString().length < 3) {
      minutes = time.toString().slice(0, 3);
    } else {
      hours = time.toString().slice(0, 2);
      minutes = time.toString().slice(2, 4);
    }

    let ampm = hours >= 12 && parseInt(hours) <= 23 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    let strTime = hours + ':' + (time.toString() < 10 ? '0' : '') + minutes + ' ' + ampm;
    return strTime;
  };

  const getDayPart = (query, id) => {
    if (!dietTemplate.searchData) {
      dietTemplate.searchData = [];
    }
    ApiClient.dayPartGet(query)
      .then((res) => {
        dietTemplate.searchData[id] = res.data;
        setDietTemplate({ ...dietTemplate });
      })
      .catch((err) => console.log(err));
  };

  const [inputValues, setInputValues] = useState([{ id: 1, value: '' }]);

  const handleInputChange = (index, value) => {
    const newInputValues = [...inputValues];
    newInputValues[index].value = value;
    setInputValues(newInputValues);
  };

  const [foodItems, setFoodItems] = useState(dietTemplate?.diet_plans || []);

  const addNewFood = (dayPartIndex) => {
    setDietTemplate((prevValue) => {
      const existingFoodItems = prevValue.diet_plans[dayPartIndex].diet_plan_food_items;
      const newFoodItem = {
        food: '',
        quantity: '',
        recipe_link: '',
        twins: String(
          existingFoodItems.length > 0 ? Number(existingFoodItems[existingFoodItems.length - 1].twins) + 1 : 1
        ),
      };

      return {
        ...prevValue,
        diet_plans: prevValue.diet_plans.map((dayPart, index) => {
          if (index === dayPartIndex) {
            return {
              ...dayPart,
              diet_plan_food_items: [...dayPart.diet_plan_food_items, newFoodItem],
            };
          }

          return dayPart;
        }),
      };
    });
  };

  const removeInputBox = (optionIndex, setIndex) => {
    setDietTemplate((prev) => {
      const newDietPlans = [...prev.diet_plans];
      newDietPlans[optionIndex].diet_plan_food_items.splice(setIndex, 1);
      return { ...prev, diet_plans: newDietPlans };
    });
  };

  const addInputBox = (optionIndex, setIndex) => {
    setDietTemplate((prev) => {
      const newDietPlans = [...prev.diet_plans];
      const currentSet = newDietPlans[optionIndex].diet_plan_food_items[setIndex];

      const currentTwins = currentSet.twins;

      const newSet = {
        food: '',
        quantity: '',
        recipe_link: '',
        twins: currentTwins,
      };

      newDietPlans[optionIndex].diet_plan_food_items.splice(setIndex + 1, 0, newSet);

      return { ...prev, diet_plans: newDietPlans };
    });
  };

  const handleChange = (optionIndex, setIndex, field, data) => {
    setDietTemplate((prev) => {
      const newDietPlans = [...prev.diet_plans];
      newDietPlans[optionIndex].diet_plan_food_items[setIndex][field] = data;
      return { ...prev, diet_plans: newDietPlans };
    });
  };

  const createArrayFromTime = (start, end) => {
    const timeRange = [];
    let startLoop = parseInt(start);
    let endLoop = parseInt(end);
    if (startLoop > endLoop) {
      endLoop += 2400;
    }
    while (startLoop <= endLoop) {
      if (!(startLoop % 100 == 0 || startLoop % 100 == 30)) {
        startLoop += 1;
        continue;
      }

      let obj = {};
      if (startLoop % 100 >= 60) {
        startLoop = Math.floor(startLoop / 100) * 100 + 100;
      }
      if (startLoop >= 1200) {
        obj.title = `${formatAMPM(startLoop)}`;
        obj.id = `${String(Number(startLoop))}`;
        timeRange.push(obj);
      } else {
        obj.title = `${formatAMPM(startLoop)} `;
        obj.id = `${String(Number(startLoop))}`;
        timeRange.push(obj);
      }
      startLoop += 1;
    }
    return timeRange;
  };

  const removeFormFieldForDiet = (index) => {
    let windowConfirm = window.confirm('Remove Day Part');
    if (windowConfirm) {
      let dataObj = dietTemplate;
      dataObj.diet_plans.splice(index, 1);
      error?.diet_plans?.splice(index, 1);
      setErrors && setErrors({ ...error });
      setDietTemplate((prevState) => {
        return { ...prevState, ...dataObj };
      });
    }
  };

  const addDayPartForDiet = () => {
    setDietTemplate((prev) => ({
      ...prev,
      diet_plans: [
        ...prev.diet_plans,
        {
          day_part_id: '',
          notification_time: '',
          diet_plan_food_items: [
            {
              food: '',
              quantity: '',
              recipe_link: '',
              twins: '1',
            },
          ],
        },
      ],
    }));
  };

  const renderDayTimeForDietPlan = () => {
    if (dietTemplate?.diet_plans) {
      return (
        <div className='w-100  mt-3'>
          {dietTemplate?.diet_plans?.length ? (
            dietTemplate?.diet_plans?.map((item, index) => {
              if (typeof item.day_part_id == 'object') {
                item.day_part_id = item.day_part_id?.id;
              }

              let timeData = dietTemplate.diet_plans[index]?.day_part_id
                ? itrateTimeFromDayPart(dietTemplate.diet_plans[index]?.day_part_id)
                : [];
              const dietPlans = dietTemplate?.diet_plans || [];
              return (
                <>
                  <div
                    key={'daypart-row' + index}
                    className='d-flex flex-column justify-content-between w-100 gap-10 mb-2 daypart_box'
                  >
                    <div className='d-flex justify-content-between gap-10'>
                      <Select2DropDown
                        retuired
                        label='Day Part'
                        data={dayPartData}
                        style={{ width: '100%' }}
                        labelKey='title'
                        valueKey='id'
                        className='w-100'
                        value={item?.day_part_id || ''}
                        defaultValue={dietTemplate.diet_plans[index].day_part_id || ''}
                        onSearch={(query) => {
                          getDayPart(query, index);
                        }}
                        showMSG={
                          errors?.diet_plan_day_parts?.length &&
                          errors?.diet_plan_day_parts[index] &&
                          errors?.diet_plan_day_parts[index]?.day_part_id
                        }
                        placeholder='Day Part'
                        onSelect={(data) => {
                          dietTemplate.diet_plans[index].day_part_id = data;
                          dietTemplate.diet_plans[index].notification_time = '';
                          setDietTemplate({ ...dietTemplate });
                        }}
                        msgStyle={{ color: 'red' }}
                      />

                      <Select2DropDown
                        retuired
                        className='w-100'
                        label='Time'
                        data={timeData}
                        style={{ width: '100%' }}
                        labelKey='title'
                        valueKey='id'
                        showMSG={
                          errors?.diet_plan_day_parts?.length &&
                          errors?.diet_plan_day_parts[index] &&
                          errors?.diet_plan_day_parts[index]?.notification_time
                        }
                        placeholder='Time'
                        value={String(item.notification_time || '')}
                        onSelect={(data) => {
                          dietTemplate.diet_plans[index].notification_time = data;
                          setDietTemplate({ ...dietTemplate });
                        }}
                        msgStyle={{ color: 'red' }}
                      />
                    </div>
                    <div>
                      <label>
                        <h7 className='inputlabel mb-2 mt-4'>Diet Regime</h7>
                      </label>

                      <div key={index}>
                        {item.diet_plan_food_items.map((set, setIndex) => (
                          <>
                            {setIndex > 0 &&
                              item.diet_plan_food_items[setIndex]?.twins !=
                                item.diet_plan_food_items[setIndex - 1]?.twins && (
                                <hr style={{ borderBlockColor: '#000000d4' }} />
                              )}
                            <div className='d-flex justify-content-between gap-10 mt-10 mb-10'>
                              <div className='mb-2 w-100'>
                                <CoreInputBox
                                  retuired
                                  label='Food'
                                  value={set.food}
                                  showMSG={
                                    errors?.diet_plan_day_parts?.length &&
                                    errors?.diet_plan_day_parts[index] &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items?.length &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items[setIndex]?.food
                                  }
                                  labelKey='food'
                                  placeholder='Food'
                                  style={{ width: '100%' }}
                                  className='w-100'
                                  setValue={(data) => handleChange(index, setIndex, 'food', data)}
                                />
                              </div>
                              <div className='mb-2 w-100'>
                                <CoreInputBox
                                  retuired
                                  label='Quantity'
                                  value={set.quantity}
                                  showMSG={
                                    errors?.diet_plan_day_parts &&
                                    errors?.diet_plan_day_parts[index] &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items?.length &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items[setIndex]?.quantity
                                  }
                                  labelKey='quantity'
                                  placeholder='Quantity'
                                  style={{ width: '100%' }}
                                  className='w-100'
                                  setValue={(data) => handleChange(index, setIndex, 'quantity', data)}
                                />
                              </div>
                              <div className='mb-2 w-100'>
                                <CoreInputBox
                                  label='Recipe Link'
                                  labelKey='recipe_link'
                                  value={set.recipe_link || ''}
                                  showMSG={
                                    errors?.diet_plan_day_parts &&
                                    errors?.diet_plan_day_parts[index] &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items?.length &&
                                    errors?.diet_plan_day_parts[index]?.diet_plan_food_items[setIndex]?.recipe_link
                                  }
                                  placeholder='Recipe Link'
                                  style={{ width: '100%' }}
                                  className='w-100'
                                  setValue={(data) => handleChange(index, setIndex, 'recipe_link', data)}
                                />
                              </div>
                              <div className='d-flex align-items-end day_part-btn mb-2'>
                                {item.diet_plan_food_items.length > 1 && (
                                  <span onClick={() => removeInputBox(index, setIndex)} class='material-icons pointer'>
                                    close
                                  </span>
                                )}
                                <div className='ms-2'>
                                  <CoreButton onClick={() => addInputBox(index, setIndex)} title='+' />
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>

                      <div className='d-flex justify-content-end align-items-end daypart-all-btn w-100'>
                        <div className='ms-2'>
                          <CoreButton onClick={() => addNewFood(index)} title='+ Add Food Item' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='d-flex day_part-btn gap-10 justify-content-end '
                    style={{ marginBottom: '10px', marginTop: '10px' }}
                  >
                    {dietTemplate?.diet_plans?.length > 1 && (
                      <span onClick={() => removeFormFieldForDiet(index)} className='material-icons pointer'>
                        close
                      </span>
                    )}
                    {dietTemplate?.diet_plans?.length - 1 == index ? (
                      <CoreButton onClick={addDayPartForDiet} title={'+ Add Day Part'} />
                    ) : null}
                  </div>
                </>
              );
            })
          ) : (
            <div className='my-4'>
              <CoreButton outline onClick={addDayPartForDiet} title={'+ Add Day Part'} />
            </div>
          )}
        </div>
      );
    } else {
      return;
    }
  };

  const renderDietPlanForm = () => {
    return (
      <>
        <div>
          {!pid ? (
            <div className='w-100'>
              <Select2DropDown
                className='mb-4'
                label={'Select Template'}
                data={searchTemplateList?.length ? searchTemplateList : templateList}
                isValidation
                onClean={() => {
                  setDietTemplate({});
                  setTemplateValue('');
                }}
                onSearch={(searchData) => searchDietTemplate(searchData)}
                onSelect={(val, objData) => {
                  if (objData?.id && templateList.findIndex((item) => item.id == objData.id) == -1) {
                    templateList.push(objData);
                    setTemplateList([...templateList]);
                    setSearchTemplateList([]);
                  }
                  setTemplateValue(val);
                }}
                value={templateValue}
              />
            </div>
          ) : null}
        </div>
        <div className='card mt-3'>
          {isShowAlert && !open && (
            <div className='mt-5'>
              <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
            </div>
          )}
          {dietTemplate?.diet_plans?.length ? (
            <div className='card-body'>
              <h5 className='card-title  pt-2 pb-2'>Add Diet</h5>
              <div className='mt-2'>{renderDayTimeForDietPlan()}</div>
              <CoreTextArea
                showMSG={(error?.diet_plans && error?.diet_plans && error?.diet_plans?.regime) || ''}
                labelStyle={{ fontWeight: 'var(--lightWeight)' }}
                label={'guidelines'}
                placeholder={'Enter Guidelines'}
                rows={5}
                value={dietTemplate?.guidelines || ''}
                setValue={(data) => {
                  setDietTemplate((prevDietTemplate) => ({
                    ...prevDietTemplate,
                    guidelines: data,
                  }));
                }}
              />
              <CoreButton
                onClick={() => {
                  if (pid) {
                    updateDiet(dietTemplate);
                  } else {
                    createDiet(dietTemplate);
                  }
                }}
                customClass='btn btn-secondary btn-lg'
                title='Save Details'
              />
              <CoreButton
                onClick={() => {
                  if (pid) {
                    updatePublishDiet(dietTemplate);
                  } else {
                    createPublishDiet(dietTemplate);
                  }
                }}
                customClass='btn btn-secondary btn-lg'
                title='Save & Publish'
              />
            </div>
          ) : null}
        </div>
      </>
    );
  };
  return <div>{renderDietPlanForm()}</div>;
}

export default AddDietplanForm;
