import React from 'react';
import { editTheme, trashRed } from 'res/images';
import CommonToolTip from './CommonToolTip';
import { scrollToTop, timeFormat, titlizeString } from './Helper';
import './profileListViewAndEdit.scss';

function ProfileListViewAndEdit(props) {
  const { data, deleteData, setUpdateData } = props;

  const getDate = (date) => {
    const newDate = new Date();
    var date = new Date(date);

    let currentDay = newDate.getDate().toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    var month = date.toLocaleString('default', { month: 'short' });
    var year = date.getFullYear();
    if (currentDay == day) {
      return 'Today';
    } else {
      return `${day} ${month} ${year}`;
    }
  };

  const showData = () => {
    return (
      <div className='profileQuestion'>
        <div className='table-responsive'>
          <table className='w-100'>
            {data &&
              data.map((item, index) => {
                return (
                  <tbody key={index}>
                    <tr className='d-flex justify-content-between boxContent'>
                      <td>
                        {' '}
                        <div className='questionDetails'>
                          <div>
                            <div className='capitalize mb-1 white-space-none text-left'>{item.question}</div>
                            <div className='d-flex align-items-center'>
                              <div className='badge lightGrayBadge'>{item.key}</div>
                              <div className='badge lightGrayBadge ms-2'>{item.unit}</div>
                              <div className='badge lightGrayBadge '>{item?.goal?.title}</div>
                              <div className='badge lightGrayBadge  ms-23'>{item?.category?.title}</div>

                              <div className='ms-1 question_icon'>
                                {!!item.enable_mobile && (
                                  <CommonToolTip
                                    placement={'right'}
                                    item={<span class='material-icons'>phone_iphone</span>}
                                    showValue={'Display On Mobile'}
                                  />
                                )}
                              </div>
                            </div>
                            <div className='keyText'>{item.type}</div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className='d-flex'>
                          <div className='userDetails'>
                            <div className='metaDetail'>Created by · {item?.admin?.full_name}</div>
                            <div className='metaDetail'>
                              Created <span className='lowercase'> at </span>· {getDate(item?.created_at)}{' '}
                              {timeFormat(item.created_at)}
                            </div>
                          </div>
                          <div
                            onClick={() => {
                              setUpdateData(item);
                            }}
                          >
                            <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                          </div>
                          <div
                            onClick={() => {
                              const windowConfirm = window.confirm('Are you sure you want to delete ?');
                              if (windowConfirm) {
                                deleteData && deleteData(item.id);
                              }
                            }}
                          >
                            <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className='hrLine' />
                      </td>
                    </tr>
                  </tbody>
                );
              })}
          </table>
        </div>
      </div>
    );
  };

  return <div>{showData()} </div>;
}

export default ProfileListViewAndEdit;
