import React, { useEffect, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import { editTheme, trashRed } from 'res/images';
import { Modal } from 'rsuite';
import CommonBorderBox from './CommonBorderBox';
import Pagination from './Pagination';
import EmptyView from './EmptyView';
import CommonToolTip from './CommonToolTip';
import DepartmentForm from 'whealth-core-web/forms/DepartmentForm';
import ClinicForm from 'whealth-core-web/forms/ClinicForm';
import { dateFormat, teamRoles, timeFormat } from './Helper';

function ClinicsSettingsView(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  const [data, setData] = useState({});
  const [updateData, setupdateData] = useState('');
  const [error, seterror] = useState('');
  const [listData, setListData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [departmentStatuses, setDepartmentStatuses] = useState([]);

  useEffect(() => {
    getClinicsList();
  }, [currentPage]);

  const createClinic = () => {
    seterror([]);
    setIsLoading(true);
    const body = {
      name: data.name,
      address1: data.address1,
      address2: data.address2,
      city: data.city,
      state: data.state,
      zip_code: data.zip_code,
      contact_number: data.contact_number,
      contact_email: data.contact_email,
    };
    ApiClient.createClinicModel(body)
      .then((res) => {
        setIsLoading(false);
        setCurrentPage(1);
        getClinicsList();
        setData({});
        setAlertType('alert-success');
        setIsShowAlert('Clinic Created Successfully.');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        if (err.response.data.detail) {
          setIsShowAlert(err.response.data.detail);
        } else {
          seterror(err.response.data.errors);
        }
        console.log(err);
      });
  };

  const getClinicsList = () => {
    setIsLoading(true);
    const pageQry = { page: currentPage };
    ApiClient.getLocations(currentPage)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(res.headers['total-pages']);
        setListData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const updateClinic = () => {
    const body = {
      name: updateData.name,
      address1: updateData.address1,
      address2: updateData.address2,
      city: updateData.city,
      state: updateData.state,
      zip_code: updateData.zip_code,
      contact_number: updateData.contact_number,
      contact_email: updateData.contact_email,
    };
    ApiClient.updateClinic(updateData.id, body)
      .then((res) => {
        setAlertType('alert-success');
        setIsShowAlert('Clinic Updated Successfully.');
        getClinicsList();
        handleModalClose();
      })
      .catch((err) => {
        setAlertType('alert-danger');
        if (err.response?.data?.detail) {
          setIsShowAlert(err.response.data.detail);
          setOpen(false);
        } else if (err?.response?.data?.errors) {
          seterror(err.response.data.errors);
        } else {
          seterror(err.response.data);
        }
      });
  };

  const deleteData = (id) => {
    seterror([]);
    const windowConfirm = window.confirm('Are you sure you want to delete ?');
    if (windowConfirm) {
      ApiClient.deleteClinic(id)
        .then((res) => {
          setAlertType('alert-success');
          setIsShowAlert(res.data.message);
          getClinicsList();
        })
        .catch((err) => {
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.detail || err.response.data.message);
          console.log(err);
        });
    }
  };

  const handleModalClose = () => {
    setOpen(false);
    setData({});
    setupdateData({});
    seterror('');
  };

  const updateDataModalOpen = (data) => {
    setOpen(true);
    data.medicine_category_title = data.medicine_category;
    let tempLocations = [];
    data?.department_status?.map((item) => tempLocations.push(item.id));
    let reformattedData = { ...data, selected_department_status: tempLocations };
    setupdateData(reformattedData);
    seterror('');
  };

  const updateModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          {renderDepartmentForm(updateData, setupdateData, true)}
        </Modal>
      </div>
    );
  };

  const renderDepartmentForm = (data, setData, isUpdate) => {
    return (
      <div className='commonBorderBox settingFormOutline mb-4'>
        <ClinicForm
          error={error}
          valueObj={data}
          setValueObj={setData}
          isUpdate={isUpdate}
          onClick={isUpdate ? updateClinic : createClinic}
          statuses={departmentStatuses}
        />
      </div>
    );
  };

  const listDataMap = () => {
    return listData.map((item, index) => {
      return (
        <tr key={index} className='my-3 font-12px'>
          <table className='table'>
            <tr>
              <td colSpan={2}>
                <div className='d-flex mb-1 align-items-center justify-content-between'>
                  <div className='align-items-flex-start'>
                    <div className='medicinename'> {item.name}</div>
                    <div className='badge lightGrayBadge mt-1'>{item?.care_manager_name?.full_name}</div>
                  </div>
                  <div
                    className='d-flex text-muted justify-content-between align-items-center medicineDataList'
                    style={{ gap: '10px' }}
                  >
                    <div>
                      Created by · {item.admin ? item.admin.full_name : item?.created_by?.full_name} (
                      {teamRoles(item.admin ? item.admin.role : item?.created_by?.role)})
                      <div>
                        Created <span className='lowercase'> at </span>· {dateFormat(item?.created_at)}
                        &nbsp;
                        {timeFormat(item.created_at)}
                      </div>
                    </div>
                    <div className='action-images d-flex'>
                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              updateDataModalOpen(item);
                            }}
                            src={editTheme}
                          />
                        }
                        showValue={'Edit'}
                      />

                      <CommonToolTip
                        placement={'top'}
                        item={
                          <img
                            onClick={() => {
                              deleteData(item.id);
                            }}
                            src={trashRed}
                          />
                        }
                        showValue={'Delete'}
                      />
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </tr>
      );
    });
  };

  const showDepartmentsList = () => {
    return <>{listDataMap()}</>;
  };

  const patination = () => {
    return (
      <Pagination
        seterror={seterror}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };

  const renderEmptyView = () => {
    return <EmptyView title='No data found' />;
  };

  return (
    <div className='medicine-form'>
      <CommonBorderBox>{renderDepartmentForm(data, setData)}</CommonBorderBox>
      <div className='table-responsive'>
        <table className='w-100'>{listData?.length > 0 ? showDepartmentsList() : renderEmptyView()}</table>
      </div>

      <div className='d-flex justify-content-end mt-3'>{patination()}</div>
      {updateModal()}
    </div>
  );
}

export default ClinicsSettingsView;
