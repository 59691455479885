import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import { editTheme, trashRed } from 'res/images';
import { Modal } from 'rsuite';
import BannerConfigCreation from '../BannerConfig/BannerConfigCreation';
import CommonToolTip from '../CommonToolTip';
import { dateFormat, timeFormat } from '../Helper';
import Pagination from '../Pagination';

function BannerConfig(props) {
  const { setIsLoading, setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  const [createData, setCreateData] = useState({});
  const [data, setData] = useState([]);
  const [updateData, setupdateData] = useState('');
  const [error, seterror] = useState('');
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    getBannerData();
  }, [currentPage]);

  const getBannerData = (type) => {
    setIsLoading(true);
    const pages = { page: currentPage, type: type };
    ApiClient.getBannerDetail(pages)
      .then((res) => {
        setIsLoading(false);
        setData(res.data);
        setTotalPages(res.headers['total-pages']);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const getUrlForMedia = () => {
    setIsLoading(true);
    const params = {
      type: 'mobile_banner',
      file_name: updateData?.media?.name || createData?.media?.name,
    };
    ApiClient.getPresignedUrl(params)
      .then((res) => {
        setIsLoading(false);
        let mediaData = res.data;
        uploadMediaToServer(mediaData);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const uploadMediaToServer = async (data) => {
    setIsLoading(true);
    let urls = [];

    for (let itemIndex in data) {
      await ApiClient.uploadFileToServer(updateData.id ? updateData.media : createData.media, data.url)
        .then((res) => {
          setIsLoading(false);
          let document_type = updateData.id ? updateData.media : createData.media;
          urls.push({ document_type, url: data.url, type: 'mobile_banner' });
        })
        .catch((err) => {
          setIsLoading(false);
          console.log(err.response);
        });
      !updateData.id ? createBanner(urls) : updateBannerData(urls);
    }
  };

  const prepateFormData = (data, urls) => {
    const formData = new FormData();
    formData.append('title', data.title || '');
    formData.append('content_url', data.content_url || urls[0]?.url || '');
    formData.append('priority', data.priority || '');
    formData.append('status', data.status || 'disable');
    return formData;
  };

  const createBanner = (urls) => {
    setIsLoading(true);
    seterror('');
    ApiClient.createMobileBanner(prepateFormData(createData, urls))
      .then((res) => {
        setIsLoading(false);
        setCurrentPage(1);
        getBannerData();
        setIsShowAlert('Banner created successfully');
        setAlertType('alert-success');
        setCreateData({});
      })
      .catch((err) => {
        seterror(err.response.data.errors);
        setIsShowAlert(err.response.data.detail);
        setAlertType('alert-danger');
        setIsLoading(false);
      });
  };

  const updateBannerEvent = (data) => {
    setOpen(true);
    setupdateData(data);
    seterror('');
  };

  const updateBannerData = (urls) => {
    setIsLoading(true);
    ApiClient.updateBannerData(updateData.id, prepateFormData(updateData, urls))
      .then((res) => {
        setIsLoading(false);
        handleModalClose();
        getBannerData();
        setAlertType('alert-success');
        setIsShowAlert('Banner updated successfully');
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.data?.detail) {
          setIsShowAlert(err.response.data.detail);
          setOpen(false);
        } else {
          seterror(err.response.data.errors);
        }
        setAlertType('alert-danger');
      });
  };

  const handleModalClose = () => {
    setOpen(false);
    setCreateData({});
    setupdateData({});
    seterror('');
  };

  const updateModal = () => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={handleModalClose}>
          <Modal.Header />
          {returnBannertForm(updateData, setupdateData, true)}
        </Modal>
      </div>
    );
  };

  const returnBannertForm = useCallback(
    (data, setData, updateData) => {
      return (
        <div>
          <BannerConfigCreation
            setAlertType={setAlertType}
            getUrlForMedia={getUrlForMedia}
            error={error}
            valueObj={data}
            setValueObj={setData}
            isUpdate={updateData}
            onClick={updateData ? updateBannerData : createBanner}
          />
        </div>
      );
    },
    [createData, updateData, error]
  );

  const deleteData = (id) => {
    ApiClient.deletebanner(id)
      .then((res) => {
        getBannerData();
        setAlertType('alert-success');
        setIsShowAlert('Banner deleted successfully.');
      })
      .catch((err) => {
        setIsShowAlert('Failed to delete Banner.');
        setAlertType('alert-danger');
        if (!Array.isArray(Object.values(err.response.data)[0])) {
          setIsShowAlert(Object.values(err.response.data)[0]);
        }
        console.log(err);
      });
  };

  const patination = () => {
    return (
      <Pagination
        seterror={seterror}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    );
  };

  const getDate = (date) => {
    const newDate = new Date();
    var date = new Date(date);

    let currentDay = newDate.getDate().toString().padStart(2, '0');
    var day = date.getDate().toString().padStart(2, '0');
    var month = date.toLocaleString('default', { month: 'short' });
    var year = date.getFullYear();
    if (currentDay == day) {
      return 'Today';
    } else {
      return `${day} ${month} ${year}`;
    }
  };

  const showBannerDetail = useMemo(() => {
    return (
      <>
        <table className='w-100'>
          {data.map((item, index) => {
            return (
              <tbody key={index}>
                <tr className='showGoals'>
                  <td>
                    <div className='d-flex align-items-center'>
                      <div className='img-thumbnail'>
                        {item.asset_type == 'video' ? (
                          <video width='80' height='60' autoplay loop controls muted>
                            <source src={item.content_url} type='video/mp4' />
                          </video>
                        ) : (
                          <img src={item.content_url} />
                        )}
                        {item.asset_type == 'youtube' && (
                          <iframe
                            width='80'
                            height='60'
                            src={
                              item.content_url
                                .replace('watch?v=', 'embed/')
                                .replace('youtu.be', 'www.youtube.com/embed')
                                .split('&')[0]
                            }
                            title='YouTube video player'
                            frameborder='0'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                            allowfullscreen
                          ></iframe>
                        )}
                      </div>
                      <div>
                        <div className='GoalsTitle' style={{ fontSize: '16px' }}>
                          {item.title}
                        </div>
                        <small className={`badge capitalize  ${item.type}`}>{item.type}</small>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className='userDetailGoal'>
                      <div className='d-flex'>
                        <div className='userDetails'>
                          <div className='metaDetail'>Created by · {item?.admin?.full_name}</div>
                          <div className='metaDetail'>
                            Created <span className='lowercase'> at </span>· {getDate(item?.created_at)}{' '}
                            {timeFormat(item.created_at)}
                          </div>
                        </div>
                        <div
                          onClick={() => {
                            updateBannerEvent(item);
                          }}
                        >
                          <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                        </div>
                        <div
                          onClick={() => {
                            const windowConfirm = window.confirm('Are you sure you want to delete ?');
                            if (windowConfirm) {
                              deleteData && deleteData(item.id);
                            }
                          }}
                        >
                          <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </>
    );
  }, [data]);

  return (
    <div>
      <div className='commonBorderBox settingFormOutline'>{returnBannertForm(createData, setCreateData)}</div>
      <div className='table-responsive'>
        <div className='profileQuestion'>{showBannerDetail}</div>
      </div>
      {updateModal()}
      <div className='d-flex justify-content-end mt-3'>{patination()}</div>
    </div>
  );
}

export default BannerConfig;
