import React from 'react';
import { CoreButton, CoreDropDown, CoreInputBox } from 'whealth-core-web/components';

function MeasuringEventForm(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick } = props;

  const typeArr = [
    { title: 'Logging', id: 'logging' },
    { title: 'Measuring', id: 'measuring' },
  ];

  return (
    <div className='measuringForm'>
      <div className='d-flex w-100 flexmeasuringForm'>
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.title}
            placeholder='Title'
            value={valueObj?.title || ''}
            label='Title'
            setValue={(data) => {
              setValueObj({ ...valueObj, title: data });
            }}
            retuired
            msgStyle={{ color: 'red' }}
            maxLength={255}
          />
        </div>

        <div className='w-100'>
          <CoreDropDown
            data={typeArr}
            showMSG={error?.type}
            placeholder='Type'
            value={valueObj?.type || ''}
            label='Type'
            setValue={(data) => {
              setValueObj({ ...valueObj, type: data });
            }}
            retuired
            msgStyle={{ color: 'red' }}
          />
        </div>
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          allowStirngAndForwardSlash
          showMSG={error?.measuring_unit}
          placeholder='Measuring Unit'
          value={valueObj?.measuring_unit || ''}
          label='Measuring Unit'
          setValue={(data) => {
            setValueObj({ ...valueObj, measuring_unit: data });
          }}
          maxLength={255}
          msgStyle={{ color: 'red' }}
          retuired
        />
      </div>

      <div style={{ margin: '20px 0' }}>
        <CoreInputBox
          showMSG={error?.image_url}
          value={''}
          label='Image'
          setValue={(data) => {
            setValueObj({ ...valueObj, image: data[0] });
          }}
          inputStyle={{ height: 'unset', paddingLeft: 'unset' }}
          msgStyle={{ color: 'red' }}
          retuired
          type='file'
          accept='image/*'
        />
      </div>
      {valueObj?.image && (
        <div>
          <img className='showImageStyle' src={URL.createObjectURL(valueObj?.image)} />
        </div>
      )}
      {valueObj?.image_url && !valueObj?.image && (
        <div>
          <img className='showImageStyle' src={valueObj.image_url} />
        </div>
      )}
      <CoreButton
        onClick={() => {
          onClick();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default MeasuringEventForm;
