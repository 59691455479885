import React, { useEffect, useMemo, useState } from 'react';
import './WebEngage.css';
import { editTheme } from 'res/images';

import RowLinks from '../RowLinks';
import { AdminPanelHeader } from 'components';
import WebEngageView from './WebEngageView';
import ListCard from '../ListCard';
import useApiManager from 'networking/ApiManager';
import CommonAlert from '../CommonAlert';
function WebEngage() {
  const [status, setStatus] = useState('webEngage');
  const [isHide, setIsHide] = useState(false);
  const [activeVerticalLink, setActiveVerticalLink] = useState('patient');

  const ApiClient = useApiManager();

  useEffect(() => {
    setActiveVerticalLink(getActiveTabLink().activeTab);
  }, [status]);

  const headerData = [
    {
      title: 'Marketing',
      link: 'webEngage',
    },
    {
      title: 'Transactional',
      link: 'fcm',
    },
  ];

  const webEngageLinks = [
    { title: 'Patient', link: 'patient' },
    { title: 'Admin', link: 'admin' },
  ];

  const fcmLinks = [
    { title: 'Patient', link: 'patient' },
    { title: 'Admin', link: 'admin' },
  ];

  const renderHeader = useMemo(() => {
    return (
      <div className='headerFiles'>
        <AdminPanelHeader hideSearch iconTitle='notifications' title='Notification' />
      </div>
    );
  }, [activeVerticalLink]);
  const getActiveTabLink = () => {
    let activeTab, func, objData;
    if (status == 'webEngage') {
      objData = webEngageLinks;
      console.warn('objData', objData);
      activeTab = webEngageLinks[0].link;
      func = renderAllDataForm;
    } else if (status == 'fcm') {
      objData = fcmLinks;
      console.warn('objData', objData);
      activeTab = fcmLinks[0].link;
      func = renderFcmForm;
    }

    return { activeTab, func, objData };
  };

  const renderAllDataForm = () => {
    if (activeVerticalLink == 'patient') {
      return (
        <div className='w-100'>
          {/* <ArticleCategoriesView
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          /> */}
          <WebEngageView
            headerData={headerData}
            editTheme={editTheme}
            status={status}
            activeVerticalLink={activeVerticalLink}
          />
        </div>
      );
    } else if (activeVerticalLink == 'admin') {
      return (
        <div className='w-100'>
          {/* <ArticleCategoriesView
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          /> */}
          <WebEngageView
            headerData={headerData}
            editTheme={editTheme}
            status={status}
            activeVerticalLink={activeVerticalLink}
          />
        </div>
      );
    }
  };

  const renderFcmForm = () => {
    if (activeVerticalLink == 'patient') {
      return (
        <div className='w-100'>
          {/* <ArticleCategoriesView
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          /> */}
          <WebEngageView
            fcm
            headerData={headerData}
            editTheme={editTheme}
            status={status}
            activeVerticalLink={activeVerticalLink}
          />
        </div>
      );
    } else if (activeVerticalLink == 'admin') {
      return (
        <div className='w-100'>
          {/* <ArticleCategoriesView
            setAlertType={setAlertType}
            setIsShowAlert={setIsShowAlert}
            setIsLoading={setIsLoading}
            isLoading={isLoading}
          /> */}
          <WebEngageView
            fcm
            headerData={headerData}
            editTheme={editTheme}
            status={status}
            activeVerticalLink={activeVerticalLink}
          />
        </div>
      );
    }
  };

  const handleClick = (item) => {
    setStatus(item.link);
    setIsHide(item.hideDiv);
  };

  const renderActiveLinkData = useMemo(() => {
    return getActiveTabLink().func();
  }, [activeVerticalLink, status]);

  const showWebEngage = () => (
    <>
      <div className='articleHeader stickyHeader'>
        {renderHeader}
        <div className='hrLine mb-3' />
        <RowLinks data={headerData} onClickHandle={handleClick} />
      </div>
      <div className='px-4 wrapperContent'>
        <div className='settingsBody'>
          <div className='d-flex cardsGap'>
            {!isHide && (
              <ListCard
                activeLink={activeVerticalLink}
                setActiveLink={setActiveVerticalLink}
                listData={getActiveTabLink().objData}
              />
            )}
            {renderActiveLinkData}
          </div>
        </div>
      </div>
    </>
  );
  return (
    <div className='wrapper'>
      {showWebEngage()}
      {/* {App()} */}
    </div>
  );
}

export default WebEngage;
