import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import { closeIcon, eyeViewWhite } from 'res/images';
import {
  CoreInputBox,
  CoreDropDown,
  Editor,
  CoreButton,
  TagsShow,
  DragAndDropFiles,
  Loader,
  Select2DropDown,
} from 'whealth-core-web/components';
import './newArticle.css';
import { checkIsUrl, scrollToTop, timeMinutesSeconds } from 'whealth-core-web/components/Helper';
import { useGetCockpitDataQuery } from 'redux/cockpitSlice';
import { DatePicker } from 'rsuite';

const NewArticle = (props) => {
  const { createArticle, getData, setIsShowAlert, setAlertType, data, id, errors, alertError } = props;
  const ApiClient = useApiManager();
  const [articlesTags, setArticlesTags] = useState([]);
  const [articlesCategory, setArticlesCategory] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState('');
  const [video, setVideo] = useState(null);
  const [videoError, setVideoError] = useState('');
  const [serverImage, setServerImage] = useState('');
  const [serverVideo, setServerVideo] = useState('');
  const [category_id, setCategory_id] = useState('');
  const [content, setContent] = useState('');
  const [tags_id, setTags_id] = useState([]);
  const [title, setTitle] = useState('');
  const [Description, setDescription] = useState('');
  const [imageError, setImageError] = useState('');
  const [category_idError, setCategory_idError] = useState('');
  const [tags_idError, setTags_idError] = useState([]);
  const [titleError, setTitleError] = useState('');
  const [segment, setSegment] = useState('');
  const [searchSegment, setSearchSegment] = useState([]);
  const [contentError, setContentError] = useState('');
  const [articleData, setArticleData] = useState({});
  const [type, setType] = useState('');
  const [youtubeUrl, setYoutubeUrl] = useState('');
  const [youtubeUrlError, setYoutubeUrlError] = useState('');
  const videoFieldref = useRef();
  const [segmentEnabled, setSegmentEnabled] = useState(false);
  const [time, setTime] = useState('00:00');
  const [articlesSearchTags, setArticlesSearchTags] = useState([]);
  const [searchTags, setSearchTags] = useState('');

  const getCockpitData = useGetCockpitDataQuery();

  let configData = getCockpitData?.data?.data?.business_config;

  useEffect(() => {
    configData?.map((item) => {
      if (item.key == 'enable_segment' && item.isActive) setSegmentEnabled(true);
    });
  }, configData);

  const handleChange = (file) => {
    setVideo(file[0]);
  };

  useEffect(() => {
    let newData = {
      image_url: image,
      video_url: video,
      category_id,
      content,
      tags_id,
      title,
      Description,
      segment,
      type,
      youtubeUrl,
      time,
    };
    getData(newData);
    setArticleData(newData);
  }, [image, category_id, content, Description, tags_id, title, segment, video, type, youtubeUrl, time]);

  useEffect(() => {
    setImageError(errors?.image_url);
    setCategory_idError(errors?.category_id);
    setTags_idError(errors?.tags_id);
    setTitleError(errors?.title);
    setContentError(errors?.content);
    setYoutubeUrlError(errors?.youtube_url);
    setVideoError(errors?.video_url);
  }, [errors, alertError]);

  useEffect(() => {
    if (Object.keys(data).length > 0) {
      setServerImage(data?.image_url);
      setServerVideo(data?.video_url);
      setCategory_id(data.category?.id);
      setContent(data.content);
      setTags_id(data.tags_id);
      setDescription(data?.short_description);
      setTitle(data?.title);
      setSegment(data?.segment_id);
      setType(data?.type || 'both');
      setYoutubeUrl(data?.youtube_url);
      setTime(data?.video_duration);
    }
  }, [data]);

  useEffect(() => {
    if (videoFieldref?.current) {
      setTimeout(() => {
        videoFieldref.current.style = 'cursor:pointer';
        videoFieldref.current.addEventListener('click', () => {
          setVideo('');
          setServerVideo('');
        });
      }, 1000);
    }
  }, [videoFieldref, document]);

  useEffect(() => {
    articleCategoryGet();
    articleTagsGet();
    renderSearchSegment();
    segmentEnabled && renderGetSegment();
  }, []);

  const renderGetSegment = () => {
    ApiClient.getSegment()
      .then((res) => {
        setSearchSegment(res.data);
      })
      .catch((err) => console.log(err));
  };

  const renderSearchSegment = (qry) => {
    let params = {
      search_str: qry,
    };
    ApiClient.getSearchSegment(params)
      .then((res) => {
        setSearchSegment(res.data);
      })
      .catch((err) => console.log(err));
  };

  const articleCategoryGet = () => {
    setIsLoading(true);
    ApiClient.articleCategoryGet()
      .then((res) => {
        setIsLoading(false);
        setArticlesCategory(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const deleteArticleImage = () => {
    setIsLoading(true);
    ApiClient.deleteArticleImage(id)
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        setServerImage('');
        setIsShowAlert(res.data.message);
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.message);
        }
        setAlertType('alert-danger');
        console.log(err);
      });
  };

  useEffect(() => {
    addNonExitsData(data?.tags);
  }, [data?.tags]);

  const addNonExitsData = (allSelectedTags) => {
    let allArticleTags = [...articlesTags];
    allSelectedTags?.forEach((check) => {
      if (allArticleTags.findIndex((item) => item.id == check.id) == -1) {
        allArticleTags.push(check);
      }
    });
    setArticlesTags(allArticleTags);
  };

  const articleTagsGet = () => {
    setIsLoading(true);
    ApiClient.articleTagsGet()
      .then((res) => {
        setIsLoading(false);
        setArticlesTags(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const articleTagsSearchGet = (search_str) => {
    if (search_str?.length > 2) {
      ApiClient.searchTags(search_str)
        .then((res) => {
          setArticlesSearchTags(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setArticlesSearchTags([]);
    }
  };

  const imageSizeError = (error) => {
    setAlertType('alert-danger');
    setIsShowAlert(error);
  };

  // const audienceData = [
  //   { title: 'All', id: 'all' },
  //   { title: 'Less than 1 month', id: '0_month' },
  //   { title: 'One Month', id: '1_month' },
  //   { title: '2 Month', id: '2_month' },
  //   { title: '3 Month', id: '3_month' },
  //   { title: '4 Month', id: '4_month' },
  //   { title: '5 Month', id: '5_month' },
  //   { title: '6 Month and above', id: '6_month_and_above' },
  // ];

  const articleTagsInput = () => {
    if (articlesTags.length > 0) {
      return (
        <div className='articleInputDiv capitalize' style={{ maxWidth: '50%' }}>
          <TagsShow
            objRequired
            detail='Minimum 3 Tags'
            msgStyle={{ color: 'red' }}
            showMSG={tags_idError}
            data={searchTags?.length > 2 ? articlesSearchTags : articlesTags}
            labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
            label='Add Tags'
            setValue={(val, obj) => {
              setSearchTags('');
              addNonExitsData(obj);
              setTags_id(val);
            }}
            value={tags_id}
            placeholder='Search and Add Tags'
            onSearch={(val) => {
              setSearchTags(val);
              articleTagsSearchGet(val);
            }}
          />
        </div>
      );
    }
  };

  const renderMedia = () => {
    if (youtubeUrl && checkIsUrl(youtubeUrl)) {
      let newUrl = youtubeUrl.replace('watch?v=', 'embed/').replace('youtu.be', 'www.youtube.com/embed');
      return (
        <div className='mx-auto mt-3'>
          <iframe
            width='320'
            height='240'
            src={newUrl}
            title='YouTube video player'
            frameborder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowfullscreen
          ></iframe>
        </div>
      );
    }
  };

  const renderVideo = () => {
    const [minutes, seconds] = time.split(':').map(Number);
    return (
      <>
        {!youtubeUrl && (
          <>
            <div className='App mt-4 w-100'>
              <CoreInputBox
                articleVideoCross={() => setVideo('')}
                setVideo={setVideo}
                showMSG={videoError}
                rightIconref={videoFieldref}
                rightIcon={closeIcon}
                id={'file-input-video'}
                type='file'
                label='Video'
                style={{ visibility: 'hidden' }}
                setValue={handleChange}
                accept='video/*'
                value=''
              />
            </div>
          </>
        )}

        {!video && !serverVideo && !youtubeUrl && (
          <div className='text-center w-100 mt-4'>
            <b> OR </b>
          </div>
        )}

        {!video && !serverVideo && (
          <div className='App mb-4 w-100'>
            <CoreInputBox
              id={'file-input-video'}
              type='url'
              showMSG={youtubeUrlError}
              label='Youtube Url'
              placeholder='Youtube Url'
              style={{ visibility: 'hidden' }}
              setValue={setYoutubeUrl}
              value={youtubeUrl}
            />
          </div>
        )}
        {renderMedia()}
        {(video || serverVideo) && (
          <div className='mt-3 d-flex justify-content-center mx-auto'>
            {video && (
              <video width='320' height='240' autoplay loop controls>
                <source src={URL.createObjectURL(video)} type='video/mp4' />
              </video>
            )}
            {serverVideo && !video && (
              <video width='320' height='240' autoplay loop controls>
                <source src={serverVideo} type='video/mp4' />
              </video>
            )}
          </div>
        )}

        <div className='App  w-100'>
          <div className='mt-2'>
            <label class='inputlabel '>Video Duration</label>
          </div>
          <div>
            <DatePicker
              format='mm:ss'
              placeholder='MM:SS'
              value={minutes == '0' && seconds == '0' ? null : new Date().setHours('00', minutes, seconds)}
              onChange={(res) => {
                const result = timeMinutesSeconds(res);
                setTime(result);
              }}
              onClean={() => setTime('00:00')}
              style={{ width: '100%' }}
            />
          </div>
        </div>
      </>
    );
  };
  const renderBodyText = () => {
    const strippedContent = content.replace(/<[^>]*>/g, '');
    const contentLength = strippedContent.length;
    return (
      <div className='articleInputDiv' style={{ width: '100%' }}>
        <Editor
          retuired
          labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
          label='Body Text'
          setValue={setContent}
          value={content}
          msgStyle={{ color: 'red' }}
          showMSG={contentLength > 0 ? '' : contentError}
        />
      </div>
    );
  };

  const renderTypeField = () => {
    let data = [
      {
        title: 'Only Video',
        id: 'video',
      },
      {
        title: 'Only Content',
        id: 'content',
      },
      {
        title: 'Both',
        id: 'both',
      },
    ];

    let renderBoth = () => {
      return (
        <>
          {renderVideo()}
          {renderBodyText()}
        </>
      );
    };

    const fields = {
      video: renderVideo,
      content: renderBodyText,
      both: renderBoth,
    };

    let renderFileds = () => {
      if (fields[type]) {
        return fields[type]();
      } else {
        return;
      }
    };

    return (
      <>
        <div className='w-100 my-4'>
          <CoreDropDown
            value={type}
            setValue={setType}
            placeholder='Article Type'
            label='Article Type'
            data={data}
            retuired
          />
        </div>
        {renderFileds()}
      </>
    );
  };

  const renderFormData = () => {
    return (
      <div className='articleFormDiv'>
        <div className='articleInputDiv' style={{ width: '100%' }}>
          <CoreInputBox
            details={(title?.length || 0) + '/120'}
            retuired={true}
            labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
            placeholder='Topic or Headline of Article'
            label='Title'
            maxLength={120}
            setValue={setTitle}
            value={title}
            msgStyle={{ color: 'red' }}
            showMSG={titleError}
          />
        </div>

        <div className='articleInputDiv mt-4'>
          {/* <CoreDropDown
            data={articlesCategory}
            retuired
            labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
            placeholder='Select Category from Dropdown '
            label='Select Category'
            setValue={setCategory_id}
            value={category_id}
            msgStyle={{ color: 'red' }}
            showMSG={category_idError}
          /> */}
          <Select2DropDown
            retuired
            label='Select Category'
            showMSG={category_idError}
            // onSearch={renderSearchSegment}
            data={articlesCategory}
            style={{ width: '100%' }}
            labelKey='title'
            valueKey='id'
            placeholder='Select Segment from Dropdown'
            value={category_id}
            onSelect={setCategory_id}
            msgStyle={{ color: 'red' }}
          />
        </div>
        {articleTagsInput()}
        <div className='articleInputDiv' style={{ width: '100%' }}>
          <CoreInputBox
            details={(Description?.length || 0) + '/120'}
            labelStyle={{ margin: '24px 0 4px 0', fontWeight: 'var(--lightWeight)' }}
            placeholder='Topic or Headline of Article'
            label='Short Description'
            maxLength={120}
            setValue={setDescription}
            value={Description}
          />
        </div>
        {segmentEnabled && (
          <div className='articleInputDiv w-100 mt-3'>
            <Select2DropDown
              showMSG={errors.segment_id}
              defaultData={data.segment}
              onSearch={renderSearchSegment}
              label='Segment'
              data={searchSegment}
              style={{ width: '100%' }}
              labelKey='title'
              valueKey='id'
              placeholder='Select Segment from Dropdown'
              value={segment || ''}
              onSelect={setSegment}
              msgStyle={{ color: 'red' }}
            />
          </div>
        )}
        {renderTypeField()}
        <div className='w-100'>
          <DragAndDropFiles
            fileTypes={['JPEG', 'JPG', 'PNG']}
            setFile={setImage}
            retuired={true}
            label='Banner Image (Upto 1MB)'
            maxFileSize={1}
            showLocalFile={image}
            serverImage={serverImage}
            setServerImage={setServerImage}
            accept='image/png, image/gif, image/jpeg'
            removeImage={data.image_url && deleteArticleImage}
            msgStyle={{ color: 'red' }}
            showMSG={imageError}
            hideDeleteBTN={data.status == 'live'}
            setFileSizeError={imageSizeError}
            fileSizeError='Image size must be less than 1 mb'
          />
        </div>

        <CoreButton
          onClick={() => {
            scrollToTop();
            createArticle('live', articleData);
          }}
          title={data.status == 'live' ? 'Save' : 'Save & Publish'}
          customDivStyle={{ width: '100%' }}
          // customClass={id && 'publishedArticle'}
          icon={eyeViewWhite}
        />
      </div>
    );
  };

  return (
    <div>
      {renderFormData()}
      <Loader show={isLoading} />
    </div>
  );
};

export default NewArticle;
