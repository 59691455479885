import React from 'react';

function CommonBodyCard(props) {
  const { label, icon, value, items, defaultstart, marginnone, flexcolumn } = props;
  return (
    <div className={`checkup-entry w-100 d-flex  align-items-center ${defaultstart} `}>
      {items ? (
        <div className='checkupIcon'>
          <span class='material-icons-outlined'>{icon}</span>{' '}
        </div>
      ) : (
        <div className='checkupIcon'>
          <img src={icon} />
        </div>
      )}
      <div
        className={`checkup-value checkup-colour1 capitalize d-flex justify-content-between w-100 align-items-center ${defaultstart} ${flexcolumn}`}
      >
        <span>{label}</span>
        <div className={`ms-4 ${marginnone}`}>{value}</div>
      </div>
    </div>
  );
}

export default CommonBodyCard;
