import useApiManager from 'networking/ApiManager';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { searchLight } from 'res/images';
import { ChipsTagsView, CoreInputBox } from './index';

function TagsFilter(props) {
  const { filterData, setFilterData } = props;
  const [inputValue, setInputValue] = useState('');
  const [data, setData] = useState([]);
  const [dataSearch, setDataSearch] = useState([]);
  const [activeData, setActiveData] = useState([]);
  const [activeDataPopular, setActiveDataPopular] = useState([]);
  const [selectedData, setselectedData] = useState([]);
  const [allselectedData, setAllSelectedData] = useState([]);
  const ApiClient = useApiManager();

  let timeOut;
  useEffect(() => {
    let stringActiveData = activeData?.toString();
    setFilterData({ tag_ids: stringActiveData, tags_data: dataSearch, tag_SelectedData: allselectedData });
  }, [activeData, allselectedData]);

  useEffect(() => {
    getTagsData();
    setAllSelectedData(new Array());
    setselectedData(new Array());
    setActiveDataPopular(new Array());
    setDataSearch(new Array());
  }, []);

  useEffect(() => {
    getUniqueData();
  }, [selectedData, activeDataPopular]);

  useEffect(() => {
    if (filterData?.tags_data) {
      setDataSearch([...filterData?.tags_data]);
    }
  }, [filterData]);

  useEffect(() => {
    timeOut = setTimeout(() => {
      if (inputValue?.trim()?.length > 1) {
        searchTags();
      }
    }, 300);
  }, [inputValue]);

  useEffect(() => {
    if (Object.keys(filterData).length == 0) {
      setActiveData([]);
    } else if (!Array.isArray(filterData)) {
      let arrData = filterData?.tag_ids && filterData?.tag_ids?.split(',').map((item) => item && parseInt(item));
      setActiveData(arrData);
    }
  }, [filterData]);

  let getUniqueData = () => {
    let data = [...selectedData, ...activeDataPopular];
    let resArr = [];
    data.filter(function (item) {
      let i = resArr.findIndex((x) => x.id == item.id);
      if (i <= -1 && activeData.includes(item.id)) {
        resArr.push(item);
      }
      return null;
    });

    setAllSelectedData(resArr);
  };

  const renderHeading = () => {
    return <div className="filterHeadings">Select Tags</div>;
  };

  const renderChipsTags = () => {
    return (
      <div className="mt-2">
        <ChipsTagsView
          setselectedData={setselectedData}
          setActiveTags={setActiveData}
          activeTags={activeData}
          data={dataSearch}
        />
      </div>
    );
  };

  const renderChipsTagsPopular = () => {
    return (
      <div>
        <div className="mb-2 mt-3 blurText">Popular tags</div>
        <ChipsTagsView
          setselectedData={setActiveDataPopular}
          setActiveTags={setActiveData}
          activeTags={activeData}
          data={data}
        />
      </div>
    );
  };

  const getTagsData = () => {
    let qry = { popular: true };
    ApiClient.articleTagsGet(inputValue, qry)
      .then((res) => {
        setData(res.data);
      })
      .catch((err) => console.log(err));
  };

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const searchTags = () => {
    ApiClient.searchTags(inputValue)
      .then((res) => {
        let data = getUniqueListBy([...dataSearch, ...res.data], 'id');
        setDataSearch(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderTagsSearch = () => {
    return (
      <CoreInputBox
        setValue={(val) => {
          clearTimeout(timeOut);
          setInputValue(val);
        }}
        value={inputValue}
        placeholder="Search Tags"
        leftIcon={searchLight}
      />
    );
  };

  return (
    <div className="tagsFilter">
      {renderHeading()}
      {renderTagsSearch()}
      {renderChipsTags()}
      {renderChipsTagsPopular()}
    </div>
  );
}

export default TagsFilter;
