import React from 'react';
import './coreCheckBox.css';

function CoreRadioBox(props) {
  const { title, checked, className, value, onChange, id, htmlFor, name, disabled } = props;

  const onChangeBox = (evt) => {
    onChange(evt.target.value, evt.target.checked);
  };

  return (
    <div className='form-check'>
      <input
        disabled={disabled}
        style={{ cursor: 'pointer' }}
        onChange={onChangeBox}
        className='form-check-input'
        checked={checked}
        type='radio'
        value={value}
        id={id}
        name={name || 'medicine_time'}
      />
      <label style={{ cursor: 'pointer' }} className={`form-check-label ${className}`} htmlFor={htmlFor}>
        {title}
      </label>
    </div>
  );
}

export default CoreRadioBox;
