import { AdminPanelHeader } from 'components';
import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useGetCockpitDataQuery, useUpdateCockpitConfigDataMutation } from 'redux/cockpitSlice';
import { Toggle } from 'rsuite';
import { CommonAlert, Loader, ReusableButton } from 'whealth-core-web/components';

function Cockpit() {
  const [alert, setAlert] = useState({});

  const getCockpitData = useGetCockpitDataQuery();
  let configData = getCockpitData?.data?.data?.business_config;
  const [functionUpdate, updateData] = useUpdateCockpitConfigDataMutation();

  useEffect(() => {
    if (updateData?.data?.status) {
      if (updateData.data.status >= 200 && updateData.data.status < 300) {
        setAlert({ type: 'alert-success', message: 'Saved Successfully.' });
      } else {
        setAlert({ type: 'alert-danger', message: updateData?.data?.data?.detail || 'Failed to save.' });
      }
    }
  }, [updateData]);

  const renderHeader = useMemo(() => {
    return (
      <div className='headerFiles'>
        <AdminPanelHeader hideSearch iconTitle='manage_accounts' title='Cockpit' />
        <div className='hrline' />
      </div>
    );
  }, []);

  let arr = [
    { label: 'Enable Regime Questions', key: 'enable_regime_question' },
    { label: 'Enable Call Log', key: 'call_log' },
    { label: 'Enable Tele Consultation', key: 'enable_tele_consultation' },
    { label: 'Enable Order', key: 'enable_order' },
    { label: 'Enable Side Bar Notification', key: 'side_bar_notification' },
    { label: 'Enable Payment From & Payment With', key: 'enable_payment_from' },
    { label: 'Enable Diet Plan', key: 'enable_diet_plan' },
    { label: 'Enable Segment', key: 'enable_segment' },
    { label: 'Enable summary', key: 'enable_summary' },
    { label: 'Enable Banner', key: 'enable_banner' },
    { label: 'Enable Departments & Clinics', key: 'departments_clinics' },
    { label: 'Enable Mandatory Medicine in Prescriptions', key: 'mandatory_medicine' },
    // { label: "Enable Settings", key: "enable_settings" },
    { label: 'Enable Service', key: 'service' },
    { label: 'Enable Service Categories', key: 'enable_PRC' },
    { label: 'Enable Patient Service', key: 'enable_patient_add_service' },
    { label: 'Enable Side Bar Report', key: 'side_bar_report' },
  ];

  const handleItemClick = (item) => {
    if (item.isActive) {
      item.isActive = !item.isActive;
    } else {
      item.isActive = true;
    }
  };

  const submitData = (data = arr) => {
    functionUpdate({ business_config: data });
  };

  const renderItem = (item, serverItem, index) => {
    item.isActive = !!serverItem?.isActive;
    return (
      <>
        <span className='my-3'>
          <Toggle
            className='me-2 '
            onChange={() => handleItemClick(item, index)}
            type='checkbox'
            id={`labelcheck${index}`}
            defaultChecked={serverItem?.isActive}
          />
          <label className='capitalize pointer' htmlFor={`labelcheck${index}`}>
            {item.label}?
          </label>
        </span>
      </>
    );
  };

  const renderCockpit = () => {
    if (configData) {
      return arr.map((item, index) => {
        return (
          <div key={index} className='my-3'>
            {renderItem(item, configData[index], index)}
          </div>
        );
      });
    } else if (configData === null) {
      return arr.map((item, index) => {
        return (
          <div key={index} className='my-3'>
            {renderItem(item, {}, index)}
          </div>
        );
      });
    }
  };

  return (
    <div className='wrapper'>
      <div className='articleHeader stickyHeader'>
        {renderHeader}
        <CommonAlert setIsShowAlert={setAlert} isShowAlert={alert.message} alertType={alert.type} />
      </div>
      <div className='px-4 wrapperContent'>
        <div className='ms-2'>{renderCockpit()}</div>
        <div className='my-5'>
          <ReusableButton onClick={() => submitData()} title='Save' active />
        </div>
        <Loader show={updateData.isLoading} />
      </div>
    </div>
  );
}

export default Cockpit;
