import React, { useEffect, useState } from 'react';
import { editTheme, search, trashRed } from 'res/images';

// import { dateFormat, teamRoles, timeFormat } from './Helper';
import '../listView.css';
import { Button, Modal } from 'rsuite';
import CoreTextArea from '../CoreTextArea';
import { dateFormat, teamRoles, timeFormat } from '../Helper';
import ReadMoreReadLess from '../ReadMoreReadLess';
import CommonToolTip from '../CommonToolTip';
import Select2DropDown from '../common/Select2DropDown';
import CoreInputBox from '../CoreInputBox';
import CommonAlert from '../CommonAlert';

function NotesTemplateList(props) {
  const {
    data,
    deleteData,
    updateId,
    setErrorInputValues,
    editError,
    setEditError,
    updateData,
    showKey,
    currentPage,
    categories,
    setEditMode,
    notesTemplate,
    handleSearchCategory,
    updateDataModalOpen,
    setSearchInputTemplate,
    searchInputTemplate,
    errorInputValues,
    btnTitle,
    handleOnClick,
    searchCategory,
    content,
    setContent,
    selectedCatObj,
    setSelectedCatObj,
    isEditMode,
  } = props;

  const [isUpdate, setIsUpdate] = useState({});
  const [inputData, setInputData] = useState('');
  const [titleReal, setTitleReal] = useState('');
  const [noteCategory, setNoteCategory] = useState('');
  const [alertType, setAlertTypes] = useState('alert-success');
  const [isAlert, setShowAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState('');

  const handleCancelClick = () => {
    setIsUpdate({});
  };

  useEffect(() => {
    if (titleReal?.length > 0 || selectedCatObj.title?.length > 0) {
      setErrors(false);
    }
  }, [titleReal, selectedCatObj]);

  const showData = () => {
    return (
      <>
        <div className='d-flex mt-4 justify-content-end'>
          <CoreInputBox
            placeholder='All Templates Search'
            value={searchInputTemplate}
            setValue={(text) => {
              setSearchInputTemplate(text);
            }}
            leftIcon={search}
          />
        </div>
        <div className={!showKey ? 'tagsView' : 'notesList'}>
          <div className='table-responsive'>
            {notesTemplate.length > 0 ? (
              <table className='w-100 table'>
                <tbody>
                  {notesTemplate &&
                    notesTemplate.map((item, index) => {
                      return (
                        <tr>
                          <td className='w-100'>
                            <div className=''>
                              <span class=' capitalize lightGrayBadge'>{item.title}</span>
                            </div>
                            <div>
                              <span class='badge capitalize lightGrayBadge mt-2 mb-2'>{item.category?.title}</span>
                            </div>
                            <div className='editInput'>
                              <div className='tags capitalize'>
                                {/* <span className={!showKey && 'badge capitalize lightGrayBadge'}> */}
                                <span className={showKey && 'badge capitalize lightGrayBadge'}>
                                  {/* {showKey ? item[showKey] : item.title} */}
                                  <ReadMoreReadLess limit={100} data={item.content} />
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className='listNotsright'>
                              <div className='metaDetails mb-4 me-3'>
                                <div>
                                  {item.admin ? item.admin.full_name : item?.created_by?.full_name} (
                                  {teamRoles(item.admin ? item.admin.role : item?.created_by?.role)})
                                  <div>
                                    Created <span className='lowercase'> at </span>· {dateFormat(item?.created_at)}
                                    &nbsp;
                                    {timeFormat(item.created_at)}
                                  </div>
                                </div>
                              </div>
                              <div className='d-flex tagsViewIcons  mb-4'>
                                <div
                                  onClick={() => {
                                    setIsUpdate(item);
                                    setNoteCategory(item?.category?.id);
                                    setSelectedCatObj(item?.category);
                                    setInputData(item.content);
                                    setTitleReal(item.title);
                                  }}
                                >
                                  <CommonToolTip placement={'top'} item={<img src={editTheme} />} showValue={'Edit'} />
                                </div>
                                <div
                                  onClick={() => {
                                    const windowConfirm = window.confirm('Are you sure you want to delete ?');
                                    if (windowConfirm) {
                                      deleteData && deleteData(item.id);
                                    }
                                  }}
                                >
                                  <CommonToolTip placement={'top'} item={<img src={trashRed} />} showValue={'Delete'} />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}{' '}
                </tbody>
              </table>
            ) : (
              <div className='emptyView my-3'>
                <div> No data found</div>
              </div>
            )}
            <Modal backdrop='static' keyboard={false} open={isUpdate?.id ? true : false} onClose={handleCancelClick}>
              <Modal.Header>{/* <Modal.Title>Add Department</Modal.Title> */}</Modal.Header>
              <Modal.Body>
                <CommonAlert
                  isShowAlert={isAlert}
                  alertType={alertType}
                  setIsShowAlert={setShowAlert}
                  className='mt-2 '
                />
                <Select2DropDown
                  data={
                    !categories.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id
                      ? [...categories, selectedCatObj]
                      : categories
                  }
                  placeholder='Select Category'
                  retuired
                  label='Select Category'
                  labelStyle={{ fontWeight: 'var(--lightFont)' }}
                  value={noteCategory}
                  labelKey={'title'}
                  valueKey={'id'}
                  showMSG={errors?.category_id}
                  getObjData
                  onSelect={(val, data) => {
                    setNoteCategory(val);
                    setSelectedCatObj(data);
                  }}
                  isValidation
                  onSearch={(srcVal) => {
                    searchCategory(srcVal);
                  }}
                />

                <div className='mt-2'>
                  <CoreInputBox
                    maxLength={30}
                    retuired
                    rows={5}
                    label='title'
                    value={titleReal}
                    showMSG={errors?.title}
                    setValue={setTitleReal}
                  />
                </div>
                <div className='mt-2'>
                  <CoreTextArea
                    rows={5}
                    label='Content'
                    showMSG={errors?.content}
                    value={inputData}
                    setValue={setInputData}
                    msgStyle={{ color: 'red' }}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  onClick={() =>
                    handleOnClick(
                      inputData,
                      titleReal,
                      isUpdate?.id,
                      noteCategory,
                      setIsUpdate,
                      setShowAlert,
                      setAlertTypes,
                      setErrors
                    )
                  }
                  appearance='primary'
                >
                  Save
                </Button>
                <Button onClick={() => handleCancelClick()} appearance='subtle'>
                  Cancel
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </>
    );
  };

  return <div>{showData()} </div>;
}

export default NotesTemplateList;
