import React from 'react';
import { Tooltip, Whisper } from 'rsuite';

function CommonToolTip(props) {
  const { item, showValue, placement } = props;
  return (
    <Whisper placement={placement} speaker={<Tooltip>{showValue}</Tooltip>}>
      <div>{item}</div>
    </Whisper>
  );
}

export default CommonToolTip;
