import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { Modal } from 'rsuite';
import CommonBorderBox from '../CommonBorderBox';
import Loader from '../Loader';
import Pagination from '../Pagination';
import AdherenceCreateForm from './AdherenceCreateForm';
import AdherenceList from './AdherenceList';
import { warn } from 'react-bootstrap-typeahead/types/utils';
import CommonAlert from '../CommonAlert';
let inputFiledObj = {
  title: '',
  start_range: '',
  end_range: '',
};

function Adherence(props) {
  // const { setAlertType, setIsShowAlert } = props;
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [alertType, setAlertType] = useState('alert-success');

  const [adherenceCreate, setAdherenceCreate] = useState(inputFiledObj);
  const [errorInputValues, setErrorInputValues] = useState(inputFiledObj);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [isEditMode, setEditMode] = useState(false);
  const ApiClient = useApiManager();

  useEffect(() => {
    handleGetAdherenceList();
  }, [currentPage]);

  const handleGetAdherenceList = async () => {
    setIsLoading(true);
    let page = { page: currentPage };
    await ApiClient.getAdherence(page).then((res) => {
      setTotalPages(res.headers['total-pages']);
      setTableData(res?.data);
      setEditMode(false);
    });
    setIsLoading(false);
  };

  const handleSaveAction = async () => {
    setIsLoading(true);
    await ApiClient.createAdherence(adherenceCreate)
      .then((res) => {
        handleGetAdherenceList();
        setCurrentPage(1);
        setAdherenceCreate(inputFiledObj);
        setAlertType('alert-success');
        setIsShowAlert('Adherence Created successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail || err.response.data.errors.non_field_errors);
        setErrorInputValues(err.response.data.errors || err.response.data.errors.non_field_errors);
      });
  };
  const updateDataModalOpen = (data) => {
    setEditMode(true);
    setAdherenceCreate(data);
    setErrorInputValues({});
  };

  const handleEditAction = async () => {
    setIsLoading(true);
    await ApiClient.updateAdherence(adherenceCreate.id, adherenceCreate)
      .then((res) => {
        handleGetAdherenceList();
        setAdherenceCreate(inputFiledObj);
        setAlertType('alert-success');
        setIsShowAlert('Adherence Updated successfully');
      })
      .catch((err) => {
        console.log('90', err);

        setIsLoading(false);
        setAlertType('alert-danger');

        if (err.response?.data?.detail || err.response.data.errors.non_field_errors) {
          setIsShowAlert(err.response.data.detail || err.response.data.errors.non_field_errors);
        } else {
          setErrorInputValues(err.response.data.errors);
        }
      });
  };

  const deleteData = async (id) => {
    setErrorInputValues({});
    setIsLoading(true);
    await ApiClient.deleteAdherence(id)
      .then((res) => {
        handleGetAdherenceList();
        setAlertType('alert-success');
        setIsShowAlert('Adherence Deleted successfully');
      })
      .catch((err) => {
        setAlertType('alert-danger');
        setIsShowAlert(err.response.data.detail);
      });
    setIsLoading(false);
  };
  return (
    <div className='medicine-form medicine-alert'>
      <div className='commonBorderBox settingFormOutline mb-4 '>
        <div className=''>
          {!isEditMode && (
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          )}
        </div>
        <div className='formTitle'>Adherence</div>

        <AdherenceCreateForm
          inputFileValues={isEditMode ? inputFiledObj : adherenceCreate}
          errorInputValues={isEditMode ? inputFiledObj : errorInputValues}
          handleOnChanges={setAdherenceCreate}
          btnTitle={'Save'}
          handleOnClick={handleSaveAction}
          setErrorInputValues={setErrorInputValues}
        />
      </div>
      <div className='table-responsive'>
        <AdherenceList tableList={tableData} updateDataModalOpen={updateDataModalOpen} deleteData={deleteData} />
      </div>
      <Loader show={isLoading} />
      <Pagination
        seterror={setErrorInputValues}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
      <Modal
        backdrop='static'
        size='md'
        open={isEditMode}
        onClose={() => {
          setEditMode(false);
          setErrorInputValues({});
          setAdherenceCreate(inputFiledObj);
        }}
      >
        <Modal.Header />
        <div className='mt-4 me-4'>
          <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
        </div>
        <AdherenceCreateForm
          inputFileValues={adherenceCreate}
          errorInputValues={errorInputValues}
          handleOnChanges={setAdherenceCreate}
          btnTitle={'Update'}
          handleOnClick={handleEditAction}
          setErrorInputValues={setErrorInputValues}
        />
      </Modal>
    </div>
  );
}

export default Adherence;
