import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useApiManager from 'networking/ApiManager';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { DatePicker, Modal } from 'rsuite';
import { CoreDropDown, CoreTextArea, CommonAlert, Loader, Select2DropDown } from 'whealth-core-web/components';
import ShowTaskList from './ShowTaskList';
import { Pagination } from 'whealth-core-web/components';
import { dateFormatString, dateFormatStringSingleDate } from 'whealth-core-web/components/Helper';

function RenderTasksTab() {
  const { patientDetails } = useSelector((state) => state.sliceReducer);
  const ApiClient = useApiManager();
  const { id } = useParams();

  const [error, setError] = useState('');
  const [errorUpdate, setErrorUpdate] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setshowAlert] = useState('');
  const [alertType, setAlertType] = useState('alert-success');
  const [listData, setlistData] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [createData, setCreateData] = useState({});
  const [updateData, setUpdateData] = useState({});
  const [completionDate, setcompletionDate] = useState(null);
  const [selectedCatObj, setSelectedCatObj] = useState({});
  let formVal = Object.keys(updateData).length > 0 ? updateData : createData;
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    showListApi();
  }, [id, currentPage]);

  useEffect(() => {
    searchTaskAssign();
  }, []);

  const searchTaskAssign = (qry) => {
    let params = {
      search_str: qry,
    };
    ApiClient.searchTaskAssign(id, params)
      .then((res) => {
        setSearchData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const renderSearchBox = (data, setData, isUpDate) => {
    const handleClick = (id, obj) => {
      data.assignId = id;
      setData({ ...data });
      setSelectedCatObj(obj);
    };
    return (
      <>
        <div>
          <Select2DropDown
            isValidation
            onSearch={searchTaskAssign}
            showMSG={isUpDate ? errorUpdate?.assigned_to_id : error?.assigned_to_id}
            defaultData={updateData.assigned_to}
            label='Assign To'
            data={
              !searchData.find((item) => selectedCatObj?.id == item.id) && selectedCatObj?.id
                ? [...searchData, selectedCatObj]
                : searchData
            }
            style={{ width: '100%' }}
            labelKey='full_name'
            valueKey='id'
            placeholder='Assign To'
            defaultValue={updateData?.assignId || ''}
            value={data.assignId || ''}
            onSelect={handleClick}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
        <div className='mt-3 capitalize w-100'>
          <div>
            <label class='inputlabel'>Completion Date</label>
          </div>
          <DatePicker
            disabledDate={(date) => {
              if (new Date(date) < new Date().setHours(0, 0, 0)) {
                return true;
              } else {
                return false;
              }
            }}
            onClean={() => {
              data.completion_date = null;
              setData({ ...data });
            }}
            style={{ width: '100%' }}
            placement='bottomStart'
            format='yyyy-MM-dd'
            placeholder='YYYY-MM-DD'
            value={data.completion_date ? new Date(data.completion_date) : null}
            calendarDefaultDate={data.completion_date ? new Date(data.completion_date) : null}
            onOk={(res) => {
              data.completion_date = dateFormatStringSingleDate(res);
              setData({ ...data });
            }}
          />
        </div>
      </>
    );
  };

  const showListApi = () => {
    setIsLoading(true);
    let params = { page: currentPage };
    ApiClient.showTasks(id, params)
      .then((res) => {
        setIsLoading(false);
        setlistData(res.data);
        setTotalPages(res.headers['total-pages']);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const prepareFormData = (data) => {
    setError('');
    return {
      content: data.content,
      assigned_to_id: data.assignId,
      completion_date: data.completion_date,
    };
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const data = prepareFormData(formVal);
    createTask(data);
  };

  const createTask = (data) => {
    setIsLoading(true);
    ApiClient.createTasks(data, id)
      .then((res) => {
        delete data.completion_date;
        setIsLoading(false);
        handleModalClose('Task created successfully');
        setcompletionDate(null);
      })
      .catch((err) => {
        setIsLoading(false);
        setError(err.response.data.errors);
        console.log(err);
      });
  };

  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    const data = prepareFormData(formVal);
    updateTask(data);
  };

  const updateTask = (data) => {
    setIsLoading(true);
    ApiClient.updateTasks(data, id, updateData.id)
      .then((res) => {
        setIsLoading(false);
        handleModalClose('Task updated successfully');
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        setError('');
        setErrorUpdate(err.response.data.errors);
        setAlertType('alert-danger');

        console.log(err);
      });
  };

  const handleModalClose = (msg) => {
    setOpen(false);
    setcompletionDate('');
    setError('');
    setCreateData({});
    setUpdateData({});
    if (msg) {
      showListApi();
      setshowAlert(msg);
      setAlertType('alert-success');
    }
  };

  const showUpdateModal = (item) => {
    setError('');
    setErrorUpdate('');
    item.assignId = item.assigned_to.id;
    setUpdateData(item);
    setOpen(true);
    setcompletionDate(item.completion_date);
  };

  const updateModal = (item) => {
    return (
      <div>
        <Modal backdrop='static' size='md' open={open} onClose={() => handleModalClose()}>
          <Modal.Header />
          {renderTasksForm(updateData, setUpdateData, true)}
        </Modal>
      </div>
    );
  };

  const deleteTask = (tasKid) => {
    setError('');
    ApiClient.deleteTask(id, tasKid)
      .then((res) => {
        setshowAlert(res.data.message);
        setAlertType('alert-success');
        showListApi();
      })
      .catch((err) => {
        setshowAlert(err.response.data.detail);
        setAlertType('alert-danger');
      });
  };

  const showTaskList = useMemo(() => {
    return (
      <div>
        <ShowTaskList deleteData={deleteTask} setUpdateData={showUpdateModal} data={listData} />
      </div>
    );
  }, [listData]);

  const renderTasksForm = (data, setData, isUpDate) => {
    return (
      <div>
        <form onSubmit={isUpDate ? handleUpdateSubmit : handleFormSubmit}>
          <div style={{ margin: '20px 0' }}>
            <CoreTextArea
              showMSG={isUpDate ? errorUpdate?.content : error?.content}
              placeholder='Content'
              value={data.content || ''}
              label='Content'
              setValue={(res) => {
                setData({ ...data, content: res });
              }}
              msgStyle={{ color: 'red' }}
              retuired
              rows={5}
              type='email'
            />
          </div>

          <div style={{ margin: '20px 0' }}>
            <div className='dropDownItemCustom'>{renderSearchBox(data, setData, isUpDate)}</div>
          </div>

          <button
            type='submit'
            onClick={isUpDate ? handleUpdateSubmit : handleFormSubmit}
            className='form-control coreBtn text-white'
          >
            {isUpDate ? 'Update' : 'Save'}
          </button>
        </form>
      </div>
    );
  };

  return (
    <div className='mt-4 tasksTab'>
      {showAlert && (
        <div className='mt-5'>
          <CommonAlert isShowAlert={showAlert} setIsShowAlert={setshowAlert} alertType={alertType} />
        </div>
      )}
      {renderTasksForm(createData, setCreateData)}
      {showTaskList}
      {updateModal()}
      <Loader show={isLoading} />
      <div className='d-flex justify-content-end'>
        <div className='patientPagination mt-3'>
          <Pagination totalPages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
      </div>
    </div>
  );
}

export default RenderTasksTab;
