import React, { useEffect, useState } from 'react';
import './alertMessage.scss';

function AlertMessage(props) {
  const { show, type, isShowAlert } = props;
  const [showAlert, setShowAlert] = useState(show);

  useEffect(() => {
    setShowAlert(show);
    setTimeout(() => {
      setShowAlert(false);
      isShowAlert && isShowAlert(false);
    }, 6000);
  }, [show, type]);

  if (showAlert) {
    return (
      <div className="alertMessage">
        <div className={`alert ${type || 'alert-success'} alert-dismissible fade show`} role="alert">
          {show}
          <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
      </div>
    );
  } else {
    return;
  }
}

export default AlertMessage;
