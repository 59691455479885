import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { useNavigate, useParams } from 'react-router-dom';

import { dateFormatString, prepareGraphData, subtractMonths } from './Helper';
import Loader from './Loader';
import DayWeekMonthView from './patient/DayWeekMonthView';

function RenderGraph(props) {
  const { data, customDate, healthTab, getDuration } = props;
  const navigate = useNavigate();
  const { id, gid } = useParams();
  const ApiClient = useApiManager();

  const [duration, setDuration] = useState({
    title: '6M',
    date: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)),
  });

  const [goalData, setgoalData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [apiCall, setApiCall] = useState(false);

  let value = data?.value || data?.goal?.value || 0;
  value = parseInt(value);

  useEffect(() => {
    getDuration && getDuration(duration.date);
  }, [duration]);

  useEffect(() => {
    if (apiCall) {
      if (gid) {
        getGraphData(duration.date);
      } else if (duration && data) {
        getGraphData(duration.date, data.id);
      }
    } else {
      setgoalData(data);
    }
    setTimeout(() => {
      setApiCall(true);
    }, 500);
  }, [duration, gid]);

  useEffect(() => {
    if (customDate) {
      setDuration({ title: '6M', date: dateFormatString(customDate[0], customDate[1]) });
    } else {
      setDuration({ title: '6M', date: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)) });
    }
  }, [customDate]);

  const getGraphData = (dateRange, goalId) => {
    const params = {
      date_range: dateRange,
    };
    setIsLoading(true);
    ApiClient.getPatientgoals(id, gid || goalId, params)
      .then((res) => {
        setIsLoading(false);
        setgoalData(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const options = {
    width: '100%',
    legend: 'none',
    curveType: 'function',
    series: {
      0: { pointSize: 8 },
      1: { pointSize: 4 },
    },
    tooltip: { isHtml: true },
    hAxis: { maxValue: 30 },

    vAxis: {
      viewWindow: { min: 1 },
      baseline: 1,
      format: '#',
    },
    explorer: { actions: ['dragToZoom', 'rightClickToReset'] },
    trendlines: {
      0: {
        type: 'exponential',
        visibleInLegend: true,
        color: 'red',
      },
    },
  };

  return (
    <div className='card mb-4'>
      <div className='card-body'>
        <div className='d-flex'>
          <div className='flex-grow-1'>
            <div
              onClick={() => {
                navigate(`/patient/${id}/health-profile/${data.id}/show`);
              }}
              className='graph-card-title energy capitalize d-flex align-items-center'
            >
              <span>
                <img className='graph-icon' src={data?.goal?.measuring_event?.image_url} />
              </span>
              {data.goal?.title}
            </div>
          </div>

          <div className='ms-auto'>
            <h5 className='card-title'>{parseFloat(data?.graph_stats?.average).toFixed(2)}</h5>
          </div>
          <div className='graph-unit m-1 capitalize'>{data?.goal?.measuring_event?.measuring_unit}</div>
        </div>

        <div className='d-flex'>
          <Chart
            selectionMode='multiple'
            chartType='LineChart'
            isStacked
            data={
              goalData?.graph_stats?.plot_date
                ? prepareGraphData(goalData?.graph_stats?.plot_date)
                : prepareGraphData([])
            }
            options={options}
          />
        </div>

        <div className='d-flex justify-content-center graph-btn-group durationGroupBtn'>
          {!customDate && <DayWeekMonthView setActive={setDuration} active={duration} />}
        </div>
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

export default RenderGraph;
