import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { femaleUser, maleUser, warningBadge, whatsapp } from 'res/images';
import './showTeamMembersList.scss';
import labels from '../../inputFields.json';

import {
  profileImage,
  dateFormat,
  showArrayValues,
  timeAgo,
  titlizeString,
  timeFormat,
  teamRoles,
} from 'whealth-core-web/components/Helper.js';
import { useDispatch } from 'react-redux';
import { setPatientActiveTab } from 'redux/Slices';
import StatusCard from './StatusCard';

function ShowPatientList(props) {
  const { data, renderStatusChange, getAdminProfiles } = props;
  const [editOnboardStatus, setEditOnboardStatus] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { vitalsSection, assignSection, profileSection } = labels?.patientSection;
  let fields = vitalsSection?.data;
  let role = assignSection?.showRole;
  useEffect(() => {
    dispatch(setPatientActiveTab('timeline'));
  }, []);

  const onBoardingStatus = [
    { title: 'Show', id: 'show' },
    { title: 'No Show', id: 'no_show' },
  ];

  const handleStatusCard = (status) => {
    setEditOnboardStatus(status);
  };

  const userCard = (data) => {
    let userAge = data?.age && data.age + 'yr';
    return (
      <>
        <div className='w-100 d-flex userImageView'>
          <div
            className='userImage pointer'
            onClick={() => {
              navigate(`/patient/${data?.id}/dashboard`, {
                state: {
                  data,
                },
              });
            }}
          >
            <img
              className='listUserImages'
              src={profileImage(data?.gender, data?.profile_url)}
            />
            <div
              className={`statusDot borderWhite ${data?.status?.title
                ?.toLowerCase()
                ?.replaceAll(' ', '')}`}
            />
          </div>
          <div className='linesView'>
            <Link
              className='userName'
              to={`/patient/${data.id}/dashboard`}
              state={data}
            >
              <div>{data?.full_name}</div>
            </Link>
            <div className='bottomTextView capitalize'>
              {showArrayValues(
                [showArrayValues([userAge, data?.gender], ', '), data?.city],
                ' · '
              )}
            </div>
            {data?.created_at && (
              <div className='bottomTextView'>
                {profileSection?.dateLabel || 'Joined At'} :{' '}
                {dateFormat(data?.created_at)}
              </div>
            )}
            <div className='userPhoneNumber d-flex'>
              <img src={whatsapp} />
              <div className='phonenumberText'>{data?.phone}</div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const roleView = (data) => {
    return (
      <div className='linesView'>
        <div className='d-flex'>
          <div
            className={` d-flex align-items-center badge capitalize onboardinglist defaultbadge ${data?.status?.title?.toLowerCase()}`}
          >
            {data?.status?.title}
          </div>
          <div className='ms-2'>
            {renderStatusChange ? (
              <StatusCard
                tagPencil
                item={data}
                setUpdateStatus={handleStatusCard}
                updateStatus={editOnboardStatus == data.id}
                status={onBoardingStatus}
                getPatientData={getAdminProfiles}
                badgeTitle={titlizeString(data.onboarding_status)}
                defaultStatus='Onboarding Status'
                defaultClass='onboarding_status_default'
              />
            ) : null}
          </div>
        </div>
        <div className='bottomTextView'>
          Last Activity : {dateFormat(data?.last_activity)}
        </div>
        {/* <div className="bottomTextView">Renewal on . 13 Dec 2022</div> */}
      </div>
    );
  };

  const renderdata = (item) => {
    return fields.map((field) => {
      if (field?.show) {
        const dateField =
          (field?.key == 'pms_checkin_date' ||
            field?.key == 'pms_consult_date') &&
          item[field?.key];
        const PipeRemove = field?.key == 'care_pathway' && item[field?.key];
        if (dateField) {
          return (
            <div className='bottomTextView capitalize'>
              {field?.label} : {dateFormat(item[field?.key] || '')}&nbsp;
              {timeFormat(item[field?.key] || '')}
            </div>
          );
        } else if (PipeRemove) {
          return (
            <div className='bottomTextView capitalize'>
              {field?.label} : {item[field?.key].replaceAll('|', ',') || ''}
            </div>
          );
        } else {
          return (
            <div className='bottomTextView capitalize'>
              {field?.label} : {item[field?.key] || 'N/A'}
            </div>
          );
        }
      } else {
        return null;
      }
    });
  };

  const bmiView = (data) => {
    return (
      <div className='linesView'>
        {data?.health_matrix?.last_activity && (
          <div className='badge capitalize lightGrayBadge'>
            {timeAgo(data?.health_matrix?.last_activity)}
          </div>
        )}
        {renderdata(data?.health_matrix)}
        {/* <div className='bottomTextView capitalize'>
          BMI : {data?.health_matrix?.current_bmi || 'N/A'}, Weight :{' '}
          {data?.health_matrix?.current_weight
            ? parseFloat(data?.health_matrix?.current_weight).toFixed(2) + ' kg'
            : }
        </div> */}
        <div className='bottomTextView capitalize'>
          {data?.health_matrix?.current_blood_pressure && (
            <span> BP : {data?.health_matrix?.current_blood_pressure},</span>
          )}
          {data?.health_matrix?.current_sugar_level && (
            <span> Sugar : {data?.health_matrix?.current_sugar_level}</span>
          )}
        </div>
      </div>
    );
  };

  const assignBtnView = (data) => {
    return (
      <>
        <div className='linesView'>
          <div className='teams'>
            <div>
              <img
                className='assinedTeamImage'
                src={profileImage(data?.gender, data?.profile_url)}
              />
            </div>
            <div className='linesView'>
              <div
                className='d-flex bottomTextView capitalize'
                // onClick={() => {
                //   navigate(`/team/${data?.id}/edit`);
                // }}
              >
                {data?.full_name}&nbsp;
                <div className='capitalize'>
                  {role
                    ? ' - ' + titlizeString(teamRoles(data?.role)) + ''
                    : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const renderData = useMemo(() => {
    return (
      <div className='table-responsive'>
        <table className='w-100'>
          <tbody>
            {data &&
              data?.map((item, index) => {
                return (
                  <tr
                    key={index}
                    onClick={() => {
                      item.status == 'in_active' &&
                        navigate(`/team/${item.id}/edit`);
                    }}
                    className='userFullCard'
                  >
                    <td> {userCard(item)}</td>
                    <td>{roleView(item)}</td>
                    <td>{bmiView(item)}</td>
                    <td>
                      <div
                        key={index}
                        className='badge capitalize lightGrayBadge'
                      >
                        Assigned Teams
                      </div>
                      {item?.admins?.map((data, keyText) => {
                        return (
                          <div key={keyText}>{assignBtnView(data, index)}</div>
                        );
                      })}
                    </td>
                    {item.status == 'in_active' && (
                      <td className='usersDivDisable' />
                    )}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }, [data, editOnboardStatus]);

  return <div className='showTeamMembersList'>{renderData}</div>;
}

export default ShowPatientList;
