import React from 'react'
import ReusableButton from '../ReusableButton';

export const ZohoConnectButton = (props) => {
  const { zohoOauthLink, isZohoOauthExpired } = props;

  const handleConnectZohoClick = () => {
    window.location.href = zohoOauthLink ?? "";
  };

  return (
    <ReusableButton
        outline
        title={isZohoOauthExpired ? "Connect Zoho" : "Zoho connected"}
        onClick={isZohoOauthExpired ? () => handleConnectZohoClick() : () => {}}
      >
        Connect ZOHO
      </ReusableButton>
  )
}
