import { AdminPanelHeader, SideBar } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Table, Pagination, DateRangePicker, Stack } from 'rsuite';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { profileImage } from 'whealth-core-web/components/Helper';
import { CoreDropDown, Loader, PrescriptionGraph, RenderGraph } from 'whealth-core-web/components';
import {
  dateFormat,
  timeFormat,
  subtractMonths,
  dateFormatString,
  showMedicineTimeString,
} from 'whealth-core-web/components/Helper';
const { Column, HeaderCell, Cell } = Table;

function PrescriptionListView() {
  const [date, setDate] = useState('');
  const [customDate, setCustomDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [medicineData, setmedicineData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const ApiClient = useApiManager();
  const { id, pid, mid } = useParams();

  useEffect(() => {
    if (customDate) {
      getListData(customDate);
    } else {
      getListData();
    }
  }, [date, id, customDate]);

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };

  const getListData = (customDate) => {
    let startDate = subtractMonths(6);
    let endDate = new Date();
    if (date?.length > 0) {
      startDate = date[0];
      endDate = date[1];
    }

    setIsLoading(true);
    const query = {
      date_range: customDate || dateFormatString(startDate, endDate),
    };

    ApiClient.prescriptionMedicine(id, pid, mid, query)
      .then((res) => {
        setmedicineData(res.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  const renderHeader = () => {
    const showTree = [
      { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
      {
        icon: profileImage(userDetails.gender, userDetails.image),
        title: userDetails.name,
        link: `/patient/${id}/dashboard/`,
      },
      {
        title: 'prescription',
        link: `/patient/${id}/dashboard/`,
      },
      {
        title: medicineData?.medicine?.medicine_title_repr || '',
      },
    ];
    return (
      <div className='my-3'>
        <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
      </div>
    );
  };

  const pageData = medicineData?.activity_logs?.filter((v, i) => {
    const start = limit * (page - 1);
    const end = start + limit;
    return i >= start && i < end;
  });

  const renderDataTable = () => {
    return (
      <div>
        <Table height={600} bordered cellBordered data={pageData}>
          <Column align='center'>
            <HeaderCell>Sr. No. </HeaderCell>
            <Cell>
              {(rowData, index) => {
                return <span>{index + 1}</span>;
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Dosage</HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <div className='d-flex listDosage'>
                    {showMedicineTimeString(rowData.value).map((item, index) => {
                      return <div className='me-2 '>{item}</div>;
                    })}
                  </div>
                );
              }}
            </Cell>
          </Column>

          <Column flexGrow={1}>
            <HeaderCell>Logged At</HeaderCell>
            <Cell>
              {(rowData) => {
                return (
                  <span>
                    {dateFormat(rowData.record_date)} · {timeFormat(rowData.record_date)}
                  </span>
                );
              }}
            </Cell>
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Source</HeaderCell>
            <Cell>
              {(rowData) => {
                return <span>{rowData.channel}</span>;
              }}
            </Cell>
          </Column>
        </Table>
        <div style={{ padding: 20 }}>
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='xs'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={medicineData?.activity_logs?.length}
            limitOptions={[10, 30, 50]}
            limit={limit}
            activePage={page}
            onChangePage={setPage}
            onChangeLimit={handleChangeLimit}
          />
        </div>
      </div>
    );
  };

  const renderDatePicker = () => {
    const predefinedBottomRanges = [
      {
        label: 'Today',
        value: [new Date(), new Date()],
        placement: 'left',
      },
      {
        label: 'Yesterday',
        value: [addDays(new Date(), -1), addDays(new Date(), -1)],
        placement: 'left',
      },
      {
        label: 'This week',
        value: [startOfWeek(new Date()), endOfWeek(new Date())],
        placement: 'left',
      },
      {
        label: 'Last 7 days',
        value: [subDays(new Date(), 6), new Date()],
        placement: 'left',
      },
      {
        label: 'Last 30 days',
        value: [subDays(new Date(), 29), new Date()],
        placement: 'left',
      },
      {
        label: 'This month',
        value: [startOfMonth(new Date()), new Date()],
        placement: 'left',
      },
      {
        label: 'Last month',
        value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
        placement: 'left',
      },
      {
        label: 'This year',
        value: [new Date(new Date().getFullYear(), 0, 1), new Date()],
        placement: 'left',
      },
      {
        label: 'Last year',
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
        placement: 'left',
      },
    ];

    return (
      <div className='w-100'>
        <Stack direction='column' spacing={8} alignItems='flex-start'>
          <DateRangePicker
            disabledDate={(date) => {
              if (date >= new Date()) {
                return true;
              } else {
                return false;
              }
            }}
            placement='bottomEnd'
            showOneCalendar
            onChange={(date) => {
              setDate(date);
              setCustomDate('');
            }}
            ranges={predefinedBottomRanges}
            placeholder='Select Date'
            defaultValue={date}
            style={{ width: '100%' }}
            format='yyyy-MM-dd'
            calendarDefaultDate={new Date()}
          />
        </Stack>
      </div>
    );
  };

  const rendermedicineData = () => {
    return (
      <>
        <div className='articleHeader stickyHeader'>{renderHeader()}</div>
        <div className='px-4 wrapperContent'>
          <div className='d-md-flex align-items-center justify-content-end me-3'>
            <div className='graphDatePicker mt-3'>{renderDatePicker()}</div>
          </div>
          <div className='p-3 my-3'>
            {medicineData && <PrescriptionGraph gatDate={setCustomDate} customDate={date} data={medicineData} />}
            {renderDataTable()}
          </div>
        </div>
      </>
    );
  };

  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {rendermedicineData()}
      <Loader show={isLoading} />
    </div>
  );
}

export default PrescriptionListView;
