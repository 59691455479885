import React from 'react';
import './header.css';

function Header(props) {
  const { logo } = props;
  return (
    <div className="loginHeader">
      <img src={logo} className="plainLogo" />
    </div>
  );
}

export default Header;
