import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../res/style/CreateMeetingModal.css';
import { Button, DatePicker, Modal } from 'rsuite';
import dayjs from 'dayjs';
import CoreInputBox from '../CoreInputBox';

export const EditMeetingModal = ({ open, handleClose, selectedDate, event }) => {
  const [title, setTitle] = useState(event?.event.title);
  const [time, setTime] = useState(event?.event.start);
  const [participants, setParticipants] = useState();
  const [loading, setLoading] = useState(false);
  const ApiClient = useApiManager();
  const [duration, setDuration] = useState(30);

  useEffect(() => {
    setTitle(event?.event?.title);
    setTime(event?.event.start);
  }, [event]);

  const handleSave = async () => {
    if (title == '' || time == '') {
      toast.error('Please enter all the details.');
    } else {
      try {
        const meeting = {
          topic: title,
          start_time: dayjs(selectedDate?.startStr)
            .set('hour', new Date(time).getHours())
            .set('minute', new Date(time).getMinutes())
            .format()
            .split('+')[0],
          end_time: dayjs(selectedDate?.startStr)
            .set('hour', new Date(time).getHours())
            .set('minute', new Date(time).getMinutes())
            .add(duration, 'minute')
            .format()
            .split('+')[0],
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        setLoading(true);
        const result = await ApiClient.updateZohoMeeting(event.event.id, meeting);
        setLoading(false);
        if (result.status == 200) {
          console.log(selectedDate);
          const calendarApi = selectedDate.view.calendar;
          calendarApi.unselect();
          event.event.remove();
          console.log(result);
          calendarApi.addEvent({
            id: `${result.data.meeting_key}`,
            title: title,
            start: dayjs(selectedDate?.startStr)
              .set('hour', new Date(time).getHours())
              .set('minute', new Date(time).getMinutes())
              .format()
              .split('+')[0],
            end: dayjs(selectedDate?.startStr)
              .set('hour', new Date(time).getHours())
              .set('minute', new Date(time).getMinutes())
              .add(duration, 'minute')
              .format()
              .split('+')[0],
            allDay: false,
          });
          toast.success('Meeting updated!');
          handleClose();
        } else {
          toast.error('Failed to schedule meeting.');
        }
      } catch (e) {
        toast.error(e.response.data.message ?? 'An error occurred.');
      }
    }
  };

  const handleTitleChange = (e) => {
    setTitle(e);
  };

  const handleParticipantsChange = (e) => {
    setParticipants(e.target.value);
  };

  const handleTimeChange = (time) => {
    console.log('Selected time: ', selectedDate);
    setTime(time);
  };

  const handleJoin = () => {
    window.open(event?.event?.extendedProps.join_link, '_blank');
  };

  const handleDelete = async () => {
    try {
      const res = await ApiClient.deleteZohoMeeting(event?.event.id);
      if (res.status == 200) {
        event.event.remove();
        toast.success('Meeting deleted.');
        handleClose();
      } else {
        toast.error('Failed to delete meeting.');
      }
    } catch (e) {
      toast.error(e.response.data.message ?? 'An error occurred.');
    }
  };

  const handleDurationChange = (value) => {
    setDuration(value);
  };
  return (
    <>
      <ToastContainer />
      <Modal backdrop='static' keyboard={false} open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Update Meeting</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='d-flex flex-column'>
            <p className='fs-6 my-1'>Title</p>
            <CoreInputBox
              className='form-control mb-2'
              type='text'
              placeholder='Title'
              value={title}
              setValue={handleTitleChange}
            />
            <p className='fs-6 my-1'>Select Time</p>
            <DatePicker
              placement='auto'
              format='HH:mm'
              placeholder='HH:MM'
              editable={false}
              value={time}
              onChange={(value) => handleTimeChange(value)}
            />
            <p className='fs-6 my-1 mt-2'>Duration (in minutes)</p>
            <CoreInputBox
              type='number'
              min={5}
              placeholder='Duration'
              value={duration}
              setValue={(value) => handleDurationChange(value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handleSave()} appearance='primary'>
            Update
          </Button>
          <Button onClick={handleDelete} appearance='subtle' color='red'>
            Delete
          </Button>
          <Button onClick={handleClose} appearance='subtle'>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
