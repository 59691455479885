import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function JoinRoom() {
  const { code } = useParams();

  useEffect(() => {
    document.body.classList.add('joinRoomBody');
  }, []);

  return (
    <div className='JoinRoom'>
      <iframe
        title='100ms-app'
        allow='camera *;microphone *;display-capture *'
        // src='https://`<your-subdomain>.100ms.live`/meeting/`<room-code>`'
        src={`https://critika-videoconf-1200.app.100ms.live/meeting/${code}`}
        styles='height: 100vh; width: 100%; border:0;'
      ></iframe>
    </div>
  );
}

export default JoinRoom;
