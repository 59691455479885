import React, { useEffect, useState } from 'react';
import StatusBar from './StatusBar';
import { Popover, Whisper } from 'rsuite';
import { laptop, laptopLink, payment, paymentLink, user, userLink } from 'whealth-core-web/assets/images';
import { dateFormat, timeFormat, titlizeString } from '../Helper';

function DoctorSchedulTile(props) {
  const {
    className,
    appointmentDetail,
    handleAppointmentEdit,
    setOpen,
    setCancelModal,
    height,
    setCreatedAppointment,
    userData,
  } = props;

  const renderStatus = () => {
    if (appointmentDetail?.consultation_status == 'overdue') {
      return <StatusBar className='overdue' icon='cancel' status='Overdue' />;
    } else if (appointmentDetail?.consultation_status == 'no_show') {
      return <StatusBar className='noshow' icon='cancel' status='No Show' />;
    } else if (appointmentDetail?.consultation_status == 'canceled') {
      return <StatusBar className='overdue' icon='cancel' status='Canceled' />;
    } else if (appointmentDetail?.consultation_status == 'show') {
      return <StatusBar className='visited' icon='check_circle' status='Visited' />;
    } else {
      return <StatusBar className='scheduled' icon='check_circle' status='Scheduled' />;
    }
  };

  const schedulPopover = (
    <div style={{ height: 100, position: 'absolute' }}>
      <Popover visible className='schedulePopover'>
        <div className='popoverPart'>
          <div className='schedulePopover-head'>
            <div className='tileName'>
              {appointmentDetail?.user?.full_name} (
              {appointmentDetail?.user?.gender && appointmentDetail?.user?.gender[0].toUpperCase()},{' '}
              {appointmentDetail?.user?.age})
            </div>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='number'>{appointmentDetail?.user?.phone}</div>
              <div className='number'>{renderStatus()}</div>
            </div>
          </div>
          <div className='schedulePopover-body'>
            <div className='d-flex justify-content-between schedule-part'>
              <div className='d-flex flex-column justify-content-start'>
                <div className='time'>
                  {dateFormat(appointmentDetail?.consult_datetime)}, {timeFormat(appointmentDetail?.consult_datetime)} -{' '}
                  {timeFormat(appointmentDetail?.consult_end_datetime)}
                </div>
                <span>With {appointmentDetail?.consultant?.full_name}</span>
                {appointmentDetail?.service?.title ? (
                  <div className=' d-flex justify-content-start  mt-1'>
                    <div className='follow'>{appointmentDetail?.service?.title}</div>
                  </div>
                ) : null}
              </div>
              <div className='d-flex flex-column align-items-center'>
                {appointmentDetail?.consultation_status != 'canceled' &&
                appointmentDetail.patient_attendance_status !== 'show' ? (
                  <div className='d-flex justify-content-center align-items-center flex-column'>
                    <div
                      onClick={() => handleAppointmentEdit(appointmentDetail?.id)}
                      className='d-flex justify-content-center align-items-center popover-btn'
                    >
                      <span class='material-icons-outlined'>mode_edit_outline</span> Edit
                    </div>
                    {userData?.role != 'ee' ? (
                      <div
                        onClick={() => {
                          setCreatedAppointment(appointmentDetail);
                          setCancelModal(true);
                        }}
                        className='d-flex justify-content-center align-items-center popover-btn'
                      >
                        <span class='material-icons-outlined'>close</span> Cancel
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </div>
            {appointmentDetail?.consult_type == 'virtual' ? (
              <div className='d-flex justify-content-between schedule-part'>
                <div className='d-flex flex-column justify-content-start'>
                  <span>
                    <img src={laptopLink} /> Online Consultation
                  </span>
                </div>
                {appointmentDetail?.consultation_status != 'canceled' &&
                appointmentDetail?.consult_type == 'virtual' ? (
                  <a
                    target='_blank'
                    href={appointmentDetail?.meeting_link}
                    className='d-flex flex-column align-items-center'
                  >
                    <div className='d-flex justify-content-center align-items-center flex-column'>
                      <div className='d-flex justify-content-center align-items-center popover-btn disabled'>Join</div>
                    </div>
                  </a>
                ) : null}
              </div>
            ) : null}

            <div className='d-flex justify-content-between  schedule-part border-0'>
              <div className='d-flex flex-column justify-content-start'>
                <span>
                  <img src={paymentLink} /> Payment
                </span>
                <p>{titlizeString(appointmentDetail?.payment_type)}</p>
                {appointmentDetail?.payment_type == 'partially_paid' ? (
                  <p>
                    Amount paid: ₹{appointmentDetail?.amount} (left ₹
                    {parseFloat(appointmentDetail?.appointment_fee - appointmentDetail?.amount).toFixed(2)})
                  </p>
                ) : null}

                {appointmentDetail?.payment_with?.name || appointmentDetail?.payment_with?.full_name ? (
                  <p>With: {appointmentDetail?.payment_with?.name || appointmentDetail?.payment_with?.full_name}</p>
                ) : null}
              </div>
              <div className='d-flex flex-column align-items-center'>
                <div className='d-flex justify-content-center align-items-center flex-column'>
                  {userData?.role == 'ee' &&
                  appointmentDetail?.patient_attendance_status != 'no_show' &&
                  appointmentDetail?.patient_attendance_status != 'show' ? (
                    <div
                      className='d-flex justify-content-center align-items-center popover-btn active'
                      onClick={() => {
                        setCreatedAppointment(appointmentDetail);
                        setOpen(true);
                      }}
                    >
                      Record
                    </div>
                  ) : userData?.role != 'ee' && appointmentDetail?.patient_attendance_status != 'no_show' ? (
                    <div
                      className='d-flex justify-content-center align-items-center popover-btn active'
                      onClick={() => {
                        setCreatedAppointment(appointmentDetail);
                        setOpen(true);
                      }}
                    >
                      Record
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popover>
    </div>
  );
  // const appointmentPopover = (
  //   <div style={{ height: 100, position: 'absolute' }}>
  //     <Popover visible>
  //       <div className='d-flex w-100 flex-column appointmentPopover schedulButton'>
  //         <div className='reusableBtnActive  reusableBtn text-nowrap  w-100 mb-2'>+ Add New Appointment</div>
  //         <div className='w-100 borderBtn mb-2 text-nowrap'>Mark As Unavailable</div>
  //       </div>
  //     </Popover>
  //   </div>
  // );

  // const actions = [
  //   { first: paymentLink, second: payment },
  //   { first: laptopLink, second: laptop },
  //   { first: user, second: userLink },
  // ];

  // const HoverImage = ({ first, second }) => {
  //   const [firstImage, setFirstImage] = useState(first);
  //   return (
  //     <div className='scheduleIcon'>
  //       <img src={firstImage} onMouseEnter={() => setFirstImage(second)} onMouseLeave={() => setFirstImage(first)} />

  //     </div>
  //   );
  // };

  return (
    <>
      <div className={`scheduleTile ${className}`}>
        <Whisper
          placement='auto'
          trigger='hover'
          speaker={schedulPopover}
          controlId='control-id-hover-enterable'
          enterable
        >
          <span className='tileName'>
            {appointmentDetail?.user?.full_name} (
            {appointmentDetail?.user?.gender && appointmentDetail?.user?.gender[0].toUpperCase()},{' '}
            {appointmentDetail?.user?.age})
          </span>
        </Whisper>
        {height > 32 && <p>{appointmentDetail?.user?.phone}</p>}
        {height > 60 && (
          <div className='d-flex justify-content-between'>
            {renderStatus()}
            {/* <div className='d-flex'>
              {actions.map((action, index) => (
                <HoverImage key={index} first={action.first} second={action.second} />
              ))}
            </div> */}
            {/* <div className='d-flex'>
            <div className={`scheduleIcon me-1 ${appointmentDetail.amount!=='null'?'active':''}`}>
                {!appointmentDetail.amount!=='null'?<img src={payment}/>:
                <img src={paymentLink}/>}
              </div>
              <div className={`scheduleIcon me-1 ${appointmentDetail.consult_type=='physical'?'active':''}`}>
                {!appointmentDetail.consult_type=='virtual'?<img src={laptop}/>:
                <img src={laptopLink}/>}
              </div>
              <div className={`scheduleIcon ${appointmentDetail.consult_type=='virtual'?'active':''}`}>
                {!appointmentDetail.consult_type=='virtual'?<img src={user}/>:
                <img src={userLink}/>}
              </div>
            </div> */}
          </div>
        )}
      </div>
    </>
  );
}

export default DoctorSchedulTile;
