import React from 'react';

function StatusTag(props) {
  const { icon, title } = props;

  return (
    <div>
      <div class='mt-2 adherenceGray capitalize'>
        <img src={icon} />
        {title}
      </div>
    </div>
  );
}

export default StatusTag;
