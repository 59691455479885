import { AdminPanelHeader, SideBar } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { AddGoalForm } from 'whealth-core-web/forms';
import useApiManager from 'networking/ApiManager';
import { Loader, CommonAlert } from './index';
import { profileImage } from './Helper';

function AddGoal() {
  const ApiClient = useApiManager();
  const location = useLocation();
  const { id, goalid } = useParams();
  const navigate = useNavigate();

  const [valueObj, setValueObj] = useState({});
  const [error, seterror] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState('');
  const [alertType, setAlertType] = useState('alert-success');

  useEffect(() => {
    if (location?.state?.item) {
      setValueObj(location?.state?.item);
    }
  }, [location]);
  // const sideBar = useMemo(() => {
  //   return <SideBar />;
  // }, []);

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails?.gender, userDetails?.image),
      title: userDetails?.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (goalid ? 'Update' : 'Assign') + ' Goal', link: '' },
  ];

  const createGoals = () => {
    valueObj.user_id = id;
    seterror('');
    setIsLoading(true);
    if (valueObj?.day_parts?.length) {
      valueObj.day_parts = valueObj.day_parts.map((item6) => {
        delete item6.id;
        return item6;
      });
    }
    ApiClient.createPatientGoal(id, valueObj)
      .then((res) => {
        console.log(res);
        setValueObj({});
        setIsLoading(false);
        setTimeout(() => {
          navigate(`/patient/${id}/dashboard`);
        }, 1000);
        setAlertType('alert-success');
        setIsShowAlert('Goal created successfully');
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        seterror(err.response.data.errors);
        setIsShowAlert(err.response.data.detail || err.response.data.errors.non_field_errors);
      });
  };

  const updateGoalsData = (patientGoalId) => {
    valueObj.user_id = id;
    setIsLoading(true);
    ApiClient.updatePatientGOAL(id, valueObj, goalid)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Goal updated successfully');
        setTimeout(() => {
          navigate(`/patient/${id}/dashboard`);
        }, 1000);
        console.log(res);
      })
      .catch((err) => {
        setIsLoading(false);
        setAlertType('alert-danger');
        seterror(err.response.data.errors);
        setIsShowAlert(err.response.data.detail || err.response.data.errors.non_field_errors);
      });
  };

  const showPatient = () => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>

        <div className='addnewform-part'>{(goalid ? 'Update' : 'Assign') + ' Goal'}</div>
        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        <div className='teamBody'>
          {isShowAlert && (
            <div className='mt-3'>
              <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
            </div>
          )}
        </div>
        {
          <AddGoalForm
            valueObj={valueObj}
            goalid={goalid}
            setValueObj={setValueObj}
            error={error}
            onClick={goalid || valueObj.is_assigned ? updateGoalsData : createGoals}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        }
      </div>
    </>
  );

  return (
    <>
      <div className='wrapper'>
        {/* {sideBar} */}
        {showPatient()}
        <Loader show={isLoading} />
      </div>
    </>
  );
}

export default AddGoal;
