import { AdminPanelHeader } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CommonAlert, CoreInputBox, Loader, SegmentDefinitionRule } from '..';
import { useCreateSegmentDataMutation, useGetSignleSegmentDataMutation } from 'redux/segmentApis';
import { useUpdateSegmentDataMutation } from 'redux/segmentApis';
import { handleResult } from '../Helper';
import SearchBox from '../common/SearchBox';
import { useSelector } from 'react-redux';

function SegmentDefinitionForm() {
  const navigate = useNavigate();
  let { id } = useParams();
  const { state } = useLocation();
  const [title, setTitle] = useState('');
  const [segmentParts, setSegmentParts] = useState([]);
  const [alertTYpe, setAlertType] = useState('');
  const [showAlert, setShowAlert] = useState('');
  const [errors, serErrors] = useState({});
  const [createSegment, createResults] = useCreateSegmentDataMutation();
  const [updateData, updateResults] = useUpdateSegmentDataMutation();
  const [segmentDetails, segmentDetailsResult] = useGetSignleSegmentDataMutation();
  const [helperIndex, setHelperIndex] = useState('');
  const { updatePatientDetails } = useSelector((state) => state.sliceReducer);
  if (segmentParts?.length === 0) {
    setSegmentParts([
      {
        joiner: 'and',
        segment_attributes: [{}],
      },
    ]);
  }

  useEffect(() => {
    handleResult(
      createResults,
      'Segment created successfully',
      setShowAlert,
      setAlertType,
      serErrors,
      helperIndex,
      () => navigate('/segments', { state: { currentPage: state?.currentPage || 1 } }),
      'error'
    );
  }, [createResults]);

  useEffect(() => {
    handleResult(
      updateResults,
      'Segment Updated successfully',
      setShowAlert,
      setAlertType,
      serErrors,
      helperIndex,
      () => navigate('/segments', { state: { currentPage: state?.currentPage || 1 } }),
      'error'
    );
  }, [updateResults]);

  useEffect(() => {
    if (id) {
      segmentDetails(id);
    }
  }, [id, updatePatientDetails]);

  useEffect(() => {
    if (segmentDetailsResult?.isSuccess) {
      setTitle(segmentDetailsResult.data.data.title);
      setSegmentParts(segmentDetailsResult.data.data.segment_part);
    }
  }, [segmentDetailsResult]);

  const Breadcrumbs = [
    {
      iconClass: 'segment',
      title: 'Segments',
      link: '/segments',
    },
    {
      iconClass: 'edit',
      title: id ? 'Update Segment' : 'New Segment',
    },
  ];

  const renderSearchBox = useMemo(() => {
    return (
      <SearchBox
        placeHolder='Search segments by title'
        searchMethod='SEGMENTDEFINITIONSEARCH'
        label='title'
        searchId='segment'
        renderPath='/segments/{source_id}/edit'
      />
    );
  }, []);

  const renderHeader = useMemo(() => {
    return <AdminPanelHeader showTree={Breadcrumbs} iconTitle='segment' title='Segment' searchBox={renderSearchBox} />;
  }, []);

  const saveSegmentDefinition = () => {
    serErrors({});
    if (id) {
      updateData({ id: id, data: { title: title, segment_part: segmentParts } });
    } else {
      createSegment({ title: title, segment_part: segmentParts });
    }
  };

  const addSegmentPart = () => {
    var newsps = segmentParts.slice();
    newsps.push({
      joiner: 'and',
      segment_attributes: [{}],
    });
    setSegmentParts(newsps);
  };

  const removeSegmentPart = (indexToRemove) => {
    segmentParts.splice(indexToRemove, 1);
    serErrors({});
    setSegmentParts([...segmentParts]);
  };

  const removeSegmentRule = (index, parentIndex) => {
    segmentParts[parentIndex].segment_attributes.splice(index, 1);
    serErrors({});
    setSegmentParts([...segmentParts]);
  };

  const updateSegmentPart = (indexToUpdate, data) => {
    var newsps = segmentParts.slice();
    newsps = newsps.map((item, index) => {
      return index === indexToUpdate ? data : item;
    });
    serErrors({});
    setSegmentParts(newsps);
  };

  const renderRules = () => {
    return segmentParts.map((item, index) => {
      return (
        <SegmentDefinitionRule
          key={index}
          errors={errors?.segment_part}
          serErrors={serErrors}
          ruleIndex={index}
          joiner={item.joiner}
          segmentPartId={item.id}
          segmentAttributes={item.segment_attributes}
          removeSegmentPart={removeSegmentPart}
          updateSegmentPart={updateSegmentPart}
          showJoiner={segmentParts.length !== index + 1}
          removeSegmentRule={removeSegmentRule}
        />
      );
    });
  };

  const renderTitle = () => {
    return (
      <div className='m-3 mt-4'>
        <CoreInputBox
          disabled={id}
          retuired={true}
          label='Title'
          showMSG={errors?.title}
          placeholder='Title'
          setValue={setTitle}
          value={title}
        />
      </div>
    );
  };

  const renderSaveButton = () => {
    return (
      <div className='d-flex mt-4 '>
        <div
          className='form-control coreBtn text-white mt-0 add-attribute-btn'
          onClick={() => {
            saveSegmentDefinition();
          }}
        >
          Save
        </div>
      </div>
    );
  };

  const renderAddNewRuleLink = () => {
    return (
      <div className='d-flex mt-4 '>
        <div onClick={() => addSegmentPart()} className='coreBtn text-white undefined px-4 ms-3 mt-0 add-attribute-btn'>
          + Add Rule
        </div>
        {/* Rule Button */}
      </div>
    );
  };

  return (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>{renderHeader}</div>
        <div className='addnewform-part'> Segment </div>
        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        <div className='mt-4'>
          <CommonAlert isShowAlert={showAlert} setIsShowAlert={setShowAlert} alertType={alertTYpe} />
          {renderTitle()}
          {segmentParts && renderRules()}
          {renderAddNewRuleLink()}
          {renderSaveButton()}
        </div>
        <Loader show={createResults.isLoading || updateResults.isLoading} />
      </div>
    </>
  );
}

export default SegmentDefinitionForm;
