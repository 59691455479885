import React from 'react';
import { Popover, Whisper } from 'rsuite';
import { profileImage } from '../Helper';

function DoctorAppointment(props) {
  const { className, data, handleAddAppointment, workingSlots, activeDate } = props;
  const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  // const appointmentListPopover = (
  //   <div style={{ height: 100, position: 'absolute' }}>
  //     <Popover visible className='schedulePopover'>
  //       <div className='popoverPart'>
  //         <div className='schedulePopover-head'>
  //           <div className='tileName'>Lorem Ipsum (M, 42)</div>
  //         </div>
  //         <div className='schedulePopover-body'>
  //           <div className='d-flex justify-content-between schedule-part'>
  //             <div className='scheduletime'>
  //               <strong>Clinic Lorem Ipsum</strong>
  //               <p className='m-0'>Mon, Wed (10:00 am -12:30 pm)</p>
  //               <p className='m-0'>Tue, Thu-Sat (10:00 am -05:30 pm)</p>
  //             </div>
  //           </div>
  //           <div className='d-flex justify-content-between schedule-part'>
  //             <div className='scheduletime'>
  //               <strong>Clinic Lorem Ipsum</strong>
  //               <p className='m-0'>Mon, Wed (10:00 am -12:30 pm)</p>
  //               <p className='m-0'>Tue, Thu-Sat (10:00 am -05:30 pm)</p>
  //             </div>
  //           </div>
  //           <div className='d-flex justify-content-between schedule-part'>
  //             <div className='scheduletime'>
  //               <strong>Clinic Lorem Ipsum</strong>
  //               <p className='m-0'>Mon, Wed (10:00 am -12:30 pm)</p>
  //               <p className='m-0'>Tue, Thu-Sat (10:00 am -05:30 pm)</p>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </Popover>
  //   </div>
  // );
  return (
    // <Whisper
    //   placement='auto'
    //   trigger='hover'
    //   speaker={appointmentListPopover}
    //   controlId='control-id-hover-enterable'
    //   enterable
    // >
    //   <div className={`doctorAppointment ${className}`}>
    //     <div className='doctorname mb-1'>{data?.full_name} </div>
    //     <span class='material-icons-outlined'>add_circle_outline</span>
    //     <div className='scheduletime mb-2'>
    //       <strong>Mon- Fri </strong>
    //       <p>10:00 am- 07:00 pm</p>
    //     </div>
    //     <div className='scheduletime '>
    //       <strong>Mon- Fri </strong>
    //       <p>10:00 am- 07:00 pm</p>
    //     </div>
    //   </div>
    // </Whisper>
    <div className={`doctorAppointment ${className}`}>
      <div class='d-flex  align-items-center'>
        <div className='doctor-pic me-2'>
          <img src={profileImage()} className='userImage' href='#' />
        </div>
        <div className='doctorname mb-1'>{data?.full_name} </div>
        <div onClick={() => handleAddAppointment(data?.id, data)}>
          <span class='material-icons-outlined'>add_circle_outline</span>
        </div>
      </div>
      {/* {workingSlots?.map((item) => {
        return (
          <div className='scheduletime mb-2'>
            <strong>{weekDays[new Date(activeDate).getDay()]} </strong>
            <p>
              {item.start_time} - {item.end_time}
            </p>
          </div>
        );
      })} */}
    </div>
  );
}

export default DoctorAppointment;
