import React from 'react';
import { useNavigate } from 'react-router-dom';
import { eyeView } from 'res/images';
import './articleDesign.scss';

function ArticleDesign(props) {
  const { data } = props;
  const navigate = useNavigate();

  const getDate = (date) => {
    var str = date;
    var date = new Date(str);
    var day = date.getDate();
    var month = date.toLocaleString('default', { month: 'short' });
    var year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };

  const postImageView = (item) => {
    return (
      <div className="postDetails">
        {item.image_url ? (
          <img className="articlePostImg" src={item.image_url} />
        ) : (
          <div className="articlePostImg" style={{ border: '1px solid #c0c0c0' }} />
        )}
        <div>
          <div
            className="postHeading"
            onClick={() => {
              navigate(`/articles/${item.id}/edit`);
            }}
          >
            {item.title.substring(0, 30)}
            {item?.title.length > 30 && <span>...</span>}
          </div>
          <div className="postbody">
            {item?.content?.replaceAll(/<\/?[^>]+(>|$)/g, '')?.substring(0, 60)}{' '}
            {item?.content?.replaceAll(/<\/?[^>]+(>|$)/g, '')?.length > 60 && <span>...</span>}
          </div>
        </div>
      </div>
    );
  };

  const postAutherDetails = (item) => {
    return (
      <div className="aboutPost">
        <div className={`badge capitalize ${item.status}`}>
          {item.status}
          {/* <CoreButton customClass={`categoryBtn ${item.status}`} title={item.status} /> */}
        </div>
        <div>By {item.author_name} </div>
        <div>
          {item.published_at ? 'Published on' : 'Created at'} · {getDate(item.published_at || item.created_at)}{' '}
        </div>
      </div>
    );
  };

  const postDetails = (item) => {
    return (
      <div className="aboutPost">
        {item?.category?.title && (
          <div className="badge capitalize lightGrayBadge">
            {item?.category?.title}
            {/* <CoreButton customClass="categoryBtn" title={item?.category?.title} /> */}
          </div>
        )}
        <div>Last Edit · {getDate(item.last_edited)} </div>
      </div>
    );
  };

  const showListData = () => {
    return data.map((item, index) => {
      return (
        <tr
          onClick={() => {
            item.status == 'unpublished' && navigate(`/articles/${item.id}/edit`);
          }}
          key={index}
          className={`imageCard ${item.status == 'unpublished' && 'usersDivDisable'}`}
        >
          <td>{postImageView(item)}</td>
          <td>{postAutherDetails(item)}</td>
          <td className="flextd">{postDetails(item)}</td>
          {/* {item.status == 'unpublished' && <td className="usersDivDisable" />} */}
        </tr>
      );
    });
  };

  return (
    <div className="showArticleList">
      <div className="table-responsive">
        <table className="w-100">
          <tbody>{showListData()}</tbody>
        </table>
      </div>
    </div>
  );
}

export default ArticleDesign;
