import { configureStore } from "@reduxjs/toolkit";
import sliceReducer from "./Slices";
import { initialState } from "./Slices";
import { setupListeners } from "@reduxjs/toolkit/query";
import { apiSlice } from "./apiSlice";
import { dashboardSlice } from "./dashboardApis";
import { cockpitSlice } from "./cockpitSlice";
import { segmentApis } from "./segmentApis";

export const store = configureStore({
  preloadedState: initialState,
  reducer: {
    sliceReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [dashboardSlice.reducerPath]: dashboardSlice.reducer,
    [cockpitSlice.reducerPath]: cockpitSlice.reducer,
    [segmentApis.reducerPath]: segmentApis.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(apiSlice.middleware)
      .concat(dashboardSlice.middleware)
      .concat(cockpitSlice.middleware)
      .concat(segmentApis.middleware),
});

setupListeners(store.dispatch);
