import React from "react";
import { editTheme, trashRed } from "res/images";
import { ReadMoreReadLess } from "whealth-core-web/components";
import { getDateTime } from "whealth-core-web/components/Helper";
import CommonToolTip from "whealth-core-web/components/CommonToolTip";

function ShowTaskList(props) {
  const { data, deleteData, setUpdateData } = props;

  const showData = () => {
    return (
      <div className="profileQuestion">
        {data &&
          data.map((item, index) => {
            const isActive = item.status == "completed" ? "badge active" : "";

            return (
              <>
                <div key={index} className="d-flex justify-content-between boxContent">
                  <div className="questionDetail">
                    <div className="capitalize">
                      <ReadMoreReadLess limit={150} data={item.content} />
                      <div className="capitalize font-12px light-black">{item.assigned_to.full_name}</div>
                      <span className={`me-2 badge ${item.status}`}>{item.status}</span>
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="userDetails">
                      <div className="metaDetail">Created by · {item?.admin.full_name}</div>
                      <div className="metaDetail">
                        Created <span className="lowercase"> at </span>· {getDateTime(item?.created_at)}
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        setUpdateData(item);
                      }}
                    >
                      {item.status !== "completed" && (
                        <CommonToolTip placement={"top"} item={<img src={editTheme} />} showValue={"Edit"} />
                      )}
                    </div>
                    <div
                      onClick={() => {
                        const windowConfirm = window.confirm("Are you sure you want to delete ?");
                        if (windowConfirm) {
                          deleteData && deleteData(item.id);
                        }
                      }}
                    >
                      {item.status !== "completed" && (
                        <CommonToolTip placement={"top"} item={<img src={trashRed} />} showValue={"Delete"} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="hrLine" />
              </>
            );
          })}
      </div>
    );
  };

  return <div>{showData()} </div>;
}

export default ShowTaskList;
