import useApiManager from 'networking/ApiManager';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { search } from 'res/images';
import { SettingsForm } from 'whealth-core-web/forms';
import CommonBorderBox from './CommonBorderBox';
import CoreInputBox from './CoreInputBox';
import EmptyView from './EmptyView';
import ListView from './ListView';
import Pagination from './Pagination';

function ArticleTagsView(props) {
  const { setIsLoading, isLoading, setIsShowAlert, setAlertType } = props;
  const ApiClient = useApiManager();
  const [articlesTags, setArticlesTags] = useState([]);
  const [tag, setTag] = useState('');
  const [error, seterror] = useState('');
  const [editError, setEditError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchInput, setSearchInput] = useState('');

  let timeOut;

  useEffect(() => {
    if (!searchInput || searchInput?.trim()?.length == 0) {
      articleTagsGet();
    }
  }, [currentPage, searchInput?.trim()?.length == 0]);

  useEffect(() => {
    timeOut = setTimeout(() => {
      if (searchInput?.trim()?.length > 2) {
        searchTags();
      }
    }, 300);
  }, [searchInput]);

  const articleTagsGet = () => {
    setIsLoading(true);
    ApiClient.articleTagsGet(currentPage)
      .then((res) => {
        setTotalPages(res.headers['total-pages']);
        setIsLoading(false);
        setArticlesTags(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const searchTags = () => {
    setIsLoading(true);
    ApiClient.searchTags(searchInput)
      .then((res) => {
        setIsLoading(false);
        setTotalPages(false);
        console.log(res);
        setArticlesTags(res.data);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const articleTagsDelete = (id) => {
    seterror('');
    setIsLoading(true);
    ApiClient.articleTagsDelete(id)
      .then((res) => {
        setIsLoading(false);
        articleTagsGet();
        console.log(res);
        setIsShowAlert(res.data.message);
        setAlertType('alert-success');
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status == 500) {
          setIsShowAlert('Internal server error');
        } else {
          setIsShowAlert(err.response.data.detail || err.response.data.message);
        }
        setAlertType('alert-danger');
        console.log(err);
      });
  };

  const articleTagsCreate = () => {
    seterror('');
    if (tag.trim().length > 0) {
      setIsLoading(true);
      const data = {
        title: tag,
      };

      ApiClient.createArticleTags(data)
        .then((res) => {
          setCurrentPage(1);
          setTag('');
          setIsLoading(false);
          articleTagsGet();
          setIsShowAlert('Tag created successfully');
          setAlertType('alert-success');
        })
        .catch((err) => {
          setIsLoading(false);
          setAlertType('alert-danger');
          setIsShowAlert(err.response.data.detail || err.response.data.errors.title);
        });
    } else {
      seterror('This field is required.');
    }
  };

  const updateTags = (val, id) => {
    setIsLoading(true);
    const data = {
      title: val,
    };
    ApiClient.updateTags(data, id)
      .then((res) => {
        setTag('');
        articleTagsGet();
        setIsShowAlert('Tag updated successfully');
        setAlertType('alert-success');
        console.log(res);
        setIsLoading(false);
        seterror(false);
      })
      .catch((err) => {
        setIsLoading(false);
        articleTagsGet();
        setIsShowAlert(err.response.data.detail || err.response.data.errors.title);
        setAlertType('alert-danger');
      });
  };

  const updateData = (data, value) => {
    updateTags(value, data.id);
  };

  const handleChange = async (text) => {
    clearTimeout(timeOut);
    setSearchInput(text);
  };

  const renderSearchInput = () => {
    return (
      <div className='mb-3'>
        <CoreInputBox placeholder='Search Article Tags' value={searchInput} setValue={handleChange} leftIcon={search} />
      </div>
    );
  };

  return (
    <div>
      {renderSearchInput()}
      <CommonBorderBox className='settingFormOutline'>
        <div className='formTitle'>Add Article Tags</div>
        <SettingsForm
          error={error}
          value={tag}
          setValue={setTag}
          label='Tag Name'
          required
          onClick={articleTagsCreate}
        />
      </CommonBorderBox>

      {articlesTags?.length > 0 ? (
        <ListView
          setEditError={setEditError}
          editError={editError}
          data={articlesTags}
          updateData={updateData}
          deleteData={articleTagsDelete}
        />
      ) : (
        <EmptyView hide={isLoading} />
      )}

      <div className='d-flex justify-content-end mt-3'>
        <Pagination
          seterror={seterror}
          totalPages={totalPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default ArticleTagsView;
