import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import endPoints from 'networking/endPoints';
import auth_axios from 'networking/AuthAxios';

const axiosBaseQuery =
  ({ baseUrl } = { baseUrl: '' }) =>
  async ({ url, method, body, params }) => {
    try {
      const result = await auth_axios({ url: baseUrl + url, method, data: body, params });
      return { data: result };
    } catch (err) {
      return { data: err.response };
    }
  };

export const apiSlice = createApi({
  reducerPath: 'apiData',
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.REACT_APP_API_ENDPOINT,
    prepareHeaders: (headers) => {
      headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);
      return headers;
    },
  }),
  tagTypes: ['AdminUser,getTeamAdminProfile, updaTeteamList'],

  endpoints: (builder) => ({
    // team apis
    getLoginUserData: builder.query({
      query: () => {
        return {
          url: endPoints.USERPROFILE,
          method: 'get',
        };
      },
      providesTags: ['AdminUser'],
    }),

    getTeamUserProfile: builder.query({
      query: (id) => {
        if (id)
          return {
            url: endPoints.ADMINPROFILE(id),
            method: 'get',
          };
      },
      providesTags: ['getTeamAdminProfile'],
    }),

    listUserTeamProfile: builder.query({
      query: (params) => {
        return {
          url: endPoints.ADMINPROFILE(),
          method: 'get',
          params: params.params,
        };
      },
      providesTags: ['AdminUser', 'updaTeteamList'],
    }),

    getAdminProfilesCount: builder.query({
      query: (params) => {
        return { url: endPoints.ADMINPROFILECOUNT, method: 'get', params: params.params };
      },
      providesTags: ['AdminUser', 'updaTeteamList'],
    }),

    updateUserTeamProfile: builder.mutation({
      query: (params) => {
        return {
          url: endPoints.ADMINPROFILE(params.id),
          method: 'patch',
          body: params.data,
        };
      },

      invalidatesTags: (result, error, arg) => {
        let isAdmin = result && arg?.self ? 'AdminUser' : '';
        let isUser = result?.statusText == 'OK' ? 'getTeamAdminProfile' : '';
        let list = ['updaTeteamList', isAdmin, isUser];
        return list;
      },
    }),

    updateWorkingHour: builder.mutation({
      query: (params) => {
        return {
          url: endPoints.UPDATEWORKINGHOUR(params.id),
          method: 'patch',
          body: params.data,
        };
      },

      invalidatesTags: (result, error, arg) => {
        let isAdmin = result && arg?.self ? 'AdminUser' : '';
        let isUser = result?.statusText == 'OK' ? 'getTeamAdminProfile' : '';
        let list = ['updaTeteamList', isAdmin, isUser];
        return list;
      },
    }),

    createUserTeamProfile: builder.mutation({
      query: (params) => {
        return {
          url: endPoints.ADMINPROFILE(),
          method: 'post',
          body: params,
        };
      },
      invalidatesTags: ['updaTeteamList'],
    }),

    // team APis

    // ____________________________________________________________________

    // userStatus
    allUserStatus: builder.query({
      query: (params) => {
        return {
          url: endPoints.STATUS,
          method: 'get',
          params: params,
        };
      },
    }),
    // userStatus
  }),
});

export const {
  useGetLoginUserDataQuery,
  useListUserTeamProfileQuery,
  useGetTeamUserProfileQuery,
  useGetAdminProfilesCountQuery,
  useUpdateUserTeamProfileMutation,
  useCreateUserTeamProfileMutation,
  useAllUserStatusQuery,
  useUpdateWorkingHourMutation,
} = apiSlice;
