import React, { useEffect, useState } from "react";
import CoreCheckBox from "../CoreCheckBox";
import { showMedicineTimeString } from "../Helper";

function RenderCheckBoxes({ data }) {
  const { value, targetValue, returnValue, setreturnValue, enable, disabled } = data;
  const [arrvalues, setArrvalues] = useState([0, 0, 0]);

  let arrActivity = value?.split("").map((item) => parseInt(item)) || [];
  let arrData = targetValue?.split("").map((item) => parseInt(item)) || [0, 0, 0];

  console.log(returnValue);

  const dayConst = ["Morning", "Afternoon", "Night"];

  useEffect(() => {
    setArrvalues([0, 0, 0]);
  }, [value, targetValue]);

  const handleChecked = (index) => {
    if (arrActivity.length && arrActivity[index]) {
      arrvalues[index] = 1;
      return true;
    } else if (arrvalues[index]) {
      return true;
    }
    return false;
  };

  const handleCheckBoxClick = (val, isChecked, index, arrvalues) => {
    let values = arrvalues;
    if (val && isChecked) {
      values[val] = 1;
    } else if (val && !isChecked) {
      values[val] = 0;
    }
    if (enable) {
      values[val] = values[val];
    }
    setArrvalues(values);
    returnValue.value = values.join("");
    setreturnValue({ ...returnValue });
  };

  const renderCheckbox = () => {
    return (
      <div className="d-flex">
        {arrData?.map((item, index) => {
          console.log(item);
          if (targetValue && !item) {
            return;
          }
          return (
            <div key={index}>
              <CoreCheckBox
                onChange={(val, checked) => handleCheckBoxClick(val, checked, index, arrvalues)}
                value={index}
                className="me-4"
                title={dayConst[index]}
                id={`patient-activity${index + 1}`}
                htmlFor={`patient-activity${index + 1}`}
                disabled={(!enable && arrActivity[index]) || disabled}
                checked={handleChecked(index)}
              />
            </div>
          );
        })}
      </div>
    );
  };

  return <div>{renderCheckbox()}</div>;
}

export default RenderCheckBoxes;
