import useApiManager from 'networking/ApiManager';
import React, { useEffect, useRef, useState } from 'react';
import {
  CoreButton,
  CoreCheckBox,
  CoreDropDown,
  CoreInputBox,
  CoreSearchDropDown,
  CoreTextArea,
  Select2DropDown,
} from 'whealth-core-web/components';
import { formulationData } from 'whealth-core-web/components/Helper';

function MedicineForm(props) {
  const { valueObj, error, isUpdate, setValueObj, onClick } = props;
  const ApiClient = useApiManager();

  const [searchArr, setSearchArr] = useState([]);
  const [medicineCategoriesData, setMedicineCategoriesData] = useState([]);

  useEffect(() => {
    medicineCategoriesGet();
  }, []);

  const medicineCategoriesGet = () => {
    ApiClient.medicineCategoriesGet()
      .then((res) => {
        setMedicineCategoriesData(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleItemClick = (item) => {
    valueObj.medicine_category_title = item.title;
    setValueObj({ ...valueObj });
    setSearchArr([]);
  };
  const searchMedicinesCategory = (inputValue) => {
    ApiClient.searchMedicinesByCategories(inputValue)
      .then((res) => {
        setSearchArr(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const typeArr = [
    { title: 'Published', id: 'published' },
    { title: 'Unpublished', id: 'unpublished' },
    { title: 'Draft', id: 'draft' },
  ];

  const typeArr2 = [
    { title: 'Primary', id: 'primary' },
    { title: 'Secondary', id: 'secondary' },
  ];

  const typeArr3 = [
    { title: 'Before Meal', id: 'before_meal' },
    { title: 'With Meal', id: 'with_meal' },
    { title: 'After Meal', id: 'after_meal' },
  ];

  const mealTimeDuration = [
    { title: 'Hour', id: 'hour' },
    { title: 'Minute', id: 'minute' },
  ];

  const typeArray = [
    { title: 'Daily', id: 'daily' },
    { title: 'Weekly', id: 'weekly' },
    { title: 'Monthly', id: 'monthly' },
    { title: 'Yearly', id: 'yearly' },
  ];

  const filterFrequencyOptions = (duration) => {
    if (duration < 7) {
      return typeArray.filter((item) => item.id === 'daily');
    } else if (duration < 28) {
      return typeArray.filter((item) => item.id === 'daily' || item.id === 'weekly');
    } else if (duration < 365) {
      return typeArray.filter((item) => item.id === 'daily' || item.id === 'weekly' || item.id === 'monthly');
    } else {
      return typeArray.filter(
        (item) => item.id === 'daily' || item.id === 'weekly' || item.id === 'monthly' || item.id === 'yearly'
      );
    }
  };

  const handleCheckBoxClick = (val, isChecked, arrvalues) => {
    let values = arrvalues;
    if (val && isChecked) {
      values[val] = '1';
    } else if (val && !isChecked) {
      values[val] = 0;
    }

    let arrToString = values.join('');
    valueObj.dosage = arrToString;
    setValueObj({ ...valueObj });
  };

  const handleAsNeededClick = (isChecked) => {
    valueObj.is_optional = isChecked;
    if (isChecked) {
      valueObj.duration = 0;
      valueObj.dosage = '000';
      valueObj.duration = 0;
      valueObj.meal_time = '';
      valueObj.meal_duration_unit = null;
      valueObj.frequency = null;
      valueObj.meal_duration_value = null;
    } else {
      valueObj.duration = 0;
      valueObj.dosage = '000';
    }
    setValueObj({ ...valueObj });
  };

  let arrvalues = valueObj?.dosage?.split('') || [0, 0, 0];

  return (
    <div className='medicineForm'>
      <div className='formTitle'>Add Medicine </div>

      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex w-100 justify-content-between medicineNamerow'>
        <div className='w-100'>
          <CoreInputBox
            showMSG={error?.name}
            placeholder='Medicine Name'
            value={valueObj?.name || ''}
            label='Medicine Name'
            setValue={(inputVal) => {
              setValueObj({ ...valueObj, name: inputVal });
            }}
            maxLength={255}
            msgStyle={{ color: 'red' }}
            retuired
          />
        </div>
        <div className=' w-100'>
          <Select2DropDown
            value={valueObj?.formulation || ''}
            data={formulationData()}
            labelKey='title'
            label={'Medicine Formulation'}
            placeholder={'Medicine Formulation'}
            valueKey='id'
            retuired
            onSelect={(inputVal) => {
              if (inputVal && inputVal != '') {
                setValueObj({ ...valueObj, formulation: inputVal });
              } else {
                setValueObj({ ...valueObj, formulation: null });
              }
            }}
            showMSG={error?.formulation}
          />
        </div>
      </div>
      <div className=' medicineNamerow'>
        <div className='d-flex gap-10 w-100 justify-content-between'>
          <div className='w-100 medicine_dropdown'>
            <CoreSearchDropDown
              hideClose
              {...props}
              isAutoSave
              inputValue={valueObj?.medicine_category_title || ''}
              inputStyle={{ height: '39px' }}
              data={searchArr}
              setData={setSearchArr}
              setInputValue={(inputVal) => {
                searchMedicinesCategory(inputVal);
                setValueObj({ ...valueObj, medicine_category_title: inputVal });
              }}
              showMSG={error?.medicine_category_title}
              onClickItem={handleItemClick}
              placeholder='Category Name'
              value={valueObj?.medicine_category_title || ''}
              label='Category Name'
              maxLength={255}
              dropDownStyle={{ position: 'absolute', right: '0px', left: '0px' }}
              searchKey='title'
              isClearValue
              isRightIconref
            />
          </div>
          <div className=' w-100'>
            <CoreInputBox
              showMSG={error?.info}
              placeholder='Medicine Information'
              value={valueObj?.info || ''}
              label='Medicine Information'
              setValue={(inputVal) => {
                setValueObj({ ...valueObj, info: inputVal });
              }}
              maxLength={255}
              msgStyle={{ color: 'red' }}
            />
          </div>
        </div>
      </div>
      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex gap-10 w-100 justify-content-between'>
        <div className='w-100'>
          <Select2DropDown
            value={valueObj?.medicine_type || ''}
            data={typeArr2}
            showMSG={error?.medicine_type}
            labelKey='title'
            label={'Medicine Type'}
            placeholder={'Medicine Type'}
            valueKey='id'
            onSelect={(inputVal) => {
              setValueObj({ ...valueObj, medicine_type: inputVal });
            }}
          />
        </div>
        <div className=' w-100'>
          <CoreDropDown
            value={valueObj?.status || 'published'}
            data={typeArr}
            showMSG={error?.status}
            labelKey='title'
            label={'Status'}
            placeholder={'Status'}
            valueKey='id'
            setValue={(inputVal) => {
              setValueObj({ ...valueObj, status: inputVal });
            }}
          />
        </div>
      </div>
      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex gap-10 w-100 justify-content-between'>
        <div className='w-100'>
          <Select2DropDown
            value={valueObj?.medicine_intake || ''}
            data={typeArr3}
            showMSG={error?.medicine_intake}
            labelKey='title'
            label={'Medicine Intake'}
            placeholder={'Medicine Intake'}
            valueKey='id'
            onSelect={(inputVal) => {
              setValueObj({ ...valueObj, medicine_intake: inputVal });
            }}
          />
        </div>
        <div className='w-100  d-flex'>
          <div className='w-100 mb-3'>
            <CoreInputBox
              maxLength={3}
              validateZero
              inputStyle={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
              labelStyle={{ fontWeight: 'var(--lightWeight)' }}
              label='Course Duration'
              placeholder='Course Duration '
              value={valueObj?.duration || ''}
              showMSG={error?.duration}
              setValue={(data) => {
                setValueObj({ ...valueObj, duration: data });
              }}
              validateNumbers
            />
          </div>

          <div className='mt-4'>
            <div className='unitBox'>Days</div>
          </div>
        </div>
      </div>
      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex gap-10 w-100 justify-content-between'>
        <div className='w-100 mb-3'>
          <div className='d-flex'>
            <div style={{ color: 'var(--lightBlack)' }}>Dose</div>
          </div>
          <div className='mt-2 d-flex'>
            <CoreCheckBox
              disabled={valueObj?.is_optional}
              onChange={(val, checked) => handleCheckBoxClick(val, checked, arrvalues)}
              value={0}
              className={'me-4'}
              title='Breakfast'
              id={`flexCheckDefault`}
              checked={!!parseInt(arrvalues[0])}
              htmlFor={`flexCheckDefault`}
            />
            <CoreCheckBox
              disabled={valueObj?.is_optional}
              onChange={(val, checked) => handleCheckBoxClick(val, checked, arrvalues)}
              value={1}
              className={'me-4'}
              title='Lunch'
              checked={!!parseInt(arrvalues[1])}
              id={`flexCheckDefault1`}
              htmlFor={`flexCheckDefault1`}
            />

            <CoreCheckBox
              disabled={valueObj?.is_optional}
              onChange={(val, checked) => handleCheckBoxClick(val, checked, arrvalues)}
              value={2}
              title='Dinner'
              className={'me-4'}
              checked={!!parseInt(arrvalues[2])}
              id={`flexCheckDefault2`}
              htmlFor={`flexCheckDefault2`}
            />
            <CoreCheckBox
              onChange={(val, checked) => handleAsNeededClick(checked)}
              disabled={arrvalues[0] == 1 || arrvalues[1] == 1 || arrvalues[2] == 1}
              title='As Needed'
              className={'me-4 w-100'}
              checked={!!valueObj?.is_optional}
              id={`flexCheckDefault3`}
              htmlFor={`flexCheckDefault3`}
            />
          </div>
        </div>
        {!valueObj?.is_optional && valueObj?.medicine_intake !== 'with_meal' && (
          <div className=' w-100'>
            <div className=''>
              <label className='inputlabel  '>{'Wait Time'}</label>
              <div className='d-flex spacifictime-box w-100'>
                <CoreInputBox
                  maxLength={2}
                  disabled={!valueObj?.medicine_intake}
                  inputStyle={{
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  }}
                  value={valueObj?.meal_duration_value || ''}
                  setValue={(data) => {
                    setValueObj({ ...valueObj, meal_duration_value: data });
                  }}
                  data={mealTimeDuration}
                  placeholder='Time'
                />
                <div className='specify-time-unit'>
                  <Select2DropDown
                    searchable={false}
                    valueKey='id'
                    labelKey='title'
                    disabled={!valueObj?.meal_duration_value}
                    value={valueObj?.meal_duration_unit || 'hour'}
                    onSelect={(data) => {
                      setValueObj({ ...valueObj, meal_duration_unit: data });
                    }}
                    data={mealTimeDuration}
                    placeholder='Unit'
                  />
                </div>
              </div>
              <div className='inputMsg ms-3'>{error?.meal_duration_value}</div>
            </div>
          </div>
        )}
      </div>
      <div style={{ margin: '20px 0', gap: '10px' }} className='d-flex gap-10 w-100 justify-content-between'>
        {!valueObj?.is_optional && (
          <div className='w-100'>
            <CoreDropDown
              data={filterFrequencyOptions(valueObj.duration)}
              labelStyle={{
                fontWeight: 'var(--lightWeight)',
              }}
              label='Frequency'
              placeholder='How many times '
              value={valueObj?.frequency || ''}
              showMSG={error?.frequency}
              setValue={(data) => {
                setValueObj({ ...valueObj, frequency: data });
              }}
            />
          </div>
        )}
        <div className='w-100  d-flex'>
          <div className='w-100 '>
            <CoreInputBox
              labelStyle={{ fontWeight: 'var(--lightWeight)' }}
              label='Side Effect and Allergies'
              placeholder='Side effect and Allergies '
              value={valueObj?.side_effect_allergies || ''}
              showMSG={error?.side_effect_allergies}
              setValue={(data) => {
                setValueObj({ ...valueObj, side_effect_allergies: data });
              }}
            />
          </div>
        </div>
      </div>
      <CoreTextArea
        labelStyle={{
          margin: '8px 5px 8px 6px',
          fontWeight: 'var(--lightWeight)',
        }}
        rows={2}
        label='Note'
        placeholder='Add Note Here'
        value={valueObj?.note || ''}
        showMSG={error?.note}
        setValue={(data) => {
          setValueObj({ ...valueObj, note: data });
        }}
      />
      <CoreButton
        onClick={() => {
          onClick();
        }}
        title={isUpdate ? 'Update' : 'Save'}
      />
    </div>
  );
}

export default MedicineForm;
