import React from "react";
import "./coreCheckBox.css";

function CoreCheckBox(props) {
  const { title, checked, className, value, onChange, id, htmlFor, disabled } =
    props;

  const onChangeBox = (evt) => {
    onChange(evt.target.value, evt.target.checked);
  };

  return (
    <div className="form-check">
      <input
        style={{ cursor: "pointer" }}
        onChange={onChangeBox}
        className="form-check-input"
        checked={checked}
        disabled={disabled}
        type="checkbox"
        value={value}
        id={id}
      />
      <label
        style={{ cursor: "pointer" }}
        className={`form-check-label ${className}`}
        htmlFor={htmlFor}
      >
        {title}
      </label>
    </div>
  );
}

export default CoreCheckBox;
