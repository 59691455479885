import { AdminPanelHeader } from 'components';
import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CommonAlert from '../CommonAlert';
import { profileImage } from '../Helper';
import Loader from '../Loader';
import Select2DropDown from '../common/Select2DropDown';
import CoreButton from '../CoreButton';
import { Panel, PanelGroup } from 'rsuite';
import AddQuestionForm from 'whealth-core-web/forms/AddQuestionForm';

function AddQuestions(props) {
  const { id, pid } = useParams();
  const ApiClient = useApiManager();
  const navigation = useNavigate();
  const { onClick } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isShowAlert, setIsShowAlert] = useState(false);
  const [errors, setErrors] = useState({});
  const [alertType, setAlertType] = useState('alert-success');
  const [prescriptionData, setPrescriptionData] = useState('');
  const [templateList, setTemplateList] = useState([]);
  const [templateValue, setTemplateValue] = useState();
  const [isEditable, setIsEditable] = useState(false);
  const [searchTemplateList, setSearchTemplateList] = useState([]);
  const [categoryQuestions, setCategoryQuestions] = useState({});

  useEffect(() => {
    if (pid) {
      getPrescriptionData();
    }
  }, [pid]);

  useEffect(() => {
    getTemplatesData();
  }, []);

  useEffect(() => {
    if (!pid && templateValue) {
      getTemplateQuestion();
    }
  }, [templateValue]);

  useEffect(() => {
    if (prescriptionData && prescriptionData.behaviour_questionnaire_answers) {
      const initialCategoryQuestions = {};

      prescriptionData.behaviour_questionnaire_answers.forEach((question) => {
        const category = question?.category?.title || question?.behaviour_question?.category?.title || 'Miscellaneous';
        if (!initialCategoryQuestions[category]) {
          initialCategoryQuestions[category] = {
            questions: [question],
            errors: [],
          };
        } else {
          initialCategoryQuestions[category].questions.push(question);
        }
      });

      setCategoryQuestions(initialCategoryQuestions);
    }
  }, [prescriptionData]);

  const getTemplateQuestion = () => {
    setIsLoading(true);
    ApiClient.getRegimeTemplate(templateValue)
      .then((res) => {
        let newQuestionData = { behaviour_questionnaire_answers: [] };
        res.data.behaviour_questions.map((item, index) => {
          newQuestionData.behaviour_questionnaire_answers[index] = {
            ...item,
            behaviour_question: { id: item.id, question: item.question },
          };
        });
        setPrescriptionData({
          behaviour_questionnaire_answers: newQuestionData.behaviour_questionnaire_answers,
          can_add_questions: res.data.editable,
        });
        setIsEditable(res.data.editable);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const searchTemplate = (data) => {
    setSearchTemplateList([]);
    if (data?.length) {
      ApiClient.searchTemplateList({ search_str: data })
        .then((res) => {
          setSearchTemplateList(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const getTemplatesData = () => {
    setIsLoading(true);
    ApiClient.getTemplateList()
      .then((res) => {
        setTemplateList(res.data);
        let defaultTemplate = res.data.findIndex((item) => item.default);
        if (defaultTemplate != -1 && !pid) {
          setTemplateValue(res.data[defaultTemplate].id);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getPrescriptionData = () => {
    setIsLoading(true);
    ApiClient.questionData(id, pid)
      .then((res) => {
        setPrescriptionData(res.data);
        setIsEditable(res.data.can_add_questions);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };
  const behaviourQuestionCreate = (data) => {
    let params = modifyParams({ ...data });

    setIsLoading(true);
    ApiClient.behaviorQuestionCreate(params, id)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Regime Report created successfully');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors || err.response.data?.detail);
        if (
          err.response.data?.errors?.non_field_errors ||
          err.response.data?.errors?.details ||
          err.response.data?.detail
        ) {
          setAlertType('alert-danger');
          setIsShowAlert(
            err.response.data?.detail ||
              err.response.data?.errors?.non_field_errors ||
              Object.values(err.response.data.errors[0])
          );
        }
      });
  };

  const modifyParams = (data) => {
    let newData = { ...data };
    data.behaviour_questionnaire_answers.map((item, index) => {
      if (typeof item.behaviour_question == 'object') {
        item.behaviour_question = item.behaviour_question.id;
      }
    });

    return newData;
  };

  const updateBehaviourQuestion = (data) => {
    let params = modifyParams({ ...data });

    setIsLoading(true);
    ApiClient.updateBehaviorPrescription(id, pid, params)
      .then((res) => {
        setIsLoading(false);
        setAlertType('alert-success');
        setIsShowAlert('Regime Report updated successfully ');
        setTimeout(() => {
          navigation(`/patient/${id}/dashboard`);
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        setErrors(err.response.data.errors || err.response.data?.detail);
        if (
          err.response.data?.errors?.non_field_errors ||
          err.response.data?.errors?.details ||
          err.response.data?.detail
        ) {
          setAlertType('alert-danger');
          setIsShowAlert(
            err.response.data?.detail ||
              err.response.data?.errors?.non_field_errors ||
              Object.values(err.response.data.errors[0])
          );
        }
      });
  };

  let userDetails = localStorage.getItem('userDetails');
  userDetails = JSON.parse(userDetails);

  const showTree = [
    { iconClass: 'person_outline', title: 'Patient', link: '/patient' },
    {
      icon: profileImage(userDetails.gender, userDetails.image),
      title: userDetails.name,
      link: `/patient/${id}/dashboard`,
    },
    { title: (pid ? 'Update' : 'New') + ' Report Card', link: '' },
  ];

  const addQuestionToCategory = (category) => {
    const newCategoryQuestions = { ...categoryQuestions };
    if (!newCategoryQuestions[category]) {
      newCategoryQuestions[category] = {
        questions: [{ behaviour_question: '' }],
        errors: [{}],
      };
    } else {
      newCategoryQuestions[category].questions.push({
        behaviour_question: '',
      });
      newCategoryQuestions[category].errors.push({});
    }

    setCategoryQuestions(newCategoryQuestions);
  };

  const removeQuestion = (category, index) => {
    const updatedCategoryQuestions = { ...categoryQuestions };
    const questions = updatedCategoryQuestions[category].questions;
    const errors = updatedCategoryQuestions[category].errors;

    if (questions.length > index) {
      const newQuestions = [...questions];
      newQuestions.splice(index, 1);
      updatedCategoryQuestions[category].questions = newQuestions;
    }

    if (errors.length > index) {
      const newErrors = [...errors];
      newErrors.splice(index, 1);
      updatedCategoryQuestions[category].errors = newErrors;
    }

    setCategoryQuestions(updatedCategoryQuestions);
  };

  const renderQuestionsByCategory = () => {
    let fromIndexError = 0;
    let toIndexError = 0;
    return (
      <PanelGroup accordion defaultActiveKey={0} className='AccordionForm'>
        {Object.keys(categoryQuestions).map((category, index) => {
          fromIndexError = toIndexError;
          toIndexError = toIndexError + categoryQuestions[category].questions.length;
          return (
            <Panel header={category} eventKey={index} id={'panel' + index}>
              <AddQuestionForm
                errors={
                  errors?.behaviour_questionnaire_answers?.length && {
                    behaviour_questionnaire_answers: errors?.behaviour_questionnaire_answers?.slice(
                      fromIndexError,
                      toIndexError
                    ),
                  }
                }
                questionCategoryID={
                  categoryQuestions[category]?.questions[0]?.category?.id ||
                  categoryQuestions[category]?.questions[0]?.behaviour_question?.category?.id ||
                  'misc'
                }
                setErrors={setErrors}
                onAddQuestion={() => addQuestionToCategory(category)}
                onRemoveQuestion={(index) => removeQuestion(category, index)}
                data={{ behaviour_questionnaire_answers: categoryQuestions[category].questions }}
                setIsLoading={setIsLoading}
                isEditable={isEditable}
              />
            </Panel>
          );
        })}
      </PanelGroup>
    );
  };

  const showPatient = () => (
    <>
      <div className='articleHeader stickyHeader'>
        <div className='headerFiles'>
          <AdminPanelHeader iconTitle='person_outline' showTree={showTree} title='Patient' />
        </div>
        <div className='addnewform-part'>{pid ? 'Update' : 'Add New'} Report Card</div>
        <div className='hrLine' />
      </div>
      <div className='px-4 wrapperContent'>
        {isShowAlert && (
          <div className='mt-5'>
            <CommonAlert isShowAlert={isShowAlert} alertType={alertType} setIsShowAlert={setIsShowAlert} />
          </div>
        )}
        {!pid ? (
          <div className='w-100'>
            <Select2DropDown
              className='mb-4'
              label={'Select Template'}
              data={searchTemplateList?.length ? searchTemplateList : templateList}
              isValidation
              onSearch={(searchData) => searchTemplate(searchData)}
              onSelect={(val, objData) => {
                if (objData?.id && templateList.findIndex((item) => item.id == objData.id) == -1) {
                  templateList.push(objData);
                  setTemplateList([...templateList]);
                  setSearchTemplateList([]);
                }
                setTemplateValue(val);
              }}
              value={templateValue}
            />
          </div>
        ) : null}
        {pid || templateValue ? renderQuestionsByCategory() : null}
        {/* <AddPrescriptionForm
        setErrors={setErrors}
        data={prescriptionData}
        errors={errors}
        onClick={pid ? updatePrescription : prescriptionCreate}
      /> */}
      </div>
    </>
  );

  return (
    <div className='wrapper'>
      {/* {sideBar} */}
      {showPatient()}
      <div className='px-4 wrapperContent'>
        <CoreButton
          onClick={() => {
            setErrors({ prescription_medicines: [] });
            // onClick(pid ? updateBehaviourQuestion(prescriptionData) : behaviourQuestionCreate(prescriptionData));
            const newPayload = {
              behaviour_questionnaire_answers: [],
              can_add_questions: isEditable,
            };

            Object.keys(categoryQuestions).forEach((category) => {
              newPayload.behaviour_questionnaire_answers = newPayload.behaviour_questionnaire_answers.concat(
                categoryQuestions[category].questions
              );
            });

            if (pid) {
              updateBehaviourQuestion(newPayload);
            } else {
              newPayload.behaviour_questionnaire_answers = newPayload.behaviour_questionnaire_answers.map((item) => {
                delete item.id;
                return { ...item };
              });
              behaviourQuestionCreate(newPayload);
            }
          }}
          customClass='btn btn-secondary btn-lg'
          title='Save Details'
        />
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

export default AddQuestions;
