import useApiManager from 'networking/ApiManager';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { editTheme } from 'res/images';
import { Dropdown } from 'rsuite';
import CommonToolTip from './CommonToolTip';
import { titlizeString } from './Helper';

function StatusCard(props) {
  const ApiClient = useApiManager();
  const {
    getPatientData,
    originalSelectDate,
    consultationItem,
    handleCreateConsultation,
    consultId,
    updateStatus,
    setUpdateStatus,
    badgeTitle,
    status,
    onboardingStatus,
    defaultStatus,
    defaultClass,
    tagPencil,
    item,
    patientIdConsult,
    selectedDate,
    patientUpdate,
    order,
    dashBoardStatus,
  } = props;

  const { id } = useParams();
  const [dashboardStatus, setDashboardStatus] = useState('');
  const navigate = useNavigate();

  const onboardingStatusChange = (item) => {
    const newFormData = new FormData();
    newFormData.append('status_uuid', item.uuid || '');
    ApiClient.createPatientStatus(newFormData, id)
      .then((res) => {
        getPatientData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const showNoShowOnchange = (status) => {
    if (status.id == 'show') {
      navigate(`/patient/${patientIdConsult}/appointment/${consultId}/edit`, {
        state: {
          check_in: 'dashboard',
        },
      });
    } else {
      setDashboardStatus(status.title);
      const params = {
        id: consultId,
        consult_type: consultationItem?.consult_type,
        title: consultationItem?.title,
        consult_datetime: consultationItem?.consult_datetime,
        consultant_id: consultationItem?.consultant?.id,
        location_id: consultationItem?.location?.id,
        patient_attendance_status: status?.id,
        payment_type: '',
        payment_mode: '',
        amount: null,
      };

      ApiClient.consultationUpdate(params, patientIdConsult)
        .then((res) => {
          if (originalSelectDate) {
            handleCreateConsultation(selectedDate);
          } else {
            getPatientData();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const showNoShowStatus = (patientStatus) => {
    const newFormData = new FormData();
    newFormData.append('onboarding_status', patientStatus.id || '');
    ApiClient.updateOnnboardingStatus(id || item.id, newFormData)
      .then((res) => {
        getPatientData();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const renderStatus = () => {
    return (
      <div className={`d-block d-flex status-change justify-content-between ${!order && 'pe-4'}`}>
        {!updateStatus && !tagPencil && (
          <span
            className={`d-flex align-items-center  badge bg-info capitalize ${
              badgeTitle?.toLowerCase() || defaultClass
            } `}
          >
            {titlizeString(badgeTitle) || defaultStatus}
            {tagPencil && (
              <CommonToolTip
                placement={'top'}
                item={
                  !updateStatus && (
                    <img
                      className='ms-1 pointer'
                      width='16px'
                      onClick={() => setUpdateStatus(item.id)}
                      src={editTheme}
                    />
                  )
                }
                showValue={'Change Status'}
              />
            )}
          </span>
        )}
        {patientUpdate && (
          <CommonToolTip
            placement={'top'}
            item={!updateStatus && <img className=' pointer' onClick={() => setUpdateStatus(true)} src={editTheme} />}
            showValue={'Change Status'}
          />
        )}
        {updateStatus && (
          <Dropdown className='capitalize pointer ' title={badgeTitle || defaultStatus}>
            {status?.map((item) => {
              return (
                <Dropdown.Item
                  onClick={(data) => {
                    const windowConfirm = window.confirm('Are you sure you want to change status?');
                    if (windowConfirm) {
                      onboardingStatus ? onboardingStatusChange(item) : showNoShowStatus(item);
                    }
                    setUpdateStatus(false);
                  }}
                >
                  {item.title}
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        )}
        {tagPencil && !updateStatus && (
          <Dropdown
            className={`capitalize pointer patient-list-show-badge ${item?.onboarding_status}`}
            title={<span className='capitalize'>{badgeTitle || defaultStatus}</span>}
          >
            {status?.map((item) => {
              return (
                <Dropdown.Item
                  onClick={() => {
                    const windowConfirm = window.confirm('Are you sure you want to change status?');
                    if (windowConfirm) {
                      if (dashBoardStatus) {
                        showNoShowOnchange(item);
                      } else {
                        onboardingStatus ? onboardingStatusChange(item) : showNoShowStatus(item);
                      }
                    }
                    setUpdateStatus(false);
                  }}
                >
                  {item.title}
                </Dropdown.Item>
              );
            })}
          </Dropdown>
        )}
      </div>
    );
  };
  return <div className='renderStatusbtn'>{renderStatus()}</div>;
}

export default StatusCard;
