import React, { useCallback, useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { dateFormatString, prepareGraphData, subtractMonths } from 'whealth-core-web/components/Helper';
import DayWeekMonthView from '../DayWeekMonthView';

function PrescriptionGraph(props) {
  const { data, customDate, gatDate } = props;
  const [graphData, setGraphData] = useState([]);

  useEffect(() => {
    if (data?.graph_stats?.plot_date?.length > 0) {
      changeDayData(prepareGraphData(data?.graph_stats?.plot_date));
    }
  }, [data]);

  const [duration, setDuration] = useState({
    title: '6M',
    date: dateFormatString(new Date(subtractMonths(5, new Date())).setDate(1)),
  });

  useEffect(() => {
    gatDate(duration.date);
  }, [duration]);

  const options = {
    curveType: 'function',
    width: '100%',
    legend: 'none',
    tooltip: { isHtml: true },
    hAxis: { maxValue: 30 },
    series: {
      0: { pointSize: 8 },
      1: { pointSize: 4 },
    },
    vAxis: {
      viewWindow: { min: 1 },
      baseline: 1,
      format: '#',
    },
    explorer: { actions: ['dragToZoom', 'rightClickToReset'] },
    trendlines: {
      0: {
        type: 'exponential',
        visibleInLegend: true,
        color: 'red',
      },
    },
  };

  const changeDayData = (graphdata) => {
    if (duration.title == 'D') {
      graphdata?.map((item, index) => {
        if (index != 0) {
          if (parseFloat(item[0]) % 4 != 0) {
            item[0] = '';
          }
        }
      });
    }
    setGraphData([...graphdata]);
  };

  const renderGraphData = useCallback(() => {
    return <Chart selectionMode='multiple' chartType='LineChart' isStacke data={graphData} options={options} />;
  }, [graphData, duration, data]);

  return (
    <div className='card mb-4'>
      <div className='card-body'>
        <div className='d-flex'>
          <div className='flex-grow-1'>
            <div className='graph-card-title energy capitalize d-flex align-items-center'>{data?.medicine?.name}</div>
          </div>
        </div>

        <div className='d-flex'>{renderGraphData()}</div>
        <div className='d-flex justify-content-center graph-btn-group durationGroupBtn'>
          {!customDate && <DayWeekMonthView setActive={setDuration} active={duration} />}
        </div>
      </div>
    </div>
  );
}

export default PrescriptionGraph;
